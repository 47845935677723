// === Import: NPM
import { Box, Button, Card, Grid, Skeleton, Typography } from "@mui/material";
import React from "react";
// === Import: LOCAL
import { UserSituation } from "../../../../../resources/PermissionConstant";
import PermissionsCheck from "../../../../generics/PermissionsCheck";

export default function VeterinaryProfileSkeleton() {
    return (
        <Grid container p={2} spacing={5}>
            <PermissionsCheck requiredPermissions={[UserSituation.REGISTERED_IN_PRACTICE]}>
                <Grid item xs={12}>
                    <Box width="100%" justifyContent="flex-end" display="flex">
                        <Button variant="contained" disabled>
                            Modifier mes données
                        </Button>
                    </Box>
                </Grid>
            </PermissionsCheck>
            <Grid item xs={12} lg={6}>
                <Typography sx={{ mb: 3 }} variant="h5">
                    Informations personnelles
                </Typography>
                <Card>
                    <Skeleton variant="rectangular" animation="wave" height={300} />
                </Card>
            </Grid>
            <Grid item xs={12} lg={6}>
                <Typography sx={{ mb: 3 }} variant="h5">
                    Situation ordinale
                </Typography>
                <Card>
                    <Skeleton variant="rectangular" animation="wave" height={300} />
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Typography sx={{ mb: 3 }} variant="h5">
                    Domiciles professionnels d'exercice
                </Typography>
                <Card sx={{ width: 400 }}>
                    <Skeleton variant="rectangular" animation="wave" height={200} width={400} />
                </Card>
            </Grid>
        </Grid>
    );
}
