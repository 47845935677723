// === NPM
import React, { ChangeEvent, FormEvent, RefObject, useEffect, useRef, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { Add, KeyboardArrowDown } from "@mui/icons-material";
import { Autocomplete, Box, Card, Grid, Stack, TextField, Typography } from "@mui/material";
import { useForm } from "../../../../../../hooks/useForm";
import useTimeout from "../../../../../../hooks/useTimeout";
import { HttpStatus } from "../../../../../../interfaces/global";
import { VaccineBatch } from "../../../../../../interfaces/vaccination";
import { defaultIntegerLimit, maxIntegerValue } from "../../../../../../resources/AppConstant";
import { FORM_TEXT, PATTERN } from "../../../../../../resources/FormUtils";
import { convertEnumToKeyLabelObject, getEnumKeyByValue, isArrayTruthy } from "../../../../../../resources/utils";
import VaccinationService from "../../../../../../services/VaccinationService";
import GenericButton from "../../../../../generics/buttons/GenericButton";
import GenericComment from "../../../../../generics/inputs/GenericComment";
import GenericSelect from "../../../../../generics/inputs/GenericSelect";
import GenericTextField from "../../../../../generics/inputs/GenericTextField";
import { StyledCardContent } from "../../../../../styled/StyledCardContent";
import {
    InjectionType,
    InterventionOutletContext,
    IVaccinationInterventionAnimalInformation,
    VaccinationInterventionType,
} from "../../../interface";
// === LOCAL
import { useFormIntervention } from "../useFormIntervention";
import AnimalBatch from "./containers/AnimalBatch";

interface AnimalInformationsProps {
    formRef: RefObject<HTMLButtonElement>;
    onValid: () => void;
    vaccineBatches: { uuid: string; number: string }[];
    setVaccineBatches: (vaccineBatches: { uuid: string; number: string }[]) => void;
}

export default function AnimalInformations({
    formRef,
    onValid,
    vaccineBatches,
    setVaccineBatches,
}: AnimalInformationsProps) {
    const { vaccines, species, subSpecies, sectors, tiers } = useOutletContext<InterventionOutletContext>();
    const { form, setForm } = useFormIntervention();
    const { animalBatches } = form.animalInformation;
    const isBreeding =
        form.type === getEnumKeyByValue(VaccinationInterventionType, VaccinationInterventionType.BREEDING);

    const { data, errors, handleSubmit, handleChange, cleanValue } = useForm<IVaccinationInterventionAnimalInformation>(
        {
            initialValues: form.animalInformation,
            validations: {
                gtinCode: {
                    required: {
                        value: true,
                        message: FORM_TEXT.required,
                    },
                },
                injectionType: {
                    required: {
                        value: true,
                        message: FORM_TEXT.required,
                    },
                },
                batchUuids: {
                    required: {
                        value: true,
                        message: FORM_TEXT.required,
                    },
                    custom: {
                        isValid: (value) => value.length > 0,
                        message: "Au moins un lot doit être renseigné",
                    },
                },
                animalCount: {
                    required: {
                        value: true,
                        message: FORM_TEXT.required,
                    },
                    pattern: {
                        value: PATTERN.positiveNumber,
                        message: "Le nombre d'animaux doit être un nombre positif entier",
                    },
                    custom: {
                        isValid: (value) => +value > 0,
                        message: "Le nombre d'animaux doit être strictement supérieur à 0",
                    },
                    limitValue: {
                        isValid: (value) => +value <= maxIntegerValue,
                        message: FORM_TEXT.maxInteger,
                    },
                },
                doseCount: {
                    required: {
                        value: true,
                        message: FORM_TEXT.required,
                    },
                    pattern: {
                        value: PATTERN.positiveNumber,
                        message: "Le nombre de doses administrées doit être un nombre positif entier",
                    },
                    limitValue: {
                        isValid: (value) => +value <= maxIntegerValue,
                        message: FORM_TEXT.maxInteger,
                    },
                },
                prescribedDoseCount: {
                    required: {
                        value: true,
                        message: FORM_TEXT.required,
                    },
                    pattern: {
                        value: PATTERN.positiveNumber,
                        message: "Le nombre de flacons prescrits doit être un nombre positif entier",
                    },
                    limitValue: {
                        isValid: (value) => +value <= maxIntegerValue,
                        message: FORM_TEXT.maxInteger,
                    },
                },
                speciesUuid: {
                    required: {
                        value: true,
                        message: FORM_TEXT.required,
                    },
                },
                subSpeciesUuid: {
                    custom: {
                        isValid: () =>
                            (currentSubSpecies.length > 0 && data.subSpeciesUuid) || currentSubSpecies.length === 0,
                        message: "La sous-espèce est obligatoire.",
                    },
                },
                sector: {
                    required: {
                        value: true,
                        message: "La filière est obligatoire.",
                    },
                },
                tier: {
                    required: {
                        value: true,
                        message: "L'étage est obligatoire.",
                    },
                },
                comment: {
                    custom: {
                        isValid: (value) => !(value.trim().length === 0 && value.length > 0),
                        message: FORM_TEXT.emptyString,
                    },
                },
            },
            onSubmit: () => {
                setIsValid(true);
            },
        }
    );
    const animalBatchesRef = useRef<Record<number, HTMLButtonElement>>({});
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isValidBatches, setIsValidBatches] = useState<boolean[]>([]);
    const [vaccineBatchSearch, setVaccineBatchSearch] = useState<string>("");
    const [selectedVaccineBatches, setSelectedVaccineBatches] = useState<VaccineBatch[]>([]);

    useTimeout(() => getVaccineBatches(), [vaccineBatchSearch]);

    useEffect(() => {
        if (form.animalInformation.batchUuids.length >= 1) {
            getVaccineBatches(form.animalInformation.batchUuids);
        }
    }, []);

    useEffect(() => {
        setForm((prev) => ({
            ...prev,
            animalInformation: {
                ...prev.animalInformation,
                ...data,
                animalBatches: prev.animalInformation.animalBatches,
            },
        }));
    }, [data]);

    useEffect(() => {
        if (isBreeding && animalBatches.length === 0) {
            handleAddAnimalBatch();
        }
    }, []);

    useEffect(() => {
        setIsValidBatches(Array(form.animalInformation.animalBatches.length).fill(false));
    }, [form.animalInformation.animalBatches.length]);

    useEffect(() => {
        if (((isValidBatches.length > 0 && isArrayTruthy(isValidBatches)) || isValidBatches.length === 0) && isValid) {
            onValid();
        }
    }, [isValidBatches, isValid]);

    const getVaccineBatches = async (uuids?: string[]) => {
        const payload = {
            page: 0,
            size: 50,
            number: vaccineBatchSearch ? vaccineBatchSearch : undefined,
            uuid: uuids || null,
        };
        const res = await VaccinationService.getVaccineBatches(data.gtinCode, payload);
        if (res.status === HttpStatus.OK) {
            setVaccineBatches(res.data);
            if (uuids) {
                setSelectedVaccineBatches(res.data);
            }
        }
    };

    const handleAddAnimalBatch = () => {
        setForm((prev) => ({
            ...prev,
            animalInformation: {
                ...prev.animalInformation,
                animalBatches: [
                    ...prev.animalInformation.animalBatches,
                    {
                        uuid: crypto.randomUUID(),
                        imepNumber: "",
                        previousInterventionId: "",
                        declaredWorkshopId: form.generalInformation.workshopId,
                        hatchingDate: "",
                        installationDate: "",
                    },
                ],
            },
        }));
    };

    const currentSubSpecies =
        data.speciesUuid && subSpecies.filter((subSpecies) => subSpecies.parentUuid === data.speciesUuid);
    const hasSubSpecies = currentSubSpecies.length > 0;

    const handleValidate = (e: FormEvent<HTMLFormElement>) => {
        setIsValidBatches((prev) => prev.map(() => false));
        Object.values(animalBatchesRef.current).forEach((batch) => batch?.click());
        setIsValid(false);
        handleSubmit(e);
    };

    const handleVaccineBatchSelect = (event: React.SyntheticEvent, newValue: VaccineBatch[]) => {
        const target = {
            target: { value: newValue.map((batch) => batch.uuid) } as unknown as HTMLInputElement,
        } as ChangeEvent<HTMLInputElement & HTMLSelectElement>;
        handleChange("batchUuids")(target);
        setSelectedVaccineBatches(newValue);
    };

    const renderVaccineInfo = () => (
        <Card sx={{ p: 2 }}>
            <StyledCardContent>
                <Grid container spacing={2}>
                    <Grid item md={6} lg={4} xs={12}>
                        <GenericSelect
                            onChange={(e) => {
                                handleChange("gtinCode")(e as ChangeEvent<HTMLInputElement & HTMLSelectElement>);
                                cleanValue("batchUuids");
                                setVaccineBatches([]);
                                setSelectedVaccineBatches([]);
                            }}
                            options={vaccines
                                .filter((v) => v.useKey === "iahp")
                                .map((v) => ({ gtinCode: v.gtinCode, name: `${v.name} ${v.packaging}` }))}
                            required
                            optionsValue="gtinCode"
                            optionsLabel="name"
                            error={!!errors.gtinCode}
                            helperText={errors.gtinCode}
                            label="Vaccin"
                            value={data.gtinCode}
                        />
                    </Grid>
                    {isBreeding && (
                        <Grid item md={6} lg={4} xs={12}>
                            <GenericSelect
                                onChange={handleChange("injectionType")}
                                options={convertEnumToKeyLabelObject(InjectionType)}
                                required
                                optionsValue="key"
                                error={!!errors.injectionType}
                                helperText={errors.injectionType}
                                label="Injection"
                                value={data.injectionType}
                            />
                        </Grid>
                    )}
                    <Grid item md={6} lg={4} xs={12}>
                        <Autocomplete
                            popupIcon={<KeyboardArrowDown />}
                            multiple
                            options={vaccineBatches}
                            value={selectedVaccineBatches}
                            onChange={handleVaccineBatchSelect}
                            inputValue={vaccineBatchSearch}
                            onInputChange={(event, newInputValue) => {
                                setVaccineBatchSearch(newInputValue);
                            }}
                            getOptionLabel={(option) => option.number}
                            isOptionEqualToValue={(option, value) => {
                                return option.uuid === value.uuid;
                            }}
                            noOptionsText={
                                vaccineBatchSearch
                                    ? "Aucun résultat"
                                    : "Saisissez des caractères pour lancer la recherche"
                            }
                            renderInput={(selected) => (
                                <TextField
                                    variant="outlined"
                                    {...selected}
                                    label="Numéro de lot du vaccin"
                                    required
                                    error={!!errors.batchUuids}
                                    helperText={errors.batchUuids}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item md={6} lg={4} xs={12}>
                        <GenericTextField
                            label="Nombre d'animaux vaccinés"
                            value={data.animalCount || ""}
                            onChange={handleChange("animalCount")}
                            required
                            error={!!errors.animalCount}
                            helperText={errors.animalCount}
                            maxLength={defaultIntegerLimit}
                        />
                    </Grid>
                    <Grid item md={6} lg={4} xs={12}>
                        <GenericTextField
                            label="Nombre de doses administrées"
                            value={data.doseCount || ""}
                            onChange={handleChange("doseCount")}
                            required
                            error={!!errors.doseCount}
                            helperText={errors.doseCount}
                            maxLength={defaultIntegerLimit}
                        />
                    </Grid>
                    <Grid item md={6} lg={4} xs={12}>
                        <GenericTextField
                            label="Nombre de flacons prescrits"
                            value={data.prescribedDoseCount || ""}
                            onChange={handleChange("prescribedDoseCount")}
                            required
                            error={!!errors.prescribedDoseCount}
                            helperText={errors.prescribedDoseCount}
                            maxLength={defaultIntegerLimit}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <GenericComment
                            label="Commentaire"
                            value={data.comment}
                            onChange={handleChange("comment")}
                            rows={4}
                            placeholder="Commentaire facultatif relatif à la vaccination"
                            helperText={errors.comment}
                        />
                    </Grid>
                </Grid>
            </StyledCardContent>
        </Card>
    );

    const renderAnimalInfo = () => (
        <Card sx={{ p: 2 }}>
            <StyledCardContent>
                <Grid container spacing={2}>
                    <Grid item md={6} lg={3} xs={12}>
                        <GenericSelect
                            onChange={handleChange("speciesUuid")}
                            options={species}
                            required
                            optionsValue="uuid"
                            error={!!errors.speciesUuid}
                            helperText={errors.speciesUuid}
                            label="Catégorie d'espèce"
                            value={data.speciesUuid}
                        />
                    </Grid>
                    <Grid item md={6} lg={3} xs={12}>
                        <GenericSelect
                            onChange={handleChange("subSpeciesUuid")}
                            options={subSpecies.filter((subSpecies) => subSpecies.parentUuid === data.speciesUuid)}
                            optionsValue="uuid"
                            disabled={!hasSubSpecies}
                            required={hasSubSpecies}
                            error={!!errors.subSpeciesUuid}
                            helperText={errors.subSpeciesUuid}
                            label="Sous-catégorie d'espèces"
                            value={data.subSpeciesUuid}
                        />
                    </Grid>
                    <Grid item md={6} lg={3} xs={12}>
                        <GenericSelect
                            onChange={handleChange("sector")}
                            options={sectors}
                            required
                            optionsValue="key"
                            error={!!errors.sector}
                            helperText={errors.sector}
                            label="Filière"
                            value={data.sector}
                        />
                    </Grid>
                    <Grid item md={6} lg={3} xs={12}>
                        <GenericSelect
                            onChange={handleChange("tier")}
                            options={tiers}
                            required
                            optionsValue="key"
                            error={!!errors.tier}
                            helperText={errors.tier}
                            label="Etage"
                            value={data.tier}
                        />
                    </Grid>
                </Grid>
            </StyledCardContent>
        </Card>
    );

    return (
        <Stack spacing={3} width="100%">
            <form onSubmit={handleValidate} noValidate>
                <Stack spacing={3} width="100%">
                    <Typography sx={{ width: { xs: "100%", md: "50%" } }} variant="h5">
                        Vaccin
                    </Typography>
                    {renderVaccineInfo()}
                    <Typography sx={{ width: { xs: "100%", md: "50%" } }} variant="h5">
                        Animaux vaccinés
                    </Typography>
                    {renderAnimalInfo()}
                </Stack>
                <button style={{ display: "none" }} type="submit" ref={formRef} />
            </form>
            {isBreeding && (
                <>
                    <Box display="flex" justifyContent="space-between">
                        <Typography sx={{ width: { xs: "100%", md: "50%" } }} variant="h5">
                            Déclaration des lots
                        </Typography>
                        <GenericButton startIcon={<Add />} onClick={handleAddAnimalBatch} label="Ajouter un lot" />
                    </Box>
                    <Stack spacing={2}>
                        {animalBatches.map((a, index) => (
                            <AnimalBatch
                                key={a.uuid}
                                batch={a}
                                index={index}
                                injectionType={data.injectionType}
                                deletable={animalBatches.length > 1}
                                animalBatchesRef={animalBatchesRef}
                                setIsValidBatches={setIsValidBatches}
                            />
                        ))}
                    </Stack>
                </>
            )}
        </Stack>
    );
}
