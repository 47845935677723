// === NPM
import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Tabs } from "@mui/material";
// === LOCAL
import PageContent from "../../generics/layout/PageContent";
import { routerLinks } from "../../../routers/RouterConstant";
import PermissionsTab from "../../../resources/PermissionsTab";
import { UserSituation } from "../../../resources/PermissionConstant";
import { FormHealthReportingProvider } from "./containers/HealthReportingForm/containers/useFormHealthReporting";
import HealthReportingService from "../../../services/HealthReportingService";
import ReferentialService from "../../../services/ReferentialService";
import { HttpStatus } from "../../../interfaces/global";
import { ISpeciesDetails } from "../../../interfaces/referential";
import { IAnomalyType } from "./interface";

export default function AnimalAbuse() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(true);
    const [anomalyTypes, setAnomalyTypes] = useState<IAnomalyType[]>([]);
    const [species, setSpecies] = useState<ISpeciesDetails[]>([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        Promise.all([getSpecies(), getAnomalyTypes()]).then(() => {
            setLoading(false);
        });
    };

    const getAnomalyTypes = async () => {
        const res = await HealthReportingService.getAnomalyTypes();
        if (res.status === HttpStatus.OK) {
            setAnomalyTypes(res.data);
        }
    };

    const getSpecies = async () => {
        const res = await ReferentialService.getSpecies({ use: ["health_reporting"] });
        if (res.status === HttpStatus.OK) {
            setSpecies(res.data);
        }
    };

    return (
        <>
            <Tabs value={location.pathname} sx={{ ml: 12 }}>
                <PermissionsTab
                    value={routerLinks.healthReporting.animalAbuse.view()}
                    onClick={() => navigate(routerLinks.healthReporting.animalAbuse.view())}
                    label="Mes signalements sanitaires"
                />

                <PermissionsTab
                    value={routerLinks.healthReporting.animalAbuse.form()}
                    onClick={() => navigate(routerLinks.healthReporting.animalAbuse.form())}
                    label="Déclarer un signalement sanitaire"
                    permissions={[UserSituation.REGISTERED_IN_PRACTICE]}
                />
            </Tabs>
            <PageContent>
                <FormHealthReportingProvider>
                    <Outlet context={{ loading, species, anomalyTypes }} />
                </FormHealthReportingProvider>
            </PageContent>
        </>
    );
}
