// === Import: NPM
import { useMemo } from "react";
import { toast } from "react-toastify";
// === Import: LOCAL
import { useAuth } from "./useAuth";
import HttpService from "../services/HttpService";
import { CALYPSO_HEADERS } from "../resources/AppConstant";
import { useNavigate } from "react-router-dom";
import { routerLinks } from "./RouterConstant";
import { DateTime } from "luxon";
import { HttpStatus, Loader } from "../interfaces/global";
import { useProvideGlobal } from "../context/useGlobalContext";

declare module "axios" {
    export interface AxiosRequestConfig {
        loader?: Loader;
    }
}

export default function AxiosInterceptors({ children }) {
    const auth = useAuth();
    const navigate = useNavigate();

    const { setLoadingButton, setLoadingRequest, setLoadingTable } = useProvideGlobal();

    const handleSetLoaders = (config: any, value: boolean) => {
        switch (config?.loader) {
            case Loader.BUTTON:
                setLoadingButton(value);
                return;
            case Loader.REQUEST:
                setLoadingRequest(value);
                return;
            case Loader.TABLE:
                setLoadingTable(value);
        }
    };

    useMemo(() => {
        HttpService.axiosApiInstance.interceptors.request.use((config) => {
            handleSetLoaders(config, true);
            return config;
        });
        HttpService.axiosApiInstance.interceptors.response.use(
            (response) => {
                handleSetLoaders(response?.config, false);
                const token = response.headers[CALYPSO_HEADERS.TOKEN];
                if (token) {
                    auth.updateToken(token);
                }
                return response;
            },
            async (error) => {
                handleSetLoaders(error?.config, false);
                let response;
                const isBlob = error.request.responseType === "blob";
                if (isBlob) {
                    response = JSON.parse(await error.response.data.text());
                } else {
                    response = error.response;
                }
                if (!auth.logged) {
                    return Promise.reject(error.response);
                }
                const customClientHeader = response?.headers?.[CALYPSO_HEADERS.CLIENT_HEADER];
                if (response.status === HttpStatus.UNAUTHORIZED) {
                    auth.logout();
                } else if (response.status === HttpStatus.FORBIDDEN) {
                    navigate(routerLinks.home);
                } else if (
                    !customClientHeader ||
                    (customClientHeader && !JSON.parse(customClientHeader).noToasterOnError)
                ) {
                    toast.info(isBlob ? response.message : response.data.message, {
                        toastId: DateTime.now().toString(),
                    });
                }
                return Promise.reject(response);
            }
        );
    }, []);

    return children;
}
