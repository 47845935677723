// === Import: NPM
import React, { ElementType } from "react";
import { Typography } from "@mui/material";
// === Import: LOCAL

interface GenericTextProps {
    value: string | number;
    bold?: boolean;
    margin?: boolean;
    component?: ElementType;
}
export function GenericText({ value, bold = false, margin = false, component = "span" }: GenericTextProps) {
    return (
        <Typography
            component={component}
            fontWeight={bold ? "600" : ""}
            sx={{ my: margin ? 1 : 0, fontSize: component === "li" ? "0.8rem" : "standard", wordBreak: "break-all" }}
        >
            {value}
        </Typography>
    );
}
