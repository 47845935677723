import { IType, IBaseUser } from "../../../../interfaces/user";

export interface ICreateUserRenderConfig {
    type: "select" | "input";
    name: string;
    label: string;
    options: {
        id: number;
        name: string;
    }[];
    required: boolean;
}

export type AdministrationUserOutletContext = {
    userTypesReferential: IType[];
};

export interface ICreateFamDamUser extends IBaseUser {
    agencyId: string;
}

export interface ICreateMaUser extends IBaseUser {
    type: MAType;
    entityInseeCode: string;
}

export type MAType = "DDPP" | "DRAAF" | "DGAL";

export interface IUserFilters {
    id: string;
    firstname: string;
    lastname: string;
    email: string;
    situation: string[];
}

export enum MaUnit {
    DDPP = "DDPP",
    DRAAF = "DRAAF",
    DGAL = "DGAL",
}
