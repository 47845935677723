import { GridRenderCellParams, GridValueFormatterParams } from "@mui/x-data-grid-pro";
import { DateTime } from "luxon";
import { FilterConfigurations } from "../components/generics/filters/GenericFilters";
import { FilterType } from "./global";
import { IReferential } from "./referential";

export enum TraceabilityActionType {
    MonitoringIntervention = "MonitoringIntervention",
    Scrapping = "Scrapping",
    DrugDelivery = "DrugDelivery",
    Agency = "Agency",
    Organization = "Organization",
    User = "User",
    VaccinationIntervention = "VaccinationIntervention",
    HealthReporting = "HealthReporting",
}

export interface ITraceability {
    uuid: string;
    authorId: string;
    date: string;
    action: string;
}

export const InfoColumnsConfig = [
    {
        field: "date",
        headerName: "Date",
        flex: 1,
        type: "date",
        valueGetter: (params: GridRenderCellParams) =>
            DateTime.fromISO(params.row.date).toLocaleString(DateTime.DATETIME_SHORT),
    },
    {
        field: "authorId",
        headerName: "Utilisateur",
        flex: 1,
    },
];

export const ActionColumnConfig = (traceabilityActions: IReferential[]) => {
    return {
        field: "action",
        headerName: "Action",
        width: 200,
        valueFormatter: (params: GridValueFormatterParams) => {
            return traceabilityActions.find((action) => action.key === params.value)?.label;
        },
    };
};

export interface IDefaultTraceabilityFilters {
    date: string[];
    authorId: string;
    action: string[];
}

export const TraceabilityBaseFilters = (
    traceabilityActions: IReferential[]
): FilterConfigurations<IDefaultTraceabilityFilters> => {
    return {
        date: { label: "Date", type: FilterType.DATEPICKER },
        authorId: { label: "Utilisateur", type: FilterType.INPUT },
        action: {
            label: "Action",
            type: FilterType.SELECT,
            values: traceabilityActions.map((action) => ({ key: action.key, label: action.label })),
        },
    };
};
