// === NPM
import React from "react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { TextField } from "@mui/material";
// === LOCAL
import { stringRequired } from "../../../../../../../resources/FormUtils";
import GenericActionsDialog from "../../../../../../generics/dialogs/GenericActionsDialog";
import GenericDialog from "../../../../../../generics/dialogs/GenericDialog";
import GenericCard from "../../../../../../generics/layout/GenericCard";
import { ICategory } from "../../../../../../../interfaces/faq";

interface CategoryDialogProps {
    onClose: () => void;
    onValid: (category: ICategory) => void;
    currentCategory?: ICategory;
    title: string;
}

const initialValues = {
    title: null,
};

const formSchema = z.object({
    title: stringRequired(),
});

type ValidationSchema = z.infer<typeof formSchema>;

export default function CategoryDialog({ onClose, onValid, title, currentCategory }: CategoryDialogProps) {
    const {
        formState: { errors },
        handleSubmit,
        register,
    } = useForm<ValidationSchema>({
        resolver: zodResolver(formSchema),
        defaultValues: currentCategory ?? initialValues,
    });

    return (
        <GenericDialog
            title={title}
            onClose={onClose}
            paperProps={{
                sx: { width: "100%" },
            }}
            renderActions={() => <GenericActionsDialog onClose={onClose} onSubmit={handleSubmit(onValid)} />}
            renderContent={() => (
                <GenericCard>
                    <TextField
                        {...register("title")}
                        label="Titre"
                        required
                        fullWidth
                        error={!!errors?.title}
                        helperText={errors?.title?.message}
                    />
                </GenericCard>
            )}
        />
    );
}
