// === Import: NPM
import React, { useState, useContext, useEffect } from "react";
// LOCAL
import { IDepartment } from "../interfaces/referential";
import { HttpStatus } from "../interfaces/global";
import ReferentialService from "../services/ReferentialService";

export interface UseDepartmentContext {
    departments: IDepartment[];
}

export const DepartmentContext = React.createContext<UseDepartmentContext>({
    departments: [],
});

export const useDepartments = (): UseDepartmentContext => {
    return useContext(DepartmentContext);
};

export const DepartmentsProvider = ({ children }: React.PropsWithChildren) => {
    const departments = ProvideDepartments();
    return <DepartmentContext.Provider value={departments}>{children}</DepartmentContext.Provider>;
};

// IMPROVEMENT : Transform this into a localization provider w/ regions (& more ?)

const ProvideDepartments = () => {
    const [departments, setDepartments] = useState<IDepartment[]>([]);

    useEffect(() => {
        getDepartments();
    }, []);

    const getDepartments = async () => {
        const res = await ReferentialService.getDepartments();
        if (res.status === HttpStatus.OK) {
            setDepartments(res.data);
        }
    };

    return {
        departments,
    };
};
