// === NPM
import React, { useEffect, useRef, useState } from "react";
import { DragIndicator, ExpandMore } from "@mui/icons-material";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Badge,
    Box,
    CircularProgress,
    Divider,
    Stack,
    Typography,
} from "@mui/material";
import { ConnectableElement, useDrag, useDrop } from "react-dnd";
import { useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
// === LOCAL
import { IQuestion, IQuestionShort, UserTypeHeader } from "../../../../../../../interfaces/faq";
import { colors, faqColors } from "../../../../../../../resources/CssConstant";
import DeleteAction from "../../../../../../generics/actions/DeleteAction";
import { HttpStatus } from "../../../../../../../interfaces/global";
import FaqService from "../../../../../../../services/FaqService";
import StyledLinearProgress from "../../../../../../styled/StyledLinearProgress";
import ChipTag from "../../../../../../generics/ChipTag";
import GenericConfirmDialog from "../../../../../../generics/dialogs/GenericConfirmDialog";
import QuestionDialog from "./QuestionDialog";
import { ReactComponent as Edit } from "../../../../../../../assets/icons/actions/edit.svg";
import IconActionButton from "../../../../../../generics/actions/IconActionButton";
import HtmlBox from "../../../../../../generics/layout/HtmlBox";
import { IQuestionDraft, FaqOutletContext, FaqDropableType } from "../../../interface";
import { useProvideGlobal } from "../../../../../../../context/useGlobalContext";

interface QuestionCardProps {
    question: IQuestionShort;
    findQuestion: (uuid: string) => { index: number };
    moveQuestion: (uuid: string, to: number, categoryUuid: string) => void;
    index: number;
}

export default function QuestionCard({ question, findQuestion, moveQuestion, index }: QuestionCardProps) {
    const { categories, getQuestions, tags } = useOutletContext<FaqOutletContext>();
    const { loadingButton, loadingTable } = useProvideGlobal();

    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: FaqDropableType.QUESTION,
            item: question,
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
            end: (item, monitor) => {
                const didDrop = monitor.didDrop();
                if (!didDrop) {
                    moveQuestion(item.uuid, index, question.categoryUuid);
                }
            },
        }),
        [question, moveQuestion, categories]
    );
    const [, drop] = useDrop(
        () => ({
            accept: FaqDropableType.QUESTION,
            hover(draggedQuestion: IQuestionShort) {
                if (draggedQuestion.uuid !== question.uuid) {
                    const { index: overIndex } = findQuestion(question.uuid);
                    moveQuestion(draggedQuestion.uuid, overIndex, question.categoryUuid);
                }
            },
            drop: () => {
                handleQuestionOrder();
            },
        }),
        [findQuestion, moveQuestion]
    );

    const ref = useRef<null | HTMLDivElement>(null);
    const [expanded, setExpanded] = useState<boolean>(false);
    const [questionDetails, setQuestionDetails] = useState<IQuestion>(null);
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
    const [openUpdateDialog, setOpenUpdateDialog] = useState<boolean>(false);
    const [draft, setDraft] = useState<IQuestionDraft>(null);

    useEffect(() => {
        if (expanded) getDetails();
    }, [expanded]);

    const getDetails = async () => {
        const data = await FaqService.getQuestionDetailsCached(question.uuid, UserTypeHeader.ADMIN);
        if (data) {
            setQuestionDetails(data);
            executeScroll();
        }
    };

    const executeScroll = () => {
        if (ref.current) {
            ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    };

    const handleQuestionOrder = async () => {
        const { index: newPosition } = findQuestion(question.uuid);
        const categoryIndex = categories.findIndex((cat) => cat.uuid === question.categoryUuid);
        const previousQuestionCount = categories.slice(0, categoryIndex).reduce((a, cat) => a + cat.questionCount, 0);
        const questionPosition = newPosition - previousQuestionCount;

        const res = await FaqService.patchQuestionPosition(question.uuid, {
            position: questionPosition,
            categoryUuid: question.categoryUuid,
        });
        if (res.status === HttpStatus.NO_CONTENT) {
            toast.success("Questions réordonnées avec succès");
        } else {
            getQuestions();
        }
    };

    const handleUpdateQuestion = async (question: IQuestion) => {
        const res = await FaqService.putQuestion(question);
        if (res.status === HttpStatus.OK) {
            await getQuestions();
            setOpenUpdateDialog(false);
            toast.success("Question modifiée avec succès");
            setQuestionDetails(res.data);
            FaqService.replaceQuestionDetailsCached(res.data);
        }
    };

    const getDialogContent = async () => {
        if (question.hasDraft) {
            await getDraft();
        } else if (!questionDetails) {
            await getDetails();
        }
        setOpenUpdateDialog(true);
    };

    const getDraft = async () => {
        const res = await FaqService.getQuestionDraft(question.uuid);
        if (res.status === HttpStatus.OK) {
            setDraft(res.data);
        }
    };

    const handleDeleteQuestion = async (confirm: boolean) => {
        if (!confirm) {
            setOpenDeleteDialog(false);
            return;
        }
        const res = await FaqService.deleteQuestion(question.uuid);
        if (res.status === HttpStatus.NO_CONTENT) {
            await getQuestions();
            setOpenDeleteDialog(false);
            toast.success("Question supprimée avec succès");
        }
    };

    const opacity = isDragging ? 0 : 1;
    return (
        <>
            <Accordion
                disableGutters
                sx={{
                    "&:before": {
                        display: "none",
                    },
                    "backgroundColor": question.published ? colors.white : faqColors.unpublish,
                    "borderRadius": 8,
                    opacity,
                }}
                expanded={expanded}
                ref={ref}
                onChange={() => setExpanded(!expanded)}
            >
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    sx={{
                        ".MuiAccordionSummary-content": {
                            justifyContent: "space-between",
                        },
                        "color": colors.primaryColor,
                        "cursor": "move",
                    }}
                    ref={(node) => drag(drop(node as ConnectableElement))}
                >
                    <Box display="flex" alignItems="center" sx={{ gap: 1 }}>
                        <DragIndicator style={{ cursor: "move" }} />
                        <Typography variant="h5" color="primary">
                            {question?.title}
                        </Typography>
                    </Box>
                    <Box>
                        <Badge overlap="circular" variant="dot" color="info" invisible={!question?.hasDraft}>
                            <IconActionButton
                                icon={loadingButton ? <CircularProgress size={20} /> : <Edit />}
                                title="Modifier la question"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    getDialogContent();
                                }}
                            />
                        </Badge>
                        <DeleteAction
                            title="Supprimer la question"
                            onClick={(e) => {
                                e.stopPropagation();
                                setOpenDeleteDialog(true);
                            }}
                        />
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <Divider />
                    {questionDetails ? (
                        <>
                            <HtmlBox content={questionDetails.content} />
                            <Stack direction="row" spacing={2}>
                                {questionDetails.tagUuids.map((uuid) => (
                                    <ChipTag key={uuid} tag={tags.find((tag) => tag.uuid === uuid)} />
                                ))}
                            </Stack>
                        </>
                    ) : (
                        <StyledLinearProgress />
                    )}
                </AccordionDetails>
            </Accordion>
            {openDeleteDialog && (
                <GenericConfirmDialog
                    title="Suppression d'une question"
                    message={`Êtes-vous sûr de vouloir supprimer la question ${question?.title} ?`}
                    onClose={handleDeleteQuestion}
                    loading={loadingTable}
                />
            )}
            {openUpdateDialog && (
                <QuestionDialog
                    onClose={() => setOpenUpdateDialog(false)}
                    onValid={handleUpdateQuestion}
                    title={"Editer une question"}
                    currentQuestion={question.hasDraft ? { ...question, ...draft } : questionDetails}
                />
            )}
        </>
    );
}
