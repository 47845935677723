// === NPM
import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Tabs } from "@mui/material";
import { HttpStatus } from "../../../interfaces/global";
import { ISpeciesDetails } from "../../../interfaces/referential";
import { Preference, UserSituation } from "../../../resources/PermissionConstant";
import PermissionsTab from "../../../resources/PermissionsTab";
// === LOCAL
import { routerLinks } from "../../../routers/RouterConstant";
import ReferentialService from "../../../services/ReferentialService";
import PageContent from "../../generics/layout/PageContent";

export default function Farm() {
    const navigate = useNavigate();
    const location = useLocation();

    const [species, setSpecies] = useState<ISpeciesDetails[]>([]);
    useEffect(() => {
        getSpecies();
    }, []);

    const getSpecies = async () => {
        const res = await ReferentialService.getSpecies({ use: ["iahp"] });
        if (res.status === HttpStatus.OK) {
            setSpecies(res.data);
        }
    };
    return (
        <>
            <Tabs value={location.pathname} sx={{ ml: 12 }}>
                <PermissionsTab
                    value={routerLinks.iahp.farm.view()}
                    onClick={() => navigate(routerLinks.iahp.farm.view())}
                    label="Mes élevages"
                />
                <PermissionsTab
                    value={routerLinks.iahp.farm.declareRelation()}
                    onClick={() => navigate(routerLinks.iahp.farm.declareRelation())}
                    label="Déclarer une relation VS"
                    permissions={[UserSituation.REGISTERED_IN_PRACTICE]}
                    preferences={[Preference.IAHP]}
                />
            </Tabs>
            <PageContent>
                <Outlet context={{ species }} />
            </PageContent>
        </>
    );
}
