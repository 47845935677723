// === NPM
import { Box } from "@mui/material";
import React, { useState } from "react";
// === LOCAL
import EditAction from "../../../../generics/actions/EditAction";
import { CalypsoEmailInput } from "../../../../generics/CalypsoEmailInput";
import GenericTitleValueText from "../../../../generics/text/GenericTitleValueText";

interface DpeMailProps {
    calypsoEmail: string;
    onConfirm: (email: string) => void;
}

export default function PharmacistMail({ calypsoEmail, onConfirm }: DpeMailProps) {
    const [editMail, setEditMail] = useState<boolean>(false);

    return (
        <Box display="flex" alignItems="center">
            {editMail ? (
                <CalypsoEmailInput calypsoEmail={calypsoEmail} setEditMail={setEditMail} onConfirm={onConfirm} />
            ) : (
                <>
                    <GenericTitleValueText title="Email" value={calypsoEmail} />
                    <EditAction title="Editer l'adresse email" onClick={() => setEditMail(true)} />
                </>
            )}
        </Box>
    );
}
