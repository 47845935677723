// === NPM
import React from "react";
import { Box, Stack } from "@mui/material";

// === LOCAL
import { colors } from "../../../resources/CssConstant";
import PowerBiBox from "../../generics/layout/PowerBiBox";

export default function IahpPowerBI() {
    return (
        <Stack height="100%" width="100%">
            <Box
                display="flex"
                sx={{
                    flexGrow: 1,
                    mx: 5,
                    mb: 4,
                    pt: 5,
                    px: 5,
                    backgroundColor: colors.background,
                    borderRadius: 5,
                }}
            >
                <PowerBiBox>
                    <iframe
                        title="suivi_vaccination"
                        width="100%"
                        height="100%"
                        src={window._env_.REACT_APP_PBI_IAHP}
                    />
                </PowerBiBox>
            </Box>
        </Stack>
    );
}
