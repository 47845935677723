// === NPM
import React from "react";
import { Card } from "@mui/material";
import { DateTime } from "luxon";
// === LOCAL
import GenericDialog from "../../../../../../generics/dialogs/GenericDialog";
import { StyledCardContent } from "../../../../../../styled/StyledCardContent";
import GenericDateTimePicker from "../../../../../../generics/inputs/GenericDateTimePicker";
import { useForm } from "../../../../../../../hooks/useForm";
import { FORM_TEXT } from "../../../../../../../resources/FormUtils";
import { useSelectedNotification } from "../../useSelectedNotification";
import { IPublishedNotificationPatch } from "../../../interface";
import GenericActionsDialog from "../../../../../../generics/dialogs/GenericActionsDialog";

interface UpdatePublishedNotificationDialogProps {
    onValid: (publicationEndDate: string) => void;
    onClose: () => void;
}

export default function UpdatePublishedNotificationDialog({
    onValid,
    onClose,
}: UpdatePublishedNotificationDialogProps) {
    const { selectedNotification } = useSelectedNotification();
    const { data, errors, handleSubmit, handleChange } = useForm<IPublishedNotificationPatch>({
        initialValues: {
            publicationEndDate: selectedNotification.publicationEndDate,
        },
        validations: {
            publicationEndDate: {
                required: {
                    value: true,
                    message: FORM_TEXT.required,
                },
                custom: {
                    isValid: () => DateTime.fromISO(data.publicationEndDate) > DateTime.now(),
                    message: "La date de fin de publication ne peut pas être dans le passé",
                },
            },
        },
        onSubmit: () => {
            onValid(data.publicationEndDate);
        },
    });

    return (
        <GenericDialog
            title={`Modification de la notification n°${selectedNotification.internalId}`}
            onClose={onClose}
            renderContent={() => (
                <Card sx={{ padding: 2 }}>
                    <StyledCardContent>
                        <GenericDateTimePicker
                            value={data.publicationEndDate}
                            name="publicationEndDate"
                            label="Date de fin de publication"
                            onChange={handleChange("publicationEndDate")}
                            minDateTime={DateTime.now()}
                            maxDateTime={DateTime.fromISO(selectedNotification.publicationDate).plus({
                                months: 6,
                            })}
                            required
                            error={!!errors.publicationEndDate}
                            helperText={errors.publicationEndDate}
                        />
                    </StyledCardContent>
                </Card>
            )}
            renderActions={() => (
                <GenericActionsDialog
                    onClose={onClose}
                    onSubmit={handleSubmit}
                    closeLabel="Fermer"
                    validLabel="Enregistrer"
                />
            )}
        />
    );
}
