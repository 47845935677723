// === NPM
import React, { useMemo, useState } from "react";
import { Box, Fab, Stack, Typography } from "@mui/material";
import { DateTime } from "luxon";
// === LOCAL
import { ReactComponent as Pingouin } from "../../../assets/icons/pingouin.svg";
import Tour from "../../generics/Tour";
import { ITourStep } from "../../../interfaces/global";
import { ReactComponent as Settings } from "../../../assets/icons/navbar/setting.svg";
import { KeyboardArrowDown, LinkRounded, QuestionMark } from "@mui/icons-material";
import { colors } from "../../../resources/CssConstant";
import { useLocation } from "react-router-dom";
import { routerLinks } from "../../../routers/RouterConstant";
import { useAuth } from "../../../routers/useAuth";
import { TourKey } from "../../../interfaces/user";
import { ReactComponent as Chronometre } from "../../../assets/icons/training/chronometre.svg";
import { ReactComponent as Graphic } from "../../../assets/icons/training/graphique.svg";
import { ReactComponent as Cup } from "../../../assets/icons/training/coupe.svg";
import { ReactComponent as One } from "../../../assets/icons/training/one.svg";
import { ReactComponent as Two } from "../../../assets/icons/training/two.svg";
import { ReactComponent as Show } from "../../../assets/icons/shared/show.svg";
import { ReactComponent as Download } from "../../../assets/icons/actions/download.svg";
import { ReactComponent as Edit } from "../../../assets/icons/actions/edit.svg";
import { ReactComponent as Delete } from "../../../assets/icons/actions/delete.svg";

import { GenericIconText } from "../../generics/text/GenericIconText";
import { useTour } from "../../generics/useTour";

interface TrainingTour {
    createTraining: boolean;
    catalogData: boolean;
    catalog: boolean;
}

export default function ContinuousTrainingTour() {
    const { tour } = useTour<TrainingTour>();
    const location = useLocation();
    const auth = useAuth();
    const [config, setSteps] = useState([]);

    useMemo(() => {
        const steps: ITourStep[] = [
            {
                target: "#tabs",
                content: (
                    <Box>
                        <Pingouin style={{ height: 50, width: 50 }} />
                        <Typography>
                            Vous êtes dans le module <span style={{ fontWeight: "bold" }}>Mes formations</span>. <br />
                            Ce module est composé de 2 parties : <br /> • Formations continues. <br /> • Catalogue des
                            formations continues.
                            <br /> Cliquez sur les liens pour entrer dans chaque partie.
                        </Typography>
                    </Box>
                ),
                disableBeacon: true,
            },
            {
                target: "#continuous-training-tab",
                content: `La première partie "Formations continues" contient toutes les données liées aux actions de formations continues que vous
                avez réalisées. \n`,
            },
            {
                target: "#follow-up",
                content: (
                    <Stack spacing={1}>
                        <Typography>
                            Dans la section "Mon suivi", vous retrouvez un résumé de votre situation à l'instant t :
                        </Typography>
                        <GenericIconText
                            iconTooltip=""
                            icon={<Chronometre style={{ height: 30, width: 30 }} />}
                            text="Le temps écoulé depuis votre dernière formation suivie"
                        />

                        <GenericIconText
                            iconTooltip=""
                            icon={<Graphic style={{ height: 30, width: 30 }} />}
                            text="Le nombre de points ECTS cumulés"
                        />
                        <GenericIconText
                            iconTooltip=""
                            icon={<Cup style={{ height: 40, width: 40 }} />}
                            text={` Le nombre de point ECTS que vous devez cumuler sur la période indiquée (
                                ${DateTime.now().minus({ years: 5 }).get("year")}-${DateTime.now().get("year")})`}
                        />
                    </Stack>
                ),
            },
            {
                target: "#continuous-training-table",
                content: (
                    <Stack spacing={1}>
                        <Typography>
                            Dans la section "Mes formations continues", vous retrouvez la liste des formations que vous
                            avez suivies sur les 5 dernières années.
                        </Typography>
                        <Box>
                            <Typography>La liste de ces formations est constituée :</Typography>{" "}
                            <Typography>
                                • Des formations télétransmises à Calypso par les organismes de formation.
                            </Typography>
                            <Typography>
                                • Des formations enregistrées manuellement par vos soins pour celles effectuées dans des
                                organismes n'ayant pas mis en place la télétransmission.
                            </Typography>
                        </Box>
                    </Stack>
                ),
                placement: "bottom",
            },
            {
                target: "#all-training-btn",
                content:
                    "En cliquant sur ce bouton, vous affichez l'intégralité de vos formations enregistrées sur Calypso. Pour revenir à l'affichage initial des formations des 5 dernières années, cliquez à nouveau sur ce bouton.",
                placement: "auto",
            },
            {
                target: "#training-table-content",
                content: (
                    <Box>
                        <img
                            className="tour-img"
                            src="/continuous-training-tour-1.webp"
                            alt="Image pour montrer la différence entre une formation ajoutée manuelle et une ajoutée automatiquement"
                        />
                        <GenericIconText
                            iconTooltip=""
                            icon={<One style={{ height: 20, width: 20 }} />}
                            text="Cette ligne correspond à une formation suivie que vous avez enregistrée manuellement"
                        />
                        <GenericIconText
                            iconTooltip=""
                            icon={<Two style={{ height: 20, width: 20 }} />}
                            text="Cette ligne correspond à une formation suivie qui a été télétransmise par un organisme de
                            formation"
                        />
                    </Box>
                ),
                styles: {
                    tooltip: {
                        minWidth: 1000,
                    },
                },
            },
            {
                target: "#training-table-content",
                content: (
                    <Box>
                        <img
                            className="tour-img"
                            src="/continuous-training-tour-2.webp"
                            alt="Image pour montrer les différentes actions en fonction du type de formation (manuelle ou automatique)"
                        />
                        <Stack spacing={1}>
                            <Typography>
                                Pour toutes les formations, vous pouvez effectuer les actions suivantes :
                            </Typography>
                            <GenericIconText
                                iconTooltip=""
                                icon={<Show style={{ height: 20, width: 20 }} />}
                                text="Voir le détail"
                            />
                            <GenericIconText
                                iconTooltip=""
                                icon={<Download style={{ height: 20, width: 20 }} />}
                                text="Télécharger l'attestation"
                            />
                            <GenericIconText
                                iconTooltip=""
                                icon={<Delete style={{ height: 20, width: 20 }} />}
                                text="Supprimer la formation"
                            />
                            <Typography>
                                Pour les formations que vous avez ajoutées manuellement, vous pouvez effectuer l'action
                                supplémentaire :
                            </Typography>
                            <GenericIconText
                                iconTooltip=""
                                icon={<Edit style={{ height: 20, width: 20 }} />}
                                text="Éditer une formation"
                            />
                        </Stack>
                    </Box>
                ),
                styles: {
                    tooltip: {
                        minWidth: 1000,
                    },
                },
            },
            {
                target: "#create-training-btn",
                content:
                    "Ce bouton permet d'ajouter une formation que vous avez réalisée. Pour poursuivre la visite, cliquez sur le bouton.",
                hideFooter: true,
                spotlightClicks: true,
                condition: () => tour.createTraining,
            },
            {
                target: "#create-training-dialog",
                content: `Dans cette fenêtre, renseignez les informations relatives à la formation que vous souhaitez ajouter. Une fois tous les champs complétés, vous cliquez sur "Ajouter à mes formations"`,
                hideBackButton: true,
            },
            {
                target: "#cancel-dialog-btn",
                content: `Cliquez sur "Annuler" pour poursuivre la visite.`,
                condition: () => !tour.createTraining,
                hideFooter: true,
                spotlightClicks: true,
            },
        ];

        if (auth.userInfo.properties?.dpes?.length > 0) {
            steps.push({
                target: "#continuous-training-dpe-table",
                content: (
                    <Stack spacing={1}>
                        <Typography>
                            Dans cette section, vous pouvez visualiser les formations suivies par les autres membres de
                            vos Domiciles Professionnels d'Exercice (nommés "DPE"), qui ont accepté de partager leurs
                            informations.
                        </Typography>
                        <Typography>
                            Le paramétrage du partage est accessible via le bouton
                            <Settings style={{ position: "relative", top: 5 }} /> vous pouvez visualiser leurs données
                            seulement s'ils ont accepté de partager leurs informations.
                        </Typography>
                        <Typography>
                            Si vous exercez dans plusieurs DPE, le bouton{" "}
                            <KeyboardArrowDown
                                sx={{
                                    backgroundColor: colors.primaryColor,
                                    color: colors.white,
                                    borderRadius: 2,
                                    position: "relative",
                                    top: 5,
                                }}
                            />{" "}
                            permet de sélectionner le DPE qui vous intéresse.
                        </Typography>
                    </Stack>
                ),
                placement: "bottom",
            });
        }
        steps.push(
            {
                target: "#catalog-tab",
                content: (
                    <Stack spacing={1}>
                        <Typography>
                            La seconde partie du module "Mes formations" expose le catalogue des formations continues.
                        </Typography>
                        <Box>
                            <Typography>
                                L'ensemble des organismes de formations agréés par le CNOV peuvent y référencer leurs
                                actions de formation.
                            </Typography>
                            <Typography>
                                Cliquez sur "Catalogue des formations continues" pour y accéder et poursuivre la visite.
                            </Typography>
                        </Box>
                    </Stack>
                ),
                condition: () => tour.catalog !== undefined,
                hideFooter: true,
                spotlightClicks: true,
            },
            {
                target: "#catalog-content",
                content: (
                    <Stack spacing={1}>
                        <Typography>
                            Dans cette section, les formations seront représentées sous forme de cartes. N'hésitez pas à
                            venir voir régulièrement si des formations ont été ajoutées au catalogue.
                        </Typography>
                    </Stack>
                ),

                spotlightClicks: true,
                hideBackButton: true,
                placement: "top-start",
            },
            {
                target: "#catalog-card-0",
                content: (
                    <Stack spacing={1}>
                        <Typography>
                            Pour chaque carte de formation, vous pouvez effectuer les actions suivantes en cliquant sur
                            le bouton dédié :
                        </Typography>
                        <GenericIconText
                            iconTooltip=""
                            icon={<Show style={{ height: 20, width: 20 }} />}
                            text="Voir le détail"
                        />
                        <GenericIconText
                            iconTooltip=""
                            icon={<LinkRounded style={{ height: 20, width: 20, color: colors.primaryColor }} />}
                            text="Être redirigé vers le site de l'organisme de formation pour vous inscrire."
                        />
                    </Stack>
                ),
                placement: "auto",
            },
            {
                target: "body",
                content: (
                    <Box>
                        <Pingouin style={{ height: 50, width: 50 }} />
                        <Typography>
                            À vous de jouer !
                            <br />
                            Vous pouvez relancer à tout moment ce guide d'aide à la navigation en cliquant sur le bouton
                            <Fab disableRipple disableFocusRipple color="primary" sx={{ mx: 1 }} size="small">
                                <QuestionMark sx={{ color: "#FFF" }} />
                            </Fab>{" "}
                        </Typography>
                    </Box>
                ),
                hideBackButton: true,
            }
        );
        setSteps(steps);
    }, [tour]);

    return (
        <Tour
            config={config}
            display={location.pathname === routerLinks.training.continuousTraining()}
            tourKey={TourKey.CONTINUOUS_TRAINING}
        />
    );
}
