import { IReferential } from "./referential";

export enum VaccineStatus {
    ACTIVE = "Actif",
    ARCHIVED = "Archivé",
}

export enum ShippingAddressType {
    ORDER_DPE = "ORDER_DPE",
    SHIPPING_DPE = "SHIPPING_DPE",
    DIFFERENT = "DIFFERENT",
}

export interface IVaccine {
    gtinCode: string;
    name: string;
    packaging: string;
    hatchery: boolean;
    injectionDelay: number;
    useKey: string;
    doseNumber: number;
    creationDate: string;
    updateDate: string;
    deliverer: string;
    status: VaccineStatus;
}

export interface VaccinationOutletContext {
    vaccines: IVaccine[];
    scrappingReasons: IReferential[];
    loading: boolean;
}

export interface IScrappingShort {
    date: string;
    dpeId: string;
    batchUuid: string;
    vaccineGtinCode: string;
    quantity: string;
    reason: string;
}

/// ---- MONITORING INTERVENTIONS

export enum MonitoringInterventionStatus {
    PLANNED = "Planifiée",
    REALIZED = "Réalisée",
    NOT_REALIZED = "Non réalisée",
}

export type VaccineBatch = { number: string; uuid: string };

export interface IMonitoringInterventionForm {
    finalized: boolean;
    generalInformation: IMonitoringInterventionGeneralInformation;
    interventionData: IMonitoringInterventionData;
    workshops: IMonitoringInterventionWorkshop[];
}

export interface IMonitoringInterventionDetails extends Omit<IMonitoringInterventionForm, "generalInformation"> {
    generalInformation: IMonitoringInterventionGeneralInformationDetails;
    certificateFileUuid: string;
}

export interface IMonitoringInterventionGeneralInformation {
    id: string;
    dpeId: string;
    veterinaryId: string;
    interventionDate: string;
    status: keyof typeof MonitoringInterventionStatus | "";
    nonFullfilmentReason: string;
    serologicalSamples: {
        sampleType: string;
        planned: boolean;
        realized: boolean;
    }[];
    plateNumber: string;
    horsePower: string;
    distance: number;
    farmId: string;
}

interface IMonitoringInterventionGeneralInformationDetails extends IMonitoringInterventionGeneralInformation {
    dpeName: string;
    veterinaryFirstname: string;
    veterinaryLastname: string;
    farmName: string;
    farmCity: string;
    farmDepartmentInseeCode: string;
}

export interface IElisa {
    realized: boolean;
    comment: string;
    bloodSampleNumber: number;
}

export interface IVaccinationSupervision {
    realized: boolean;
    sampleNumber: number;
    compliantAnalysis: boolean;
    comment: string;
}

export interface IMonitoringInterventionWorkshop {
    workshopId: string;
    controls: {
        imeps: string[];
        elisa: IElisa;
        vaccinationSupervision: IVaccinationSupervision;
    };
}

export interface IMonitoringInterventionData {
    complianceClinicalExamination: boolean;
    clinicalNonComplianceReason: string;
    monitoringResultAvailability: boolean;
    comment: string;
    virologySampleNumber: number;
    virologySampleComment: string;
}

export enum InterventionCorrectionType {
    UPDATE = "UPDATE",
    DELETE = "DELETE",
}
