// === NPM
import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { HttpStatus } from "../../../../interfaces/global";
// === LOCAL
import { IType } from "../../../../interfaces/user";
import UserService from "../../../../services/UserService";
import PageContent from "../../../generics/layout/PageContent";

export default function User() {
    const [userTypesReferential, setUserTypes] = useState<IType[]>([]);

    useEffect(() => {
        getTypesReferential();
    }, []);

    const getTypesReferential = async () => {
        const res = await UserService.getUserTypesReferential();
        if (res.status === HttpStatus.OK) {
            setUserTypes(res.data);
        }
    };

    return (
        <PageContent>
            <Outlet context={{ userTypesReferential }} />
        </PageContent>
    );
}
