import { ISpeciesDetails } from "../../../interfaces/referential";

export interface HealthReportingOutletContext {
    species: ISpeciesDetails[];
    anomalyTypes: IAnomalyType[];
}

export interface IHealthReportingForm {
    reportInformations: IReportInformations;
    workshopInformations: IWorkshopInformations;
    reportedAnomalies: IReportedAnomaly[];
    comment: string;
    attachedFiles: IAttachedFile[];
}

export enum HealthReportingStatus {
    NEW = "Nouveau",
    IN_PROCESS = "En cours de traitement",
    PROCESSED = "Traité",
}

export enum HolderType {
    PRIVATE = "Particulier",
    PROFESSIONAL = "Professionnel",
}

export interface IReportInformations {
    dpeId: string;
    observationDate: string;
    transmitted: boolean;
}

export interface IReportInformationsFull {
    dpeId: string;
    dpeName: string;
    veterinaryId: string;
    veterinaryLastname: string;
    veterinaryFirstname: string;
    observationDate: string;
    transmitted: boolean;
}

export enum TypeIdentifiant {
    AUTFAB = "AUTFAB",
    EDE = "EDE",
    EGET = "EGET",
    FINESS = "FINESS",
    INUAV = "INUAV",
    ORDRE = "ORDRE",
    SIREN = "SIREN",
    SIRET = "SIRET",
}

export interface IWorkshopInformations {
    holderType: HolderType;
    typeIdentifiant?: TypeIdentifiant;
    holderId?: string;
    businessName?: string;
    holderLastname: string;
    holderFirstname: string;
    address: string;
    postalCode: string;
    city: string;
}

export interface IReportedAnomaly {
    anomalyTypeUuid: string;
    species: string;
    animalCount: number;
    individualIdentification: string;
    internalId?: string;
}

export interface IReportedAnomalyFull {
    anomalyType: string;
    species: string;
    animalCount: number;
    individualIdentification: string;
}

export interface IAnomalyType {
    uuid: string;
    label: string;
}

export interface IAttachedFile {
    filename: string;
    uuid: string;
}

export interface IHealthReportingFilters {
    id: string;
    reportingDate: string[];
    dpe: string;
    veterinary: string;
    holderType: string[];
    holderId: string;
    holderBusinessName: string;
    holder: string;
    holderPostalCode: string;
    holderCity: string;
    status: string[];
}

export interface IHealthReporting {
    id: string;
    reportingDate: string;
    dpeId: string;
    dpeName: string;
    veterinaryId: string;
    veterinaryLastName: string;
    veterinaryFirstName: string;
    holderType: string;
    holderId: string;
    holderBusinessName: string;
    holderLastName: string;
    holderFirstName: string;
    holderPostalCode: string;
    holderInseeCode: string;
    holderCity: string;
    status: string;
}

export interface IHealthReportingStatusForm {
    status: string;
}

export interface IHealthReportingCounts {
    newStatus: string;
    inProcessStatus: string;
    processedStatus: string;
}

export interface IHealthReportingFull {
    id: string;
    status: HealthReportingStatus;
    reportingDate: string;
    comment: string;
    reportInformations: IReportInformationsFull & IWorkshopInformations;
    reportedAnomalies: IReportedAnomalyFull[];
    files: IAttachedFile[];
}
