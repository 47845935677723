// === NPM
import React, { useEffect } from "react";
import { Box, FormControl, Grid, Stack, TextField } from "@mui/material";
import { DateTime } from "luxon";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
// === LOCAL
import { IAgency, ILocalAgency } from "../../../../../../../interfaces/user";
import { FORM_TEXT, stringRequired } from "../../../../../../../resources/FormUtils";
import GenericActionsDialog from "../../../../../../generics/dialogs/GenericActionsDialog";
import GenericDialog from "../../../../../../generics/dialogs/GenericDialog";
import CityPostalCodeAutocomplete from "../../../../../../generics/inputs/CityPostalCodeAutocomplete";
import GenericDatePicker from "../../../../../../generics/inputs/GenericDatePicker";
import GenericSelect from "../../../../../../generics/inputs/GenericSelect";
import GenericCard from "../../../../../../generics/layout/GenericCard";
import useIsFirstRender from "../../../../../../../hooks/useIsFirstRender";
import { ICreateLocalAgency } from "../../../interface";

interface LocalAgencyDialogProps {
    onClose: () => void;
    onValid: (agency: ICreateLocalAgency) => void;
    currentLocalAgency?: ILocalAgency;
    title: string;
    activeAgencies: IAgency[];
}

const initialValues: ICreateLocalAgency = {
    name: null,
    authorizationNumber: null,
    city: "",
    postalCode: "",
    openingDate: "",
    agencyId: null,
};

export default function LocalAgencyDialog({
    onClose,
    onValid,
    title,
    currentLocalAgency,
    activeAgencies,
}: LocalAgencyDialogProps) {
    const formSchema = z.object({
        agencyId: z.coerce.number({
            required_error: FORM_TEXT.required,
            invalid_type_error: "Veuillez saisir un nombre positif",
        }),
        name: stringRequired(),
        authorizationNumber: stringRequired(),
        city: stringRequired(),
        postalCode: stringRequired(),
        ...(!currentLocalAgency && {
            openingDate: stringRequired(),
        }),
    });

    type ValidationSchema = z.infer<typeof formSchema>;

    const {
        formState: { errors },
        handleSubmit,
        control,
        watch,
        setValue,
        register,
    } = useForm<ValidationSchema>({
        resolver: zodResolver(formSchema),
        defaultValues: currentLocalAgency
            ? { ...currentLocalAgency, agencyId: +currentLocalAgency.agencyId }
            : initialValues,
    });

    const watchAgencyId = watch("agencyId");
    const watchCity = watch("city");
    const watchPostalCode = watch("postalCode");

    const isFirstRender = useIsFirstRender();

    useEffect(() => {
        if (isFirstRender || !watchAgencyId) return;
        setValue("name", activeAgencies.find((a) => a.id === +watchAgencyId)?.name);
    }, [watchAgencyId]);

    const handleCityPostalCodeChange = (event) => {
        setValue("postalCode", event.target.value?.postalCode ?? "");
        setValue("city", event.target.value?.name ?? "");
    };

    const renderContent = () => {
        return (
            <Box p={2} mt={2} width="100%">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormControl fullWidth required error={!!errors.agencyId}>
                            <Controller
                                name="agencyId"
                                control={control}
                                render={({ field: { value, onChange }, fieldState: { error } }) => (
                                    <GenericSelect
                                        value={value}
                                        label="Groupe parent"
                                        name="agencyId"
                                        error={!!error}
                                        helperText={error?.message}
                                        optionsValue="id"
                                        optionsLabel="name"
                                        onChange={onChange}
                                        options={activeAgencies}
                                        required
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            {...register("name")}
                            label="Nom de l'établissement"
                            error={!!errors?.name}
                            helperText={errors?.name?.message}
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            {...register("authorizationNumber")}
                            label="Identifiant externe"
                            error={!!errors?.authorizationNumber}
                            helperText={errors?.authorizationNumber?.message}
                            required
                            fullWidth
                        />
                    </Grid>

                    {!currentLocalAgency && (
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth required error={!!errors.openingDate}>
                                <Controller
                                    name="openingDate"
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                                        <GenericDatePicker
                                            label="Date d'ouverture"
                                            value={value}
                                            name="openingDate"
                                            onChange={onChange}
                                            error={!!error}
                                            helperText={error?.message}
                                            maxDate={DateTime.now()}
                                            required
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                    )}

                    <Grid item xs={12} md={12}>
                        <Stack spacing={2} direction="row">
                            <CityPostalCodeAutocomplete
                                handleChange={handleCityPostalCodeChange}
                                defaultCitySearch={watchCity}
                                defaultPostalCodeSearch={watchPostalCode}
                                initialValues={
                                    watchCity
                                        ? {
                                              name: watchCity,
                                              postalCode: watchPostalCode,
                                          }
                                        : null
                                }
                                errors={{ name: errors?.city?.message, postalCode: errors?.postalCode?.message }}
                                required
                            />
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        );
    };

    return (
        <GenericDialog
            title={title}
            onClose={onClose}
            paperProps={{
                sx: { width: "100%" },
            }}
            renderActions={() => <GenericActionsDialog onClose={onClose} onSubmit={handleSubmit(onValid)} />}
            renderContent={() => <GenericCard>{renderContent()}</GenericCard>}
        />
    );
}
