// === NPM
import { Box } from "@mui/material";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { AxiosResponse } from "axios";
import { useOutletContext } from "react-router-dom";

// === LOCAL
import { AdministrationApiKeysOutletContext, ICreateAccount, IPeriodicity, Profile } from "../interface";
import { IOrganization } from "../../../../../interfaces/referential";
import ApiKeyService from "../../../../../services/ApiKeyService";
import GenericButton from "../../../../generics/buttons/GenericButton";
import ConfirmDialogWithWarning from "../../../../generics/dialogs/ConfirmDialogWithWarning";
import CreateAccountDialog from "./dialogs/CreateAccountDialog";
import PeriodicitySplitButton from "./PeriodicitySplitButton";
import { ReactComponent as Add } from "../../../../../assets/icons/actions/plus.svg";
import { HttpStatus } from "../../../../../interfaces/global";

interface PeriodicityAndCreateAccountProps {
    profile: Profile;
    organizations: IOrganization[];
    createAccount: (newAccount: ICreateAccount) => Promise<AxiosResponse>;
    getAccounts: () => void;
    disabled?: boolean;
}

export default function PeriodicityAndCreateAccount({
    profile,
    organizations,
    createAccount,
    getAccounts,
    disabled = false,
}: PeriodicityAndCreateAccountProps) {
    const { periodicitiesReferential, apiKeyProfilesReferential, updateProfilePeriodicity } =
        useOutletContext<AdministrationApiKeysOutletContext>();
    const [openChangePeriodiciyDialog, setOpenChangePeriodiciyDialog] = useState<boolean>(false);
    const [openCreateAccountDialog, setOpenCreateAccountDialog] = useState<boolean>(false);
    const [newPeriodicity, setNewPeriodicity] = useState<IPeriodicity>(null);

    const createNewAccount = async (newAccount: ICreateAccount) => {
        const res = await createAccount(newAccount);
        if (res.status === HttpStatus.CREATED) {
            setOpenCreateAccountDialog(false);
            toast.success("Compte créé avec succès");
            getAccounts();
        }
    };

    const handleChangePeriodicity = async (value) => {
        if (!value) {
            setOpenChangePeriodiciyDialog(false);
            setNewPeriodicity(null);
            return;
        }
        const res = await ApiKeyService.putPeriodicity(profile, {
            periodicityKey: newPeriodicity.key,
        });
        if (res.status === HttpStatus.OK) {
            setOpenChangePeriodiciyDialog(false);
            updateProfilePeriodicity(profile, res.data.periodicityKey);
            toast.success("Périodicité modifiée avec succès");
            setNewPeriodicity(null);
        }
    };

    const periodicitiesOptions = periodicitiesReferential?.map((p) => ({
        ...p,
        signature: (periodicity: IPeriodicity) => {
            setOpenChangePeriodiciyDialog(true);
            setNewPeriodicity(periodicity);
        },
    }));

    return (
        <>
            <Box justifyContent="space-between" display="flex" gap={3}>
                <PeriodicitySplitButton
                    disabled={disabled}
                    options={periodicitiesOptions || []}
                    currentPeriodicity={apiKeyProfilesReferential?.find((p) => p.key === profile)?.periodicityKey}
                />
                <GenericButton
                    disabled={disabled}
                    label="Ajouter un compte"
                    onClick={() => setOpenCreateAccountDialog(true)}
                    startIcon={<Add />}
                />
            </Box>
            {openChangePeriodiciyDialog && (
                <ConfirmDialogWithWarning
                    title="Mise à jour de la périodicité"
                    warningDisplay={newPeriodicity.key === "never"}
                    onClose={handleChangePeriodicity}
                    warningText="Cette configuration n'est pas recommandée car non sécurisée"
                    confirmationText={`Êtes-vous sûr de vouloir passer la périodicité à ${newPeriodicity.label} ?`}
                />
            )}
            {openCreateAccountDialog && (
                <CreateAccountDialog
                    onClose={() => setOpenCreateAccountDialog(false)}
                    organizations={organizations}
                    onValid={createNewAccount}
                    typeSelection={profile === Profile.DRUG_TRANSFER}
                />
            )}
        </>
    );
}
