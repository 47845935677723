import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { DateTime } from "luxon";
import { displayFormat } from "../../../resources/AppConstant";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterCalypso } from "../../../resources/AdapterCalypso";

interface GenericDatePickerProps {
    label: string;
    value: string;
    name: string;
    onChange: any;
    required?: boolean;
    error?: boolean;
    helperText?: string;
    disabled?: boolean;
    maxDate?: DateTime;
    minDate?: DateTime;
    size?: "small" | "medium";
    fullWidth?: boolean;
}

GenericDatePicker.defaultProps = {
    onChange: () => {
        console.info("No onChange method");
    },
};

export default function GenericDatePicker({
    label,
    onChange,
    name,
    value,
    error,
    helperText,
    required,
    disabled,
    maxDate,
    minDate,
    size,
    fullWidth = true,
}: GenericDatePickerProps) {
    const [date, setDate] = useState<DateTime>(null);
    const [invalidFormat, setInvalidFormat] = useState(false);

    useEffect(() => {
        const newDate = value ? DateTime.fromISO(value) : null;

        setDate(newDate);
    }, [value]);

    const handleChange = (event) => {
        setInvalidFormat(false);
        const targetEvent = {
            target: {
                name,
                value: event ? event.toISODate() : "",
            },
        };
        if (event?.invalid) {
            setInvalidFormat(true);
        }
        onChange(targetEvent);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterCalypso} adapterLocale={"fr-FR"}>
            <DatePicker
                label={label}
                value={date}
                disabled={disabled}
                inputFormat={displayFormat}
                onChange={handleChange}
                maxDate={maxDate}
                minDate={minDate}
                renderInput={(params) => (
                    <TextField
                        variant="outlined"
                        {...params}
                        error={error || invalidFormat}
                        helperText={invalidFormat ? "Le format est incorrect" : helperText}
                        required={required}
                        fullWidth={fullWidth}
                        size={size}
                    />
                )}
            />
        </LocalizationProvider>
    );
}
