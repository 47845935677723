// === NPM
import React, { Dispatch, RefObject, SetStateAction, useEffect, useState } from "react";
import {
    Box,
    Button,
    Card,
    FormControl,
    FormControlLabel,
    Grid,
    InputAdornment,
    Stack,
    Switch,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { Delete, Help } from "@mui/icons-material";
import { DateTime } from "luxon";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
// === LOCAL
import { FORM_TEXT, stringRequired } from "../../../../../../../resources/FormUtils";
import { IWorkshop } from "../../../../../../../interfaces/farm";
import GenericAutocomplete from "../../../../../../generics/inputs/GenericAutocomplete";
import { useFormIntervention } from "../../useFormIntervention";
import GenericDatePicker from "../../../../../../generics/inputs/GenericDatePicker";
import { getEnumKeyByValue, toLocaleDateFormat } from "../../../../../../../resources/utils";
import GenericTextField from "../../../../../../generics/inputs/GenericTextField";
import VaccinationService from "../../../../../../../services/VaccinationService";
import { HttpStatus } from "../../../../../../../interfaces/global";
import useTimeout from "../../../../../../../hooks/useTimeout";
import { StyledCardContent } from "../../../../../../styled/StyledCardContent";
import FarmService from "../../../../../../../services/FarmService";
import { IMEPMaskComponent } from "../../../../../MonitoringIntervention/Form/Controls/containers/IMEPMaskComponent";
import { IAnimalBatch, InjectionType } from "../../../../interface";

interface AnimalBatchProps {
    batch: IAnimalBatch;
    index: number;
    injectionType: keyof typeof InjectionType;
    deletable: boolean;
    animalBatchesRef: RefObject<Record<number, HTMLButtonElement>>;
    setIsValidBatches: Dispatch<SetStateAction<boolean[]>>;
}

export default function AnimalBatch({
    batch,
    index,
    injectionType,
    deletable,
    animalBatchesRef,
    setIsValidBatches,
}: AnimalBatchProps) {
    const isFirstDose = injectionType === getEnumKeyByValue(InjectionType, InjectionType.FIRST_DOSE);
    const { form, setForm } = useFormIntervention();
    const [interventionIds, setInterventionIds] = useState<{ id: string }[]>([]);
    const [batchSearch, setBatchSearch] = useState<string>(batch.previousInterventionId ?? "");
    const [declareWorkshopSearch, setDeclareWorkshopSearch] = useState<string>(batch.declaredWorkshopId);
    const [declareWorkshops, setDeclareWorkshops] = useState<IWorkshop[]>([]);

    const animalBatchSchema = z
        .object({
            uuid: z.string(),
            hasImep: z.boolean(),
            imepNumber: z
                .string()
                .nullable()
                .optional()
                .refine((value) => !value || RegExp("[A-Z]{3}[0-9]{2}.[0-9]{3}.[0-9]{3}").test(value), {
                    message: "Le format de l'IMEP est incorrect",
                }),
            declaredWorkshopId: stringRequired(),
            previousInterventionId: z
                .string()
                .nullable()
                .optional()
                .refine(
                    (value) =>
                        (!value && injectionType === getEnumKeyByValue(InjectionType, InjectionType.FIRST_DOSE)) ||
                        value,
                    { message: FORM_TEXT.required }
                ),
            installationDate: z
                .string()
                .nullable()
                .optional()
                .refine(
                    (value) =>
                        (!value && injectionType === getEnumKeyByValue(InjectionType, InjectionType.FIRST_DOSE)) ||
                        value,
                    { message: FORM_TEXT.required }
                )
                .refine((value) => !value || (value && DateTime.fromISO(value) <= DateTime.now().startOf("day")), {
                    message: "La date de mise en place déclarée doit être dans le passé",
                }),
            hatchingDate: z
                .string()
                .nullable()
                .optional()
                .refine(
                    (value) =>
                        (!value && injectionType === getEnumKeyByValue(InjectionType, InjectionType.REMINDER)) || value,
                    {
                        message: FORM_TEXT.required,
                    }
                )
                .refine(
                    (value) =>
                        !value ||
                        (value && DateTime.fromISO(value) < DateTime.fromISO(form.generalInformation.vaccinationDate)),
                    {
                        message: `La date d'éclosion doit être avant la date d'intervention (${toLocaleDateFormat(
                            form.generalInformation.vaccinationDate
                        )})`,
                    }
                ),
        })
        .superRefine((values, context) => {
            if (values.hasImep && !values.imepNumber) {
                context.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: FORM_TEXT.required,
                    path: ["imepNumber"],
                });
            }
        });

    type AnimalBatchValidationSchema = z.infer<typeof animalBatchSchema>;

    const {
        formState: { errors },
        control,
        register,
        handleSubmit,
        setValue,
        watch,
    } = useForm<AnimalBatchValidationSchema>({
        resolver: zodResolver(animalBatchSchema),
        defaultValues: {
            hasImep: !!batch.imepNumber,
            imepNumber: batch.imepNumber ?? null,
            declaredWorkshopId: batch.declaredWorkshopId ?? "",
            previousInterventionId: batch.previousInterventionId ?? "",
            installationDate: batch.installationDate ?? "",
            hatchingDate: batch.hatchingDate ?? "",
            uuid: batch.uuid,
        },
    });
    const hasImep = watch("hasImep");
    const hatchingDate = watch("hatchingDate");
    const previousInterventionId = watch("previousInterventionId");
    const declaredWorkshopId = watch("declaredWorkshopId");

    useEffect(() => {
        if (declareWorkshopSearch) getWorkshops();
        if (batchSearch) getInterventionIds();
    }, []);

    useTimeout(() => getInterventionIds(), [batchSearch]);
    useTimeout(() => getWorkshops(), [declareWorkshopSearch]);

    const validateBatchInformation = (formValues: AnimalBatchValidationSchema) => {
        const newBatch: IAnimalBatch = {
            imepNumber: formValues.imepNumber,
            previousInterventionId: formValues.previousInterventionId,
            declaredWorkshopId: formValues.declaredWorkshopId,
            hatchingDate: formValues.hatchingDate,
            installationDate: formValues.installationDate,
            uuid: formValues.uuid,
        };
        setForm((prev) => ({
            ...prev,
            animalInformation: {
                ...prev.animalInformation,
                animalBatches: prev.animalInformation.animalBatches.map((b, i) => (i === index ? newBatch : b)),
            },
        }));
        setIsValidBatches((prev) => prev.map((v, i) => (i === index ? true : v)));
    };

    const handleDeleteBatch = () => {
        setForm((prev) => ({
            ...prev,
            animalInformation: {
                ...prev.animalInformation,
                animalBatches: prev.animalInformation.animalBatches.filter((_, i) => i !== index),
            },
        }));
    };

    const getWorkshops = async () => {
        const payload = {
            page: 0,
            size: 50,
            id: declareWorkshopSearch,
        };
        const res = await FarmService.getWorkshops(payload);
        if (res.status === HttpStatus.OK) {
            setDeclareWorkshops(res.data);
        }
    };

    const getInterventionIds = async () => {
        const injectionTypes = [];
        if (injectionType === getEnumKeyByValue(InjectionType, InjectionType.REMINDER)) {
            injectionTypes.push(
                getEnumKeyByValue(InjectionType, InjectionType.SECOND_DOSE),
                getEnumKeyByValue(InjectionType, InjectionType.REMINDER)
            );
        } else {
            injectionTypes.push(getEnumKeyByValue(InjectionType, InjectionType.FIRST_DOSE));
        }
        const res = await VaccinationService.getInterventions("id", {
            page: 0,
            size: 50,
            id: batchSearch,
            injectionType: injectionTypes,
        });
        if (res.status === HttpStatus.OK) {
            setInterventionIds(res.data);
        }
    };

    return (
        <Card sx={{ p: 2 }}>
            <StyledCardContent>
                <form onSubmit={handleSubmit(validateBatchInformation)} noValidate>
                    <Stack>
                        <Box justifyContent="space-between" display="flex" alignItems="center">
                            <Typography variant="bold">Lot {index + 1}</Typography>
                            {deletable && (
                                <Button startIcon={<Delete />} color="error" onClick={handleDeleteBatch}>
                                    Supprimer le lot
                                </Button>
                            )}
                        </Box>
                        <Controller
                            name="hasImep"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Typography variant={!value ? "bold" : "body1"}>IMEP Inconnu</Typography>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={value}
                                                onChange={(event) => {
                                                    onChange(event);
                                                    setValue("imepNumber", null);
                                                }}
                                            />
                                        }
                                        label=""
                                    />
                                    <Typography variant={value ? "bold" : "body1"}>IMEP Connu</Typography>
                                </Stack>
                            )}
                        />
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} lg={4}>
                                <TextField
                                    {...register("imepNumber")}
                                    label="IMEP"
                                    fullWidth
                                    helperText={errors?.imepNumber?.message}
                                    error={!!errors.imepNumber}
                                    required={hasImep}
                                    disabled={!hasImep}
                                    InputLabelProps={{ shrink: hasImep }}
                                    InputProps={
                                        hasImep
                                            ? {
                                                  inputComponent: IMEPMaskComponent as any,
                                                  startAdornment: (
                                                      <InputAdornment position="start">
                                                          <Tooltip title="Le format attendu est AAA11.111.111, merci de remplacer les _ par des majuscules et les # par des chiffres.">
                                                              <Help />
                                                          </Tooltip>
                                                      </InputAdornment>
                                                  ),
                                              }
                                            : null
                                    }
                                />
                            </Grid>
                            {!isFirstDose && (
                                <Grid item xs={12} md={6} lg={4}>
                                    <GenericAutocomplete
                                        label="Numéro d’intervention de vaccination précédente"
                                        options={interventionIds}
                                        value={interventionIds.find((o) => o.id === previousInterventionId) || null}
                                        onChange={(event) => setValue("previousInterventionId", event.target.value?.id)}
                                        required
                                        inputValue={batchSearch}
                                        onInputChange={(event, newInputValue) => {
                                            setBatchSearch(newInputValue);
                                        }}
                                        getOptionLabel={(option) => option.id}
                                        error={!!errors.previousInterventionId}
                                        helperText={errors?.previousInterventionId?.message}
                                        noOptionsText={
                                            batchSearch
                                                ? "Aucun résultat"
                                                : "Disponible sur le certificat de la vaccination précédente"
                                        }
                                    />
                                </Grid>
                            )}
                            <Grid item xs={12} md={6} lg={4}>
                                <GenericAutocomplete
                                    label="INUAV de mise en place déclarée"
                                    options={declareWorkshops}
                                    value={declareWorkshops.find((w) => w.id === declaredWorkshopId) || null}
                                    onChange={(event) => setValue("declaredWorkshopId", event.target.value?.id)}
                                    required
                                    inputValue={declareWorkshopSearch}
                                    onInputChange={(event, newInputValue) => {
                                        setDeclareWorkshopSearch(newInputValue);
                                    }}
                                    getOptionLabel={(option) => option.id}
                                    error={!!errors.declaredWorkshopId}
                                    helperText={errors?.declaredWorkshopId?.message}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <FormControl
                                    fullWidth
                                    required={
                                        injectionType !== getEnumKeyByValue(InjectionType, InjectionType.FIRST_DOSE)
                                    }
                                    error={!!errors.installationDate}
                                >
                                    <Controller
                                        name="installationDate"
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <GenericDatePicker
                                                value={value}
                                                onChange={onChange}
                                                name="installationDate"
                                                error={!!error}
                                                helperText={error?.message}
                                                label="Date de mise en place déclarée"
                                                maxDate={DateTime.now()}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <FormControl
                                    fullWidth
                                    required={
                                        injectionType !== getEnumKeyByValue(InjectionType, InjectionType.REMINDER)
                                    }
                                    error={!!errors.hatchingDate}
                                >
                                    <Controller
                                        name="hatchingDate"
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <GenericDatePicker
                                                value={value}
                                                onChange={onChange}
                                                name="hatchingDate"
                                                error={!!error}
                                                helperText={error?.message}
                                                label="Date d'éclosion"
                                                maxDate={DateTime.fromISO(
                                                    form.generalInformation.vaccinationDate
                                                ).minus({ day: 1 })}
                                                required={
                                                    injectionType !==
                                                    getEnumKeyByValue(InjectionType, InjectionType.REMINDER)
                                                }
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <GenericTextField
                                    label="Âge à la vaccination (jours)"
                                    disabled
                                    value={
                                        hatchingDate
                                            ? Math.round(
                                                  DateTime.fromISO(hatchingDate).diff(
                                                      DateTime.fromISO(form.generalInformation.vaccinationDate),
                                                      ["days"]
                                                  ).days
                                              ) * -1
                                            : ""
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Stack>
                    <button
                        style={{ display: "none" }}
                        type="submit"
                        ref={(el) => (animalBatchesRef.current[index] = el)}
                    />
                </form>
            </StyledCardContent>
        </Card>
    );
}
