// === NPM
import React from "react";
import { Card, Link, Stack, Typography } from "@mui/material";
import { SellOutlined } from "@mui/icons-material";
// === LOCAL
import { IWorkshopRelation } from "../../../../../../interfaces/farm";
import { StyledCardContent } from "../../../../../styled/StyledCardContent";
import GenericDialog from "../../../../../generics/dialogs/GenericDialog";
import GenericActionsDialog from "../../../../../generics/dialogs/GenericActionsDialog";
import { ReactComponent as Home } from "../../../../../../assets/icons/shared/home.svg";
import { ReactComponent as Location } from "../../../../../../assets/icons/shared/location.svg";
import { ReactComponent as Workshop } from "../../../../../../assets/icons/shared/workshop.svg";
import { ReactComponent as Calendar } from "../../../../../../assets/icons/shared/calendar.svg";
import { ReactComponent as Paper } from "../../../../../../assets/icons/shared/paper.svg";
import { ReactComponent as User } from "../../../../../../assets/icons/shared/user.svg";
import { ReactComponent as Comment } from "../../../../../../assets/icons/shared/comment.svg";
import { ReactComponent as Close } from "../../../../../../assets/icons/farm/close-square.svg";
import { ReactComponent as Check } from "../../../../../../assets/icons/farm/check.svg";
import { GenericIconText } from "../../../../../generics/text/GenericIconText";
import { IReferential } from "../../../../../../interfaces/referential";
import { toLocaleDateFormat } from "../../../../../../resources/utils";

interface ViewRelationDialogProps {
    onClose: () => void;
    relation: IWorkshopRelation;
    getFile: (workshopId: string, fileUuid: string) => void;
    closureReasons: IReferential[];
}
export default function ViewRelationDialog({ onClose, relation, getFile, closureReasons }: ViewRelationDialogProps) {
    const renderCloseRelationReason = () => (
        <Card>
            <StyledCardContent>
                <Stack spacing={3} p={2}>
                    <Typography variant="h6" fontWeight={700}>
                        Informations de clôture
                    </Typography>
                    <Stack spacing={1}>
                        <GenericIconText
                            icon={<User />}
                            text={closureReasons.find((reason) => relation.closingReason === reason.key)?.label}
                            iconTooltip="Motif de clôture"
                        />
                        <GenericIconText
                            icon={<Calendar />}
                            text={toLocaleDateFormat(relation.closingDate)}
                            iconTooltip="Date de clôture"
                        />
                        {relation.closingComment && (
                            <GenericIconText
                                icon={<Comment />}
                                text={relation.closingComment}
                                iconTooltip="Commentaire"
                            />
                        )}
                    </Stack>
                </Stack>
            </StyledCardContent>
        </Card>
    );

    const renderRelationInformations = () => (
        <Card>
            <StyledCardContent>
                <Stack spacing={3} p={2}>
                    <Typography variant="h6" fontWeight={700}>
                        Informations de la relation
                    </Typography>
                    <Stack direction="row" justifyContent="space-between">
                        <Stack spacing={1}>
                            <Typography variant="bold">Établissement concerné</Typography>
                            <GenericIconText
                                icon={<SellOutlined />}
                                text={relation.farmName}
                                iconTooltip="Nom de l'établissement"
                            />
                            <GenericIconText
                                icon={<Home />}
                                text={`${relation.dpeId} - ${relation.dpeName}`}
                                iconTooltip="Informations du DPE"
                            />
                        </Stack>
                        <Stack spacing={1}>
                            <Typography variant="bold">Atelier</Typography>
                            <GenericIconText
                                icon={<Workshop />}
                                text={`Identifiant : ${relation.workshopId}`}
                                iconTooltip="Identifiant de l'atelier"
                            />

                            <GenericIconText
                                icon={<Location />}
                                text={`${relation.workshopDepartmentCode} ${relation.workshopDepartmentName} - ${relation.workshopCity}`}
                                iconTooltip="Département - Commune"
                            />
                            {relation.workshopClosingDate && (
                                <GenericIconText
                                    icon={<Calendar />}
                                    text={relation.workshopClosingDate}
                                    iconTooltip="Date de clôture de l'atelier"
                                />
                            )}
                        </Stack>

                        <Stack spacing={1}>
                            <Typography variant="bold">Statut de la relation</Typography>
                            <GenericIconText
                                icon={relation.closingDate ? <Close /> : <Check />}
                                text={`${relation.closingDate ? "Clôturée" : "Active"}`}
                                iconTooltip="Statut de la relation"
                            />
                            {relation.cerfaFileUuid && (
                                <Stack direction="row" alignItems="center">
                                    <GenericIconText
                                        icon={<Paper />}
                                        text={relation.cerfaFileUuid ? "" : "Aucun CERFA ajouté"}
                                        iconTooltip="Document CERFA"
                                    />
                                    {relation.cerfaFileUuid && (
                                        <Link
                                            component="button"
                                            variant="body2"
                                            color="inherit"
                                            onClick={() => getFile(relation.uuid, relation.cerfaFileUuid)}
                                        >
                                            Document CERFA
                                        </Link>
                                    )}
                                </Stack>
                            )}
                        </Stack>
                    </Stack>
                </Stack>
            </StyledCardContent>
        </Card>
    );

    const renderContent = () => (
        <Stack spacing={2}>
            {renderRelationInformations()}
            {relation.closingDate && renderCloseRelationReason()}
        </Stack>
    );

    return (
        <GenericDialog
            title="Détails de la relation"
            renderContent={() => renderContent()}
            onClose={onClose}
            renderActions={() => <GenericActionsDialog onClose={onClose} displaySubmit={false} closeLabel="Fermer" />}
        />
    );
}
