import { Typography, Stack, Switch } from "@mui/material";
import React from "react";

interface GenericSwitchProps {
    title: string;
    leftText: string;
    rightText: string;
    value: boolean;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function GenericSwitch({ title, leftText, rightText, value, onChange }: GenericSwitchProps) {
    return (
        <>
            <Typography variant="bold">{title}</Typography>
            <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant={!value ? "bold" : "body1"}>{leftText}</Typography>
                <Switch checked={value} onChange={onChange} />
                <Typography variant={value ? "bold" : "body1"}>{rightText}</Typography>
            </Stack>
        </>
    );
}
