export const CREATE = 1;
export const READ = 2;
export const UPDATE = 4;
export const DELETE = 8;
export const CREATE_UPDATE = 5;

export enum Features {
    CONTINUOUS_TRAINING = "CONTINUOUS_TRAINING",
    CONTINUOUS_TRAINING_CATALOG = "CONTINUOUS_TRAINING_CATALOG",
    VETERINARY_INFORMATION_USER = "VETERINARY_INFORMATION_USER",
    MANAGE_REFERENTIAL_CONTINUOUS_TRAINING = "MANAGE_REFERENTIAL_CONTINUOUS_TRAINING",
    MANAGE_USERS_FAM_DAM = "MANAGE_USERS_FAM_DAM",
    MANAGE_USERS_MA = "MANAGE_USERS_MA",
    MANAGE_REFERENTIAL_DELIVERY = "MANAGE_REFERENTIAL_DELIVERY",
    MANAGE_REFERENTIAL_DELIVERY_SYNCRHO = "MANAGE_REFERENTIAL_DELIVERY_SYNCHRO",
    MANAGE_REFERENTIAL_GLOBAL = "MANAGE_REFERENTIAL_GLOBAL",
    MANAGE_REFERENTIAL_OV_SYNCHRO = "MANAGE_REFERENTIAL_OV_SYNCHRO",
    MANAGE_REFERENTIAL_GEO_SYNCHRO = "MANAGE_REFERENTIAL_GEO_SYNCHRO",
    MANAGE_REFERENTIAL_HORSE = "MANAGE_REFERENTIAL_HORSE",
    MANAGE_USERS = "MANAGE_USERS",
    MANAGE_API_KEYS = "MANAGE_API_KEYS",
    MANAGE_NOTIFICATION = "MANAGE_NOTIFICATION",
    NOTIFICATIONS = "NOTIFICATIONS",
    DELIVERY = "DELIVERY",
    REPORTING_DELIVERY = "REPORTING_DELIVERY",
    TRACEABILITY_DELIVERY = "TRACEABILITY_DELIVERY",
    REFERENTIAL_DRUG = "REFERENTIAL_DRUG",
    DIRECTORY_DPE = "DIRECTORY_DPE",
    TRACEABILITY_CONTINUOUS_TRAINING = "TRACEABILITY_CONTINUOUS_TRAINING",
    TRACEABILITY_AGENCY = "TRACEABILITY_AGENCY",
    REFERENTIAL_FAM_DAM = "REFERENTIAL_FAM_DAM",
    DELIVERY_PBI = "DELIVERY_PBI",
    MANUAL_DELIVERY = "MANUAL_DELIVERY",
    PHARMACIST_REFERENTIAL = "PHARMACIST_REFERENTIAL",
    MANAGE_FAQ = "MANAGE_FAQ",
    FARMS = "FARMS",
    VACCINE_ORDER = "VACCINE_ORDER",
    VACCINATION_INTERVENTION = "VACCINATION_INTERVENTION",
    TRACEABILITY_VACCINATION_INTERVENTION = "TRACEABILITY_VACCINATION_INTERVENTION",
    VACCINE_AUDIT = "VACCINE_AUDIT",
    MANAGE_FARMS = "MANAGE_FARMS",
    MONITORING_INTERVENTION = "MONITORING_INTERVENTION",
    VACCINE_SCRAPPING = "VACCINE_SCRAPPING",
    IAHP_EXPORT = "IAHP_EXPORT",
    TRACEABILITY_SCRAPPING = "TRACEABILITY_SCRAPPING",
    TRACEABILITY_MONITORING_INTERVENTION = "TRACEABILITY_MONITORING_INTERVENTION",
    IAHP_PBI = "IAHP_PBI",
    BUTCHERY_SECTOR_EXCLUSION = "BUTCHERY_SECTOR_EXCLUSION",
    HORSE_RESEARCH = "HORSE_RESEARCH",
}

export enum UserSituation {
    REGISTERED_IN_PRACTICE = "registered_in_practice",
    PUBLIC_SECTOR_VETERINARIAN = "public_sector_veterinarian",
    THIRD_COUNTRIES_VETERINARY_REGISTERED_DIPLOMA = "third_countries_veterinary_registered_diploma",
    LIBERAL_VETERINARY = "liberal_veterinary",
    ADMIN_CALYPSO = "admin_calypso",
    FAM_DAM_USER = "fam_dam_user",
    FAM_DAM_ADMIN = "fam_dam_admin",
    ANMV_MED = "anmv_med",
    ANMV_NOTIF = "anmv_notif",
    PHARMACIST = "pharmacist",
    ADMIN_DDPP = "admin_ddpp",
    ADMIN_DRAAF = "admin_draaf",
    ADMIN_DGAL = "admin_dgal",
    USER_DDPP = "user_ddpp",
    USER_DRAAF = "user_draaf",
    USER_DGAL = "user_dgal",
}

export enum Preference {
    EMAIL_NOTIFICATION = "emailNotification",
    IAHP = "iahp",
    BUTCHERY_SECTOR = "butcherySector",
    BLUE_TONGUE_CATTLE = "blueTongueCattle",
    BLUE_TONGUE_SHEEP = "blueTongueSheep",
}
