// === NPM
import React from "react";
import * as DOMPurify from "dompurify";
import { Box, SxProps, Theme } from "@mui/material";

interface HtmlBoxProps {
    content: string;
    sx?: SxProps<Theme>;
}

export default function HtmlBox({ content, sx }: HtmlBoxProps) {
    return <Box className="ql-editor" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }} sx={sx}></Box>;
}
