// === NPM
import React, { Dispatch, RefObject, SetStateAction } from "react";
import { Card, FormControl, Stack, TextField, Typography } from "@mui/material";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
// === LOCAL
import { stringRequired } from "../../../../../../../../resources/FormUtils";
import TextEditor from "../../../../../../../generics/TextEditor";
import { StyledCardContent } from "../../../../../../../styled/StyledCardContent";
import { validateFile, getEnumKeyByValue } from "../../../../../../../../resources/utils";
import { DocumentMimeTypeFile } from "../../../../../../../../resources/AppConstant";
import FileUploader from "../../../../../../../generics/FileUploader";
import { DisplayType } from "../../../../../../../../interfaces/notification";
import { INotificationCreateUpdate, SendMode } from "../../../../interface";
import { colors } from "../../../../../../../../resources/CssConstant";

interface NotificationContentProps {
    onValid: () => void;
    form: INotificationCreateUpdate;
    setForm: Dispatch<SetStateAction<INotificationCreateUpdate>>;
    formRef: RefObject<HTMLButtonElement>;
    files: File[];
    setFiles: Dispatch<SetStateAction<File[]>>;
}

const formSchema = z.object({
    subject: stringRequired(),
    content: stringRequired(),
});

type ValidationSchema = z.infer<typeof formSchema>;

export default function NotificationContent({
    form,
    setForm,
    formRef,
    onValid,
    files,
    setFiles,
}: NotificationContentProps) {
    const {
        formState: { errors },
        handleSubmit,
        control,
        register,
    } = useForm<ValidationSchema>({
        resolver: zodResolver(formSchema),
        defaultValues: { subject: form.subject, content: form.content },
    });

    const handleValidateForm = (data: ValidationSchema) => {
        setForm((prev) => ({ ...prev, ...data }));
        onValid();
    };

    return (
        <form onSubmit={handleSubmit(handleValidateForm)} noValidate>
            <Stack spacing={2}>
                <Typography sx={{ width: { xs: "100%", md: "50%" } }} variant="h5">
                    Contenu de la notification
                </Typography>
                <Card>
                    <StyledCardContent>
                        <Stack spacing={4} sx={{ p: 2 }}>
                            <Typography variant="h5">Objet</Typography>
                            <TextField
                                {...register("subject")}
                                label="Objet"
                                error={!!errors?.subject}
                                helperText={errors?.subject?.message}
                                required
                            />
                            <Stack direction="row">
                                <Typography variant="h5" marginRight="0.2em">
                                    Contenu
                                </Typography>
                                <Typography color={colors.error}>*</Typography>
                            </Stack>
                            {form.displayType !== getEnumKeyByValue(DisplayType, DisplayType.ALERT_BANNER) && (
                                <>
                                    <FormControl fullWidth required error={!!errors.content}>
                                        <Controller
                                            name="content"
                                            control={control}
                                            render={({ field: { value, onChange }, fieldState: { error } }) => (
                                                <TextEditor
                                                    value={value}
                                                    handleChange={onChange}
                                                    placeholder="Contenu de la notification"
                                                    error={!!error}
                                                    helperText={error?.message}
                                                    required
                                                    allowImages={false}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                    <Typography variant="h5">Pièces jointes</Typography>
                                    <FileUploader
                                        files={files}
                                        setFiles={(fileList) => {
                                            setFiles(fileList);
                                        }}
                                        maxFiles={
                                            form.sendMode === getEnumKeyByValue(SendMode, SendMode.CALYPSO_APPLICATION)
                                                ? 3
                                                : 100
                                        }
                                        validator={validateFile}
                                        label="Déposer les fichiers"
                                        info="Les formats acceptés sont .docx, .jpg, .pdf et la taille des fichiers ne doit pas excéder 10 Mo."
                                        acceptedFiles={DocumentMimeTypeFile}
                                    />
                                </>
                            )}
                            {form.displayType === getEnumKeyByValue(DisplayType, DisplayType.ALERT_BANNER) && (
                                <TextField
                                    {...register("content")}
                                    helperText={errors?.content?.message}
                                    error={!!errors?.content}
                                    label="Contenu de la notification"
                                    rows={5}
                                    required
                                />
                            )}
                        </Stack>
                    </StyledCardContent>
                </Card>
            </Stack>
            <button style={{ display: "none" }} type="submit" ref={formRef} />
        </form>
    );
}
