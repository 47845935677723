// === NPM
import { Tabs } from "@mui/material";
import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
// === LOCAL
import { UserSituation } from "../../resources/PermissionConstant";
import { routerLinks } from "../../routers/RouterConstant";
import PageContent from "../generics/layout/PageContent";
import PermissionsTab from "../../resources/PermissionsTab";

export default function Parameters() {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.pathname === routerLinks.parameters.base) navigate(routerLinks.parameters.dpePreferences());
    }, []);

    return (
        <>
            <Tabs value={location.pathname} sx={{ ml: 12 }}>
                <PermissionsTab
                    value={routerLinks.parameters.dpePreferences()}
                    onClick={() => navigate(routerLinks.parameters.dpePreferences())}
                    label="Gérer mes préférences de DPE"
                    permissions={[
                        UserSituation.PUBLIC_SECTOR_VETERINARIAN,
                        UserSituation.REGISTERED_IN_PRACTICE,
                        UserSituation.THIRD_COUNTRIES_VETERINARY_REGISTERED_DIPLOMA,
                    ]}
                />
                <PermissionsTab
                    value={routerLinks.parameters.userPreferences()}
                    onClick={() => navigate(routerLinks.parameters.userPreferences())}
                    label="Gérer mes préférences personnelles"
                />
            </Tabs>
            <PageContent>
                <Outlet />
            </PageContent>
        </>
    );
}
