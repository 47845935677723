// === NPM
import React, { ChangeEvent, useEffect, useState } from "react";
import {
    Card,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    Radio,
    RadioGroup,
    Stack,
    Switch,
    Typography,
} from "@mui/material";
import { useOutletContext } from "react-router-dom";
import { DateTime } from "luxon";
// === LOCAL
import GenericActionsDialog from "../../../generics/dialogs/GenericActionsDialog";
import GenericDialog from "../../../generics/dialogs/GenericDialog";
import { StyledCardContent } from "../../../styled/StyledCardContent";
import { useForm } from "../../../../hooks/useForm";
import { FORM_TEXT, PATTERN } from "../../../../resources/FormUtils";
import GenericDatePicker from "../../../generics/inputs/GenericDatePicker";
import GenericTextField from "../../../generics/inputs/GenericTextField";
import GenericSelect from "../../../generics/inputs/GenericSelect";
import { convertEnumToKeyLabelObject, getEnumKeyByValue } from "../../../../resources/utils";
import { defaultIntegerLimit, maxIntegerValue } from "../../../../resources/AppConstant";
import { IAudit, InterventionOutletContext, ComplianceType } from "../interface";
import GenericComment from "../../../generics/inputs/GenericComment";

interface AuditDialogProps {
    onClose: () => void;
    onValid: (hasAudit: boolean, audit: IAudit) => void;
    initAudit: boolean;
    interventionId: string;
    initialValues?: IAudit;
}

export default function AuditDialog({ onClose, onValid, interventionId, initAudit, initialValues }: AuditDialogProps) {
    const { horsePower } = useOutletContext<InterventionOutletContext>();
    const { data, errors, handleSubmit, handleChange, cleanValue } = useForm<IAudit>({
        initialValues: initialValues ?? {
            complianceType: null,
            comment: "",
            date: "",
            distance: null,
            plateNumber: "",
            horsePower: "",
        },
        validations: {
            comment: {
                limitValue: {
                    isValid: (value) => !(value.trim().length === 0 && value.length > 0),
                    message: FORM_TEXT.emptyString,
                },
                custom: {
                    isValid: (value) =>
                        (hasAudit &&
                            data.complianceType !== getEnumKeyByValue(ComplianceType, ComplianceType.COMPLIANT) &&
                            value.length > 0) ||
                        (!value &&
                            data.complianceType === getEnumKeyByValue(ComplianceType, ComplianceType.COMPLIANT)) ||
                        (!hasAudit && !value),
                    message: FORM_TEXT.required,
                },
            },
            complianceType: {
                required: {
                    value: true,
                    message: FORM_TEXT.required,
                },
            },
            date: {
                custom: {
                    isValid: (value) =>
                        (value && hasAudit && DateTime.fromISO(value) <= DateTime.now()) || (!value && !hasAudit),
                    message: "La date de l'audit doit être inférieure ou égale à la date du jour",
                },
            },
            distance: {
                pattern: {
                    value: PATTERN.positiveNumber,
                    message: "La distance doit être un nombre positif entier",
                },
                custom: {
                    isValid: (value) => (hasAudit && value?.toString() && +value >= 0) || (!value && !hasAudit),
                    message: FORM_TEXT.required,
                },
                limitValue: {
                    isValid: (value) => +value <= maxIntegerValue,
                    message: FORM_TEXT.maxInteger,
                },
            },
            plateNumber: {
                pattern: {
                    value: "^[A-Z0-9-]{1,15}$",
                    message:
                        "Les caractères acceptés sont des majuscules, des chiffres et des tirets, avec 15 caractères maximum",
                },
                custom: {
                    isValid: (value) => (hasAudit && value) || (!value && !hasAudit),
                    message: FORM_TEXT.required,
                },
            },
            horsePower: {
                custom: {
                    isValid: (value) => (hasAudit && value) || (!value && !hasAudit),
                    message: FORM_TEXT.required,
                },
            },
        },
        onSubmit: () => {
            onValid(hasAudit, data);
        },
    });
    const [hasAudit, setHasAudit] = useState<boolean>(initAudit);

    useEffect(() => {
        if (!hasAudit) {
            cleanValue("comment");
            cleanValue("date");
            cleanValue("distance");
            cleanValue("plateNumber");
            cleanValue("horsePower");
        }
    }, [hasAudit]);

    const renderContent = () => (
        <Stack spacing={3} width="100%">
            <Typography variant="h5">Audit </Typography>
            {renderAuditInfo()}
            {hasAudit && (
                <>
                    <Typography variant="h5">Facturation de l'audit </Typography>
                    {renderBillingInfo()}
                </>
            )}
        </Stack>
    );

    const renderAuditInfo = () => (
        <Card sx={{ p: 2 }}>
            <StyledCardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <Typography variant="h5">
                            Réalisation de l'audit{" "}
                            <Typography component="span" variant="h5" color="error">
                                *
                            </Typography>
                        </Typography>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography variant={!hasAudit ? "bold" : "body1"}>Audit non réalisé</Typography>
                            <Switch
                                checked={hasAudit}
                                onChange={(e) => {
                                    setHasAudit(e.target.checked);
                                }}
                            />
                            <Typography variant={hasAudit ? "bold" : "body1"}>Audit réalisé</Typography>
                        </Stack>
                    </Grid>
                    {hasAudit && (
                        <>
                            <Grid item xs={12} md={4}>
                                <GenericDatePicker
                                    label="Date de l'audit"
                                    value={data.date}
                                    required
                                    fullWidth
                                    onChange={handleChange("date")}
                                    name="date"
                                    maxDate={DateTime.now()}
                                    error={!!errors.date}
                                    helperText={errors.date}
                                />
                            </Grid>
                            <Grid item xs={12} md={4} sx={{ pl: 3 }}>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <FormControl>
                                        <Typography variant="h5">
                                            Résultat de l'audit{" "}
                                            <Typography component="span" variant="h5" color="error">
                                                *
                                            </Typography>
                                        </Typography>

                                        <RadioGroup
                                            value={data.complianceType}
                                            onChange={(e) => {
                                                handleChange("complianceType")(
                                                    e as ChangeEvent<HTMLInputElement & HTMLSelectElement>
                                                );
                                                if (
                                                    e.target.value ===
                                                    getEnumKeyByValue(ComplianceType, ComplianceType.COMPLIANT)
                                                ) {
                                                    cleanValue("comment");
                                                }
                                            }}
                                        >
                                            {convertEnumToKeyLabelObject(ComplianceType).map((item) => (
                                                <FormControlLabel
                                                    key={item.key}
                                                    value={item.key}
                                                    control={<Radio />}
                                                    label={item.label}
                                                    labelPlacement="end"
                                                />
                                            ))}
                                        </RadioGroup>
                                        {errors.complianceType && (
                                            <FormHelperText error={!!errors.complianceType}>
                                                {errors.complianceType}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Stack>
                            </Grid>

                            {data.complianceType !== getEnumKeyByValue(ComplianceType, ComplianceType.COMPLIANT) && (
                                <Grid item xs={12}>
                                    <GenericComment
                                        label="Motif de non conformité"
                                        value={data.comment}
                                        onChange={handleChange("comment")}
                                        rows={3}
                                        required
                                        placeholder="Commentaire relatif sur la non conformité de l'audit"
                                        helperText={errors.comment}
                                    />
                                </Grid>
                            )}
                        </>
                    )}
                </Grid>
            </StyledCardContent>
        </Card>
    );

    const renderBillingInfo = () => (
        <Card sx={{ p: 2 }}>
            <StyledCardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <GenericTextField
                            label="Kilomètres parcourus (aller et retour)"
                            value={data.distance}
                            onChange={handleChange("distance")}
                            required
                            error={!!errors.distance}
                            helperText={errors.distance}
                            maxLength={defaultIntegerLimit}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <GenericTextField
                            label="Immatriculation"
                            value={data.plateNumber}
                            onChange={handleChange("plateNumber")}
                            required
                            error={!!errors.plateNumber}
                            helperText={errors.plateNumber}
                            maxLength={15}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <GenericSelect
                            onChange={handleChange("horsePower")}
                            options={horsePower}
                            required
                            optionsValue="key"
                            error={!!errors.horsePower}
                            helperText={errors.horsePower}
                            label="Chevaux fiscaux"
                            value={data.horsePower}
                        />
                    </Grid>
                </Grid>
            </StyledCardContent>
        </Card>
    );

    return (
        <GenericDialog
            maxWidth="lg"
            title={`Informations d'audit de l'intervention de vaccination ${interventionId}`}
            onClose={onClose}
            renderContent={() => renderContent()}
            renderActions={() => (
                <GenericActionsDialog onClose={onClose} closeLabel="Annuler" onSubmit={handleSubmit} />
            )}
        />
    );
}
