// === NPM
import { Card, CardContent, Stack, Box, Typography } from "@mui/material";
import { GridRenderCellParams, GridSortModel } from "@mui/x-data-grid-pro";
import React, { useEffect, useState } from "react";
import { DateTime } from "luxon";
// === LOCAL
import useTimeout from "../../../../../hooks/useTimeout";
import { ActionsColumnProps, ArrayOfTwo, FilterType, HttpStatus, IPagination } from "../../../../../interfaces/global";
import { CALYPSO_HEADERS, defaultPagination } from "../../../../../resources/AppConstant";
import { colors } from "../../../../../resources/CssConstant";
import { createPayload } from "../../../../../resources/utils";
import DrugService from "../../../../../services/DrugService";
import { FilterConfigurations, GenericFilters } from "../../../../generics/filters/GenericFilters";
import GenericTable from "../../../../generics/table/GenericTable";
import DetailDialog from "./containers/DetailDialog";
import ViewAction from "../../../../generics/actions/ViewAction";
import { IDrugPrescriptionReporting } from "../../interface";

interface Filters {
    prescriptionVeterinaryId: string;
    prescriptionId: string;
    prescriptionDate: ArrayOfTwo;
    importDate: ArrayOfTwo;
    drugType: string[];
    gtinCode: string;
    drugName: string;
}

export default function PrescriptionDashboard() {
    const [drugs, setDrugs] = useState<IDrugPrescriptionReporting[]>([]);
    const [pagination, setPagination] = useState<IPagination>(defaultPagination);
    const [openDetailDialog, setOpenDetailDialog] = useState<boolean>(false);
    const [sortModel, setSortModel] = useState<GridSortModel>([]);
    const [rowCount, setRowCount] = useState<number>(0);
    const [selectedDrug, setSelectedDrug] = useState<IDrugPrescriptionReporting>(null);

    const [inputFilters, setInputFilters] = useState<Filters>({
        prescriptionVeterinaryId: "",
        prescriptionId: "",
        importDate: [null, null],
        prescriptionDate: [null, null],
        drugType: [],
        drugName: "",
        gtinCode: "",
    });

    useTimeout(() => setPagination((prev) => ({ ...prev, page: 0 })), [inputFilters]);

    useEffect(() => {
        getDeliveryReporting();
    }, [pagination, sortModel]);

    const getDeliveryReporting = async () => {
        const res = await DrugService.getDrugPrescriptionReporting({
            page: pagination.page,
            size: pagination.pageSize,
            sorts: sortModel.map((s) => `${s.field},${s.sort}`),
            ...createPayload(inputFilters),
        });
        if (res.status === HttpStatus.OK) {
            setDrugs(res.data);
            setRowCount(+res.headers[CALYPSO_HEADERS.TABLE_COUNT]);
        }
    };

    const handlePageSizeChange = (pageSize: number) => {
        setPagination({ ...pagination, pageSize, page: 0 });
    };

    const handlePageChange = (page: number) => {
        setPagination({ ...pagination, page });
    };

    const columns = [
        {
            field: "dpeOrdinalNumber",
            headerName: "N° DPE",
            width: 100,
        },
        {
            field: "prescriptionVeterinaryId",
            headerName: "N° Vétérinaire",
            width: 100,
        },
        {
            field: "importDate",
            headerName: "Date de déclaration",
            width: 150,
            valueGetter: (params: GridRenderCellParams) => DateTime.fromISO(params.row.importDate).toLocaleString(),
        },
        {
            field: "prescriptionDate",
            headerName: "Date de l'ordonnance",
            width: 150,
            valueGetter: (params: GridRenderCellParams) =>
                params.row.prescriptionDate ? DateTime.fromISO(params.row.prescriptionDate).toLocaleString() : "",
        },
        {
            field: "prescriptionId",
            headerName: "ID Ordonnance",
            width: 250,
        },
        {
            field: "drugType",
            headerName: "Type de médicaments",
            width: 150,
        },
        {
            field: "gtinCode",
            headerName: "Code GTIN",
            width: 250,
        },
        {
            field: "drugName",
            headerName: "Nom du médicament",
            flex: 1,
        },
        {
            ...ActionsColumnProps,
            renderCell: (params: GridRenderCellParams) => (
                <Box>
                    <ViewAction
                        title="Voir le détail"
                        onClick={() => {
                            setOpenDetailDialog(true);
                            setSelectedDrug(params.row);
                        }}
                    />
                </Box>
            ),
            disableColumnMenu: true,
            width: 100,
        },
    ];

    const filterConfigurations: FilterConfigurations<Filters> = {
        prescriptionVeterinaryId: { label: "N°Ordre Vétérinaire prescripteur", type: FilterType.INPUT },
        importDate: { label: "Date de déclaration", type: FilterType.DATEPICKER },
        prescriptionDate: { label: "Date de l’ordonnance", type: FilterType.DATEPICKER },
        prescriptionId: { label: "Identifiant unique de l’ordonnance", type: FilterType.INPUT },
        drugType: {
            label: "Type de médicaments",
            type: FilterType.SELECT,
            values: [
                { label: "V", key: "V" },
                { label: "H", key: "H" },
            ],
        },
        gtinCode: { label: "Code GTIN", type: FilterType.INPUT },
        drugName: { label: "Nom du médicament", type: FilterType.INPUT },
    };

    return (
        <Stack spacing={2} height="100%" width="100%">
            <Typography variant="h4">Suivi des ordonnances</Typography>
            <Card variant="outlined" sx={{ display: "flex", flexGrow: 1, flexDirection: "column" }}>
                <CardContent
                    sx={{
                        "& .excluded": {
                            backgroundColor: colors.lightGray,
                        },
                    }}
                >
                    <Stack height="100%" spacing={1}>
                        <Box sx={{ pt: 2 }}>
                            <GenericFilters
                                inputFilters={inputFilters}
                                filterConfigurations={filterConfigurations}
                                initialValues={{
                                    prescriptionVeterinaryId: "",
                                    prescriptionId: "",
                                    importDate: [null, null],
                                    prescriptionDate: [null, null],
                                    drugType: [],
                                    drugName: "",
                                    gtinCode: "",
                                }}
                                setInputFilters={setInputFilters}
                            />
                        </Box>
                        <Box height="100%">
                            <GenericTable
                                columns={columns}
                                rows={drugs}
                                autoHeight
                                onPageSizeChange={handlePageSizeChange}
                                onPageChange={handlePageChange}
                                page={pagination.page}
                                pageSize={pagination.pageSize}
                                rowCount={rowCount}
                                getRowId={(row) => row.uuid}
                                onSortModelChange={(model) => setSortModel(model)}
                                getRowClassName={(params) => (params.row.excluded ? "excluded" : "")}
                                rowsPerPageOptions={[10, 25, 50, 100, 250]}
                                sortingMode="server"
                                paginationMode="server"
                                filterMode="server"
                            />
                        </Box>
                    </Stack>
                </CardContent>
            </Card>
            {openDetailDialog && <DetailDialog onClose={() => setOpenDetailDialog(false)} drug={selectedDrug} />}
        </Stack>
    );
}
