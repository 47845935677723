// === NPM
import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Box, Tabs, Typography } from "@mui/material";
import { HttpStatus } from "../../../interfaces/global";
import { IReferential } from "../../../interfaces/referential";
import { UserType } from "../../../interfaces/user";
import {
    IMonitoringInterventionForm,
    InterventionCorrectionType,
    MonitoringInterventionStatus,
} from "../../../interfaces/vaccination";
import { Preference, UserSituation } from "../../../resources/PermissionConstant";
import PermissionsTab from "../../../resources/PermissionsTab";
import { getEnumKeyByValue, nullifyEmptyFields } from "../../../resources/utils";
import { routerLinks } from "../../../routers/RouterConstant";
import { useAuth } from "../../../routers/useAuth";
import VaccinationService from "../../../services/VaccinationService";
// === LOCAL
import PageContent from "../../generics/layout/PageContent";
import { FormMonitoringInterventionProvider } from "./Form/containers/useFormMonitoringIntervention";
import { IMonitoringInterventionCorrection } from "./interface";

export default function MonitoringIntervention() {
    const navigate = useNavigate();
    const auth = useAuth();
    const location = useLocation();
    const [loading, setLoading] = useState<boolean>(true);
    const [horsePower, setHorsePower] = useState<IReferential[]>([]);
    const [serologicalTypes, setSerologicalTypes] = useState<IReferential[]>([]);
    const [clinicalNonComplianceReasons, setClinicalNonComplianceReasons] = useState<IReferential[]>([]);
    const [nonFullfilmentReasons, setNonFullfilmentReasons] = useState<IReferential[]>([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        Promise.all([
            getHorsePower(),
            getSerologicalTypes(),
            getClinicalNonComplianceReasons(),
            getNonFullfilmentReasons(),
        ]).then(() => {
            setLoading(false);
        });
    };

    const getSerologicalTypes = async () => {
        const res = await VaccinationService.getSerologicalTypes();
        if (res.status === HttpStatus.OK) {
            setSerologicalTypes(res.data);
        }
    };

    const getHorsePower = async () => {
        const res = await VaccinationService.getHorsePower();
        if (res.status === HttpStatus.OK) {
            setHorsePower(res.data);
        }
    };

    const getClinicalNonComplianceReasons = async () => {
        const res = await VaccinationService.getClinicalNonComplianceReasons();
        if (res.status === HttpStatus.OK) {
            setClinicalNonComplianceReasons(res.data);
        }
    };

    const getNonFullfilmentReasons = async () => {
        const res = await VaccinationService.getNonFullfilmentReasons();
        if (res.status === HttpStatus.OK) {
            setNonFullfilmentReasons(res.data);
        }
    };

    const formatIntervention = (intervention: IMonitoringInterventionForm): IMonitoringInterventionForm => {
        switch (intervention.generalInformation.status) {
            case getEnumKeyByValue(MonitoringInterventionStatus, MonitoringInterventionStatus.REALIZED):
                return nullifyEmptyFields({
                    ...intervention,
                    generalInformation: {
                        ...intervention.generalInformation,
                        nonFullfilmentReason: null,
                    },
                });
            case getEnumKeyByValue(MonitoringInterventionStatus, MonitoringInterventionStatus.PLANNED):
                return nullifyEmptyFields({
                    finalized: false,
                    generalInformation: {
                        ...intervention.generalInformation,
                        nonFullfilmentReason: null,
                        plateNumber: null,
                        horsePower: null,
                        distance: null,
                    },
                    interventionData: null,
                    workshops: intervention.workshops,
                });
            case getEnumKeyByValue(MonitoringInterventionStatus, MonitoringInterventionStatus.NOT_REALIZED):
                return intervention;
        }
    };

    const correctIntervention = async (
        correctionType: InterventionCorrectionType,
        reason: string,
        intervention: IMonitoringInterventionForm
    ) => {
        const formattedIntervention = { ...formatIntervention(intervention), finalized: true };
        const payload: IMonitoringInterventionCorrection = {
            type: correctionType,
            reason: reason,
            body: formattedIntervention,
        };
        const res = await VaccinationService.correctIntervention(intervention.generalInformation.id, payload);
        if (res.status === HttpStatus.NO_CONTENT) {
            navigate(routerLinks.iahp.monitoringIntervention.view());
            toast.success(
                correctionType === InterventionCorrectionType.UPDATE
                    ? "L'intervention a bien été modifiée"
                    : "L'intervention a bien été supprimée"
            );
        }
        return res;
    };

    return (
        <>
            <Tabs value={location.pathname} sx={{ ml: 12 }}>
                <PermissionsTab
                    value={routerLinks.iahp.monitoringIntervention.view()}
                    onClick={() => navigate(routerLinks.iahp.monitoringIntervention.view())}
                    label="Mes interventions de surveillance"
                />
                <PermissionsTab
                    value={routerLinks.iahp.monitoringIntervention.form()}
                    onClick={() => navigate(routerLinks.iahp.monitoringIntervention.form())}
                    label="Saisir une intervention de surveillance"
                    permissions={[UserSituation.REGISTERED_IN_PRACTICE]}
                    preferences={[Preference.IAHP]}
                />
            </Tabs>
            <FormMonitoringInterventionProvider>
                <PageContent>
                    {auth.userInfo.type !== UserType.VETERINARY ||
                    auth.userInfo.properties.dpes.some((dpe) => dpe.sanitaryDpe) ? (
                        <Outlet
                            context={{
                                loading,
                                horsePower,
                                serologicalTypes,
                                clinicalNonComplianceReasons,
                                nonFullfilmentReasons,
                                correctIntervention,
                                formatIntervention,
                            }}
                        />
                    ) : (
                        <Box display="flex" alignItems="center" justifyContent="center" width="100%">
                            <Typography variant="h4">
                                Vous ne pouvez pas effectuer de saisie car vous ne possédez pas de DPE ayant un lien
                                vétérinaire sanitaire.
                            </Typography>
                        </Box>
                    )}
                </PageContent>
            </FormMonitoringInterventionProvider>
        </>
    );
}
