// === NPM
import React, { useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Stack, Typography } from "@mui/material";
import { useVirtualizer } from "@tanstack/react-virtual";
import { ExpandMore } from "@mui/icons-material";
// === LOCAL
import { ITag, UserTypeHeader, ICategory } from "../../interfaces/faq";
import { HttpStatus } from "../../interfaces/global";
import FaqService from "../../services/FaqService";
import QuestionCard from "./containers/QuestionCard";
import { colors } from "../../resources/CssConstant";
import FaqFilters from "./containers/FaqFilters";
import useTimeout from "../../hooks/useTimeout";
import { createPayload } from "../../resources/utils";
import FaqSkeleton from "./containers/FaqSkeleton";
import { IUserQuestionShort, IFaqFilters } from "./interface";

export default function Faq() {
    const parentRef = React.useRef<HTMLDivElement | null>(null);
    const parentOffsetRef = React.useRef(0);

    const [tags, setTags] = useState<ITag[]>([]);
    const [categories, setCategories] = useState<ICategory[]>([]);
    const [questions, setQuestions] = useState<IUserQuestionShort[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [filters, setFilters] = useState<IFaqFilters>({
        search: "",
        tags: [],
    });

    const displayCategories = categories.filter((category) => questions.some((q) => q.categoryUuid === category.uuid));

    React.useLayoutEffect(() => {
        parentOffsetRef.current = parentRef.current?.offsetTop ?? 0;
    }, []);

    const virtualizer = useVirtualizer({
        count: displayCategories.length + 1,
        estimateSize: () => 800,
        getScrollElement: () => parentRef.current,
        paddingEnd: 200,
    });
    const items = virtualizer.getVirtualItems();
    const start = items.length > 0 ? items[0].start : 0;

    useTimeout(() => getQuestions(), [filters]);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        Promise.all([getTags(), getCategories(), getQuestions()]).then(() => {
            setLoading(false);
        });
    };

    const getTags = async () => {
        const res = await FaqService.getTags();
        if (res.status === HttpStatus.OK) setTags(res.data);
    };

    const getCategories = async () => {
        const res = await FaqService.getCategories(UserTypeHeader.USER);
        if (res.status === HttpStatus.OK) setCategories(res.data);
    };

    const getQuestions = async () => {
        const res = await FaqService.getQuestions(UserTypeHeader.USER, createPayload(filters) as IFaqFilters);
        if (res.status === HttpStatus.OK) setQuestions(res.data);
    };

    const renderQuestionGroup = (category: ICategory) => {
        return (
            <Accordion sx={{ background: colors.background, border: "none" }}>
                <AccordionSummary
                    expandIcon={
                        <ExpandMore
                            sx={{
                                fontSize: 37,
                                background: colors.white,
                                borderRadius: "50%",
                                border: `1px solid rgba(0, 0, 0, 0.12)`,
                            }}
                        />
                    }
                >
                    <Typography variant="h5">{category.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack spacing={2}>
                        {questions
                            .filter((q) => q.categoryUuid === category.uuid)
                            .map((question) => (
                                <QuestionCard question={question} key={question.uuid} tags={tags} />
                            ))}
                    </Stack>
                </AccordionDetails>
            </Accordion>
        );
    };

    return loading ? (
        <FaqSkeleton />
    ) : (
        <Box
            ref={parentRef}
            sx={{
                height: "100%",
                width: "100%",
                overflowY: "auto",
                contain: "strict",
                p: 4,
            }}
        >
            <Box
                sx={{
                    height: virtualizer.getTotalSize() + 50,
                    width: "100%",
                    position: "relative",
                    backgroundColor: colors.background,
                    borderRadius: 5,
                }}
            >
                <Stack
                    sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        transform: `translateY(${start}px)`,
                        p: 4,
                    }}
                    spacing={4}
                >
                    {items.map((virtualRow) => {
                        return (
                            <div key={virtualRow.key} data-index={virtualRow.index} ref={virtualizer.measureElement}>
                                {virtualRow.index === 0 ? (
                                    <FaqFilters
                                        key={virtualRow.index}
                                        tags={tags}
                                        filters={filters}
                                        setFilters={setFilters}
                                    />
                                ) : (
                                    renderQuestionGroup(displayCategories[virtualRow.index - 1])
                                )}
                            </div>
                        );
                    })}
                </Stack>
            </Box>
        </Box>
    );
}
