import { LinearProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import { colors } from "../../resources/CssConstant";
import { linearProgressClasses } from "@mui/material/LinearProgress";

const StyledLinearProgress = styled(LinearProgress)(() => ({
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: colors.white,
    },
    [`& .${linearProgressClasses.bar}`]: {
        backgroundColor: "rgb(171, 209, 194)",
    },
}));

export default StyledLinearProgress;
