// === Import: NPM
import React from "react";
import { Link } from "@mui/material";
import { saveAs } from "file-saver";
// === Import: LOCAL
import { IAttachment } from "../../../interfaces/notification";
import NotificationService from "../../../services/NotificationService";
import { HttpStatus } from "../../../interfaces/global";

interface DownloadLinkProps {
    file: IAttachment;
    notificationUuid: string;
}

export default function DownloadLink({ file, notificationUuid }: DownloadLinkProps) {
    const getFile = async () => {
        const res = await NotificationService.getUserNotificationFile(notificationUuid, file.uuid);
        if (res.status === HttpStatus.OK) {
            const file = new Blob([res.data], { type: res.headers["content-type"] });
            saveAs(file, res.headers["content-disposition"].split("filename=")[1].slice(1, -1));
        }
    };

    return (
        <Link component="button" variant="body2" color="inherit" onClick={() => getFile()}>
            {file.filename}
        </Link>
    );
}
