// === NPM
import React, { useEffect, useState } from "react";
import { Stack, Typography } from "@mui/material";
// === LOCAL
import { FilterConfigurations, GenericFilters } from "../../../../../../../../generics/filters/GenericFilters";
import { FilterType } from "../../../../../../../../../interfaces/global";
import { UserType } from "../../../../../../../../../interfaces/user";
import useTimeout from "../../../../../../../../../hooks/useTimeout";

interface PharmacistFilters {
    lastname: string;
    firstname: string;
    ordinalNumber: string;
    pharmacyId: string;
    pharmacyName: string;
}

interface PharmacistProps {
    getUsers: (userType: string, filters) => void;
}

export default function Pharmacist({ getUsers }: PharmacistProps) {
    const [inputFilters, setInputFilters] = useState({
        lastname: "",
        firstname: "",
        ordinalNumber: "",
        pharmacyId: "",
        pharmacyName: "",
    });

    useTimeout(() => {
        getUsers(UserType.PHARMACIST, inputFilters);
    }, [inputFilters]);

    useEffect(() => {
        getUsers(UserType.PHARMACIST, inputFilters);
    }, []);

    const filtersConfiguration: FilterConfigurations<PharmacistFilters> = {
        lastname: { label: "Nom", type: FilterType.INPUT },
        firstname: { label: "Prénom", type: FilterType.INPUT },
        ordinalNumber: { label: "Identifiant", type: FilterType.INPUT },
        pharmacyId: { label: "ID FINESS", type: FilterType.INPUT },
        pharmacyName: { label: "Nom de la pharmacie", type: FilterType.INPUT },
    };

    return (
        <Stack spacing={1}>
            <Typography>Pharmacien</Typography>
            <GenericFilters
                inputFilters={inputFilters}
                filterConfigurations={filtersConfiguration}
                initialValues={{
                    lastname: "",
                    firstname: "",
                    ordinalNumber: "",
                    pharmacyId: "",
                    pharmacyName: "",
                }}
                setInputFilters={setInputFilters}
            />
        </Stack>
    );
}
