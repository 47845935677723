// === Import: NPM
import { Typography } from "@mui/material";
import React, { useState } from "react";

// === Import: LOCAL
import FileUploader from "../FileUploader";
import GenericActionsDialog from "./GenericActionsDialog";
import GenericDialog from "./GenericDialog";

interface ImportDialogProps {
    previousFiles?: File[];
    onClose: () => void;
    onValid: (file: File) => void;
    validateFile: (file: File) => { code: string; message: string } | null;
    title: string;
    acceptedFiles: string[];
}

export default function ImportDialog({
    previousFiles,
    onClose,
    onValid,
    validateFile,
    acceptedFiles,
    title,
}: ImportDialogProps) {
    const [files, setFiles] = useState<File[]>(previousFiles ?? []);
    const [fileError, setFileError] = useState<string>("");

    const validImport = () => {
        if (!files[0]) {
            setFileError("Le fichier est obligatoire");
        } else {
            setFileError("");
            onValid(files[0]);
        }
    };

    return (
        <GenericDialog
            title={title}
            onClose={onClose}
            paperProps={{
                sx: { width: "100%" },
            }}
            renderContent={() => (
                <>
                    <FileUploader
                        files={files}
                        setFiles={(fileList) => {
                            setFiles(fileList);
                            setFileError("");
                        }}
                        required
                        validator={validateFile}
                        acceptedFiles={acceptedFiles}
                        label="Déposer le fichier"
                    />
                    {fileError && (
                        <Typography sx={{ textAlign: "center" }} color="error">
                            {fileError}
                        </Typography>
                    )}
                </>
            )}
            renderActions={() => (
                <GenericActionsDialog
                    onClose={onClose}
                    closeLabel="Fermer"
                    validLabel="Importer"
                    onSubmit={validImport}
                />
            )}
        />
    );
}
