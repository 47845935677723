// === NPM
import React from "react";
import { Stack, Typography } from "@mui/material";
// === LOCAL
import GenericDialog from "../../../../generics/dialogs/GenericDialog";
import GenericCard from "../../../../generics/layout/GenericCard";
import GenericTextField from "../../../../generics/inputs/GenericTextField";
import GenericActionsDialog from "../../../../generics/dialogs/GenericActionsDialog";
import { useForm } from "../../../../../hooks/useForm";
import { FORM_TEXT } from "../../../../../resources/FormUtils";
import { InterventionCorrectionType } from "../../../../../interfaces/vaccination";
import IconBannerText from "../../../../generics/IconBannerText";
import { ReactComponent as Warning } from "../../../../../assets/icons/shared/danger-triangle.svg";
import { statusColor } from "../../../../../resources/CssConstant";
import { defaultTextLimit } from "../../../../../resources/AppConstant";

interface CorrectionDialogProps {
    selectedInterventionId: string;
    correctionType: InterventionCorrectionType;
    onClose: () => void;
    onValid: (motif: string) => void;
}

interface CorrectionField {
    reason: string;
}

export default function CorrectionDialog({
    selectedInterventionId,
    correctionType,
    onClose,
    onValid,
}: CorrectionDialogProps) {
    const { data, errors, handleChange, handleSubmit } = useForm<CorrectionField>({
        initialValues: {
            reason: "",
        },
        validations: {
            reason: {
                required: {
                    value: true,
                    message: FORM_TEXT.required,
                },
            },
        },
        onSubmit: () => {
            onValid(data.reason);
        },
    });

    const renderContent = () => (
        <Stack spacing={2}>
            <Typography variant="h5">Intervention n°{selectedInterventionId}</Typography>
            <IconBannerText
                icon={<Warning />}
                message={
                    correctionType === InterventionCorrectionType.DELETE
                        ? "Attention : la suppression d'une intervention de vaccination est définitive et supprime également le certificat de vaccination dans Calypso. Le certificat déjà édité et transmis ne sera plus valable."
                        : "Attention : la fonctionnalité de correction ne modifie pas le certificat de vaccination déjà généré."
                }
                color={statusColor.warningText}
                backgroundColor={statusColor.warningBackground}
            />
            <GenericCard>
                <Stack spacing={2}>
                    <GenericTextField
                        label="Motif de correction de l'intervention"
                        value={data.reason}
                        onChange={handleChange("reason")}
                        required
                        error={!!errors.reason}
                        helperText={errors.reason ?? `${data.reason.length}/${defaultTextLimit}`}
                        rows={3}
                        maxLength={defaultTextLimit}
                    />
                </Stack>
            </GenericCard>
        </Stack>
    );

    return (
        <GenericDialog
            title={
                correctionType === InterventionCorrectionType.DELETE
                    ? "Suppression de l'intervention de vaccination"
                    : "Correction de l'intervention de vaccination"
            }
            onClose={onClose}
            maxWidth="xl"
            renderContent={renderContent}
            renderActions={() => (
                <GenericActionsDialog onClose={onClose} onSubmit={handleSubmit} validLabel="Continuer" />
            )}
        />
    );
}
