// === NPM
import React, { useEffect, useState } from "react";
import { Box, Button, Chip, Divider, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useOutletContext } from "react-router-dom";
import { Close } from "@mui/icons-material";
// === LOCAL
import { AnchorEl, FilterConfigurations } from "../../../../../../../../generics/filters/GenericFilters";
import { FilterType } from "../../../../../../../../../interfaces/global";
import { UserType } from "../../../../../../../../../interfaces/user";
import useTimeout from "../../../../../../../../../hooks/useTimeout";
import GenericButtonFilter from "../../../../../../../../generics/filters/components/GenericButtonFilter";
import SelectFilter from "../../../../../../../../generics/filters/components/SelectFilter";
import GenericTextField from "../../../../../../../../generics/inputs/GenericTextField";
import { colors } from "../../../../../../../../../resources/CssConstant";
import { AllowedTargetSituation, NotificationContext } from "../../../../../interface";

interface Filters {
    situation: string[];
    lastname: string;
    firstname: string;
    ordinalNumber: string;
    dpeCity: string;
    dpePostalCode: string;
    dpeName: string;
    dpeOrdinalNumber: string;
}

const initialValues = {
    situation: [],
    lastname: "",
    firstname: "",
    ordinalNumber: "",
    dpeCity: "",
    dpePostalCode: "",
    dpeName: "",
    dpeOrdinalNumber: "",
};

interface VeterinariesProps {
    getUsers: (userType: string, filters) => void;
}

export default function Veterinaries({ getUsers }: VeterinariesProps) {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.up("md"));

    const { allowedTargets } = useOutletContext<NotificationContext>();

    const [anchorEls, setAnchorEls] = useState<AnchorEl<string[]>>({});

    const [inputFilters, setInputFilters] = useState<Filters>(initialValues);

    useTimeout(() => {
        getUsers(UserType.VETERINARY, inputFilters);
    }, [inputFilters]);

    useEffect(() => {
        getUsers(UserType.VETERINARY, inputFilters);
    }, []);

    const filterConfigurations: FilterConfigurations<Filters> = {
        situation: {
            label: "Situation",
            type: FilterType.SELECT,
            values: allowedTargets
                .find((target) => target.type === UserType.VETERINARY)
                ?.situations.map((situation) => ({ key: situation, label: AllowedTargetSituation[situation] })),
        },
        lastname: { label: "Nom", type: FilterType.INPUT },
        firstname: { label: "Prénom", type: FilterType.INPUT },
        ordinalNumber: { label: "Numéro ordinal", type: FilterType.INPUT },
        dpeCity: { label: "Ville", type: FilterType.INPUT },
        dpePostalCode: { label: "Code postal", type: FilterType.INPUT },
        dpeName: { label: "Nom du DPE", type: FilterType.INPUT },
        dpeOrdinalNumber: { label: "Numéro ordinal du DPE", type: FilterType.INPUT },
    };

    const setAnchor = (key: string) => (el: HTMLButtonElement | null) => {
        setAnchorEls({ ...anchorEls, [key]: el });
    };

    const handleSelectFilter = (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name } = event.target;
        const currFilter = inputFilters[key];
        currFilter.indexOf(name) === -1 ? currFilter.push(name) : currFilter.splice(currFilter.indexOf(name), 1);
        setInputFilters((prevState) => ({
            ...prevState,
            [key]: [...currFilter],
        }));
    };

    const displayChipValue = (filter: { name: string; value: string; position?: number }) => {
        const config = filterConfigurations[filter.name];
        let value = filter.value;
        switch (config.type) {
            case FilterType.INPUT:
                value = filter.value;
                break;
            case FilterType.SELECT:
                value = config.values?.find((su) => su.key === filter.value)?.label;
                break;
        }

        return value;
    };

    const handleDeleteFilter = (deletedFilter: { name: string; value: string; position?: number }) => {
        let currFilter = inputFilters[deletedFilter.name];
        if (Array.isArray(currFilter)) {
            currFilter = currFilter.filter((f, i) => i !== deletedFilter.position);
        } else {
            currFilter = "";
        }
        setInputFilters((prevState) => ({
            ...prevState,
            [deletedFilter.name]: currFilter,
        }));
    };

    const renderChipFilter = () => {
        const chipList = [];

        Object.entries(inputFilters).forEach(([name, value]) => {
            if (Array.isArray(value)) {
                value.forEach((val, index) => {
                    if (!val) return;
                    chipList.push({ name: name, value: val, position: index });
                });
            } else if (value) {
                chipList.push({ name, value });
            }
        });

        return (
            chipList.length > 0 && (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5, mt: 2 }}>
                    {chipList.map((f, i) => {
                        // retrieve config & got value from it
                        return (
                            <Chip
                                key={i}
                                label={`${filterConfigurations[f.name].label} : ${displayChipValue(f)}`}
                                onDelete={() => handleDeleteFilter(f)}
                                sx={{
                                    "& .MuiChip-deleteIcon": {
                                        color: colors.black,
                                    },
                                }}
                                deleteIcon={<Close />}
                            />
                        );
                    })}
                    <Button
                        onClick={() => {
                            setInputFilters({ ...initialValues });
                        }}
                        sx={{ textTransform: "none", ml: "auto", mb: 1 }}
                        color="secondary"
                    >
                        Effacer les filtres
                    </Button>
                </Box>
            )
        );
    };

    return (
        <>
            <Stack justifyContent="space-evenly" direction={md ? "row" : "column"} spacing={2}>
                <Stack direction="column" sx={{ mb: 2, flexWrap: "wrap", width: md ? "30%" : "100%" }}>
                    <Typography>Vétérinaire</Typography>
                    <GenericButtonFilter
                        key="situation"
                        active={inputFilters["situation"].some((f) => !!f)}
                        buttonLabel={filterConfigurations.situation.label}
                        open={Boolean(anchorEls["situation"])}
                        anchor={anchorEls["situation"]}
                        setAnchor={setAnchor("situation")}
                    >
                        <SelectFilter
                            config={filterConfigurations.situation}
                            value={inputFilters["situation"]}
                            name="situation"
                            handleSelectFilter={handleSelectFilter}
                        />
                    </GenericButtonFilter>
                    <GenericButtonFilter
                        key="lastname"
                        active={inputFilters["lastname"].length > 0}
                        buttonLabel={filterConfigurations.lastname.label}
                        open={Boolean(anchorEls["lastname"])}
                        anchor={anchorEls["lastname"]}
                        setAnchor={setAnchor("lastname")}
                    >
                        <Box p={2}>
                            <GenericTextField
                                value={inputFilters["lastname"]}
                                onChange={(event) =>
                                    setInputFilters((prevState) => ({
                                        ...prevState,
                                        ["lastname"]: event.target.value,
                                    }))
                                }
                                label={filterConfigurations.lastname.label}
                                size="small"
                                variant="standard"
                                autoFocus
                            />
                        </Box>
                    </GenericButtonFilter>
                    <GenericButtonFilter
                        key="firstname"
                        active={inputFilters["firstname"].length > 0}
                        buttonLabel={filterConfigurations.firstname.label}
                        open={Boolean(anchorEls["firstname"])}
                        anchor={anchorEls["firstname"]}
                        setAnchor={setAnchor("firstname")}
                    >
                        <Box p={2}>
                            <GenericTextField
                                value={inputFilters["firstname"]}
                                onChange={(event) =>
                                    setInputFilters((prevState) => ({
                                        ...prevState,
                                        ["firstname"]: event.target.value,
                                    }))
                                }
                                label={filterConfigurations.firstname.label}
                                size="small"
                                variant="standard"
                                autoFocus
                            />
                        </Box>
                    </GenericButtonFilter>
                    <GenericButtonFilter
                        key="ordinalNumber"
                        active={inputFilters["ordinalNumber"].length > 0}
                        buttonLabel={filterConfigurations.ordinalNumber.label}
                        open={Boolean(anchorEls["ordinalNumber"])}
                        anchor={anchorEls["ordinalNumber"]}
                        setAnchor={setAnchor("ordinalNumber")}
                    >
                        <Box p={2}>
                            <GenericTextField
                                value={inputFilters["ordinalNumber"]}
                                onChange={(event) =>
                                    setInputFilters((prevState) => ({
                                        ...prevState,
                                        ["ordinalNumber"]: event.target.value,
                                    }))
                                }
                                label={filterConfigurations.ordinalNumber.label}
                                size="small"
                                variant="standard"
                                autoFocus
                            />
                        </Box>
                    </GenericButtonFilter>
                </Stack>
                <Stack direction="column" sx={{ mb: 2, flexWrap: "wrap", width: md ? "30%" : "100%" }}>
                    <Typography>Lieu d'exercice</Typography>
                    <GenericButtonFilter
                        key="dpeCity"
                        active={inputFilters["dpeCity"].length > 0}
                        buttonLabel={filterConfigurations.dpeCity.label}
                        open={Boolean(anchorEls["dpeCity"])}
                        anchor={anchorEls["dpeCity"]}
                        setAnchor={setAnchor("dpeCity")}
                    >
                        <Box p={2}>
                            <GenericTextField
                                value={inputFilters["dpeCity"]}
                                onChange={(event) =>
                                    setInputFilters((prevState) => ({
                                        ...prevState,
                                        ["dpeCity"]: event.target.value,
                                    }))
                                }
                                label={filterConfigurations.dpeCity.label}
                                size="small"
                                variant="standard"
                                autoFocus
                            />
                        </Box>
                    </GenericButtonFilter>
                    <GenericButtonFilter
                        key="dpePostalCode"
                        active={inputFilters["dpePostalCode"].length > 0}
                        buttonLabel={filterConfigurations.dpePostalCode.label}
                        open={Boolean(anchorEls["dpePostalCode"])}
                        anchor={anchorEls["dpePostalCode"]}
                        setAnchor={setAnchor("dpePostalCode")}
                    >
                        <Box p={2}>
                            <GenericTextField
                                value={inputFilters["dpePostalCode"]}
                                onChange={(event) =>
                                    setInputFilters((prevState) => ({
                                        ...prevState,
                                        ["dpePostalCode"]: event.target.value,
                                    }))
                                }
                                label={filterConfigurations.dpePostalCode.label}
                                size="small"
                                variant="standard"
                                autoFocus
                            />
                        </Box>
                    </GenericButtonFilter>
                    <GenericButtonFilter
                        key="dpeName"
                        active={inputFilters["dpeName"].length > 0}
                        buttonLabel={filterConfigurations.dpeName.label}
                        open={Boolean(anchorEls["dpeName"])}
                        anchor={anchorEls["dpeName"]}
                        setAnchor={setAnchor("dpeName")}
                    >
                        <Box p={2}>
                            <GenericTextField
                                value={inputFilters["dpeName"]}
                                onChange={(event) =>
                                    setInputFilters((prevState) => ({
                                        ...prevState,
                                        ["dpeName"]: event.target.value,
                                    }))
                                }
                                label={filterConfigurations.dpeName.label}
                                size="small"
                                variant="standard"
                                autoFocus
                            />
                        </Box>
                    </GenericButtonFilter>
                    <GenericButtonFilter
                        key="dpeOrdinalNumber"
                        active={inputFilters["dpeOrdinalNumber"].length > 0}
                        buttonLabel={filterConfigurations.dpeOrdinalNumber.label}
                        open={Boolean(anchorEls["dpeOrdinalNumber"])}
                        anchor={anchorEls["dpeOrdinalNumber"]}
                        setAnchor={setAnchor("dpeOrdinalNumber")}
                    >
                        <Box p={2}>
                            <GenericTextField
                                value={inputFilters["dpeOrdinalNumber"]}
                                onChange={(event) =>
                                    setInputFilters((prevState) => ({
                                        ...prevState,
                                        ["dpeOrdinalNumber"]: event.target.value,
                                    }))
                                }
                                label={filterConfigurations.dpeOrdinalNumber.label}
                                size="small"
                                variant="standard"
                                autoFocus
                            />
                        </Box>
                    </GenericButtonFilter>
                </Stack>
            </Stack>
            {renderChipFilter()}
            <Divider />
        </>
    );
}
