// === Import: NPM
import React, { useState, useContext, Dispatch, SetStateAction, useMemo, PropsWithChildren } from "react";
import { toast } from "react-toastify";
import useWebSocket from "react-use-websocket";

// === Import: LOCAL
import { HttpStatus } from "../interfaces/global";
import { CALYPSO_TOKEN } from "../resources/AppConstant";
import LocalStorageService from "../services/LocalStorageService";

export interface UseWebSocket {
    drugDeliveryLoading: boolean;
    setDrugDeliveryLoading: Dispatch<SetStateAction<boolean>>;
    open: () => void;
    close: () => void;
    workshopImportUpload: number;
    setWorkshopImportUpload: Dispatch<SetStateAction<number>>;
    downloadAvailable: boolean;
}

interface WebSocketMessage {
    requestId: string;
    userId: string;
    message: string;
    requestStatus: {
        code: HttpStatus;
        message: string;
    };
    context: WsContext;
}

enum WsContext {
    DELIVERY = "DELIVERY",
    WORKSHOP_IMPORT_STATUS = "WORKSHOP_IMPORT_STATUS",
    DOWNLOAD_AVAILABLE = "DOWNLOAD_AVAILABLE",
}

export const WebSocketContext = React.createContext<UseWebSocket>(null);

export const useSocket = (): UseWebSocket => {
    return useContext(WebSocketContext);
};

export const WebSocketProvider = ({ children }: PropsWithChildren) => {
    const webSocket = ProvideWebSocket();
    return <WebSocketContext.Provider value={webSocket}>{children}</WebSocketContext.Provider>;
};

const ProvideWebSocket = () => {
    const getUrl = () => {
        return `${window._env_.REACT_APP_WEBSOCKET_URL}/ws/open?token=${LocalStorageService.getLocaleStorageItem(
            CALYPSO_TOKEN
        )}`;
    };

    const [url, setUrl] = useState<string>(getUrl());
    const [isConnected, setIsConnected] = useState<boolean>(false);
    const [drugDeliveryLoading, setDrugDeliveryLoading] = useState<boolean>(false);
    const [workshopImportUpload, setWorkshopImportUpload] = useState<number>(null);
    const [downloadAvailable, setDownloadAvailable] = useState<boolean>(null);

    useMemo(() => setUrl(getUrl()), [isConnected]);

    const handleMessage = (event: WebSocketEventMap["message"]) => {
        const data: WebSocketMessage = JSON.parse(event.data);
        if (data.requestStatus.code !== HttpStatus.OK) {
            toast.info(data.requestStatus.message);
        }
        switch (data.context) {
            case WsContext.DELIVERY:
                setDrugDeliveryLoading(false);
                break;
            case WsContext.WORKSHOP_IMPORT_STATUS:
                const message: { processed: number; errors: number; total: number } = JSON.parse(data.message);
                setWorkshopImportUpload(Math.floor((message.processed / message.total) * 100));
                break;
            case WsContext.DOWNLOAD_AVAILABLE:
                if (data.requestStatus.code === HttpStatus.OK) {
                    toast.success(data.message);
                    setDownloadAvailable(true);
                }
                break;
        }
    };

    useWebSocket(
        url,
        {
            onError: () => handleClose(),
            onClose: () => handleClose(),
            onMessage: (event: WebSocketEventMap["message"]) => handleMessage(event),
            shouldReconnect: () => false,
        },
        isConnected
    );

    const handleClose = () => {
        setDrugDeliveryLoading(false);
    };

    const open = () => {
        setIsConnected(true);
    };

    const close = () => {
        setIsConnected(false);
        setDrugDeliveryLoading(false);
        setWorkshopImportUpload(null);
        setDownloadAvailable(false);
    };

    return {
        open,
        close,
        drugDeliveryLoading,
        setDrugDeliveryLoading,
        workshopImportUpload,
        setWorkshopImportUpload,
        downloadAvailable,
        setDownloadAvailable,
    };
};
