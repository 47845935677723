// === Import: NPM
import React, { PropsWithChildren } from "react";
import { Preference, UserSituation } from "../../resources/PermissionConstant";
// === Import: LOCAL
import { useAuth } from "../../routers/useAuth";

interface PermissionsCheckProps {
    requiredPermissions: UserSituation[];
    preferences?: Preference[];
}

export default function PermissionsCheck({
    requiredPermissions,
    preferences,
    children,
}: PropsWithChildren<PermissionsCheckProps>) {
    const auth = useAuth();

    if (requiredPermissions.length > 0 && !requiredPermissions.includes(auth.userInfo.situation as UserSituation))
        return null;

    if (preferences?.length > 0 && preferences.every((p) => auth.userInfo.preferences[p] === false)) return null;

    return <>{children}</>;
}
