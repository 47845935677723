// === NPM
import React, { useMemo } from "react";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { Box, Drawer as MuiDrawer, Toolbar, useMediaQuery, useTheme } from "@mui/material";
import { ReactComponent as ApiKey } from "../../assets/icons/drawer/apikey.svg";
import { ReactComponent as ButcherySector } from "../../assets/icons/drawer/butchery_sector.svg";
import { ReactComponent as Buy } from "../../assets/icons/drawer/buy.svg";
import { ReactComponent as ContinuousTrainingReferential } from "../../assets/icons/drawer/continuous_training_ref.svg";
import { ReactComponent as Dashboard } from "../../assets/icons/drawer/dashboard.svg";
import { ReactComponent as Delivery } from "../../assets/icons/drawer/delivery.svg";
import { ReactComponent as DrugReferential } from "../../assets/icons/drawer/drug_ref.svg";
import { ReactComponent as Establishment } from "../../assets/icons/drawer/establishment.svg";
import { ReactComponent as Exports } from "../../assets/icons/drawer/exports.svg";
import { ReactComponent as Faq } from "../../assets/icons/drawer/faq.svg";
import { ReactComponent as Farm } from "../../assets/icons/drawer/farm.svg";
import { ReactComponent as HealthReporting } from "../../assets/icons/drawer/health_reporting.svg";
import { ReactComponent as Information } from "../../assets/icons/drawer/information.svg";
import { ReactComponent as Notification } from "../../assets/icons/drawer/notification.svg";
import { ReactComponent as PowerBi } from "../../assets/icons/drawer/powerbi.svg";
import { ReactComponent as Profile } from "../../assets/icons/drawer/profile.svg";
import { ReactComponent as GlobalReferential } from "../../assets/icons/drawer/referential_global.svg";
import { ReactComponent as Settings } from "../../assets/icons/drawer/setting.svg";
import { ReactComponent as Traceability } from "../../assets/icons/drawer/traceability.svg";
import { ReactComponent as ManualDelivery } from "../../assets/icons/shared/edit_circular.svg";
import { ReactComponent as Catalog } from "../../assets/icons/shared/paper.svg";
import { ReactComponent as Show } from "../../assets/icons/shared/show.svg";
import { ReactComponent as Syringe } from "../../assets/icons/shared/syringe.svg";
import { ReactComponent as User } from "../../assets/icons/shared/user.svg";
import { useProvideGlobal } from "../../context/useGlobalContext";
import { FeatureFlags, hasFeatureFlag } from "../../resources/hasFeatureFlag";
// === LOCAL
import { Preference, UserSituation } from "../../resources/PermissionConstant";
import { routerLinks } from "../../routers/RouterConstant";
import { useAuth } from "../../routers/useAuth";
import IconActionButton from "../generics/actions/IconActionButton";
import DrawerClose from "./containers/DrawerClose";
import DrawerOpen from "./containers/DrawerOpen";
import { IDrawerSection } from "./interface";

export function Drawer() {
    const auth = useAuth();
    const drawerContext = useProvideGlobal();
    const drawerWidth = drawerContext.drawerOpen ? 320 : 80;
    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.up("sm"));

    const config: IDrawerSection[] = useMemo<IDrawerSection[]>(() => {
        return [
            {
                label: "Mes formations",
                permissions: [UserSituation.REGISTERED_IN_PRACTICE, UserSituation.ADMIN_CALYPSO],
                children: [
                    {
                        label: "Mes formations continues",
                        permissions: [UserSituation.REGISTERED_IN_PRACTICE],
                        icon: <ContinuousTrainingReferential />,
                        path: routerLinks.training.continuousTraining(),
                    },
                    {
                        label: "Catalogue de formations continues",
                        permissions: [UserSituation.REGISTERED_IN_PRACTICE, UserSituation.ADMIN_CALYPSO],
                        icon: <Catalog />,
                        path: routerLinks.training.continousTrainingCatalog(),
                    },
                ],
            },
            {
                label: "Mes cessions antimicrobiens",
                permissions: [
                    UserSituation.ADMIN_CALYPSO,
                    UserSituation.ANMV_MED,
                    UserSituation.FAM_DAM_ADMIN,
                    UserSituation.FAM_DAM_USER,
                    UserSituation.PHARMACIST,
                    UserSituation.PUBLIC_SECTOR_VETERINARIAN,
                    UserSituation.REGISTERED_IN_PRACTICE,
                    UserSituation.THIRD_COUNTRIES_VETERINARY_REGISTERED_DIPLOMA,
                ],
                children: [
                    {
                        label: "Mes données de cession",
                        permissions: [
                            UserSituation.ADMIN_CALYPSO,
                            UserSituation.ANMV_MED,
                            UserSituation.FAM_DAM_ADMIN,
                            UserSituation.FAM_DAM_USER,
                            UserSituation.PHARMACIST,
                            UserSituation.PUBLIC_SECTOR_VETERINARIAN,
                            UserSituation.REGISTERED_IN_PRACTICE,
                            UserSituation.THIRD_COUNTRIES_VETERINARY_REGISTERED_DIPLOMA,
                        ],
                        icon: <Delivery />,
                        expandedValue: "delivery",
                        selectedPath: routerLinks.delivery.dashboard.base(),
                        children: [
                            {
                                label: "Suivi des flux de collecte",
                                permissions: [
                                    UserSituation.ADMIN_CALYPSO,
                                    UserSituation.ANMV_MED,
                                    UserSituation.FAM_DAM_ADMIN,
                                    UserSituation.FAM_DAM_USER,
                                    UserSituation.PHARMACIST,
                                    UserSituation.PUBLIC_SECTOR_VETERINARIAN,
                                    UserSituation.REGISTERED_IN_PRACTICE,
                                    UserSituation.THIRD_COUNTRIES_VETERINARY_REGISTERED_DIPLOMA,
                                ],
                                path: routerLinks.delivery.dashboard.trackingLogs(),
                            },
                            {
                                label: "Suivi des cessions",
                                permissions: [
                                    UserSituation.ADMIN_CALYPSO,
                                    UserSituation.ANMV_MED,
                                    UserSituation.FAM_DAM_ADMIN,
                                    UserSituation.FAM_DAM_USER,
                                    UserSituation.PHARMACIST,
                                    UserSituation.PUBLIC_SECTOR_VETERINARIAN,
                                    UserSituation.REGISTERED_IN_PRACTICE,
                                    UserSituation.THIRD_COUNTRIES_VETERINARY_REGISTERED_DIPLOMA,
                                ],
                                path: routerLinks.delivery.dashboard.deliveries(),
                            },
                            {
                                label: "Suivi des ordonnances",
                                permissions: [
                                    UserSituation.ADMIN_CALYPSO,
                                    UserSituation.ANMV_MED,
                                    UserSituation.PUBLIC_SECTOR_VETERINARIAN,
                                    UserSituation.REGISTERED_IN_PRACTICE,
                                    UserSituation.THIRD_COUNTRIES_VETERINARY_REGISTERED_DIPLOMA,
                                ],
                                path: routerLinks.delivery.dashboard.prescriptions(),
                            },
                        ],
                    },
                    {
                        label: "Mes tableaux d'analyse",
                        permissions: [
                            UserSituation.ADMIN_CALYPSO,
                            UserSituation.ANMV_MED,
                            UserSituation.REGISTERED_IN_PRACTICE,
                            UserSituation.PUBLIC_SECTOR_VETERINARIAN,
                            UserSituation.THIRD_COUNTRIES_VETERINARY_REGISTERED_DIPLOMA,
                            UserSituation.LIBERAL_VETERINARY,
                        ],
                        icon: <PowerBi />,
                        expandedValue: "delivery_pbi",
                        selectedPath: routerLinks.delivery.powerBi.base(),
                        children: [
                            {
                                label: "Tableaux de suivi",
                                permissions: [UserSituation.ADMIN_CALYPSO, UserSituation.ANMV_MED],
                                path: routerLinks.delivery.powerBi.followUp(),
                            },
                            {
                                label: "Tableaux d'indicateurs",
                                permissions: [UserSituation.ADMIN_CALYPSO, UserSituation.ANMV_MED],
                                path: routerLinks.delivery.powerBi.indicators(),
                            },
                            {
                                label: "Tableau de bord",
                                permissions: [],
                                path: routerLinks.delivery.powerBi.dashBoard(),
                            },
                        ],
                    },
                    {
                        label: "Mes saisies manuelles",
                        permissions: [
                            UserSituation.PHARMACIST,
                            UserSituation.PUBLIC_SECTOR_VETERINARIAN,
                            UserSituation.REGISTERED_IN_PRACTICE,
                            UserSituation.THIRD_COUNTRIES_VETERINARY_REGISTERED_DIPLOMA,
                        ],
                        icon: <ManualDelivery />,
                        expandedValue: "manual_delivery",
                        selectedPath: routerLinks.delivery.manualDelivery.base(),
                        children: [
                            {
                                label: "Consultation des saisies manuelles",
                                permissions: [
                                    UserSituation.PHARMACIST,
                                    UserSituation.PUBLIC_SECTOR_VETERINARIAN,
                                    UserSituation.REGISTERED_IN_PRACTICE,
                                    UserSituation.THIRD_COUNTRIES_VETERINARY_REGISTERED_DIPLOMA,
                                ],
                                path: routerLinks.delivery.manualDelivery.view(),
                            },
                            {
                                label: "Déclaration de saisie manuelle",
                                permissions: [
                                    UserSituation.PHARMACIST,
                                    UserSituation.PUBLIC_SECTOR_VETERINARIAN,
                                    UserSituation.REGISTERED_IN_PRACTICE,
                                    UserSituation.THIRD_COUNTRIES_VETERINARY_REGISTERED_DIPLOMA,
                                ],
                                path: routerLinks.delivery.manualDelivery.form(),
                            },
                        ],
                    },
                ],
            },
            {
                label: "Campagnes de vaccination",
                permissions: [
                    UserSituation.ADMIN_CALYPSO,
                    UserSituation.ADMIN_DDPP,
                    UserSituation.ADMIN_DGAL,
                    UserSituation.ADMIN_DRAAF,
                    UserSituation.USER_DDPP,
                    UserSituation.USER_DGAL,
                    UserSituation.USER_DRAAF,
                    UserSituation.REGISTERED_IN_PRACTICE,
                ],
                preferences: [Preference.IAHP, Preference.BLUE_TONGUE_CATTLE, Preference.BLUE_TONGUE_SHEEP],
                children: [
                    {
                        label: "Mes commandes de vaccins",
                        permissions: [
                            UserSituation.ADMIN_CALYPSO,
                            UserSituation.ADMIN_DDPP,
                            UserSituation.ADMIN_DGAL,
                            UserSituation.ADMIN_DRAAF,
                            UserSituation.USER_DDPP,
                            UserSituation.USER_DGAL,
                            UserSituation.USER_DRAAF,
                            UserSituation.REGISTERED_IN_PRACTICE,
                        ],
                        preferences: [Preference.IAHP, Preference.BLUE_TONGUE_CATTLE, Preference.BLUE_TONGUE_SHEEP],
                        icon: <Buy />,
                        expandedValue: "vaccine",
                        selectedPath: routerLinks.vaccinationCampaign.vaccine.base,
                        children: [
                            {
                                label: "Suivi des commandes de vaccin",
                                permissions: [
                                    UserSituation.ADMIN_CALYPSO,
                                    UserSituation.ADMIN_DDPP,
                                    UserSituation.ADMIN_DGAL,
                                    UserSituation.ADMIN_DRAAF,
                                    UserSituation.USER_DDPP,
                                    UserSituation.USER_DGAL,
                                    UserSituation.USER_DRAAF,
                                    UserSituation.REGISTERED_IN_PRACTICE,
                                ],
                                preferences: [
                                    Preference.IAHP,
                                    Preference.BLUE_TONGUE_CATTLE,
                                    Preference.BLUE_TONGUE_SHEEP,
                                ],
                                path: routerLinks.vaccinationCampaign.vaccine.view(),
                            },
                            {
                                label: "Saisie d'une commande de vaccin",
                                permissions: [UserSituation.REGISTERED_IN_PRACTICE],
                                preferences: [
                                    Preference.IAHP,
                                    Preference.BLUE_TONGUE_CATTLE,
                                    Preference.BLUE_TONGUE_SHEEP,
                                ],
                                path: routerLinks.vaccinationCampaign.vaccine.order(),
                            },
                            {
                                label: "Suivi des mises au rebut",
                                permissions: [
                                    UserSituation.ADMIN_CALYPSO,
                                    UserSituation.ADMIN_DDPP,
                                    UserSituation.ADMIN_DGAL,
                                    UserSituation.ADMIN_DRAAF,
                                    UserSituation.USER_DDPP,
                                    UserSituation.USER_DGAL,
                                    UserSituation.USER_DRAAF,
                                    UserSituation.REGISTERED_IN_PRACTICE,
                                ],
                                preferences: [
                                    Preference.IAHP,
                                    Preference.BLUE_TONGUE_CATTLE,
                                    Preference.BLUE_TONGUE_SHEEP,
                                ],
                                path: routerLinks.vaccinationCampaign.vaccine.scrappingView(),
                            },
                            {
                                label: "Saisie d'une mise au rebut",
                                permissions: [UserSituation.REGISTERED_IN_PRACTICE],
                                preferences: [
                                    Preference.IAHP,
                                    Preference.BLUE_TONGUE_CATTLE,
                                    Preference.BLUE_TONGUE_SHEEP,
                                ],
                                path: routerLinks.vaccinationCampaign.vaccine.scrappingDeclare(),
                            },
                        ],
                    },
                ],
            },
            {
                label: "Vaccination IAHP",
                permissions: [
                    UserSituation.ADMIN_CALYPSO,
                    UserSituation.ADMIN_DDPP,
                    UserSituation.ADMIN_DGAL,
                    UserSituation.ADMIN_DRAAF,
                    UserSituation.USER_DDPP,
                    UserSituation.USER_DGAL,
                    UserSituation.USER_DRAAF,
                    UserSituation.REGISTERED_IN_PRACTICE,
                ],
                preferences: [Preference.IAHP],
                children: [
                    {
                        label: "Élevages",
                        permissions: [
                            UserSituation.ADMIN_CALYPSO,
                            UserSituation.ADMIN_DDPP,
                            UserSituation.ADMIN_DGAL,
                            UserSituation.ADMIN_DRAAF,
                            UserSituation.USER_DDPP,
                            UserSituation.USER_DGAL,
                            UserSituation.USER_DRAAF,
                            UserSituation.REGISTERED_IN_PRACTICE,
                        ],
                        preferences: [Preference.IAHP],
                        icon: <Farm />,
                        expandedValue: "farm",
                        selectedPath: routerLinks.iahp.farm.base,
                        children: [
                            {
                                label: "Mes élevages",
                                permissions: [
                                    UserSituation.ADMIN_CALYPSO,
                                    UserSituation.ADMIN_DDPP,
                                    UserSituation.ADMIN_DGAL,
                                    UserSituation.ADMIN_DRAAF,
                                    UserSituation.USER_DDPP,
                                    UserSituation.USER_DGAL,
                                    UserSituation.USER_DRAAF,
                                    UserSituation.REGISTERED_IN_PRACTICE,
                                ],
                                preferences: [Preference.IAHP],
                                path: routerLinks.iahp.farm.view(),
                            },
                            {
                                label: "Déclarer une relation VS",
                                permissions: [UserSituation.REGISTERED_IN_PRACTICE],
                                preferences: [Preference.IAHP],
                                path: routerLinks.iahp.farm.declareRelation(),
                            },
                        ],
                    },
                    {
                        label: "Mes interventions de vaccination",
                        permissions: [
                            UserSituation.ADMIN_CALYPSO,
                            UserSituation.ADMIN_DDPP,
                            UserSituation.ADMIN_DGAL,
                            UserSituation.ADMIN_DRAAF,
                            UserSituation.USER_DDPP,
                            UserSituation.USER_DGAL,
                            UserSituation.USER_DRAAF,
                            UserSituation.REGISTERED_IN_PRACTICE,
                        ],
                        preferences: [Preference.IAHP],
                        icon: <Syringe />,
                        expandedValue: "vaccination_intervention",
                        selectedPath: routerLinks.iahp.vaccinationIntervention.base,
                        children: [
                            {
                                label: "Mes interventions de vaccination",
                                permissions: [
                                    UserSituation.ADMIN_CALYPSO,
                                    UserSituation.ADMIN_DDPP,
                                    UserSituation.ADMIN_DGAL,
                                    UserSituation.ADMIN_DRAAF,
                                    UserSituation.USER_DDPP,
                                    UserSituation.USER_DGAL,
                                    UserSituation.USER_DRAAF,
                                    UserSituation.REGISTERED_IN_PRACTICE,
                                ],
                                preferences: [Preference.IAHP],
                                path: routerLinks.iahp.vaccinationIntervention.view(),
                            },
                            {
                                label: "Saisir une intervention de vaccination",
                                permissions: [UserSituation.REGISTERED_IN_PRACTICE],
                                preferences: [Preference.IAHP],
                                path: routerLinks.iahp.vaccinationIntervention.form(),
                            },
                        ],
                    },
                    {
                        label: "Mes interventions de surveillance",
                        permissions: [
                            UserSituation.ADMIN_CALYPSO,
                            UserSituation.ADMIN_DDPP,
                            UserSituation.ADMIN_DGAL,
                            UserSituation.ADMIN_DRAAF,
                            UserSituation.USER_DDPP,
                            UserSituation.USER_DGAL,
                            UserSituation.USER_DRAAF,
                            UserSituation.REGISTERED_IN_PRACTICE,
                        ],
                        preferences: [Preference.IAHP],
                        icon: <Show />,
                        expandedValue: "monitoring_intervention",
                        selectedPath: routerLinks.iahp.monitoringIntervention.base,
                        children: [
                            {
                                label: "Mes interventions de surveillance",
                                permissions: [
                                    UserSituation.ADMIN_CALYPSO,
                                    UserSituation.ADMIN_DDPP,
                                    UserSituation.ADMIN_DGAL,
                                    UserSituation.ADMIN_DRAAF,
                                    UserSituation.USER_DDPP,
                                    UserSituation.USER_DGAL,
                                    UserSituation.USER_DRAAF,
                                    UserSituation.REGISTERED_IN_PRACTICE,
                                ],
                                preferences: [Preference.IAHP],
                                path: routerLinks.iahp.monitoringIntervention.view(),
                            },
                            {
                                label: "Saisir une intervention de surveillance",
                                permissions: [UserSituation.REGISTERED_IN_PRACTICE],
                                preferences: [Preference.IAHP],
                                path: routerLinks.iahp.monitoringIntervention.form(),
                            },
                        ],
                    },

                    {
                        label: "Exports",
                        permissions: [
                            UserSituation.ADMIN_CALYPSO,
                            UserSituation.ADMIN_DDPP,
                            UserSituation.ADMIN_DRAAF,
                            UserSituation.ADMIN_DGAL,
                            UserSituation.USER_DDPP,
                            UserSituation.USER_DRAAF,
                            UserSituation.USER_DGAL,
                            UserSituation.REGISTERED_IN_PRACTICE,
                        ],
                        preferences: [Preference.IAHP],
                        icon: <Exports />,
                        path: routerLinks.iahp.exports(),
                    },

                    {
                        label: "Traçabilité IAHP",
                        permissions: [UserSituation.ADMIN_CALYPSO],
                        icon: <Traceability />,
                        expandedValue: "traceability_iahp",
                        selectedPath: routerLinks.iahp.traceabilityIahp.base(),
                        children: [
                            {
                                label: "Interventions de vaccination",
                                permissions: [UserSituation.ADMIN_CALYPSO],
                                path: routerLinks.iahp.traceabilityIahp.vaccinationIntervention(),
                            },
                            {
                                label: "Interventions de surveillance",
                                permissions: [UserSituation.ADMIN_CALYPSO],
                                path: routerLinks.iahp.traceabilityIahp.monitoringIntervention(),
                            },
                            {
                                label: "Mise au rebut",
                                permissions: [UserSituation.ADMIN_CALYPSO],
                                path: routerLinks.iahp.traceabilityIahp.scrapping(),
                            },
                        ],
                    },

                    {
                        label: "Mes tableaux de suivi",
                        permissions: [
                            UserSituation.ADMIN_CALYPSO,
                            UserSituation.ADMIN_DDPP,
                            UserSituation.ADMIN_DGAL,
                            UserSituation.ADMIN_DRAAF,
                            UserSituation.USER_DDPP,
                            UserSituation.USER_DGAL,
                            UserSituation.USER_DRAAF,
                        ],
                        icon: <PowerBi />,
                        path: routerLinks.iahp.iahpPowerBi(),
                    },
                ],
            },

            {
                label: "Mes suivis sanitaires",
                permissions: [UserSituation.ADMIN_CALYPSO, UserSituation.REGISTERED_IN_PRACTICE],
                preferences: [Preference.BUTCHERY_SECTOR],
                children: [
                    {
                        label: "Exclusion de la filière bouchère",
                        permissions: [UserSituation.ADMIN_CALYPSO, UserSituation.REGISTERED_IN_PRACTICE],
                        preferences: [Preference.BUTCHERY_SECTOR],
                        icon: <ButcherySector />,
                        selectedPath: routerLinks.sanitaryFollowUp.butcherySector.base(),
                        expandedValue: "butchery_sector",
                        children: [
                            {
                                label: "Exclusion de la filière bouchère",
                                permissions: [UserSituation.ADMIN_CALYPSO, UserSituation.REGISTERED_IN_PRACTICE],
                                preferences: [Preference.BUTCHERY_SECTOR],
                                path: routerLinks.sanitaryFollowUp.butcherySector.exclusion(),
                            },
                            {
                                label: "Recherche équidés",
                                permissions: [UserSituation.REGISTERED_IN_PRACTICE],
                                preferences: [Preference.BUTCHERY_SECTOR],
                                path: routerLinks.sanitaryFollowUp.butcherySector.search(),
                            },
                        ],
                    },
                ],
            },
            ...(hasFeatureFlag(FeatureFlags.HEALTH_REPORTING)
                ? [
                      {
                          label: "Mes signalements sanitaires",
                          permissions: [
                              UserSituation.ADMIN_CALYPSO,
                              UserSituation.REGISTERED_IN_PRACTICE,
                              UserSituation.ADMIN_DDPP,
                              UserSituation.ADMIN_DGAL,
                              UserSituation.ADMIN_DRAAF,
                              UserSituation.USER_DDPP,
                              UserSituation.USER_DGAL,
                              UserSituation.USER_DRAAF,
                          ],
                          children: [
                              {
                                  label: "Maltraitance animale",
                                  permissions: [
                                      UserSituation.ADMIN_CALYPSO,
                                      UserSituation.REGISTERED_IN_PRACTICE,
                                      UserSituation.ADMIN_DDPP,
                                      UserSituation.ADMIN_DGAL,
                                      UserSituation.ADMIN_DRAAF,
                                      UserSituation.USER_DDPP,
                                      UserSituation.USER_DGAL,
                                      UserSituation.USER_DRAAF,
                                  ],
                                  icon: <HealthReporting />,
                                  selectedPath: routerLinks.healthReporting.base,
                                  expandedValue: "health_reporting",
                                  children: [
                                      {
                                          label: "Mes signalements sanitaires",
                                          permissions: [
                                              UserSituation.ADMIN_CALYPSO,
                                              UserSituation.REGISTERED_IN_PRACTICE,
                                              UserSituation.ADMIN_DDPP,
                                              UserSituation.ADMIN_DGAL,
                                              UserSituation.ADMIN_DRAAF,
                                              UserSituation.USER_DDPP,
                                              UserSituation.USER_DGAL,
                                              UserSituation.USER_DRAAF,
                                          ],
                                          path: routerLinks.healthReporting.animalAbuse.view(),
                                      },
                                      {
                                          label: "Déclarer un nouveau signalement",
                                          permissions: [UserSituation.REGISTERED_IN_PRACTICE],
                                          path: routerLinks.healthReporting.animalAbuse.form(),
                                      },
                                  ],
                              },
                          ],
                      },
                  ]
                : []),
            {
                label: "Administration",
                permissions: [
                    UserSituation.ADMIN_CALYPSO,
                    UserSituation.FAM_DAM_ADMIN,
                    UserSituation.ADMIN_DDPP,
                    UserSituation.ADMIN_DGAL,
                    UserSituation.ADMIN_DRAAF,
                    UserSituation.REGISTERED_IN_PRACTICE,
                    UserSituation.PUBLIC_SECTOR_VETERINARIAN,
                    UserSituation.REGISTERED_IN_PRACTICE,
                    UserSituation.THIRD_COUNTRIES_VETERINARY_REGISTERED_DIPLOMA,
                    UserSituation.ANMV_NOTIF,
                ],
                children: [
                    {
                        label: "Utilisateurs",
                        permissions: [UserSituation.ADMIN_CALYPSO],
                        icon: <User />,
                        path: routerLinks.admin.users.global(),
                    },
                    {
                        label: "Gestion des profils",
                        permissions: [UserSituation.ADMIN_CALYPSO],
                        icon: <Profile />,
                        path: routerLinks.admin.profile(),
                    },
                    {
                        label: `Utilisateurs ${auth.userInfo.properties?.agencyName}`,
                        permissions: [UserSituation.FAM_DAM_ADMIN],
                        icon: <User />,
                        path: routerLinks.admin.users.famDam(),
                        id: "fam_dam_users",
                    },
                    {
                        label: `Utilisateurs ${auth.userInfo.properties?.name}`,
                        permissions: [UserSituation.ADMIN_DDPP, UserSituation.ADMIN_DGAL, UserSituation.ADMIN_DRAAF],
                        icon: <User />,
                        path: routerLinks.admin.users.ma(),
                    },
                    {
                        label: "Paramètres",
                        permissions: [
                            UserSituation.PUBLIC_SECTOR_VETERINARIAN,
                            UserSituation.REGISTERED_IN_PRACTICE,
                            UserSituation.THIRD_COUNTRIES_VETERINARY_REGISTERED_DIPLOMA,
                        ],
                        icon: <Settings />,
                        path: routerLinks.parameters.dpePreferences(),
                    },
                    {
                        label: "Profil",
                        permissions: [
                            UserSituation.PUBLIC_SECTOR_VETERINARIAN,
                            UserSituation.REGISTERED_IN_PRACTICE,
                            UserSituation.THIRD_COUNTRIES_VETERINARY_REGISTERED_DIPLOMA,
                        ],
                        icon: <User />,
                        path: routerLinks.profile,
                    },
                    {
                        label: "Clefs d'API",
                        permissions: [UserSituation.ADMIN_CALYPSO],
                        icon: <ApiKey />,
                        expandedValue: "api_key",
                        selectedPath: routerLinks.admin.apiKey.base(),
                        children: [
                            {
                                label: "API cession de médicaments",
                                permissions: [UserSituation.ADMIN_CALYPSO],
                                path: routerLinks.admin.apiKey.delivery(),
                            },
                            {
                                label: "SI Partenaires",
                                permissions: [UserSituation.ADMIN_CALYPSO],
                                path: routerLinks.admin.apiKey.siPartners(),
                            },
                            {
                                label: "API formations continues",
                                permissions: [UserSituation.ADMIN_CALYPSO],
                                path: routerLinks.admin.apiKey.continuousTraining(),
                            },
                            {
                                label: "Alertes",
                                permissions: [UserSituation.ADMIN_CALYPSO],
                                path: routerLinks.admin.apiKey.alerts(),
                            },
                        ],
                    },
                    {
                        label: "Traçabilité",
                        permissions: [
                            UserSituation.ADMIN_CALYPSO,
                            UserSituation.ADMIN_DDPP,
                            UserSituation.ADMIN_DGAL,
                            UserSituation.ADMIN_DRAAF,
                            UserSituation.USER_DDPP,
                            UserSituation.USER_DGAL,
                            UserSituation.USER_DRAAF,
                        ],
                        icon: <Traceability />,
                        expandedValue: "traceability",
                        selectedPath: routerLinks.admin.traceability.base(),
                        children: [
                            {
                                label: "Utilisateurs",
                                permissions: [UserSituation.ADMIN_CALYPSO],
                                path: routerLinks.admin.traceability.users(),
                            },
                            {
                                label: "Données de cessions",
                                permissions: [UserSituation.ADMIN_CALYPSO],
                                path: routerLinks.admin.traceability.delivery(),
                            },
                            {
                                label: "Déclarations de non cession",
                                permissions: [UserSituation.ADMIN_CALYPSO],
                                path: routerLinks.admin.traceability.nonDelivery(),
                            },
                            {
                                label: "Groupes et établissements FAM/DAM",
                                permissions: [UserSituation.ADMIN_CALYPSO],
                                path: routerLinks.admin.traceability.famDamAgencies(),
                            },
                            {
                                label: "Organismes de formation",
                                permissions: [UserSituation.ADMIN_CALYPSO],
                                path: routerLinks.admin.traceability.organizations(),
                            },
                            {
                                label: "Signalements sanitaires",
                                permissions: [
                                    UserSituation.ADMIN_CALYPSO,
                                    UserSituation.ADMIN_DDPP,
                                    UserSituation.ADMIN_DGAL,
                                    UserSituation.ADMIN_DRAAF,
                                    UserSituation.USER_DDPP,
                                    UserSituation.USER_DGAL,
                                    UserSituation.USER_DRAAF,
                                ],
                                path: routerLinks.admin.traceability.healthReportings(),
                            },
                        ],
                    },
                    {
                        label: "Foire aux questions",
                        permissions: [UserSituation.ADMIN_CALYPSO],
                        icon: <Faq />,
                        expandedValue: "faq_admin",
                        selectedPath: routerLinks.admin.faq.base(),
                        children: [
                            {
                                label: "Catégories et tags",
                                permissions: [UserSituation.ADMIN_CALYPSO],
                                path: routerLinks.admin.faq.categoryAndTag(),
                            },
                            {
                                label: "Gestion des questions",
                                permissions: [UserSituation.ADMIN_CALYPSO],
                                path: routerLinks.admin.faq.questions(),
                            },
                        ],
                    },
                    {
                        label: "Notifications",
                        permissions: [UserSituation.ADMIN_CALYPSO, UserSituation.ANMV_NOTIF],
                        icon: <Notification />,
                        selectedPath: routerLinks.admin.notifications.base(),
                        children: [
                            {
                                label: "Gestion des notifications",
                                permissions: [UserSituation.ADMIN_CALYPSO, UserSituation.ANMV_NOTIF],
                                path: routerLinks.admin.notifications.gestion(),
                            },
                            {
                                label: "Saisie de notification",
                                permissions: [UserSituation.ADMIN_CALYPSO, UserSituation.ANMV_NOTIF],
                                path: routerLinks.admin.notifications.form(),
                            },
                            {
                                label: "Gestion des notifications automatiques",
                                permissions: [UserSituation.ADMIN_CALYPSO],
                                path: routerLinks.admin.notifications.autoNotifConfig(),
                            },
                            {
                                label: "Suivi des notifications automatiques",
                                permissions: [UserSituation.ADMIN_CALYPSO],
                                path: routerLinks.admin.notifications.automatic(),
                            },
                        ],
                    },
                ],
            },
            {
                label: "Référentiels",
                permissions: [
                    UserSituation.ADMIN_CALYPSO,
                    UserSituation.ADMIN_DDPP,
                    UserSituation.ADMIN_DGAL,
                    UserSituation.ADMIN_DRAAF,
                    UserSituation.USER_DDPP,
                    UserSituation.USER_DGAL,
                    UserSituation.USER_DRAAF,
                    UserSituation.ANMV_MED,
                    UserSituation.FAM_DAM_ADMIN,
                    UserSituation.FAM_DAM_USER,
                    UserSituation.PHARMACIST,
                ],
                children: [
                    {
                        label: "Monitoring",
                        permissions: [UserSituation.ADMIN_CALYPSO],
                        icon: <Dashboard />,
                        path: routerLinks.referential.dashboard(),
                    },
                    {
                        label: "Référentiel transverse",
                        permissions: [
                            UserSituation.ADMIN_CALYPSO,
                            UserSituation.ADMIN_DDPP,
                            UserSituation.ADMIN_DGAL,
                            UserSituation.ADMIN_DRAAF,
                            UserSituation.USER_DDPP,
                            UserSituation.USER_DGAL,
                            UserSituation.USER_DRAAF,
                            UserSituation.ANMV_MED,
                            UserSituation.FAM_DAM_ADMIN,
                            UserSituation.FAM_DAM_USER,
                        ],
                        icon: <GlobalReferential />,
                        expandedValue: "referential_global",
                        selectedPath: routerLinks.referential.global.base(),

                        children: [
                            {
                                label: "DPE",
                                permissions: [
                                    UserSituation.ADMIN_CALYPSO,
                                    UserSituation.ADMIN_DDPP,
                                    UserSituation.ADMIN_DGAL,
                                    UserSituation.ADMIN_DRAAF,
                                    UserSituation.USER_DDPP,
                                    UserSituation.USER_DGAL,
                                    UserSituation.USER_DRAAF,
                                    UserSituation.ANMV_MED,
                                    UserSituation.FAM_DAM_ADMIN,
                                    UserSituation.FAM_DAM_USER,
                                ],
                                path: routerLinks.referential.global.dpeDirectory(),
                            },
                            {
                                label: "Établissements",
                                permissions: [UserSituation.FAM_DAM_ADMIN, UserSituation.FAM_DAM_USER],
                                icon: <Establishment />,
                                path: routerLinks.referential.global.famDamAgencies(),
                                id: "establishments",
                            },
                            {
                                label: "Espèces",
                                permissions: [UserSituation.ADMIN_CALYPSO],
                                path: routerLinks.referential.global.species(),
                            },
                            {
                                label: "Ateliers",
                                permissions: [
                                    UserSituation.ADMIN_CALYPSO,
                                    UserSituation.ADMIN_DDPP,
                                    UserSituation.ADMIN_DGAL,
                                    UserSituation.ADMIN_DRAAF,
                                    UserSituation.USER_DDPP,
                                    UserSituation.USER_DGAL,
                                    UserSituation.USER_DRAAF,
                                ],
                                path: routerLinks.referential.global.workshops(),
                            },
                            {
                                label: "Services administratifs",
                                permissions: [UserSituation.ADMIN_CALYPSO],
                                path: routerLinks.referential.global.agriMinistryGeoUnit(),
                            },
                        ],
                    },
                    {
                        label: "Cession de médicament",
                        permissions: [UserSituation.ADMIN_CALYPSO, UserSituation.ANMV_MED, UserSituation.PHARMACIST],
                        icon: <DrugReferential />,
                        expandedValue: "referential_delivery",
                        selectedPath: routerLinks.referential.delivery.base(),

                        children: [
                            {
                                label: "Type identifiant",
                                permissions: [UserSituation.ADMIN_CALYPSO],
                                path: routerLinks.referential.delivery.typeIdentifiant(),
                            },
                            {
                                label: "Médicaments",
                                permissions: [
                                    UserSituation.ADMIN_CALYPSO,
                                    UserSituation.ANMV_MED,
                                    UserSituation.PHARMACIST,
                                ],
                                path: routerLinks.referential.delivery.drugs(),
                            },
                            {
                                label: "Groupes et établissements FAM/DAM",
                                permissions: [UserSituation.ADMIN_CALYPSO, UserSituation.ANMV_MED],
                                path: routerLinks.referential.delivery.famDam(),
                            },
                            {
                                label: "Souches logicielles",
                                permissions: [UserSituation.ADMIN_CALYPSO],
                                path: routerLinks.referential.delivery.drugTransferOrganization(),
                            },
                            {
                                label: "Pharmacies",
                                permissions: [UserSituation.ADMIN_CALYPSO],
                                path: routerLinks.referential.delivery.pharmacies(),
                            },
                        ],
                    },
                    {
                        label: "Formation continue",
                        permissions: [UserSituation.ADMIN_CALYPSO],
                        icon: <ContinuousTrainingReferential />,
                        expandedValue: "referential_continuous_training",
                        selectedPath: routerLinks.referential.training.base(),
                        children: [
                            {
                                label: "Types",
                                permissions: [UserSituation.ADMIN_CALYPSO],
                                path: routerLinks.referential.training.types(),
                            },
                            {
                                label: "Formats",
                                permissions: [UserSituation.ADMIN_CALYPSO],
                                path: routerLinks.referential.training.formats(),
                            },
                            {
                                label: "Organismes",
                                permissions: [UserSituation.ADMIN_CALYPSO],
                                path: routerLinks.referential.training.organizations(),
                            },
                        ],
                    },
                ],
            },
            {
                label: "Informations",
                permissions: [],
                children: [
                    {
                        label: "Foire aux questions",
                        permissions: [],
                        icon: <Information />,
                        path: routerLinks.faq,
                    },
                ],
            },
        ] as IDrawerSection[];
    }, [auth.userInfo]);

    const handleDrawerOpen = () => {
        drawerContext.setDrawerOpen(true);
    };

    const handlerDrawerClose = () => {
        drawerContext.setDrawerOpen(false);
    };

    return (
        <Box sx={{ width: drawerWidth }}>
            <MuiDrawer
                variant={sm ? "permanent" : "temporary"}
                open={drawerContext.drawerOpen}
                anchor="left"
                sx={{
                    "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        border: "none",
                        width: drawerWidth,
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    },
                }}
            >
                <Toolbar />
                <Box minHeight={55} />
                <Box display="flex" justifyContent="flex-end">
                    <IconActionButton
                        title=""
                        icon={drawerContext.drawerOpen ? <ArrowBackIos /> : <ArrowForwardIos />}
                        onClick={drawerContext.drawerOpen ? handlerDrawerClose : handleDrawerOpen}
                    />
                </Box>
                <Box sx={{ overflow: "auto" }}>
                    {drawerContext.drawerOpen ? <DrawerOpen config={config} /> : <DrawerClose config={config} />}
                </Box>
            </MuiDrawer>
        </Box>
    );
}
