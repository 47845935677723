// === Import: NPM
import React from "react";
// === Import: LOCAL
import { IconButton, Tooltip } from "@mui/material";
import { IActionButton } from "../../../interfaces/global";
import { colors } from "../../../resources/CssConstant";
import { useProvideGlobal } from "../../../context/useGlobalContext";

interface IconActionButtonProps extends IActionButton {
    icon: React.ReactElement;
    id?: string;
    color?: string;
}

export default function IconActionButton({ title, onClick, icon, id, color }: IconActionButtonProps) {
    const { loadingTable, loadingButton } = useProvideGlobal();
    return (
        <Tooltip title={title}>
            <IconButton
                onClick={onClick}
                id={id}
                sx={{ alignSelf: "center", color: color ?? colors.primaryColor }}
                disabled={loadingTable || loadingButton}
            >
                {icon}
            </IconButton>
        </Tooltip>
    );
}
