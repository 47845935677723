// === NPM
import { Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

// === LOCAL
import { HttpStatus } from "../../../interfaces/global";
import { ISpeciesDetails } from "../../../interfaces/referential";
import { UserSituation } from "../../../resources/PermissionConstant";
import { routerLinks } from "../../../routers/RouterConstant";
import ReferentialService from "../../../services/ReferentialService";
import PageContent from "../../generics/layout/PageContent";
import FamDamDeliveryTour from "./containers/FamDamDeliveryTour";
import PermissionsTab from "../../../resources/PermissionsTab";

export default function Dashboard() {
    const location = useLocation();
    const navigate = useNavigate();
    const [species, setSpecies] = useState<ISpeciesDetails[]>([]);

    useEffect(() => {
        if (location.pathname === routerLinks.delivery.dashboard.base())
            navigate(routerLinks.delivery.dashboard.trackingLogs());
    }, []);

    useEffect(() => {
        getSpecies();
    }, []);

    const getSpecies = async () => {
        const res = await ReferentialService.getSpecies({ use: ["drug_transfer"] });
        if (res.status === HttpStatus.OK) {
            setSpecies(res.data);
        }
    };

    return (
        <>
            <FamDamDeliveryTour />
            <>
                <Tabs value={location.pathname} sx={{ ml: 12 }}>
                    <PermissionsTab
                        value={routerLinks.delivery.dashboard.trackingLogs()}
                        onClick={() => navigate(routerLinks.delivery.dashboard.trackingLogs())}
                        label="Suivi des flux de collecte"
                    />
                    <PermissionsTab
                        value={routerLinks.delivery.dashboard.deliveries()}
                        onClick={() => navigate(routerLinks.delivery.dashboard.deliveries())}
                        label="Suivi des cessions"
                        id="drug-delivery-tab"
                    />
                    <PermissionsTab
                        value={routerLinks.delivery.dashboard.prescriptions()}
                        onClick={() => navigate(routerLinks.delivery.dashboard.prescriptions())}
                        label="Suivi des ordonnances"
                        permissions={[
                            UserSituation.ADMIN_CALYPSO,
                            UserSituation.ANMV_MED,
                            UserSituation.PUBLIC_SECTOR_VETERINARIAN,
                            UserSituation.REGISTERED_IN_PRACTICE,
                            UserSituation.THIRD_COUNTRIES_VETERINARY_REGISTERED_DIPLOMA,
                        ]}
                    />
                </Tabs>
                <PageContent>
                    <Outlet context={{ species }} />
                </PageContent>
            </>
        </>
    );
}
