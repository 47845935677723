// === NPM
import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Box, Tabs, Typography } from "@mui/material";
import { HttpStatus } from "../../../interfaces/global";
import { IReferential, ISpeciesDetails } from "../../../interfaces/referential";
import { UserType } from "../../../interfaces/user";
import { InterventionCorrectionType, IVaccine } from "../../../interfaces/vaccination";
import { Preference, UserSituation } from "../../../resources/PermissionConstant";
import PermissionsTab from "../../../resources/PermissionsTab";
import { routerLinks } from "../../../routers/RouterConstant";
import { useAuth } from "../../../routers/useAuth";
import ReferentialService from "../../../services/ReferentialService";
import VaccinationService from "../../../services/VaccinationService";
// === LOCAL
import PageContent from "../../generics/layout/PageContent";
import { FormInterventionProvider } from "./FormIntervention/containers/useFormIntervention";
import { IVaccinationInterventionForm } from "./interface";

export default function VaccinationIntervention() {
    const navigate = useNavigate();
    const auth = useAuth();
    const location = useLocation();
    const [vaccines, setVaccines] = useState<IVaccine[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [species, setSpecies] = useState<ISpeciesDetails[]>([]);
    const [horsePower, setHorsePower] = useState<IReferential[]>([]);
    const [sectors, setSectors] = useState<IReferential[]>([]);
    const [tiers, setTiers] = useState<IReferential[]>([]);
    const [correctionReason, setCorrectionReason] = useState<string>(null);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        Promise.all([getVaccines(), getSpecies(), getHorsePower(), getSectors(), getTiers()]).then(() => {
            setLoading(false);
        });
    };

    const getVaccines = async () => {
        setLoading(true);
        const res = await VaccinationService.getVaccines();
        if (res.status === HttpStatus.OK) {
            setVaccines(res.data);
        }
        setLoading(false);
    };

    const getHorsePower = async () => {
        const res = await VaccinationService.getHorsePower();
        if (res.status === HttpStatus.OK) {
            setHorsePower(res.data);
        }
    };

    const getSpecies = async () => {
        const res = await ReferentialService.getSpecies({ use: ["iahp"] });
        if (res.status === HttpStatus.OK) {
            setSpecies(res.data);
        }
    };

    const getSectors = async () => {
        const res = await VaccinationService.getSectors();
        if (res.status === HttpStatus.OK) {
            setSectors(res.data);
        }
    };

    const getTiers = async () => {
        const res = await VaccinationService.getTiers();
        if (res.status === HttpStatus.OK) {
            setTiers(res.data);
        }
    };

    const correctIntervention = async (
        intervention: IVaccinationInterventionForm,
        reason: string,
        type: InterventionCorrectionType
    ) => {
        const res = await VaccinationService.putInterventionCorrection(intervention.generalInformation.id, {
            body: intervention,
            reason: reason,
            type: type,
        });

        if (res.status === HttpStatus.NO_CONTENT) {
            navigate(routerLinks.iahp.vaccinationIntervention.view());
            toast.success(
                type === InterventionCorrectionType.UPDATE
                    ? "L'intervention a bien été modifiée"
                    : "L'intervention a bien été supprimée"
            );
            setCorrectionReason(null);
        }

        return res;
    };

    return (
        <>
            <Tabs value={location.pathname} sx={{ ml: 12 }}>
                <PermissionsTab
                    value={routerLinks.iahp.vaccinationIntervention.view()}
                    onClick={() => navigate(routerLinks.iahp.vaccinationIntervention.view())}
                    label="Mes interventions de vaccination"
                />
                <PermissionsTab
                    value={routerLinks.iahp.vaccinationIntervention.form()}
                    onClick={() => navigate(routerLinks.iahp.vaccinationIntervention.form())}
                    label="Saisir une intervention de vaccination"
                    permissions={[UserSituation.REGISTERED_IN_PRACTICE]}
                    preferences={[Preference.IAHP]}
                />
            </Tabs>
            <FormInterventionProvider>
                <PageContent>
                    {auth.userInfo.type !== UserType.VETERINARY ||
                    auth.userInfo.properties.dpes.some((dpe) => dpe.sanitaryDpe) ? (
                        <Outlet
                            context={{
                                vaccines,
                                loading,
                                species: species.filter((s) => !s.parentUuid),
                                subSpecies: species.filter((s) => s.parentUuid),
                                horsePower,
                                sectors,
                                tiers,
                                correctIntervention,
                                correctionReason,
                                setCorrectionReason,
                            }}
                        />
                    ) : (
                        <Box display="flex" alignItems="center" justifyContent="center" width="100%">
                            <Typography variant="h4">
                                Vous ne pouvez pas effectuer de saisie car vous ne possédez pas de DPE ayant un lien
                                vétérinaire sanitaire.
                            </Typography>
                        </Box>
                    )}
                </PageContent>
            </FormInterventionProvider>
        </>
    );
}
