export interface ICity {
    name: string;
    inseeCode: string;
    postalCodes: string[];
    department: IDepartment;
}

export interface IReferential {
    key: string;
    label: string;
}

export interface IDrug {
    uuid?: string;
    name: string;
    gtinCode: string;
    ammStatus: string;
    ammDate: string;
    type: "V" | "H";
    pharmaceuticalForm: string;
    quantityUnit: string;
    unpackedQuantityUnit: string;
    activeSubstances: ActiveSubstance[];
}

interface ActiveSubstance {
    name: string;
    concentration: number;
    concentrationUnit: string;
}

export interface IDepartment {
    name: string;
    inseeCode: string;
}

export enum LocalizationType {
    DDPP = "Départemental",
    DRAAF = "Régional",
    DGAL = "National",
}

export interface IRegion {
    name: string;
    inseeCode: string;
    departments: IDepartment[];
}

export interface IOrganization {
    uuid: string;
    email: string;
    label: string;
}

export interface IContinuousTrainingOrganization extends IOrganization {
    archived: boolean;
    externalId: string;
    other: boolean;
    type: string;
    teletransmissionDate: string;
    approvalStartDate: string;
    approvalEndDate: string;
}

export interface ISpecies {
    uuid: string;
    label: string;
    code: string;
    use: string;
    parentUuid?: string;
}

export interface ISpeciesDetails extends ISpecies {
    subSpeciesCount: number;
}

export interface ITypeIdentifiant {
    label: string;
    description: string;
}

export enum TypeIdentifiantUse {
    UNKNOWN = "UNKNOWN",
    FARM = "FARM",
}
