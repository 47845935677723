import React from "react";
import { Tab, TabProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useAuth } from "../routers/useAuth";
import { Preference, UserSituation } from "./PermissionConstant";

const StyledTab = styled(Tab)(`
  &.Mui-selected {
    font-family: 'Nunito-Bold';
  }
`);

interface PermissionsTabProps extends TabProps {
    permissions?: UserSituation[];
    preferences?: Preference[];
}

export default function PermissionsTab(props: PermissionsTabProps) {
    const auth = useAuth();

    if (
        props.permissions &&
        props.permissions.length > 0 &&
        !props.permissions.includes(auth.userInfo.situation as UserSituation)
    )
        return null;

    //y'a un pb avec cette gestion, car y'a des gens qui ont des préférences partielles
    if (props.preferences?.length > 0 && props.preferences.every((p) => auth.userInfo.preferences[p] === false))
        return null;
    return <StyledTab {...props} />;
}
