// === NPM
import {
    Stack,
    Typography,
    Card,
    Grid,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    InputAdornment,
    Tooltip,
} from "@mui/material";
import React, { ChangeEvent, Dispatch, RefObject, SetStateAction, useEffect, useState } from "react";
import { DateTime } from "luxon";
import { KeyboardArrowDown, Help } from "@mui/icons-material";
// === LOCAl
import GenericTextField from "../../../../../generics/inputs/GenericTextField";
import { StyledCardContent } from "../../../../../styled/StyledCardContent";
import GenericDatePicker from "../../../../../generics/inputs/GenericDatePicker";
import { useForm } from "../../../../../../hooks/useForm";
import { useFormIntervention } from "../useFormIntervention";
import { FORM_TEXT } from "../../../../../../resources/FormUtils";
import { useAuth } from "../../../../../../routers/useAuth";
import { renderVeterinaryOptions } from "../../../../../AntimicrobialDisposal/ManualDelivery/containers/FormManager/containers/Form/containers/FormUtils";
import GenericAutocomplete from "../../../../../generics/inputs/GenericAutocomplete";
import useIsFirstRender from "../../../../../../hooks/useIsFirstRender";
import { HttpStatus } from "../../../../../../interfaces/global";
import UserService from "../../../../../../services/UserService";
import { UserWithoutProperties } from "../../../../../../interfaces/user";
import { IFarm, IWorkshopRelation } from "../../../../../../interfaces/farm";
import FarmService from "../../../../../../services/FarmService";
import useTimeout from "../../../../../../hooks/useTimeout";
import { IVaccinationInterventionGeneralInformation } from "../../../interface";

interface InterventionInformationsProps {
    formRef: RefObject<HTMLButtonElement>;
    onValid: () => void;
    setFarm: Dispatch<SetStateAction<IFarm>>;
    farm: IFarm;
    veterinaries: UserWithoutProperties[];
    setVeterinaries: Dispatch<SetStateAction<UserWithoutProperties[]>>;
}

export default function InterventionInformations({
    onValid,
    formRef,
    setFarm,
    farm,
    veterinaries,
    setVeterinaries,
}: InterventionInformationsProps) {
    const isFirstRender = useIsFirstRender();
    const auth = useAuth();
    const { form, setForm } = useFormIntervention();

    const { data, errors, handleSubmit, handleChange, cleanValue } =
        useForm<IVaccinationInterventionGeneralInformation>({
            initialValues: form.generalInformation,
            validations: {
                vaccinationDate: {
                    required: {
                        value: true,
                        message: FORM_TEXT.required,
                    },
                    custom: {
                        isValid: (value) => value && DateTime.fromISO(value) <= DateTime.now(),
                        message: "La date de l'intervention doit être inférieure ou égale à la date du jour",
                    },
                },
                dpeId: {
                    required: {
                        value: true,
                        message: FORM_TEXT.required,
                    },
                },
                veterinaryId: {
                    required: {
                        value: true,
                        message: FORM_TEXT.required,
                    },
                },
                workshopId: {
                    required: {
                        value: true,
                        message: FORM_TEXT.required,
                    },
                },
            },
            onSubmit: () => {
                setForm((prev) => ({ ...prev, generalInformation: data }));
                onValid();
            },
        });
    const [workshops, setWorkshops] = useState<IWorkshopRelation[]>([]);
    const [workshopSearch, setWorkshopSearch] = useState<string>(form.generalInformation.workshopId ?? "");

    const disabled = !!form.generalInformation.id;

    useTimeout(() => getWorkshops(), [workshopSearch]);

    useEffect(() => {
        if (workshopSearch) getWorkshops();
    }, []);

    useEffect(() => {
        if (data.dpeId) {
            if (!isFirstRender) {
                const target = {
                    target: { value: auth.userInfo.id },
                } as ChangeEvent<HTMLInputElement & HTMLSelectElement>;
                handleChange("veterinaryId")(target);
                cleanValue("workshopId");
                setFarm(null);
            }
            getVeterinaries(data.dpeId);
        }
    }, [data.dpeId]);

    useEffect(() => {
        if (data.workshopId) getFarmByWorkshop();
    }, [data.workshopId]);

    const getVeterinaries = async (ordinalNumber: string) => {
        const responses = await UserService.getDpeUsers(ordinalNumber);
        if (responses.status === HttpStatus.OK) setVeterinaries(responses.data);
    };

    const getFarmByWorkshop = async () => {
        const res = await FarmService.getFarmByWorkshop(data.workshopId);
        if (res.status === HttpStatus.OK) {
            setFarm(res.data);
        }
    };

    const getWorkshops = async () => {
        const payload = {
            page: 0,
            size: 50,
            dpeId: data.dpeId,
            workshopId: workshopSearch,
            isClosed: "false",
        };
        const res = await FarmService.getWorkshopLinks(payload);
        if (res.status === HttpStatus.OK) {
            setWorkshops(res.data);
        }
    };

    const handleVeterinarySelect = (event) => {
        const target = {
            target: { value: event.target.value?.id ?? "" },
        } as ChangeEvent<HTMLInputElement & HTMLSelectElement>;
        handleChange("veterinaryId")(target);
    };

    const handleWorkshopSelect = (event) => {
        const target = {
            target: { value: event.target.value?.workshopId ?? "" },
        } as ChangeEvent<HTMLInputElement & HTMLSelectElement>;
        handleChange("workshopId")(target);
    };

    const renderInformations = () => (
        <Card sx={{ p: 2 }}>
            <StyledCardContent>
                <Grid container spacing={2}>
                    <Grid item md={4} xs={12}>
                        <GenericDatePicker
                            label="Date d'intervention"
                            value={data.vaccinationDate}
                            required
                            onChange={handleChange("vaccinationDate")}
                            name="vaccinationDate"
                            maxDate={DateTime.now()}
                            error={!!errors.vaccinationDate}
                            helperText={errors.vaccinationDate}
                            disabled={disabled}
                        />
                    </Grid>
                    {data.id && (
                        <Grid item md={4} xs={12}>
                            <GenericTextField label="Numéro Calypso" value={data.id} disabled />
                        </Grid>
                    )}
                </Grid>
            </StyledCardContent>
        </Card>
    );

    const renderVeterinaryInfo = () => (
        <Card sx={{ p: 2 }}>
            <StyledCardContent>
                <Grid container spacing={2}>
                    <Grid item md={4} xs={12}>
                        {disabled ? (
                            <GenericTextField
                                label="N° ordre - Nom du DPE"
                                value={`${auth.userInfo.properties.dpes.find((dpe) => dpe.id === data.dpeId)?.id} - ${
                                    auth.userInfo.properties.dpes.find((dpe) => dpe.id === data.dpeId)?.name
                                }`}
                                disabled
                            />
                        ) : (
                            <FormControl fullWidth error={!!errors.dpeId} required>
                                <InputLabel>N° ordre - Nom du DPE</InputLabel>
                                <Select
                                    label="N° ordre - Nom du DPE"
                                    onChange={handleChange("dpeId")}
                                    value={data.dpeId}
                                    IconComponent={KeyboardArrowDown}
                                    disabled={disabled}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <Tooltip title="Si vous n’avez aucun DPE disponible, veuillez mettre à jour les informations de votre DPE dans la section “Mes élevages”">
                                                <Help />
                                            </Tooltip>
                                        </InputAdornment>
                                    }
                                >
                                    {auth.userInfo.properties.dpes
                                        .filter((dpe) => dpe.sanitaryDpe)
                                        .map((dpe) => (
                                            <MenuItem key={dpe.id} value={dpe.id}>
                                                {`${dpe.id} - ${dpe.name}`}
                                            </MenuItem>
                                        ))}
                                </Select>
                                <FormHelperText>{errors.dpeId}</FormHelperText>
                            </FormControl>
                        )}
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <GenericAutocomplete
                            label="N° ordre - Nom du vétérinaire"
                            options={veterinaries}
                            value={veterinaries.find((v) => v.id === data.veterinaryId) || null}
                            onChange={handleVeterinarySelect}
                            required
                            getOptionLabel={(option) =>
                                option.id ? `${option.id} - ${option.lastname} ${option.firstname}` : ""
                            }
                            renderOption={renderVeterinaryOptions}
                            error={!!errors.veterinaryId}
                            helperText={errors.veterinaryId}
                            disabled={disabled}
                        />
                    </Grid>
                </Grid>
            </StyledCardContent>
        </Card>
    );

    const renderPlaceInfo = () => (
        <Card sx={{ p: 2 }}>
            <StyledCardContent>
                <Grid container spacing={2}>
                    <Grid item md={4} xs={12}>
                        {disabled ? (
                            <GenericTextField
                                label="INUAV de l'atelier de vaccination"
                                value={data.workshopId}
                                disabled
                            />
                        ) : (
                            <GenericAutocomplete
                                label="INUAV de l'atelier de vaccination"
                                options={workshops}
                                value={workshops.find((w) => w.workshopId === data.workshopId) || null}
                                onChange={handleWorkshopSelect}
                                required
                                inputValue={workshopSearch}
                                onInputChange={(event, newInputValue) => {
                                    setWorkshopSearch(newInputValue);
                                }}
                                getOptionLabel={(option) => option.workshopId}
                                error={!!errors.workshopId}
                                helperText={errors.workshopId}
                                noOptionsText={
                                    workshopSearch
                                        ? "Aucun résultat"
                                        : "Saisissez des caractères pour lancer la recherche"
                                }
                                disabled={!data.dpeId}
                            />
                        )}
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <GenericTextField label="Identifiant de l'établissement" value={farm?.id ?? ""} disabled />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <GenericTextField label="Nom de l'établissement" value={farm?.name ?? ""} disabled />
                    </Grid>
                </Grid>
            </StyledCardContent>
        </Card>
    );

    return (
        <form onSubmit={handleSubmit} noValidate>
            <Stack spacing={3} width="100%">
                <Typography sx={{ width: { xs: "100%", md: "50%" } }} variant="h5">
                    Informations d'intervention
                </Typography>
                {renderInformations()}
                <Typography sx={{ width: { xs: "100%", md: "50%" } }} variant="h5">
                    Responsable de l'intervention
                </Typography>
                {renderVeterinaryInfo()}
                <Typography sx={{ width: { xs: "100%", md: "50%" } }} variant="h5">
                    Lieu d'intervention
                </Typography>
                {renderPlaceInfo()}
            </Stack>
            <button style={{ display: "none" }} type="submit" ref={formRef} />
        </form>
    );
}
