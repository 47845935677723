import { useOidc } from "@axa-fr/react-oidc";
import { useEffect } from "react";

interface TriggerSsoLoginProps {
    configurationName: string;
}

/**
 *
 *  @description useOidc hook can't be use instantiate without configurationName but we need to be able to switch the configuration
 * between 2 SSO, so TriggerSsoLogin goal is to run login() function when he's mounted, we use a ternary operator in Login.tsx for
 * render TriggerSsoLogin only when configurationName is defined.
 * This is clearly hacky but no other solution found for now. If a genius find a better way to handle it just refacto this :).
 * */
export default function TriggerSsoLogin({ configurationName }: TriggerSsoLoginProps) {
    const { login } = useOidc(configurationName);

    useEffect(() => {
        login();
    }, []);

    return null;
}
