// === NPM
import { TextField } from "@mui/material";
import { DateTime } from "luxon";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import React, { useEffect, useState } from "react";
import { AdapterCalypso } from "../../../../resources/AdapterCalypso";

interface YearPickerProps {
    value: string;
    name: string;
    onChange: any;
}
export default function YearFilter({ onChange, name, value }: YearPickerProps) {
    const [date, setDate] = useState<DateTime>(null);

    useEffect(() => {
        const newDate = value ? DateTime.fromObject({ year: +value }) : null;
        setDate(newDate);
    }, [value]);

    const handleChange = (event) => {
        const targetEvent = {
            target: {
                name,
                value: event ? event.get("year") : "",
            },
        };
        onChange(targetEvent, name);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterCalypso} adapterLocale={"fr-FR"}>
            <DatePicker
                openTo="year"
                views={["year"]}
                value={date}
                onChange={handleChange}
                renderInput={(params) => <TextField variant="outlined" {...params} size="small" fullWidth />}
            />
        </LocalizationProvider>
    );
}
