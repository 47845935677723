// === NPM
import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Tabs } from "@mui/material";
// === LOCAL
import PageContent from "../../generics/layout/PageContent";
import { routerLinks } from "../../../routers/RouterConstant";
import { FormDeliveryProvider } from "./containers/FormManager/containers/useFormDelivery";
import PermissionsTab from "../../../resources/PermissionsTab";

export default function ManualDelivery() {
    const navigate = useNavigate();
    const location = useLocation();
    return (
        <>
            <Tabs value={location.pathname} sx={{ ml: 12 }}>
                <PermissionsTab
                    value={routerLinks.delivery.manualDelivery.view()}
                    onClick={() => navigate(routerLinks.delivery.manualDelivery.view())}
                    label="Consultation des saisies manuelles"
                />
                <PermissionsTab
                    value={routerLinks.delivery.manualDelivery.form()}
                    onClick={() => navigate(routerLinks.delivery.manualDelivery.form())}
                    label="Déclaration de saisie manuelle"
                />
            </Tabs>
            <PageContent>
                <FormDeliveryProvider>
                    <Outlet />
                </FormDeliveryProvider>
            </PageContent>
        </>
    );
}
