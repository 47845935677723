import { AxiosResponse } from "axios";
import { HttpMethod, Loader } from "../interfaces/global";
import { endpoints } from "./EndpointConstant";
import HttpService from "./HttpService";
import { ICategory, IQuestion, IQuestionShort, ITag, UserTypeHeader } from "../interfaces/faq";
import { CALYPSO_HEADERS } from "../resources/AppConstant";
import { formatParams } from "../resources/utils";
import { IQuestionDraft } from "../components/Administration/containers/Faq/interface";
import { IFaqFilters, IUserQuestionShort } from "../components/Faq/interface";

class FaqService {
    // --------------- CATEGORIES ---------------

    async getCategories(userType: UserTypeHeader): Promise<AxiosResponse<ICategory[]>> {
        return HttpService.fetch({
            url: endpoints.faqService.categories.base,
            method: HttpMethod.GET,
            headers: {
                [CALYPSO_HEADERS.USER_TYPE]: userType,
            },
            loader: Loader.REQUEST,
        });
    }

    async postCategory(category: ICategory): Promise<AxiosResponse<ICategory>> {
        return HttpService.fetch({
            url: endpoints.faqService.categories.base,
            method: HttpMethod.POST,
            data: category,
            loader: Loader.REQUEST,
        });
    }

    async putCategory(category: ICategory): Promise<AxiosResponse<ICategory>> {
        return HttpService.fetch({
            url: endpoints.faqService.categories.category(category.uuid),
            method: HttpMethod.PUT,
            data: category,
            loader: Loader.REQUEST,
        });
    }

    async deleteCategory(categoryUuid: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.faqService.categories.category(categoryUuid),
            method: HttpMethod.DELETE,
            loader: Loader.REQUEST,
        });
    }

    async patchCategoryPosition(categoryUuid: string, position: number): Promise<AxiosResponse<ICategory>> {
        return HttpService.fetch({
            url: endpoints.faqService.categories.position(categoryUuid),
            method: HttpMethod.PATCH,
            data: { position },
        });
    }

    // --------------- TAGS ---------------
    async getTags(): Promise<AxiosResponse<ITag[]>> {
        return HttpService.fetch({
            url: endpoints.faqService.tags.base,
            method: HttpMethod.GET,
            loader: Loader.REQUEST,
        });
    }

    async postTag(tag: ITag): Promise<AxiosResponse<ITag>> {
        return HttpService.fetch({
            url: endpoints.faqService.tags.base,
            method: HttpMethod.POST,
            data: tag,
            loader: Loader.REQUEST,
        });
    }

    async putTag(tag: ITag): Promise<AxiosResponse<ITag>> {
        return HttpService.fetch({
            url: endpoints.faqService.tags.tag(tag.uuid),
            method: HttpMethod.PUT,
            data: tag,
            loader: Loader.REQUEST,
        });
    }

    async deleteTag(tagUuid: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.faqService.tags.tag(tagUuid),
            method: HttpMethod.DELETE,
            loader: Loader.REQUEST,
        });
    }

    // --------------- QUESTION ---------------

    async getQuestions(userType: UserTypeHeader.ADMIN): Promise<AxiosResponse<IQuestionShort[]>>;
    async getQuestions(
        userType: UserTypeHeader.USER,
        payload?: IFaqFilters
    ): Promise<AxiosResponse<IUserQuestionShort[]>>;
    async getQuestions(
        userType: UserTypeHeader,
        payload?: IFaqFilters
    ): Promise<AxiosResponse<IQuestionShort[] | IUserQuestionShort[]>> {
        return HttpService.fetch({
            url: endpoints.faqService.questions.base,
            method: HttpMethod.GET,
            headers: {
                [CALYPSO_HEADERS.USER_TYPE]: userType,
            },
            params: formatParams(payload ?? {}),
            loader: Loader.TABLE,
        });
    }

    async postQuestion(question: IQuestion): Promise<AxiosResponse<IQuestion>> {
        return HttpService.fetch({
            url: endpoints.faqService.questions.base,
            method: HttpMethod.POST,
            data: question,
            loader: Loader.REQUEST,
        });
    }

    async putQuestion(question: IQuestion): Promise<AxiosResponse<IQuestion>> {
        return HttpService.fetch({
            url: endpoints.faqService.questions.question(question.uuid),
            method: HttpMethod.PUT,
            data: question,
            loader: Loader.REQUEST,
        });
    }

    async deleteQuestion(questionUuid: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.faqService.questions.question(questionUuid),
            method: HttpMethod.DELETE,
            loader: Loader.REQUEST,
        });
    }

    questionDetailsCache: Map<string, IQuestion> = new Map();

    getQuestionDetailsCached = async (questionUuid: string, userType: UserTypeHeader): Promise<IQuestion> => {
        if (!this.questionDetailsCache.has(questionUuid)) {
            this.questionDetailsCache.set(questionUuid, (await this.getQuestionDetails(questionUuid, userType)).data);
        }
        return this.questionDetailsCache.get(questionUuid);
    };

    replaceQuestionDetailsCached = (question: IQuestion): void => {
        this.questionDetailsCache.set(question.uuid, question);
    };

    removeQuestionDetailsCached = (questionUuid: string): void => {
        this.questionDetailsCache.delete(questionUuid);
    };

    async getQuestionDetails(questionUuid: string, userType: UserTypeHeader): Promise<AxiosResponse<IQuestion>> {
        return HttpService.fetch({
            url: endpoints.faqService.questions.question(questionUuid),
            method: HttpMethod.GET,
            headers: {
                [CALYPSO_HEADERS.USER_TYPE]: userType,
            },
            loader: Loader.BUTTON,
        });
    }

    getQuestionDraft = async (questionUuid: string): Promise<AxiosResponse<IQuestionDraft>> => {
        return HttpService.fetch({
            url: endpoints.faqService.questions.draft(questionUuid),
            method: HttpMethod.GET,
            loader: Loader.BUTTON,
        });
    };

    async patchQuestionPosition(
        questionUuid: string,
        data: { position: number; categoryUuid: string }
    ): Promise<AxiosResponse<IQuestionShort>> {
        return HttpService.fetch({
            url: endpoints.faqService.questions.position(questionUuid),
            method: HttpMethod.PATCH,
            data,
        });
    }
}

export default new FaqService();
