// === NPM
import React from "react";
import { IOrganization } from "../../../../../../../interfaces/referential";
import { Box, Stack, Grid, Card, CardContent, Skeleton, Typography } from "@mui/material";
import { AxiosResponse } from "axios";
// === NPM
import { IAccountWithKeys, ICreateAccount, Profile } from "../../../interface";
import PeriodicityAndCreateAccount from "../../PeriodicityAndCreateAccount";
import SIPartnersCard from "./SIPartnersCard";
import { ReactComponent as Info } from "../../../../../../../assets/icons/info.svg";
import { colors } from "../../../../../../../resources/CssConstant";

interface SIPartnerLayoutProps {
    accounts: IAccountWithKeys[];
    organizations: IOrganization[];
    createAccount: (newAccount: ICreateAccount) => Promise<AxiosResponse>;
    profile: Profile;
    getAccounts: () => void;
    loading: boolean;
}

export default function SIPartnerLayout({
    loading,
    accounts,
    organizations,
    createAccount,
    profile,
    getAccounts,
}: SIPartnerLayoutProps) {
    const renderCardSkeleton = () => {
        return (
            <Card variant="outlined" sx={{ paddingY: 1, paddingX: 2, width: "33%" }}>
                <CardContent>
                    <Stack spacing={2}>
                        <Typography variant="h5">
                            <Skeleton />
                        </Typography>
                        <Typography variant="body1">
                            <Skeleton />
                        </Typography>
                        <Typography variant="body1">
                            <Skeleton />
                        </Typography>
                    </Stack>
                </CardContent>
            </Card>
        );
    };

    const renderCardGrid = () =>
        accounts.length > 0 ? (
            <Grid container sx={{ width: "100%" }} spacing={2}>
                {accounts.map((account) => (
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4} key={account.uuid}>
                        <SIPartnersCard account={account} getAccounts={getAccounts} />
                    </Grid>
                ))}
            </Grid>
        ) : (
            <Stack spacing={1} direction="row" alignItems="center" py={1}>
                <Box display="flex" alignItems="center" sx={{ color: colors.primaryColor }}>
                    <Info />
                </Box>
                <Typography variant="bold" color={colors.primaryColor}>
                    Aucun compte créé pour ce partenaire
                </Typography>
            </Stack>
        );

    return (
        <Stack spacing={3} width="100%">
            <PeriodicityAndCreateAccount
                disabled={loading}
                organizations={organizations}
                createAccount={createAccount}
                profile={profile}
                getAccounts={getAccounts}
            />
            {loading ? renderCardSkeleton() : renderCardGrid()}
        </Stack>
    );
}
