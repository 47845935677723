// === NPM
import React, { useEffect, useState } from "react";
import { Stack, Typography } from "@mui/material";
// === LOCAL
import { FilterConfigurations, GenericFilters } from "../../../../../../../../generics/filters/GenericFilters";
import { FilterType } from "../../../../../../../../../interfaces/global";
import { UserType } from "../../../../../../../../../interfaces/user";
import useTimeout from "../../../../../../../../../hooks/useTimeout";

interface FamDamFilters {
    lastname: string;
    firstname: string;
    ordinalNumber: string;
    agencyCity: string;
    agencyPostalCode: string;
}

interface FamDamProps {
    getUsers: (userType: string, filters) => void;
}

export default function FamDam({ getUsers }: FamDamProps) {
    const [inputFilters, setInputFilters] = useState<FamDamFilters>({
        lastname: "",
        firstname: "",
        ordinalNumber: "",
        agencyCity: "",
        agencyPostalCode: "",
    });

    useTimeout(() => {
        getUsers(UserType.FAM_DAM, inputFilters);
    }, [inputFilters]);

    useEffect(() => {
        getUsers(UserType.FAM_DAM, inputFilters);
    }, []);

    const famDamFiltersConfiguration: FilterConfigurations<FamDamFilters> = {
        lastname: { label: "Nom", type: FilterType.INPUT },
        firstname: { label: "Prénom", type: FilterType.INPUT },
        ordinalNumber: { label: "Identifiant", type: FilterType.INPUT },
        agencyCity: { label: "Ville", type: FilterType.INPUT },
        agencyPostalCode: { label: "Code postal", type: FilterType.INPUT },
    };

    return (
        <Stack spacing={1}>
            <Typography>Fabricant d'aliment</Typography>
            <GenericFilters
                inputFilters={inputFilters}
                filterConfigurations={famDamFiltersConfiguration}
                initialValues={{
                    lastname: "",
                    firstname: "",
                    ordinalNumber: "",
                    agencyCity: "",
                    agencyPostalCode: "",
                }}
                setInputFilters={setInputFilters}
            />
        </Stack>
    );
}
