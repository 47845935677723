// === NPM
import React from "react";
import { Box, CircularProgress } from "@mui/material";

interface SpinnerProps {
    size?: number;
    sx?: object;
}

export default function Spinner({ size = 100, sx }: SpinnerProps) {
    return (
        <Box
            sx={{
                display: "flex",
                width: "100%",
                height: "50%",
                alignItems: "center",
                justifyContent: "center",
                ...sx,
            }}
        >
            <CircularProgress size={size} color="secondary" />
        </Box>
    );
}
