// === NPM
import React, { PropsWithChildren } from "react";
import { Box } from "@mui/material";
// === LOCAL
import { colors } from "../../../resources/CssConstant";

export default function PageContent({ children }: PropsWithChildren) {
    return (
        <Box
            display="flex"
            sx={{
                flexGrow: 1,
                mx: 5,
                mb: 4,
                p: 5,
                backgroundColor: colors.background,
                borderRadius: 5,
            }}
        >
            {children}
        </Box>
    );
}
