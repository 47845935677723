// === Import: NPM
import { KeyboardArrowDown } from "@mui/icons-material";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";

// === Import: LOCAL

interface GenericSelectProps {
    label?: string;
    name?: string;
    value?: string | number | string[];
    onChange: any;
    size?: "medium" | "small";
    options: any[];
    error?: boolean;
    helperText?: string;
    required?: boolean;
    optionsValue?: string;
    optionsLabel?: string;
    disabled?: boolean;
    noneLabel?: string;
    multiple?: boolean;
    variant?: "outlined" | "filled" | "standard";
}

GenericSelect.defaultProps = {
    variant: "outlined",
};

export default function GenericSelect(props: GenericSelectProps) {
    const optionsValue = props.optionsValue || "value";
    const optionsLabel = props.optionsLabel || "label";

    return (
        <FormControl
            fullWidth
            size={props.size}
            error={props.error}
            required={props.required}
            disabled={props.disabled}
            variant={props.variant}
        >
            <InputLabel>{props.label}</InputLabel>
            <Select
                label={props.label}
                onChange={props.onChange}
                value={props.value}
                multiple={props.multiple}
                IconComponent={KeyboardArrowDown}
            >
                {props.options.map((o) => (
                    <MenuItem key={o[optionsValue]} value={o[optionsValue]}>
                        {o[optionsLabel]}
                    </MenuItem>
                ))}
                {props.noneLabel && <MenuItem value="">{props.noneLabel}</MenuItem>}
            </Select>
            {props.helperText && <FormHelperText>{props.helperText}</FormHelperText>}
        </FormControl>
    );
}
