// === NPM
import React, { useEffect, useState } from "react";
import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import { GridRenderCellParams, GridSortModel } from "@mui/x-data-grid-pro";
import { toast } from "react-toastify";
import { saveAs } from "file-saver";
// === LOCAL
import { CALYPSO_HEADERS, defaultPagination } from "../../resources/AppConstant";
import {
    ActionsColumnProps,
    ArrayOfTwo,
    FilterType,
    HttpStatus,
    IPagination,
    SortDirection,
} from "../../interfaces/global";
import { IAttachment, DisplayType, NotificationType } from "../../interfaces/notification";
import NotificationService from "../../services/NotificationService";
import { UserTypeHeader } from "../../interfaces/faq";
import GenericTable from "../../components/generics/table/GenericTable";
import PageContent from "../generics/layout/PageContent";
import ViewAction from "../generics/actions/ViewAction";
import DetailsDialog from "./containers/DetailsDialog";
import DeleteAction from "../generics/actions/DeleteAction";
import NotificationReadStatus from "./containers/NotificationReadStatus";
import { colors, statusColor } from "../../resources/CssConstant";
import DownloadLink from "./containers/DownloadLink";
import DownloadAction from "../generics/actions/DownloadAction";
import GenericConfirmDialog from "../generics/dialogs/GenericConfirmDialog";
import { ReactComponent as Info } from "../../assets/icons/navbar/information.svg";
import { convertEnumToKeyLabelObject, createPayload, toLocaleDateFormat } from "../../resources/utils";
import { FilterConfigurations, GenericFilters } from "../generics/filters/GenericFilters";
import useTimeout from "../../hooks/useTimeout";
import { useNotificationInfo } from "./containers/useNotificationInfo";
import { IUserNotification, IUserNotificationDetails } from "./interface";
import { IReferential } from "../../interfaces/referential";

interface UserNotificationFilters {
    type: string;
    displayType: string[];
    subject: string;
    authorType: string[];
    publicationDate: ArrayOfTwo;
}

export default function UserNotifications() {
    const { unreadNotificationCount, setUnreadNotificationCount, getNotificationInfo } = useNotificationInfo();
    const [notifications, setNotifications] = useState<IUserNotification[]>([]);
    const [notificationDetails, setNotificationDetails] = useState<IUserNotificationDetails>(null);
    const [notificationUuid, setNotificationUuid] = useState<string>(null);
    const [sortModel, setSortModel] = useState<GridSortModel>([]);
    const [pagination, setPagination] = useState<IPagination>(defaultPagination);
    const [rowCount, setRowCount] = useState<number>(0);
    const [inputFilters, setInputFilters] = useState<UserNotificationFilters>({
        type: "",
        displayType: [],
        subject: "",
        authorType: [],
        publicationDate: [null, null],
    });
    const [authorsTypes, setAuthorsTypes] = useState<IReferential[]>([]);

    useTimeout(() => setPagination((prev) => ({ ...prev, page: 0 })), [inputFilters]);

    useEffect(() => {
        getNotificationInfo();
        getAuthorsTypes();
    }, []);

    useEffect(() => {
        getNotifications();
    }, [pagination, sortModel]);

    const handlePageSizeChange = (pageSize: number) => {
        setPagination({ ...pagination, pageSize, page: 0 });
    };

    const handlePageChange = (page: number) => {
        setPagination({ ...pagination, page });
    };

    const getAuthorsTypes = async () => {
        const res = await NotificationService.getAuthorsTypes();
        if (res.status === HttpStatus.OK) {
            setAuthorsTypes(res.data);
        }
    };

    const getNotifications = async () => {
        const payload = {
            page: pagination.page,
            size: pagination.pageSize,
            sorts: sortModel.map((s) => `${s.field},${s.sort}`),
            ...createPayload(inputFilters),
        };
        const res = await NotificationService.getNotifications(UserTypeHeader.USER, payload);
        if (res.status === HttpStatus.OK) {
            setNotifications(res.data);
            setRowCount(+res.headers[CALYPSO_HEADERS.TABLE_COUNT]);
        }
    };

    const getNotificationDetails = async (uuid: string) => {
        const res = await NotificationService.getNotificationByUuid(uuid, UserTypeHeader.USER);
        if (res.status === HttpStatus.OK) {
            setNotificationDetails(res.data);
        }
    };

    const markNotificationAsRead = async (uuid: string) => {
        const res = await NotificationService.markUserNotificationAsRead(uuid);
        if (res.status === HttpStatus.NO_CONTENT) {
            getNotifications();
            setUnreadNotificationCount(Math.max(unreadNotificationCount - 1, 0));
        }
    };

    const handleDeleteNotification = async (confirm: boolean) => {
        if (!confirm) {
            setNotificationUuid(null);
            return;
        }
        const res = await NotificationService.deleteNotification(notificationUuid, UserTypeHeader.USER);
        if (res.status === HttpStatus.NO_CONTENT) {
            getNotifications();
            setNotificationUuid(null);
            toast.success("Notification supprimée");
        }
    };

    const handleDownloadNotification = async (uuid: string) => {
        const res = await NotificationService.getUserNotificationZip(uuid);
        if (res.status === HttpStatus.OK) {
            const file = new Blob([res.data], { type: res.headers["content-type"] });
            saveAs(file, res.headers["content-disposition"].split("filename=")[1].slice(1, -1));
        }
    };

    const renderBoldCell = (read: boolean, text: string) => (read ? text : <strong>{text}</strong>);

    const columns = [
        {
            field: "type",
            headerName: "Type",
            flex: 1,
            valueGetter: (params: GridRenderCellParams) =>
                renderBoldCell(params.row.read, NotificationType[params.row.type]),
        },
        {
            field: "displayType",
            headerName: "Catégorie",
            flex: 1,
            valueGetter: (params: GridRenderCellParams) =>
                renderBoldCell(params.row.read, DisplayType[params.row.displayType]),
        },
        {
            field: "subject",
            headerName: "Objet",
            flex: 1,
            valueGetter: (params: GridRenderCellParams) => renderBoldCell(params.row.read, params.value),
        },
        {
            field: "authorType",
            headerName: "Émetteur",
            flex: 1,
            valueGetter: (params: GridRenderCellParams) =>
                renderBoldCell(params.row.read, `${params.row.authorFirstname} ${params.row.authorLastname}`),
        },
        {
            field: "publicationDate",
            headerName: "Date de publication",
            flex: 1,
            valueGetter: (params: GridRenderCellParams) =>
                renderBoldCell(params.row.read, toLocaleDateFormat(params.row.publicationDate)),
        },
        {
            field: "publicationEndDate",
            headerName: "Date de fin de publication",
            flex: 1,
            valueGetter: (params: GridRenderCellParams) =>
                renderBoldCell(params.row.read, toLocaleDateFormat(params.row.publicationEndDate)),
        },
        {
            field: "attachments",
            headerName: "Pièces jointes",
            flex: 1,
            sortable: false,
            filterable: false,
            disableExport: true,
            renderCell: (params: GridRenderCellParams) => (
                <Stack alignItems={"flex-start"}>
                    {params.row.attachments.map((attachment: IAttachment) => (
                        <DownloadLink key={attachment.uuid} file={attachment} notificationUuid={params.row.uuid} />
                    ))}
                </Stack>
            ),
        },
        {
            ...ActionsColumnProps,
            width: 200,
            renderCell: (params: GridRenderCellParams) => (
                <Stack direction="row">
                    <NotificationReadStatus
                        read={params.row.read}
                        onClick={() => markNotificationAsRead(params.row.uuid)}
                    />
                    <ViewAction
                        title="Voir le détail"
                        onClick={() => {
                            if (!params.row.read) {
                                markNotificationAsRead(params.row.uuid);
                            }
                            getNotificationDetails(params.row.uuid);
                        }}
                    />
                    <DownloadAction
                        title="Télécharger la notification"
                        onClick={() => handleDownloadNotification(params.row.uuid)}
                    />
                    <DeleteAction
                        title="Supprimer la notification"
                        onClick={() => setNotificationUuid(params.row.uuid)}
                    />
                </Stack>
            ),
        },
    ];

    const renderWarning = () => (
        <Stack spacing={1} direction="row" alignItems="center" py={3}>
            <Box display="flex" alignItems="center">
                <Info />
            </Box>
            <Typography variant="bold" color={colors.primaryColor}>
                Les notifications datant de plus de 5 ans seront automatiquement supprimées. Vous avez la possibilité de
                télécharger les notifications que vous souhaitez conserver.
            </Typography>
        </Stack>
    );

    const filterValues = convertEnumToKeyLabelObject(DisplayType).filter(
        (value) => value.label !== DisplayType.ALERT_BANNER
    );

    const filterConfigurations: FilterConfigurations<UserNotificationFilters> = {
        type: {
            label: "Type",
            type: FilterType.SINGLE_SELECT,
            values: convertEnumToKeyLabelObject(NotificationType),
        },
        displayType: {
            label: "Catégorie",
            type: FilterType.SELECT,
            values: filterValues,
        },
        subject: { label: "Objet", type: FilterType.INPUT },
        authorType: {
            label: "Émetteur",
            type: FilterType.SELECT,
            values: authorsTypes,
        },
        publicationDate: { label: "Date de publication", type: FilterType.DATEPICKER },
    };

    return (
        <PageContent>
            <Stack width="100%" spacing={3}>
                <Typography variant="h4">Mes notifications</Typography>
                <Card>
                    <CardContent
                        sx={{
                            "& .unread": {
                                backgroundColor: statusColor.successBackground,
                            },
                        }}
                    >
                        {renderWarning()}
                        <GenericFilters
                            inputFilters={inputFilters}
                            filterConfigurations={filterConfigurations}
                            initialValues={{
                                type: "",
                                displayType: [],
                                subject: "",
                                authorType: [],
                                publicationDate: [null, null],
                            }}
                            setInputFilters={setInputFilters}
                        />
                        <GenericTable
                            columns={columns}
                            rows={notifications}
                            getRowId={(row) => row.uuid}
                            getRowClassName={(params) => (params.row.read ? "" : "unread")}
                            rowCount={rowCount}
                            sortingMode="server"
                            sortModel={sortModel}
                            onSortModelChange={(model) => setSortModel(model)}
                            filterMode="server"
                            paginationMode="server"
                            autoHeight
                            onPageSizeChange={handlePageSizeChange}
                            onPageChange={handlePageChange}
                            page={pagination.page}
                            pageSize={pagination.pageSize}
                            sortingOrder={[SortDirection.ASC, SortDirection.DESC]}
                        />
                    </CardContent>
                </Card>
            </Stack>
            {notificationDetails && (
                <DetailsDialog onClose={() => setNotificationDetails(null)} notificationDetails={notificationDetails} />
            )}
            {notificationUuid && (
                <GenericConfirmDialog
                    title={`Supprimer la notification ${
                        notifications?.find((notification) => notification.uuid === notificationUuid)?.subject
                    }`}
                    message="L'action est irréversible. Êtes-vous sûr de vouloir supprimer cette notification ainsi que les pièces attachées ?"
                    onClose={handleDeleteNotification}
                />
            )}
        </PageContent>
    );
}
