// === NPM
import React from "react";
import { Grid, Box, Stack, Typography } from "@mui/material";
// === LOCAL
import { ReactComponent as Data } from "../../../../assets/icons/shared/paper.svg";
import { ReactComponent as Syringe } from "../../../../assets/icons/shared/syringe.svg";
import GenericAccordion from "../../../generics/layout/GenericAccordion";
import GenericTitleValueText from "../../../generics/text/GenericTitleValueText";
import { IMonitoringInterventionData } from "../../../../interfaces/vaccination";
import { useOutletContext } from "react-router-dom";
import { MonitoringInterventionOutletContext } from "../interface";

interface InterventionDataAccordionProps {
    interventionData: IMonitoringInterventionData;
}

export default function InterventionDataAccordion({ interventionData }: InterventionDataAccordionProps) {
    const { clinicalNonComplianceReasons: clinicalNonCompliantReasons } =
        useOutletContext<MonitoringInterventionOutletContext>();
    return (
        <GenericAccordion title="Données et prélèvements" defaultExpanded>
            <Grid container rowSpacing={4} columnSpacing={2}>
                <Grid item xs={12} md={4} display="flex" gap={1}>
                    <Box>
                        <Data />
                    </Box>
                    <Stack spacing={1}>
                        <Box mt={-0.25}>
                            <Typography variant="bold" fontSize={18}>
                                Données de l'intervention
                            </Typography>
                        </Box>
                        <GenericTitleValueText
                            title="Résultat de l'exament clinique"
                            value={interventionData.complianceClinicalExamination ? "Conforme" : "Non conforme"}
                        />
                        {interventionData.clinicalNonComplianceReason && (
                            <GenericTitleValueText
                                title="Motif de non conformité"
                                value={
                                    clinicalNonCompliantReasons.find(
                                        (c) => c.key === interventionData.clinicalNonComplianceReason
                                    )?.label
                                }
                            />
                        )}
                        <GenericTitleValueText
                            title="Mise à disposition des résultats de la surveillance passive"
                            value={interventionData.monitoringResultAvailability ? "Oui" : "Non"}
                        />
                        {interventionData.comment && (
                            <GenericTitleValueText title="Commentaire" value={interventionData.comment} />
                        )}
                    </Stack>
                </Grid>

                <Grid item xs={12} md={4} display="flex" gap={1}>
                    <Box>
                        <Syringe />
                    </Box>
                    <Stack spacing={1}>
                        <Box mt={-0.25}>
                            <Typography variant="bold" fontSize={18}>
                                Prélèvement pour virologie
                            </Typography>
                        </Box>
                        <GenericTitleValueText
                            title="Nombre d'écouvillons prélevés"
                            value={interventionData.virologySampleNumber}
                        />
                        {interventionData.virologySampleComment && (
                            <GenericTitleValueText title="Commentaire" value={interventionData.virologySampleComment} />
                        )}
                    </Stack>
                </Grid>
            </Grid>
        </GenericAccordion>
    );
}
