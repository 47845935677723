// === NPM
import React from "react";
import { Box } from "@mui/material";
// === LOCAL
import { UserWithoutProperties } from "../../../../../../../../interfaces/user";
import { IManualDeliveryDrug } from "../../../../../interface";

export const checkDuplicateDrugs = (drugs: IManualDeliveryDrug[]): Record<number, string> => {
    const gtinCodes = drugs.map((drug) => drug.gtinCode);
    let gtinCodeErrors = {};
    gtinCodes.forEach((gtinCode, index) => {
        if (gtinCode && gtinCodes.indexOf(gtinCode) !== index) {
            gtinCodeErrors = { ...gtinCodeErrors, [index]: "Ce code GTIN est déjà renseigné." };
        }
    });
    return gtinCodeErrors;
};

export const renderVeterinaryOptions = (props: object, option: UserWithoutProperties) => (
    <Box component="li" {...props} key={option.id}>
        {option.id} - {option.lastname} {option.firstname}
    </Box>
);
