import { AxiosResponse } from "axios";
import { HttpMethod, IExportFilename, IExportParams, Loader, SearchParams } from "../interfaces/global";
import HttpService from "./HttpService";
import { endpoints } from "./EndpointConstant";
import {
    IMonitoringInterventionDetails,
    IScrappingShort,
    IVaccine,
    IMonitoringInterventionForm,
} from "../interfaces/vaccination";
import { formatParams } from "../resources/utils";
import { IReferential } from "../interfaces/referential";
import { CALYPSO_HEADERS } from "../resources/AppConstant";
import { IServipharOrder, IManualOrder, IOrder } from "../components/VaccinationIahp/Vaccination/Order/interface";
import {
    TrackingOrderFilters,
    ITrackingOrder,
    IOrderDetails,
} from "../components/VaccinationIahp/Vaccination/OrderTracking/interface";
import { IScrappingFilters } from "../components/VaccinationIahp/Vaccination/ScrappingTracking/interface";
import {
    IVaccinationInterventionFilters,
    IIntervention,
    IAudit,
    IVaccinationInterventionForm,
    IVaccinationInterventionDetails,
    IVaccinationInterventionCorrection,
} from "../components/VaccinationIahp/VaccinationIntervention/interface";
import {
    IMonitoringInterventionFilters,
    IMonitoringIntervention,
    IMonitoringInterventionCorrection,
} from "../components/VaccinationIahp/MonitoringIntervention/interface";

class VaccinationService {
    async getVaccines(): Promise<AxiosResponse<IVaccine[]>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.referential.vaccines(),
            method: HttpMethod.GET,
        });
    }

    async getSectors(): Promise<AxiosResponse<IReferential[]>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.referential.sectors(),
            method: HttpMethod.GET,
        });
    }

    async getTiers(): Promise<AxiosResponse<IReferential[]>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.referential.tiers(),
            method: HttpMethod.GET,
        });
    }

    async postOrder(data: IServipharOrder | IManualOrder): Promise<AxiosResponse<IOrder>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.orders.base,
            method: HttpMethod.POST,
            data,
            loader: Loader.REQUEST,
        });
    }

    async getOrders(payload: SearchParams & Partial<TrackingOrderFilters>): Promise<AxiosResponse<ITrackingOrder[]>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.orders.base,
            method: HttpMethod.GET,
            params: formatParams(payload),
            loader: Loader.TABLE,
        });
    }

    async getOrderDetails(id: string): Promise<AxiosResponse<IOrderDetails>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.orders.order(id),
            method: HttpMethod.GET,
            loader: Loader.BUTTON,
        });
    }

    async getOrderStatuses(): Promise<AxiosResponse<IReferential[]>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.referential.orderStatuses(),
            method: HttpMethod.GET,
        });
    }

    async getInterventions(
        dataType: "id",
        payload: SearchParams & Partial<IVaccinationInterventionFilters>
    ): Promise<AxiosResponse<{ id: string }[]>>;
    async getInterventions(
        dataType: "",
        payload: SearchParams & Partial<IVaccinationInterventionFilters>
    ): Promise<AxiosResponse<IIntervention[]>>;
    async getInterventions(
        dataType: "id" | "",
        payload: SearchParams & Partial<IVaccinationInterventionFilters>
    ): Promise<AxiosResponse<IIntervention[] | { id: string }[]>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.vaccinationInterventions.base,
            method: HttpMethod.GET,
            params: formatParams(payload),
            headers: {
                [CALYPSO_HEADERS.DATA_TYPE]: dataType,
            },
            loader: Loader.TABLE,
        });
    }

    async getVaccineBatches(
        vaccineGtinCode: string,
        payload: SearchParams & { number?: string; uuid?: string[] }
    ): Promise<AxiosResponse<{ uuid: string; number: string }[]>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.referential.batches(vaccineGtinCode),
            method: HttpMethod.GET,
            params: formatParams(payload),
        });
    }

    async getHorsePower(): Promise<AxiosResponse<IReferential[]>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.referential.horsePower(),
            method: HttpMethod.GET,
        });
    }

    async postAudit(interventionId: string, data: IAudit): Promise<AxiosResponse<IAudit>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.vaccinationInterventions.audit(interventionId),
            method: HttpMethod.POST,
            data,
            loader: Loader.REQUEST,
        });
    }

    async getAudit(interventionId: string): Promise<AxiosResponse<IAudit>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.vaccinationInterventions.audit(interventionId),
            method: HttpMethod.GET,
            loader: Loader.BUTTON,
        });
    }

    async deleteAudit(interventionId: string): Promise<AxiosResponse<void>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.vaccinationInterventions.audit(interventionId),
            method: HttpMethod.DELETE,
            loader: Loader.REQUEST,
        });
    }

    async putAudit(interventionId: string, data: IAudit): Promise<AxiosResponse<IAudit>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.vaccinationInterventions.audit(interventionId),
            method: HttpMethod.PUT,
            data,
            loader: Loader.REQUEST,
        });
    }

    async postIntervention(
        data: IVaccinationInterventionForm,
        certificateGeneration: boolean
    ): Promise<AxiosResponse<IVaccinationInterventionForm>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.vaccinationInterventions.base,
            method: HttpMethod.POST,
            data: { ...data, certificateGeneration },
            loader: Loader.REQUEST,
        });
    }

    async putIntervention(
        id: string,
        data: IVaccinationInterventionForm,
        certificateGeneration: boolean
    ): Promise<AxiosResponse<IVaccinationInterventionForm>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.vaccinationInterventions.intervention(id),
            method: HttpMethod.PUT,
            data: { ...data, certificateGeneration },
            loader: Loader.REQUEST,
        });
    }

    async putInterventionCorrection(
        id: string,
        data: IVaccinationInterventionCorrection
    ): Promise<AxiosResponse<IVaccinationInterventionForm>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.vaccinationInterventions.interventionCorrection(id),
            method: HttpMethod.PUT,
            data: data,
            loader: Loader.REQUEST,
        });
    }

    async deleteIntervention(id: string): Promise<AxiosResponse<void>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.vaccinationInterventions.intervention(id),
            method: HttpMethod.DELETE,
            loader: Loader.REQUEST,
        });
    }

    async getIntervention(id: string): Promise<AxiosResponse<IVaccinationInterventionDetails>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.vaccinationInterventions.intervention(id),
            method: HttpMethod.GET,
            loader: Loader.BUTTON,
        });
    }

    async patchIntervention(id: string, data: { uuid: string; imepNumber: string }[]) {
        return HttpService.fetch({
            url: endpoints.vaccinationService.vaccinationInterventions.intervention(id),
            method: HttpMethod.PATCH,
            data,
        });
    }

    async getFile(interventionId: string, fileId: string) {
        return HttpService.fetch({
            url: endpoints.vaccinationService.vaccinationInterventions.file(interventionId, fileId),
            method: HttpMethod.GET,
            responseType: "blob",
            loader: Loader.BUTTON,
        });
    }

    /// ---- SCRAPPING

    async getScrappings(payload: SearchParams & Partial<IScrappingFilters>) {
        return HttpService.fetch({
            url: endpoints.vaccinationService.scrappings.base,
            method: HttpMethod.GET,
            params: formatParams(payload),
            loader: Loader.TABLE,
        });
    }

    async deleteScrapping(uuid: string) {
        return HttpService.fetch({
            url: endpoints.vaccinationService.scrappings.scrapping(uuid),
            method: HttpMethod.DELETE,
            loader: Loader.REQUEST,
        });
    }

    async getScrappingReasons() {
        return HttpService.fetch({
            url: endpoints.vaccinationService.referential.reasons(),
            method: HttpMethod.GET,
        });
    }

    /// ---- MONITORING INTERVENTIONS

    async getMonitoringInterventions(
        payload: SearchParams & Partial<IMonitoringInterventionFilters>
    ): Promise<AxiosResponse<IMonitoringIntervention[]>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.monitoringInterventions.base,
            method: HttpMethod.GET,
            params: formatParams(payload),
            loader: Loader.TABLE,
        });
    }

    async getMonitoringIntervention(id: string): Promise<AxiosResponse<IMonitoringInterventionDetails>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.monitoringInterventions.intervention(id),
            method: HttpMethod.GET,
            loader: Loader.BUTTON,
        });
    }

    async postMonitoringIntervention(data: IMonitoringInterventionForm): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.monitoringInterventions.base,
            method: HttpMethod.POST,
            data,
            loader: Loader.REQUEST,
        });
    }

    async putMonitoringIntervention(id: string, data: IMonitoringInterventionForm): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.monitoringInterventions.intervention(id),
            method: HttpMethod.PUT,
            data,
            loader: Loader.REQUEST,
        });
    }

    async deleteMonitoringIntervention(id: string): Promise<AxiosResponse<void>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.monitoringInterventions.intervention(id),
            method: HttpMethod.DELETE,
            loader: Loader.REQUEST,
        });
    }

    async getMonitoringInterventionFile(interventionId: string, fileId: string) {
        return HttpService.fetch({
            url: endpoints.vaccinationService.monitoringInterventions.file(interventionId, fileId),
            method: HttpMethod.GET,
            responseType: "blob",
            loader: Loader.BUTTON,
        });
    }

    async postScrapping(data: IScrappingShort) {
        return HttpService.fetch({
            url: endpoints.vaccinationService.scrappings.base,
            method: HttpMethod.POST,
            data,
            loader: Loader.REQUEST,
        });
    }

    async putScrapping(uuid: string, data: IScrappingShort) {
        return HttpService.fetch({
            url: endpoints.vaccinationService.scrappings.scrapping(uuid),
            method: HttpMethod.PUT,
            data,
            loader: Loader.REQUEST,
        });
    }
    async getSerologicalTypes(): Promise<AxiosResponse<IReferential[]>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.referential.serologicalTypes(),
            method: HttpMethod.GET,
        });
    }

    async getNonFullfilmentReasons(): Promise<AxiosResponse<IReferential[]>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.referential.nonFullfilmentReasons(),
            method: HttpMethod.GET,
        });
    }

    async getClinicalNonComplianceReasons(): Promise<AxiosResponse<IReferential[]>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.referential.clinicalNonComplianceReasons(),
            method: HttpMethod.GET,
        });
    }

    async getMonitoringInterventionStatuses(): Promise<AxiosResponse<IReferential[]>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.referential.monitoringInterventionStatuses(),
            method: HttpMethod.GET,
        });
    }

    async getVaccinationInterventionExport(data: IExportParams): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.exports.vaccinationIntervention(),
            method: HttpMethod.POST,
            data,
        });
    }

    async getMonitoringInterventionExport(data: IExportParams): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.exports.monitoringIntervention(),
            method: HttpMethod.POST,
            data,
        });
    }

    async getRelationExport(data: IExportParams): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.exports.relation(),
            method: HttpMethod.POST,
            data,
        });
    }

    async correctIntervention(id: string, data: IMonitoringInterventionCorrection): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.monitoringInterventions.correction(id),
            method: HttpMethod.PUT,
            data,
            loader: Loader.REQUEST,
        });
    }

    async exportIahpData(data: IExportFilename): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.exports.base,
            method: HttpMethod.POST,
            data,
        });
    }
}

export default new VaccinationService();
