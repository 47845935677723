// === NPM
import { Stack } from "@mui/material";
import React, { RefObject, useEffect, useRef, useState } from "react";
// === LOCAL
import { useFormMonitoringIntervention } from "../containers/useFormMonitoringIntervention";
import Workshop from "./containers/Workshop";
import { isArrayTruthy } from "../../../../../resources/utils";
import useEffectAfterMount from "../../../../../hooks/useEffectAfterMount";

interface ControlsProps {
    formRef: RefObject<HTMLButtonElement>;
    previousRef: RefObject<HTMLButtonElement>;
    handlePreviousStep: () => void;
    onValid: () => void;
}

export default function Controls({ formRef, previousRef, handlePreviousStep, onValid }: ControlsProps) {
    const [isValidWorkshops, setIsValidWorkshops] = useState<Record<string, boolean>>({});
    const workshopRef = useRef<Record<number, HTMLButtonElement>>({});
    const [isPrevious, setIsPrevious] = useState<boolean>(false);

    useEffect(() => {
        if (isArrayTruthy(Object.values(isValidWorkshops))) {
            onValid();
        }
    }, [isValidWorkshops]);

    useEffectAfterMount(() => {
        if (isPrevious) handlePreviousStep();
    }, [isPrevious]);

    const handleSubmit = () => {
        setIsValidWorkshops(form.workshops.reduce((acc, ws) => ({ ...acc, [ws.workshopId]: false }), {}));
        Object.values(workshopRef.current).forEach((ws) => ws?.click());
    };

    const { form } = useFormMonitoringIntervention();
    return (
        <Stack spacing={4} width="100%">
            {form.workshops.map((workshop) => (
                <Workshop
                    key={workshop.workshopId}
                    workshop={workshop}
                    workshopRef={workshopRef}
                    setIsValidWorkshops={setIsValidWorkshops}
                    isPrevious={isPrevious}
                />
            ))}

            <button
                style={{ display: "none" }}
                onClick={() => {
                    setIsPrevious(true);
                }}
                ref={previousRef}
            />

            <button style={{ display: "none" }} onClick={handleSubmit} ref={formRef} />
        </Stack>
    );
}
