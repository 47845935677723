// === Import: NPM
import React, { useState, useContext, PropsWithChildren } from "react";
// === Import: LOCAL
import DownloadService from "../../services/DownloadService";
import { HttpStatus } from "../../interfaces/global";
import { IDownload } from "./interface";

export interface UseDownload {
    downloads: IDownload[];
    availableDownloadCount: number;
    getAvailableDownloads: () => void;
}

export const downloadContext = React.createContext<UseDownload>({
    downloads: [],
    availableDownloadCount: 0,
    getAvailableDownloads: null,
});

export const useDownload = (): UseDownload => {
    return useContext(downloadContext);
};

export const DownloadProvider = ({ children }: PropsWithChildren) => {
    const download = useDownloadProvider();
    return <downloadContext.Provider value={download}>{children}</downloadContext.Provider>;
};

const useDownloadProvider = () => {
    const [downloads, setDownloads] = useState<IDownload[]>([]);
    const [availableDownloadCount, setAvailableDownloadCount] = useState<number>(0);

    const getAvailableDownloads = async () => {
        const res = await DownloadService.getDownloads();
        if (res.status === HttpStatus.OK) {
            setDownloads(res.data);
            setAvailableDownloadCount(res.data.length);
        }
    };

    return {
        downloads,
        availableDownloadCount,
        getAvailableDownloads,
    };
};
