import React, { useRef, useState } from "react";
import { ButtonGroup, Button, Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem } from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";
import { IPeriodicity } from "../interface";

interface PeriodicitySplitButtonProps {
    options: Options[];
    currentPeriodicity: string;
    disabled?: boolean;
}

interface Options extends IPeriodicity {
    signature: any;
}

export default function PeriodicitySplitButton({
    options,
    currentPeriodicity,
    disabled = false,
}: PeriodicitySplitButtonProps) {
    const [open, setOpen] = useState<boolean>(false);
    const anchorRef = useRef<any>(null);

    const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
        setOpen(false);
        options[index].signature(options[index]);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpen(false);
    };

    return (
        <>
            <ButtonGroup variant="contained" disableElevation>
                <Button disableTouchRipple disabled={disabled}>
                    Périodicité de renouvellement des clefs d'API
                </Button>
                <Button disabled={disabled} size="small" onClick={handleToggle} ref={anchorRef}>
                    {options.find((o) => o.key === currentPeriodicity)?.label}
                    <ArrowDropDown />
                </Button>
            </ButtonGroup>
            <Popper open={open} anchorEl={anchorRef.current} transition style={{ zIndex: 1000 }}>
                {({ TransitionProps }) => (
                    <Grow {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem>
                                    {options.map((option, index) => (
                                        <MenuItem
                                            key={index}
                                            selected={option.key === currentPeriodicity}
                                            onClick={(event) => handleMenuItemClick(event, index)}
                                        >
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
}
