// === NPM
import React from "react";
import { useOutletContext } from "react-router-dom";
import { Card, Stack, Typography } from "@mui/material";
import { GridValueFormatterParams } from "@mui/x-data-grid-pro";
import { DateTime } from "luxon";
import { ReactComponent as Calendar } from "../../../../../assets/icons/shared/calendar.svg";
// === LOCAL
import { ReactComponent as Home } from "../../../../../assets/icons/shared/home.svg";
import { ReactComponent as Location } from "../../../../../assets/icons/shared/location.svg";
import { ReactComponent as Phone } from "../../../../../assets/icons/vaccination/phone.svg";
import { ReactComponent as Sum } from "../../../../../assets/icons/vaccination/sum.svg";
import { ReactComponent as Vaccine } from "../../../../../assets/icons/vaccination/vaccine.svg";
import { IDpeProperties } from "../../../../../interfaces/user";
import { VaccinationOutletContext } from "../../../../../interfaces/vaccination";
import { getEnumKeyByValue } from "../../../../../resources/utils";
import GenericActionsDialog from "../../../../generics/dialogs/GenericActionsDialog";
import GenericDialog from "../../../../generics/dialogs/GenericDialog";
import GenericTable from "../../../../generics/table/GenericTable";
import { GenericIconText } from "../../../../generics/text/GenericIconText";
import { StyledCardContent } from "../../../../styled/StyledCardContent";
import { DelivererType, IManualDelivery, IOrderRoot, IServipharForm } from "../interface";

interface ConfirmDialogProps {
    onClose: () => void;
    onConfirm: () => void;
    orderInformationData: IOrderRoot;
    servipharData: IServipharForm;
    manualDeliveries: IManualDelivery[];
    dpes: IDpeProperties[];
}

export default function ConfirmDialog({
    onClose,
    onConfirm,
    orderInformationData,
    servipharData,
    manualDeliveries,
    dpes,
}: ConfirmDialogProps) {
    const { vaccines } = useOutletContext<VaccinationOutletContext>();

    const vaccine = vaccines.find((v) => v.gtinCode === orderInformationData.vaccineGtinCode);
    const dpe = dpes.find((d) => d.id === orderInformationData.dpeId);
    const isManual = vaccine?.deliverer === getEnumKeyByValue(DelivererType, DelivererType.OTHER);

    const handleSubmit = () => {
        onConfirm();
    };

    const columns = [
        {
            field: "batchNumber",
            headerName: "N° lot de vaccin",
            flex: 1,
            sortable: false,
        },
        {
            field: "expeditionDate",
            headerName: "Date d'expédition",
            flex: 1,
            valueFormatter: (params: GridValueFormatterParams<string>) =>
                params.value ? DateTime.fromISO(params.value).toLocaleString() : "",
            sortable: false,
        },
        {
            field: "deliveredQuantity",
            headerName: "Quantité livrée",
            flex: 1,
            sortable: false,
        },
        {
            field: "expirationDate",
            headerName: "Date de péremption",
            flex: 1,
            valueFormatter: (params: GridValueFormatterParams<string>) =>
                params.value ? DateTime.fromISO(params.value).toLocaleString() : "",
            sortable: false,
        },
    ];

    const renderManualDeliveries = () => (
        <GenericTable
            rows={manualDeliveries}
            columns={columns}
            autoHeight
            hideFooter
            noRowsMessage="Pas de livraison pour cette commande"
            getRowId={(row) => row.internalId}
            disableToolbarButton
        />
    );

    const renderServiphar = () => (
        <Stack spacing={1}>
            <Typography variant="bold">Informations de livraison</Typography>
            <GenericIconText
                icon={<Phone />}
                text={servipharData.phoneNumber}
                iconTooltip="Numéro de téléphone portable"
            />
            <GenericIconText
                icon={<Calendar />}
                text={DateTime.fromISO(servipharData.requestedDeliveryDate).toLocaleString()}
                iconTooltip="Date de livraison souhaité"
            />
            <GenericIconText icon={<Home />} text={servipharData.deliveryName} iconTooltip="Nom" />
            <GenericIconText
                icon={<Location />}
                text={`${servipharData.address} ${servipharData.complementaryAddress ?? ""}, ${servipharData.postalCode} ${servipharData.city}`}
                iconTooltip="Adresse de livraison"
            />
        </Stack>
    );

    const renderContent = () => (
        <Card>
            <StyledCardContent>
                <Stack spacing={3} p={2}>
                    <Stack spacing={1}>
                        <Typography variant="bold">Dpe</Typography>
                        <GenericIconText icon={<Home />} text={dpe?.name} iconTooltip="Dpe de la commande" />
                    </Stack>
                    <Stack spacing={1}>
                        <Typography variant="bold">Vaccin commandé</Typography>
                        <GenericIconText
                            icon={<Vaccine />}
                            text={`${vaccine?.name} ${vaccine?.packaging}`}
                            iconTooltip="Vaccin"
                        />
                        <GenericIconText
                            icon={<Sum />}
                            text={`${orderInformationData.quantity} unités de ${vaccine?.doseNumber} doses chacune`}
                            iconTooltip="Quantité"
                        />
                        {orderInformationData.quantity > 1000 && (
                            <Typography variant="bold" color="error">
                                {vaccine.deliverer === getEnumKeyByValue(DelivererType, DelivererType.SERVIPHAR)
                                    ? "Êtes-vous sûr de commander autant de vaccin ?"
                                    : "Êtes-vous sûr d'avoir commandé autant de vaccin ?"}
                            </Typography>
                        )}
                    </Stack>
                    {isManual ? renderManualDeliveries() : renderServiphar()}
                </Stack>
            </StyledCardContent>
        </Card>
    );

    return (
        <GenericDialog
            title="Confirmer la commande de vaccins"
            onClose={onClose}
            renderContent={() => renderContent()}
            renderActions={() => (
                <GenericActionsDialog
                    validLabel={
                        vaccine.deliverer === getEnumKeyByValue(DelivererType, DelivererType.SERVIPHAR)
                            ? "Confirmer et passer la commande"
                            : "Confirmer et enregistrer la commande"
                    }
                    onClose={onClose}
                    onSubmit={handleSubmit}
                />
            )}
        />
    );
}
