// === NPM
import React from "react";
import { Outlet } from "react-router-dom";
// === LOCAL
import PageContent from "../../generics/layout/PageContent";

export default function TraceabilityIahp() {
    return (
        <PageContent>
            <Outlet />
        </PageContent>
    );
}
