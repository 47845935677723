// === NPM
import { Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
// === LOCAL
import { HttpStatus } from "../../interfaces/global";
import { IContinuousTrainingOrganization } from "../../interfaces/referential";
import { ITrainingReferential } from "../../interfaces/training";
import { UserSituation } from "../../resources/PermissionConstant";
import { routerLinks } from "../../routers/RouterConstant";
import ReferentialService from "../../services/ReferentialService";
import TrainingService from "../../services/TrainingService";
import PageContent from "../generics/layout/PageContent";
import ContinuousTrainingTour from "./containers/ContinuousTrainingTour";
import PermissionsTab from "../../resources/PermissionsTab";

export default function Training() {
    const [types, setTypes] = useState<ITrainingReferential[]>([]);
    const [organizations, setOrganizations] = useState<IContinuousTrainingOrganization[]>([]);
    const [formats, setFormats] = useState<ITrainingReferential[]>([]);

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        getTrainingTypesReferential();
        getTrainingOrganizationsReferential();
        getTrainingFormatsReferential();
    }, []);

    const getTrainingOrganizationsReferential = async () => {
        const res = await ReferentialService.getContinuousTrainingOrganizationReferential({ archived: ["false"] });
        if (res.status === HttpStatus.OK) {
            setOrganizations(res.data);
        }
    };

    const getTrainingFormatsReferential = async () => {
        const res = await TrainingService.getFormats();
        if (res.status === HttpStatus.OK) {
            setFormats(res.data.filter((format) => !format.archived));
        }
    };

    const getTrainingTypesReferential = async () => {
        const res = await TrainingService.getTypes();
        if (res.status === HttpStatus.OK) {
            setTypes(res.data);
        }
    };

    return (
        <>
            <ContinuousTrainingTour />
            <Tabs value={location.pathname} sx={{ ml: 12 }} id="tabs">
                <PermissionsTab
                    value={routerLinks.training.continuousTraining()}
                    onClick={() => navigate(routerLinks.training.continuousTraining())}
                    label="Formations continues"
                    id="continuous-training-tab"
                    permissions={[UserSituation.REGISTERED_IN_PRACTICE]}
                />
                <PermissionsTab
                    value={routerLinks.training.continousTrainingCatalog()}
                    onClick={() => navigate(routerLinks.training.continousTrainingCatalog())}
                    label="Catalogue des formations continues"
                    id="catalog-tab"
                    permissions={[UserSituation.REGISTERED_IN_PRACTICE, UserSituation.ADMIN_CALYPSO]}
                />
            </Tabs>
            <PageContent>
                <Outlet context={{ types, organizations, formats }} />
            </PageContent>
        </>
    );
}
