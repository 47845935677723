import { Stack, TextField } from "@mui/material";
import React from "react";
import { ArrayOfTwo } from "../../../../interfaces/global";

interface IntervalFilterProps {
    value: ArrayOfTwo;
    onChange: (event, name: string) => void;
    name: string;
}

export default function IntervalFilter({ value, onChange, name }: IntervalFilterProps) {
    const handleChange = (newValue: string, position: number) => {
        const updatedValue = [...value];
        updatedValue[position - 1] = newValue;
        const targetEvent = {
            target: {
                value: updatedValue,
            },
        };
        onChange(targetEvent, name);
    };

    return (
        <Stack spacing={2} direction="row">
            <TextField
                size="small"
                type="number"
                label="Min"
                value={value[0]}
                onChange={(event) => handleChange(event.target.value, 1)}
                autoFocus
            />
            <TextField
                size="small"
                type="number"
                label="Max"
                value={value[1]}
                onChange={(event) => handleChange(event.target.value, 2)}
            />
        </Stack>
    );
}
