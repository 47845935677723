// === NPM
import React from "react";
import { FormHelperText } from "@mui/material";
import { FieldError, Merge } from "react-hook-form";

interface GenericErrorMessageTextProps {
    fieldError: Merge<FieldError, FieldError[]>;
}
export default function GenericErrorMessageText({ fieldError }: GenericErrorMessageTextProps) {
    return fieldError && <FormHelperText>{fieldError?.message}</FormHelperText>;
}
