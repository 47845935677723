import { AxiosResponse } from "axios";
import { HttpMethod, Loader, SearchParams } from "../interfaces/global";
import { IDefaultTraceabilityFilters } from "../interfaces/traceability";
import { formatParams } from "../resources/utils";
import { endpoints } from "./EndpointConstant";
import HttpService from "./HttpService";
import { IReferential } from "../interfaces/referential";
import {
    IDeliveryTraceability,
    IContinuousTrainingOrganizationTraceability,
    IUserTraceability,
    IAgencyTraceability,
    INonDeliveryTraceability,
    IHealthReportingTraceabilityFilters,
    IHealthReportingTraceability,
} from "../components/Administration/containers/Traceability/interface";
import {
    IVaccinationInterventionTraceability,
    IMonitoringInterventionTraceability,
    IScrappingTraceability,
    ScrappingTraceabilityDetailsType,
} from "../components/VaccinationIahp/TraceabilityIahp/interface";

class TraceabilityService {
    async getTraceabilityDeliveries(
        payload: SearchParams &
            Partial<{
                date: string[];
                authorId: string;
                importStatus: string[];
                deliveryTypeCode: string[];
            }>
    ): Promise<AxiosResponse<IDeliveryTraceability[]>> {
        return HttpService.fetch({
            url: endpoints.traceabilityService.deliveries(),
            method: HttpMethod.GET,
            params: formatParams(payload),
            loader: Loader.TABLE,
        });
    }

    async getFile(deliveryUuid: string, fileUuid: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.traceabilityService.deliveryFile(deliveryUuid, fileUuid),
            method: HttpMethod.GET,
            responseType: "blob",
        });
    }

    async getTraceabilityContinuousTrainingOrganization(
        payload: SearchParams &
            Partial<{
                date: string[];
                authorId: string;
                action: string;
            }>
    ): Promise<AxiosResponse<IContinuousTrainingOrganizationTraceability[]>> {
        return HttpService.fetch({
            url: endpoints.traceabilityService.continuousTrainingOrganization(),
            method: HttpMethod.GET,
            params: formatParams(payload),
            loader: Loader.TABLE,
        });
    }
    async getTraceabilityUsers(
        payload: SearchParams &
            Partial<{
                date: string[];
                authorId: string;
                action: string;
            }>
    ): Promise<AxiosResponse<IUserTraceability[]>> {
        return HttpService.fetch({
            url: endpoints.traceabilityService.users(),
            method: HttpMethod.GET,
            params: formatParams(payload),
            loader: Loader.TABLE,
        });
    }

    async getTraceabilityAgencies(
        payload: SearchParams &
            Partial<{
                date: string[];
                authorId: string;
                action: string;
            }>
    ): Promise<AxiosResponse<IAgencyTraceability[]>> {
        return HttpService.fetch({
            url: endpoints.traceabilityService.agencies(),
            method: HttpMethod.GET,
            params: formatParams(payload),
            loader: Loader.TABLE,
        });
    }

    async getTraceabilityNonDeliveries(
        payload: SearchParams &
            Partial<{
                date: string[];
                authorId: string;
                action: string;
                year: string;
                authorizationNumber: string;
                quarter: string;
            }>
    ): Promise<AxiosResponse<INonDeliveryTraceability[]>> {
        return HttpService.fetch({
            url: endpoints.traceabilityService.nonDeliveries(),
            method: HttpMethod.GET,
            params: formatParams(payload),
            loader: Loader.TABLE,
        });
    }

    async getTraceabilityVaccinationIntervention(
        payload: SearchParams & Partial<{ interventionId: string & IDefaultTraceabilityFilters }>
    ): Promise<AxiosResponse<IVaccinationInterventionTraceability[]>> {
        return HttpService.fetch({
            url: endpoints.traceabilityService.vaccinationIntervention(),
            method: HttpMethod.GET,
            params: formatParams(payload),
            loader: Loader.TABLE,
        });
    }

    async getTraceabilityActions(type: string): Promise<AxiosResponse<IReferential[]>> {
        return HttpService.fetch({
            url: endpoints.traceabilityService.referential.actions(),
            method: HttpMethod.GET,
            params: formatParams({ type }),
        });
    }

    async getTraceabilityMonitoringIntervention(
        payload: SearchParams & Partial<{ interventionId: string & IDefaultTraceabilityFilters }>
    ): Promise<AxiosResponse<IMonitoringInterventionTraceability[]>> {
        return HttpService.fetch({
            url: endpoints.traceabilityService.monitoringIntervention(),
            method: HttpMethod.GET,
            params: formatParams(payload),
            loader: Loader.TABLE,
        });
    }

    async getTraceabilityScrapping(
        payload: SearchParams & Partial<{ scrappingId: string; dpe: string & IDefaultTraceabilityFilters }>
    ): Promise<AxiosResponse<IScrappingTraceability[]>> {
        return HttpService.fetch({
            url: endpoints.traceabilityService.scrapping.base,
            method: HttpMethod.GET,
            params: formatParams(payload),
            loader: Loader.TABLE,
        });
    }

    async getTraceabilityScrappingDetails(uuid: string): Promise<AxiosResponse<ScrappingTraceabilityDetailsType>> {
        return HttpService.fetch({
            url: endpoints.traceabilityService.scrapping.details(uuid),
        });
    }

    async getTraceabilityMonitoringInterventionDetails(uuid: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.traceabilityService.monitoringInterventionDetails(uuid),
            method: HttpMethod.GET,
        });
    }

    async getTraceabilityHealthReportings(
        payload: SearchParams & Partial<IHealthReportingTraceabilityFilters>
    ): Promise<AxiosResponse<IHealthReportingTraceability[]>> {
        return HttpService.fetch({
            url: endpoints.traceabilityService.healthReportings(),
            method: HttpMethod.GET,
            params: formatParams(payload),
            loader: Loader.TABLE,
        });
    }

    async getUserSituations(): Promise<AxiosResponse<IReferential[]>> {
        return HttpService.fetch({
            url: endpoints.traceabilityService.referential.userSituations(),
            method: HttpMethod.GET,
        });
    }

    async getHealthReportingStatuses(): Promise<AxiosResponse<IReferential[]>> {
        return HttpService.fetch({
            url: endpoints.traceabilityService.referential.healthReportingStatuses(),
            method: HttpMethod.GET,
        });
    }
}

export default new TraceabilityService();
