// === NPM
import React from "react";
// === LOCAL
import { colors } from "../../../resources/CssConstant";

interface DrawerIconProps {
    icon: React.ReactElement;
    selected: boolean;
}

export default function DrawerIcon({ icon, selected }: DrawerIconProps) {
    const iconSize = 30;
    return React.cloneElement(icon, {
        style: { width: iconSize, height: iconSize, color: selected ? colors.primaryColor : colors.secondaryColor },
    });
}
