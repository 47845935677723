// === NPM
import React from "react";
import { Grid, Box, Stack, Typography } from "@mui/material";
// === LOCAL
import { ReactComponent as Calendar } from "../../../../assets/icons/shared/calendar.svg";
import { ReactComponent as Location } from "../../../../assets/icons/shared/location.svg";
import { ReactComponent as User } from "../../../../assets/icons/shared/user.svg";
import { toLocaleDateFormat } from "../../../../resources/utils";
import GenericAccordion from "../../../generics/layout/GenericAccordion";
import GenericTitleValueText from "../../../generics/text/GenericTitleValueText";
import { IVaccinationInterventionGeneralInformation } from "../interface";

interface InterventionAccordionProps {
    generalInformation: IVaccinationInterventionGeneralInformation;
    farmName: string;
    farmId: string;
    dpeName: string;
    veterinaryFirstname: string;
    veterinaryLastname: string;
}

export default function InterventionAccordion({
    generalInformation,
    farmName,
    farmId,
    dpeName,
    veterinaryFirstname,
    veterinaryLastname,
}: InterventionAccordionProps) {
    return (
        <GenericAccordion title="Intervention de vaccination" defaultExpanded>
            <Grid container rowSpacing={3} columnSpacing={2}>
                <Grid item xs={12} md={4} display="flex" gap={1}>
                    <Box>
                        <Calendar />
                    </Box>
                    <Stack spacing={1}>
                        <Box mt={-0.25}>
                            <Typography variant="bold" fontSize={18}>
                                Intervention
                            </Typography>
                        </Box>
                        <GenericTitleValueText
                            title="Date de l'intervention"
                            value={toLocaleDateFormat(generalInformation.vaccinationDate)}
                        />
                        {generalInformation.id && (
                            <GenericTitleValueText title="Numéro Calypso" value={generalInformation.id} />
                        )}
                    </Stack>
                </Grid>
                <Grid item xs={12} md={3.5} display="flex" gap={1}>
                    <Box>
                        <User />
                    </Box>
                    <Stack spacing={1}>
                        <Box mt={-0.25}>
                            <Typography variant="bold" fontSize={18}>
                                Responsable de l'intervention
                            </Typography>
                        </Box>
                        <GenericTitleValueText
                            title="Vétérinaire"
                            value={`${veterinaryLastname} ${veterinaryFirstname} - ${generalInformation.veterinaryId}`}
                        />
                        <GenericTitleValueText title="DPE" value={`${dpeName} - ${generalInformation.dpeId}`} />
                    </Stack>
                </Grid>
                <Grid item xs={12} md={4.5} display="flex" gap={1}>
                    <Box>
                        <Location />
                    </Box>
                    <Stack spacing={1}>
                        <Box mt={-0.25}>
                            <Typography variant="bold" fontSize={18}>
                                Lieu de l'intervention
                            </Typography>
                        </Box>
                        <GenericTitleValueText title="Établissement" value={farmName} />
                        <GenericTitleValueText title="Identifiant de l'établissement" value={farmId} />
                        <GenericTitleValueText title="INUAV" value={generalInformation.workshopId} />
                    </Stack>
                </Grid>
            </Grid>
        </GenericAccordion>
    );
}
