// === NPM
import React from "react";
import { Stack, Typography, TextField, Grid, LinearProgress, Box } from "@mui/material";
// === LOCAL
import { ITag } from "../../../interfaces/faq";
import ChipTag from "../../generics/ChipTag";
import { colors } from "../../../resources/CssConstant";
import { IFaqFilters } from "../interface";
import { useProvideGlobal } from "../../../context/useGlobalContext";

interface FaqFiltersProps {
    tags: ITag[];
    filters: IFaqFilters;
    setFilters: React.Dispatch<React.SetStateAction<IFaqFilters>>;
}

export default function FaqFilters({ tags, filters, setFilters }: FaqFiltersProps) {
    const { loadingTable } = useProvideGlobal();
    const handleTagClick = (tagUuid: string) => {
        const tagIndex = filters.tags.indexOf(tagUuid);
        let tagLists = [];
        if (tagIndex === -1) {
            tagLists = [...filters.tags, tagUuid];
        } else {
            tagLists = filters.tags.filter((_, index) => index !== tagIndex);
        }
        setFilters((prev) => ({ ...prev, tags: tagLists }));
    };

    return (
        <Stack spacing={2} alignItems="center">
            <Typography variant="h4">Comment pouvons-nous vous aider ?</Typography>
            <TextField
                placeholder="Rechercher un mot clef"
                sx={{ borderRadius: 8, background: colors.white, width: "33%" }}
                InputProps={{ sx: { borderRadius: 8 } }}
                onChange={(e) => setFilters((prev) => ({ ...prev, search: e.target.value }))}
            />
            <Typography>Rechercher par tag : </Typography>
            <Grid container spacing={2} sx={{ justifyContent: "center" }}>
                {tags.map((tag) => (
                    <Grid item key={tag.uuid}>
                        <ChipTag
                            tag={tag}
                            handleClick={() => handleTagClick(tag.uuid)}
                            selected={filters.tags.includes(tag.uuid)}
                        />
                    </Grid>
                ))}
            </Grid>

            {loadingTable && (
                <Box p={2} width="100%">
                    <LinearProgress />
                </Box>
            )}
        </Stack>
    );
}
