// === NPM
import React, { Dispatch, SetStateAction } from "react";
import { Done, Close } from "@mui/icons-material";
import { Tooltip, IconButton, TextField } from "@mui/material";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
// === LOCAL
import { FORM_TEXT, stringRequired } from "../../resources/FormUtils";

interface CalypsoEmailInputProps {
    calypsoEmail: string;
    onConfirm: (email: string) => void;
    setEditMail: Dispatch<SetStateAction<boolean>>;
}

const formSchema = z.object({
    newCalypsoEmail: stringRequired().email({ message: FORM_TEXT.email }),
});

type ValidationSchema = z.infer<typeof formSchema>;

export function CalypsoEmailInput({ calypsoEmail, setEditMail, onConfirm }: CalypsoEmailInputProps) {
    const {
        formState: { errors },
        handleSubmit,
        register,
        setValue,
    } = useForm<ValidationSchema>({
        resolver: zodResolver(formSchema),
        defaultValues: { newCalypsoEmail: calypsoEmail ?? "" },
    });

    return (
        <>
            <TextField
                {...register("newCalypsoEmail")}
                label="Email"
                fullWidth
                required
                variant="standard"
                helperText={errors.newCalypsoEmail?.message}
                error={!!errors.newCalypsoEmail}
            />

            <Tooltip title="Valider">
                <IconButton
                    color="success"
                    onClick={handleSubmit((data) => {
                        onConfirm(data.newCalypsoEmail);
                        setEditMail(false);
                    })}
                >
                    <Done />
                </IconButton>
            </Tooltip>
            <Tooltip title="Annuler">
                <IconButton
                    color="error"
                    onClick={() => {
                        setEditMail(false);
                        setValue("newCalypsoEmail", calypsoEmail ?? "");
                    }}
                >
                    <Close />
                </IconButton>
            </Tooltip>
        </>
    );
}
