// === NPM
import React from "react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { TextField } from "@mui/material";
// === LOCAL
import { IAgency } from "../../../../../../../interfaces/user";
import { stringRequired } from "../../../../../../../resources/FormUtils";
import GenericActionsDialog from "../../../../../../generics/dialogs/GenericActionsDialog";
import GenericDialog from "../../../../../../generics/dialogs/GenericDialog";
import GenericCard from "../../../../../../generics/layout/GenericCard";

interface AgencyDialogProps {
    onClose: () => void;
    onValid: (agency: IAgency) => void;
    currentAgency?: IAgency;
    title: string;
}

const initialValues = {
    name: null,
};

const formSchema = z.object({
    name: stringRequired(),
});

type ValidationSchema = z.infer<typeof formSchema>;

export default function AgencyDialog({ onClose, onValid, title, currentAgency }: AgencyDialogProps) {
    const {
        formState: { errors },
        handleSubmit,
        register,
    } = useForm<ValidationSchema>({
        resolver: zodResolver(formSchema),
        defaultValues: currentAgency ?? initialValues,
    });

    return (
        <GenericDialog
            title={title}
            onClose={onClose}
            paperProps={{
                sx: { width: "100%" },
            }}
            renderActions={() => <GenericActionsDialog onClose={onClose} onSubmit={handleSubmit(onValid)} />}
            renderContent={() => (
                <GenericCard>
                    <TextField
                        {...register("name")}
                        label="Nom du groupe"
                        error={!!errors?.name}
                        helperText={errors?.name?.message}
                        required
                    />
                </GenericCard>
            )}
        />
    );
}
