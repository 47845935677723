// === NPM
import { Box } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
// === LOCAL

export default function Referentials() {
    return (
        <Box sx={{ flexGrow: 1, mt: 4 }}>
            <Outlet />
        </Box>
    );
}
