// === NPM
import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Tabs } from "@mui/material";
import { Preference, UserSituation } from "../../../resources/PermissionConstant";
import PermissionsTab from "../../../resources/PermissionsTab";
import { routerLinks } from "../../../routers/RouterConstant";
// === LOCAL
import PageContent from "../../generics/layout/PageContent";

export default function ButcherySector() {
    const navigate = useNavigate();
    return (
        <>
            <Tabs value={location.pathname} sx={{ ml: 12 }}>
                <PermissionsTab
                    value={routerLinks.sanitaryFollowUp.butcherySector.exclusion()}
                    onClick={() => navigate(routerLinks.sanitaryFollowUp.butcherySector.exclusion())}
                    label="Exclusion de la filière bouchère"
                />
                <PermissionsTab
                    permissions={[UserSituation.REGISTERED_IN_PRACTICE]}
                    preferences={[Preference.BUTCHERY_SECTOR]}
                    value={routerLinks.sanitaryFollowUp.butcherySector.search()}
                    onClick={() => navigate(routerLinks.sanitaryFollowUp.butcherySector.search())}
                    label="Recherche équidés"
                />
            </Tabs>
            <PageContent>
                <Outlet />
            </PageContent>
        </>
    );
}
