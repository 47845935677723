// === NPM
import React, { useEffect, useRef, useState } from "react";
import { useFormMonitoringIntervention } from "./containers/useFormMonitoringIntervention";
import { Skeleton, Stack, Typography } from "@mui/material";
import { useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
// === NPM
import GeneralInformation from "./GeneralInformation/GeneralInformation";
import { IMonitoringInterventionForm, MonitoringInterventionStatus } from "../../../../interfaces/vaccination";
import { routerLinks } from "../../../../routers/RouterConstant";
import GenericConfirmDialog from "../../../generics/dialogs/GenericConfirmDialog";
import { getEnumKeyByValue, nullifyEmptyFields } from "../../../../resources/utils";
import FormStepper from "./containers/FormStepper";
import InterventionData from "./InterventionData/InterventionData";
import { IFarmMonitoring } from "../../../../interfaces/farm";
import Controls from "./Controls/Controls";
import VaccinationService from "../../../../services/VaccinationService";
import { HttpStatus } from "../../../../interfaces/global";
import { UserWithoutProperties } from "../../../../interfaces/user";
import RecapDialog from "./containers/RecapDialog";
import { MonitoringInterventionOutletContext } from "../interface";

export function Form() {
    const navigate = useNavigate();
    const formRef = useRef<HTMLButtonElement>(null);
    const previousRef = useRef<HTMLButtonElement>(null);

    const { loading, formatIntervention } = useOutletContext<MonitoringInterventionOutletContext>();
    const { form, setForm } = useFormMonitoringIntervention();
    const [activeStep, setActiveStep] = useState<number>(null);
    const [openConfirmCancelDialog, setOpenConfirmCancelDialog] = useState<boolean>(false);
    const [openRecapDialog, setOpenRecapDialog] = useState<boolean>(false);
    const [farms, setFarms] = useState<IFarmMonitoring[]>([]);
    const [veterinaries, setVeterinaries] = useState<UserWithoutProperties[]>([]);
    const [loadingGeneration, setLoadingGeneration] = useState<boolean>(false);

    useEffect(() => {
        return () => {
            setForm(null);
        };
    }, []);

    const handleConfirmCancel = (confirm: boolean) => {
        setOpenConfirmCancelDialog(false);
        if (confirm) {
            resetData();
        }
    };

    const resetData = () => {
        setForm(null);
        navigate(routerLinks.iahp.monitoringIntervention.view());
    };

    const handleSubmitGeneralInformation = async (status: keyof typeof MonitoringInterventionStatus) => {
        const formattedIntervention = formatIntervention(form);
        switch (status) {
            case getEnumKeyByValue(MonitoringInterventionStatus, MonitoringInterventionStatus.REALIZED):
                setForm(formattedIntervention);
                return setActiveStep(0);
            case getEnumKeyByValue(MonitoringInterventionStatus, MonitoringInterventionStatus.PLANNED):
                if (!form.generalInformation.id) {
                    const res = await VaccinationService.postMonitoringIntervention(formattedIntervention);
                    if (res.status === HttpStatus.CREATED) {
                        toast.success("Intervention créée avec succès");
                        navigate(routerLinks.iahp.monitoringIntervention.view());
                        setForm(null);
                    }
                } else {
                    const res = await VaccinationService.putMonitoringIntervention(
                        form.generalInformation.id,
                        formattedIntervention
                    );
                    if (res.status === HttpStatus.OK) {
                        toast.success("Intervention mise à jour");
                        navigate(routerLinks.iahp.monitoringIntervention.view());
                        setForm(null);
                    }
                }
                return;
            case getEnumKeyByValue(MonitoringInterventionStatus, MonitoringInterventionStatus.NOT_REALIZED):
                setOpenRecapDialog(true);
                break;
        }
    };

    const afterValidIntervention = (message: string) => {
        toast.success(message);
        setOpenRecapDialog(false);
        resetData();
    };

    const handleValidIntervention = async (finalized: boolean) => {
        setLoadingGeneration(true);
        const updatedForm = nullifyEmptyFields(form) as IMonitoringInterventionForm;
        if (!form.generalInformation.id) {
            const res = await VaccinationService.postMonitoringIntervention({
                ...updatedForm,
                finalized,
            });
            if (res.status === HttpStatus.CREATED) {
                afterValidIntervention("Intervention enregistrée");
            }
        } else {
            const res = await VaccinationService.putMonitoringIntervention(form.generalInformation.id, {
                ...updatedForm,
                finalized,
            });
            if (res.status === HttpStatus.OK) {
                afterValidIntervention("Intervention mise à jour");
            }
        }
        setLoadingGeneration(false);
    };

    const handlePreviousStep = () => {
        if (activeStep === 0) {
            setActiveStep(null);
        } else {
            setActiveStep(activeStep - 1);
        }
    };

    const handleSubmitInterventionData = () => {
        if (form.generalInformation.serologicalSamples.some((s) => s.realized)) setActiveStep(1);
        else {
            setForm((prev) => ({ ...prev, workshops: prev.workshops.map((w) => ({ ...w, controls: null })) }));
            setOpenRecapDialog(true);
        }
    };

    const getSteps = () => {
        const steps = ["Données et prélèvements"];
        if (form.generalInformation.serologicalSamples.some((s) => s.realized)) {
            steps.push("Contrôles");
        }
        return steps;
    };

    const renderSteps = () => {
        switch (activeStep) {
            case 0:
                return (
                    <InterventionData
                        formRef={formRef}
                        previousRef={previousRef}
                        onValid={handleSubmitInterventionData}
                        handlePreviousStep={handlePreviousStep}
                    />
                );
            case 1:
                return (
                    <Controls
                        formRef={formRef}
                        previousRef={previousRef}
                        handlePreviousStep={handlePreviousStep}
                        onValid={() => setOpenRecapDialog(true)}
                    />
                );
            default:
                return null;
        }
    };

    const renderSkeleton = () => (
        <Stack width="100%" spacing={2}>
            <Skeleton variant="text" height={40} width="50%" />
            <Skeleton variant="text" width="50%" />
            <Skeleton variant="rounded" height={250} />
            <Skeleton variant="text" width="50%" />
            <Skeleton variant="rounded" height={250} />
        </Stack>
    );

    return loading ? (
        renderSkeleton()
    ) : (
        <>
            <Stack spacing={2} width="100%">
                <Typography variant="h4">
                    {form?.generalInformation?.id
                        ? `Intervention de surveillance ${form?.generalInformation?.id}`
                        : "Nouvelle intervention de surveillance"}
                </Typography>
                {activeStep !== null ? (
                    <FormStepper
                        activeStep={activeStep}
                        handleNextStep={() => {
                            formRef.current.click();
                        }}
                        handlePreviousStep={() => {
                            previousRef.current?.click();
                        }}
                        steps={getSteps()}
                        setOpenConfirmCancelDialog={setOpenConfirmCancelDialog}
                    >
                        {renderSteps()}
                    </FormStepper>
                ) : (
                    <GeneralInformation
                        setOpenConfirmCancelDialog={setOpenConfirmCancelDialog}
                        onValid={handleSubmitGeneralInformation}
                        farms={farms}
                        setFarms={setFarms}
                        veterinaries={veterinaries}
                        setVeterinaries={setVeterinaries}
                    />
                )}
            </Stack>
            {openConfirmCancelDialog && (
                <GenericConfirmDialog
                    title="Annuler la saisie"
                    message="Êtes-vous sûr de vouloir annuler ? Vous allez perdre toutes les informations saisies."
                    onClose={handleConfirmCancel}
                />
            )}
            {openRecapDialog && (
                <RecapDialog
                    onClose={() => setOpenRecapDialog(false)}
                    intervention={form}
                    veterinaries={veterinaries}
                    farms={farms}
                    onValid={handleValidIntervention}
                    loading={loadingGeneration}
                />
            )}
        </>
    );
}
