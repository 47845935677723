// === NPM
import { Box, Stack, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
// === LOCAL
import { useLocation } from "react-router-dom";
import { ITourStep } from "../../interfaces/global";
import { TourKey, UserType } from "../../interfaces/user";
import { routerLinks } from "../../routers/RouterConstant";
import { useAuth } from "../../routers/useAuth";
import { ReactComponent as Pingouin } from "../../assets/icons/pingouin.svg";
import Tour from "../generics/Tour";
import { GenericIconText } from "../generics/text/GenericIconText";
import { ReactComponent as Calendar } from "../../assets/icons/home/calendar.svg";
import { ReactComponent as Delivery } from "../../assets/icons/home/delivery.svg";
import { ReactComponent as Building } from "../../assets/icons/home/building.svg";
import { UserSituation } from "../../resources/PermissionConstant";

export default function HomeFamDamTour() {
    const location = useLocation();
    const auth = useAuth();
    const [config, setSteps] = useState([]);

    useMemo(() => {
        const steps: ITourStep[] = [
            {
                target: "body",
                content: (
                    <Box>
                        <Pingouin style={{ height: 50, width: 50 }} />
                        <Typography>
                            Bienvenue sur Calypso ! <br /> Déclarez en ligne facilement et en toute sécurité vos
                            cessions d'antimicrobiens.
                        </Typography>
                    </Box>
                ),
                disableBeacon: true,
                placement: "center",
            },
            {
                target: "#home-activities",
                content: (
                    <Stack spacing={1}>
                        <Typography>
                            Dans cette section "Mon activité", vous retrouvez un résumé de votre situation à l'instant T
                            :
                        </Typography>
                        <GenericIconText
                            iconTooltip=""
                            icon={<Calendar style={{ height: 40, width: 40 }} />}
                            text="Date de votre dernière connexion"
                        />
                        <GenericIconText
                            iconTooltip=""
                            icon={<Delivery style={{ height: 40, width: 40 }} />}
                            text="Date et statut de votre dernière déclaration"
                        />
                        <GenericIconText
                            iconTooltip=""
                            icon={<Building style={{ height: 40, width: 40 }} />}
                            text={
                                auth.userInfo.situation === UserSituation.FAM_DAM_ADMIN
                                    ? "Le groupe dont vous êtes l’administrateur"
                                    : "Le groupe auquel vous êtes rattaché"
                            }
                        />
                    </Stack>
                ),
            },
            {
                target: "#edit-home-modules",
                content:
                    "En cliquant sur le stylet, vous pouvez ajouter et supprimer les accès rapides aux différents modules de votre plateforme.",
            },
            {
                target: "#parameters",
                content: "Ce bouton vous permet de configurer les paramètres de votre compte.",
                placement: "bottom",
            },
            {
                target: "#downloads",
                content: "Tous vos documents exportés seront disponibles dans cet espace.",
            },
            {
                target: "#notifications",
                content: "Ici, vous retrouverez vos notifications.",
            },
            {
                target: "#faq",
                content: "En cliquant sur cette icône, vous accédez à la foire aux questions.",
            },
        ];

        if (auth.userInfo.situation === UserSituation.FAM_DAM_ADMIN) {
            steps.push({
                target: "#fam_dam_users",
                content:
                    "En tant qu'administrateur, ce bouton vous permet d'accéder à une page pour gérer les utilisateurs de votre groupe.",
            });
        }
        steps.push(
            {
                target: "#establishments",
                content: "Ce bouton vous permet de consulter la liste des établissements de votre groupe.",
            },
            {
                target: "#dpes",
                content: "Ce bouton vous permet de consulter la liste des Domiciles Professionnels d'Exercice (DPE).",
            },
            {
                target: "body",
                content:
                    "Pour mieux comprendre le fonctionnement de chaque module, cliquez dessus et laissez-vous guider !",
                placement: "center",
            }
        );

        setSteps(steps);
    }, []);

    return (
        <Tour
            config={config}
            display={location.pathname === routerLinks.home && auth.userInfo.type === UserType.FAM_DAM}
            tourKey={TourKey.HOME_FAM_DAM}
        />
    );
}
