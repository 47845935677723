// === NPM
import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import { Add } from "@mui/icons-material";
import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import { GridRenderCellParams, GridSortModel, GridValueFormatterParams } from "@mui/x-data-grid-pro";
import { saveAs } from "file-saver";
import { DateTime } from "luxon";
import { ReactComponent as Info } from "../../../../assets/icons/info.svg";
import { ReactComponent as Edit } from "../../../../assets/icons/shared/edit_circular.svg";
import { useDepartments } from "../../../../context/useDepartmentContext";
import useTimeout from "../../../../hooks/useTimeout";
// === LOCAL
import { ActionsColumnProps, FilterType, HttpStatus, IPagination } from "../../../../interfaces/global";
import { InterventionCorrectionType } from "../../../../interfaces/vaccination";
import { CALYPSO_HEADERS, defaultPagination } from "../../../../resources/AppConstant";
import { colors, statusColor } from "../../../../resources/CssConstant";
import { Preference, UserSituation } from "../../../../resources/PermissionConstant";
import { convertEnumToKeyLabelObject, createPayload } from "../../../../resources/utils";
import { routerLinks } from "../../../../routers/RouterConstant";
import VaccinationService from "../../../../services/VaccinationService";
import DeleteAction from "../../../generics/actions/DeleteAction";
import DownloadAction from "../../../generics/actions/DownloadAction";
import EditAction from "../../../generics/actions/EditAction";
import IconActionButton from "../../../generics/actions/IconActionButton";
import ViewAction from "../../../generics/actions/ViewAction";
import GenericButton from "../../../generics/buttons/GenericButton";
import GenericConfirmDialog from "../../../generics/dialogs/GenericConfirmDialog";
import { FilterConfigurations, GenericFilters } from "../../../generics/filters/GenericFilters";
import PermissionsCheck from "../../../generics/PermissionsCheck";
import GenericTable from "../../../generics/table/GenericTable";
import WarningText from "../../../generics/WarningText";
import AuditDialog from "../containers/AuditDialog";
import { useFormIntervention } from "../FormIntervention/containers/useFormIntervention";
import {
    IAudit,
    IIntervention,
    InjectionType,
    IVaccinationInterventionDetails,
    IVaccinationInterventionFilters,
    VaccinationInterventionOutletContext,
    VaccinationInterventionType,
} from "../interface";
import CorrectionDialog from "./containers/CorrectionDialog";
import ViewDialog from "./containers/ViewDialog";

export default function InterventionFollowUp() {
    const { departments } = useDepartments();
    const navigate = useNavigate();
    const { vaccines, correctIntervention, setCorrectionReason } =
        useOutletContext<VaccinationInterventionOutletContext>();
    const { setForm, setHasAudit } = useFormIntervention();

    const [interventions, setInterventions] = useState<IIntervention[]>([]);
    const [inputFilters, setInputFilters] = useState<IVaccinationInterventionFilters>({
        id: "",
        farmId: "",
        workshopId: "",
        vaccinationDate: [null, null],
        vaccineGtinCode: [],
        vaccinationInterventionType: [],
        injectionType: [],
        isCertified: "",
        hasAudit: "",
        dpe: "",
        veterinary: "",
        workshopCity: "",
        workshopDepartmentCode: [],
    });
    const [pagination, setPagination] = useState<IPagination>(defaultPagination);
    const [rowCount, setRowCount] = useState<number>(0);
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "id", sort: "desc" }]);
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
    const [selectedInterventionId, setSelectedInterventionId] = useState<string>("");
    const [selectedIntervention, setSelectedIntervention] = useState<IVaccinationInterventionDetails>(null);
    const [openRecapDialog, setOpenRecapDialog] = useState<boolean>(false);
    const [openAuditDialog, setOpenAuditDialog] = useState<boolean>(false);
    const [correctionType, setCorrectionType] = useState<InterventionCorrectionType>(null);
    const [selectedAuditInfo, setSelectedAuditInfo] = useState<IAudit>(null);

    useTimeout(() => setPagination((prev) => ({ ...prev, page: 0 })), [inputFilters]);

    useEffect(() => {
        getInterventions();
    }, [pagination, sortModel]);

    const getInterventions = async () => {
        const payload = {
            page: pagination.page,
            size: pagination.pageSize,
            sorts: sortModel.map((s) => `${s.field},${s.sort}`),
            ...createPayload(inputFilters),
        };
        const res = await VaccinationService.getInterventions("", payload);
        if (res.status === HttpStatus.OK) {
            setInterventions(res.data);
            setRowCount(+res.headers[CALYPSO_HEADERS.TABLE_COUNT]);
        }
    };

    const handleDeleteAction = (intervention: IIntervention) => {
        setSelectedInterventionId(intervention.id);
        if (intervention.certificateFileUuid) {
            setCorrectionType(InterventionCorrectionType.DELETE);
        } else {
            setOpenDeleteDialog(true);
        }
    };

    const deleteIntervention = async (confirm: boolean) => {
        if (!confirm) {
            setOpenDeleteDialog(false);
            return;
        }
        const res = await VaccinationService.deleteIntervention(selectedInterventionId);
        if (res.status === HttpStatus.NO_CONTENT) {
            setOpenDeleteDialog(false);
            toast.success("Intervention supprimée avec succès");
            getInterventions();
        }
    };

    const deleteCorrectionIntervention = async (reason: string) => {
        const intervention = await VaccinationService.getIntervention(selectedInterventionId);
        if (intervention.status === HttpStatus.OK) {
            const interventionDtoOutObject = {
                ...intervention.data,
                generalInformation: {
                    vaccinationDate: intervention.data.generalInformation.vaccinationDate,
                    dpeId: intervention.data.generalInformation.dpeId,
                    veterinaryId: intervention.data.generalInformation.veterinaryId,
                    workshopId: intervention.data.generalInformation.workshopId,
                    id: intervention.data.generalInformation.id,
                },
                certificateGeneration: false,
            };

            const res = await correctIntervention(interventionDtoOutObject, reason, InterventionCorrectionType.DELETE);
            if (res.status === HttpStatus.NO_CONTENT) {
                setCorrectionType(null);
                setSelectedInterventionId(null);
                getInterventions();
            }
        }
    };

    const handleViewIntervention = async (id: string) => {
        const res = await VaccinationService.getIntervention(id);
        if (res.status === HttpStatus.OK) {
            setSelectedIntervention(res.data);
            setOpenRecapDialog(true);
        }
    };

    const getAudit = async (id: string) => {
        const res = await VaccinationService.getAudit(id);
        if (res.status === HttpStatus.OK) {
            setSelectedAuditInfo(res.data);
            setOpenAuditDialog(true);
        }
    };

    const onValidAudit = async (hasAudit: boolean, audit: IAudit) => {
        const intervention = interventions.find((i) => i.id === selectedInterventionId);
        let res;
        if (!hasAudit && !intervention.hasAudit) {
            afterAuditRequest();
            return;
        }
        if (!hasAudit && intervention.hasAudit) {
            res = await VaccinationService.deleteAudit(selectedInterventionId);
            if (res.status === HttpStatus.NO_CONTENT) {
                afterAuditRequest();
                toast.success("Informations d'audit supprimées");
            }
            return;
        }
        if (hasAudit && !intervention.hasAudit) {
            res = await VaccinationService.postAudit(selectedInterventionId, audit);
            if (res.status === HttpStatus.CREATED) {
                afterAuditRequest();
                toast.success("Informations d’audit enregistrées");
            }
        }
        if (hasAudit && intervention.hasAudit) {
            res = await VaccinationService.putAudit(selectedInterventionId, audit);
            if (res.status === HttpStatus.OK) {
                afterAuditRequest();
                toast.success("Informations d’audit modifiées");
            }
        }
    };

    const getFile = async (interventionId: string, fileUuid: string) => {
        const res = await VaccinationService.getFile(interventionId, fileUuid);
        if (res.status === HttpStatus.OK) {
            const file = new Blob([res.data], { type: res.headers["content-type"] });
            saveAs(file, res.headers["content-disposition"].split("filename=")[1].slice(1, -1));
        }
    };

    const afterAuditRequest = () => {
        setOpenAuditDialog(false);
        setSelectedInterventionId("");
        setSelectedAuditInfo(null);
        getInterventions();
    };

    const updateForm = async (intervention: IVaccinationInterventionDetails) => {
        setForm({
            ...intervention,
            generalInformation: {
                vaccinationDate: intervention.generalInformation.vaccinationDate,
                dpeId: intervention.generalInformation.dpeId,
                veterinaryId: intervention.generalInformation.veterinaryId,
                workshopId: intervention.generalInformation.workshopId,
                id: intervention.generalInformation.id,
            },
            certificateGeneration: !!intervention.certificateFileUuid,
        });
        setHasAudit(intervention.hasAudit);
    };

    const handleEditIntervention = async (id: string) => {
        setSelectedInterventionId(id);
        const res = await VaccinationService.getIntervention(id);
        if (res.status === HttpStatus.OK) {
            setSelectedIntervention(res.data);
            if (res.data.certificateFileUuid) {
                setCorrectionType(InterventionCorrectionType.UPDATE);
            } else {
                updateForm(res.data);
                navigate(routerLinks.iahp.vaccinationIntervention.form());
            }
        }
    };

    const handleSaveCorrection = async (reason: string) => {
        if (correctionType === InterventionCorrectionType.UPDATE) {
            updateForm(selectedIntervention);
            setSelectedIntervention(null);
            setCorrectionReason(reason);
            navigate(routerLinks.iahp.vaccinationIntervention.form());
        }
        if (correctionType === InterventionCorrectionType.DELETE) {
            deleteCorrectionIntervention(reason);
        }
    };

    const columns = [
        {
            field: "id",
            headerName: "Numéro intervention Calypso",
            width: 150,
        },
        {
            field: "vaccinationDate",
            headerName: "Date de vaccination",
            width: 150,
            valueFormatter: (params: GridValueFormatterParams<string>) =>
                params.value ? DateTime.fromISO(params.value).toLocaleString() : "",
        },
        {
            field: "dpeId",
            headerName: "N° DPE",
            width: 100,
        },
        {
            field: "dpeName",
            headerName: "Nom du DPE",
            width: 200,
        },
        {
            field: "veterinaryId",
            headerName: "N° ordinal vétérinaire",
            width: 150,
        },
        {
            field: "veterinaryLastname",
            headerName: "Vétérinaire",
            width: 200,
            valueGetter: (params: GridRenderCellParams) =>
                `${params.row.veterinaryFirstname} ${params.row.veterinaryLastname}`,
        },
        {
            field: "workshopId",
            headerName: "Identifiant de l'atelier",
            width: 150,
        },
        {
            field: "workshopCity",
            headerName: "Commune atelier",
            width: 150,
        },
        {
            field: "workshopDepartmentCode",
            headerName: "Département atelier",
            width: 150,
            valueGetter: (params: GridRenderCellParams) =>
                params.row.workshopDepartmentCode
                    ? `${params.row.workshopDepartmentCode} - ${params.row.workshopDepartmentName}`
                    : "",
        },
        {
            field: "farmId",
            headerName: "Identifiant établissement",
            width: 150,
        },
        {
            field: "hasAudit",
            headerName: "Audit réalisé",
            width: 150,
            valueFormatter: (params: GridValueFormatterParams) => (params.value ? "Oui" : "Non"),
        },
        {
            field: "vaccineGtinCode",
            headerName: "Vaccin",
            width: 150,
            valueFormatter: (params: GridValueFormatterParams) =>
                vaccines.find((v) => v.gtinCode === params.value)?.name,
            sortable: false,
        },
        {
            field: "injectionType",
            headerName: "N°injection",
            width: 150,
            valueFormatter: (params: GridValueFormatterParams<string>) => InjectionType[params.value],
            sortable: false,
        },
        {
            field: "vaccinationInterventionType",
            headerName: "Type d'intervention",
            width: 100,
            valueFormatter: (params: GridValueFormatterParams<string>) => VaccinationInterventionType[params.value],
            sortable: false,
        },
        {
            field: "animalCount",
            headerName: "Animaux vaccinés",
            width: 100,
        },
        {
            field: "doseCount",
            headerName: "Doses administrées",
            width: 100,
        },
        {
            field: "certificateFileUuid",
            headerName: "Attestation",
            width: 100,
            valueFormatter: (params: GridValueFormatterParams) => (params.value ? "Oui" : "Non"),
        },
        {
            ...ActionsColumnProps,
            renderCell: (params: GridRenderCellParams) => (
                <Box>
                    <ViewAction title="Voir le détail" onClick={() => handleViewIntervention(params.row.id)} />

                    <PermissionsCheck
                        requiredPermissions={[UserSituation.REGISTERED_IN_PRACTICE]}
                        preferences={[Preference.IAHP]}
                    >
                        <EditAction title="Modifier" onClick={() => handleEditIntervention(params.row.id)} />
                    </PermissionsCheck>
                    <PermissionsCheck
                        requiredPermissions={[UserSituation.REGISTERED_IN_PRACTICE]}
                        preferences={[Preference.IAHP]}
                    >
                        <IconActionButton
                            icon={<Edit />}
                            title={params.row.hasAudit ? "Modifier les informations d'audit" : "Créer un audit"}
                            onClick={() => {
                                if (params.row.hasAudit) {
                                    getAudit(params.row.id);
                                } else {
                                    setOpenAuditDialog(true);
                                }
                                setSelectedInterventionId(params.row.id);
                            }}
                        />
                    </PermissionsCheck>
                    {params.row.certificateFileUuid && (
                        <DownloadAction
                            title="Télécharger le certificat"
                            onClick={() => getFile(params.row.id, params.row.certificateFileUuid)}
                        />
                    )}
                    <PermissionsCheck
                        requiredPermissions={[UserSituation.REGISTERED_IN_PRACTICE]}
                        preferences={[Preference.IAHP]}
                    >
                        <DeleteAction title="Supprimer" onClick={() => handleDeleteAction(params.row)} />
                    </PermissionsCheck>
                </Box>
            ),
            width: 250,
        },
    ];

    const handlePageSizeChange = (pageSize: number) => {
        setPagination({ pageSize, page: 0 });
    };

    const handlePageChange = (page: number) => {
        setPagination({ ...pagination, page });
    };

    const filterConfigurations: FilterConfigurations<IVaccinationInterventionFilters> = {
        id: { label: "Numéro Calypso", type: FilterType.INPUT },
        vaccinationDate: { label: "Date de vaccination", type: FilterType.DATEPICKER },
        dpe: { label: "DPE", type: FilterType.INPUT },
        veterinary: { label: "Vétérinaire", type: FilterType.INPUT },
        workshopId: { label: "Identifiant atelier", type: FilterType.INPUT },
        workshopCity: { label: "Commune atelier", type: FilterType.INPUT },
        workshopDepartmentCode: {
            label: "Département atelier",
            type: FilterType.SELECT_AUTOCOMPLETE,
            values: departments.map((d) => ({ label: `${d.inseeCode} - ${d.name}`, key: d.inseeCode })),
        },
        farmId: { label: "Identifiant établissement", type: FilterType.INPUT },
        hasAudit: {
            label: "Audit réalisé",
            type: FilterType.SINGLE_SELECT,
            values: [
                { label: "Oui", key: "true" },
                { label: "Non", key: "false" },
            ],
        },
        vaccineGtinCode: {
            label: "Vaccin",
            type: FilterType.SELECT,
            values: vaccines.map((v) => ({ key: v.gtinCode, label: v.name })),
        },
        injectionType: {
            label: "N° injection",
            type: FilterType.SELECT,
            values: convertEnumToKeyLabelObject(InjectionType),
        },
        vaccinationInterventionType: {
            label: "Type intervention",
            type: FilterType.SELECT,
            values: convertEnumToKeyLabelObject(VaccinationInterventionType),
        },
        isCertified: {
            label: "Attestation",
            type: FilterType.SINGLE_SELECT,
            values: [
                { label: "Générée", key: "true" },
                { label: "Non générée", key: "false" },
            ],
        },
    };

    const renderWarning = () => (
        <Stack spacing={1} py={3}>
            <WarningText
                py={0}
                message="En jaune, les interventions de vaccination avec un ou plusieurs IMEP manquants."
            />
            <WarningText py={0} message="En vert, les interventions de vaccination dont l'attestation a été générée." />
        </Stack>
    );

    return (
        <>
            <Stack width="100%" spacing={2}>
                <Box display="flex" justifyContent="space-between" width="100%">
                    <Typography variant="h4">Mes interventions de vaccination</Typography>
                    <PermissionsCheck
                        requiredPermissions={[UserSituation.REGISTERED_IN_PRACTICE]}
                        preferences={[Preference.IAHP]}
                    >
                        <GenericButton
                            onClick={() => navigate(routerLinks.iahp.vaccinationIntervention.form())}
                            label="Nouvelle intervention"
                            startIcon={<Add />}
                        />
                    </PermissionsCheck>
                </Box>
                <Card>
                    <CardContent
                        sx={{
                            "& .missing": {
                                backgroundColor: statusColor.warningBackground,
                            },
                            "& .has-certificate": {
                                backgroundColor: statusColor.successBackground,
                            },
                        }}
                    >
                        {renderWarning()}
                        <GenericFilters
                            inputFilters={inputFilters}
                            filterConfigurations={filterConfigurations}
                            initialValues={{
                                id: "",
                                farmId: "",
                                workshopId: "",
                                vaccinationDate: [null, null],
                                vaccineGtinCode: [],
                                vaccinationInterventionType: [],
                                injectionType: [],
                                isCertified: "",
                                hasAudit: "",
                                dpe: "",
                                veterinary: "",
                                workshopCity: "",
                                workshopDepartmentCode: [],
                            }}
                            setInputFilters={setInputFilters}
                        />

                        <GenericTable
                            rows={interventions}
                            columns={columns}
                            onPageSizeChange={handlePageSizeChange}
                            onPageChange={handlePageChange}
                            page={pagination.page}
                            pageSize={pagination.pageSize}
                            autoHeight
                            sortingMode="server"
                            paginationMode="server"
                            sortModel={sortModel}
                            rowCount={rowCount}
                            onSortModelChange={(model) => setSortModel(model)}
                            getRowClassName={(params) =>
                                params.row.hasImepMissing
                                    ? "missing"
                                    : params.row.certificateFileUuid
                                      ? "has-certificate"
                                      : ""
                            }
                            sortingOrder={["asc", "desc"]}
                            filterMode="server"
                        />
                    </CardContent>
                </Card>
            </Stack>
            {openDeleteDialog && (
                <GenericConfirmDialog
                    title={`Suppression de l'intervention n°${selectedInterventionId}`}
                    message="Êtes-vous sûr de vouloir supprimer cette intervention de vaccination ? Cette action est définitive."
                    onClose={deleteIntervention}
                />
            )}
            {openRecapDialog && (
                <ViewDialog
                    intervention={selectedIntervention}
                    onClose={() => {
                        setOpenRecapDialog(false);
                        setSelectedIntervention(null);
                    }}
                />
            )}
            {openAuditDialog && (
                <AuditDialog
                    onClose={() => {
                        setOpenAuditDialog(false);
                        setSelectedInterventionId("");
                    }}
                    initAudit={interventions.find((i) => i.id === selectedInterventionId)?.hasAudit}
                    onValid={onValidAudit}
                    interventionId={selectedInterventionId}
                    initialValues={selectedAuditInfo}
                />
            )}
            {correctionType && (
                <CorrectionDialog
                    selectedInterventionId={selectedInterventionId}
                    correctionType={correctionType}
                    onClose={() => {
                        setCorrectionType(null);
                        setSelectedInterventionId("");
                        setSelectedIntervention(null);
                        setCorrectionReason(null);
                    }}
                    onValid={handleSaveCorrection}
                />
            )}
        </>
    );
}
