// === Import: NPM
import React from "react";
import { Box, createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { frFR } from "@mui/x-data-grid-pro";
import { Close } from "@mui/icons-material";

// === Import: LOCAL
import "./App.scss";
import AppRouter from "./AppRouter";
import { AuthProvider } from "./routers/useAuth";
import { colors, drawerColors, statusColor } from "./resources/CssConstant";
import NunitoRegular from "./assets/fonts/Nunito-Regular.ttf";
import NunitoBold from "./assets/fonts/Nunito-Bold.ttf";
import NunitoExtraBold from "./assets/fonts/Nunito-ExtraBold.ttf";
import IconActionButton from "./components/generics/actions/IconActionButton";
import { WebSocketProvider } from "./context/useSocket";

declare global {
    // eslint-disable-next-line no-unused-vars
    interface Window {
        _env_: any;
    }
}

declare module "@mui/material/styles" {
    interface Palette {
        neutral: Palette["primary"];
    }

    // allow configuration using `createTheme`
    interface PaletteOptions {
        neutral?: PaletteOptions["primary"];
    }
}

// Update the Button's color prop options
declare module "@mui/material/Button" {
    // eslint-disable-next-line no-unused-vars
    interface ButtonPropsColorOverrides {
        neutral: true;
    }
}

declare module "@mui/material/styles" {
    interface TypographyVariants {
        bold: React.CSSProperties;
        patched: React.CSSProperties;
        secondaryBold: React.CSSProperties;
        drawer: React.CSSProperties;
    }

    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        bold?: React.CSSProperties;
        patched: React.CSSProperties;
        secondaryBold: React.CSSProperties;
        drawer: React.CSSProperties;
    }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
    interface TypographyPropsVariantOverrides {
        bold: true;
        patched: true;
        secondaryBold: true;
        drawer: true;
    }
}

const theme = createTheme(
    {
        typography: {
            fontFamily: ["Nunito"].join(","),
            h6: {
                fontFamily: '"Nunito", sans-serif',
            },
            h5: {
                fontFamily: "Nunito-Bold",
                fontSize: 20,
            },
            h4: {
                fontFamily: "Nunito-Bold",
                fontSize: 30,
            },
            fontSize: 14,
            bold: {
                fontFamily: "Nunito-Bold",
            },
            secondaryBold: {
                color: colors.secondaryColor,
                fontFamily: "Nunito-Bold",
            },
            patched: {
                fontFamily: "Nunito-Bold",
                backgroundColor: statusColor.warningBackground,
                color: statusColor.warningText,
                borderRadius: 1,
                paddingLeft: 1,
                paddingRight: 1,
            },
            drawer: {
                color: drawerColors.text,
                fontFamily: "Nunito-ExtraBold",
                textTransform: "uppercase",
                fontSize: 16,
                paddingLeft: 10,
            },
        },
        components: {
            MuiCssBaseline: {
                styleOverrides: `
            @font-face {
                font-family: 'Nunito';
                src: local('Nunito'), local('Nunito-Regular'), url(${NunitoRegular}) format('woff2');
                unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
                font-display: swap;
            }
            @font-face {
                font-family: 'Nunito-Bold';
                src: local('Nunito-Bold'), local('Nunito-Bold'), url(${NunitoBold}) format('woff2');
                unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
                font-display: swap;
            }
            @font-face {
                font-family: 'Nunito-ExtraBold';
                src: local('Nunito-ExtraBold'), local('Nunito-ExtraBold'), url(${NunitoExtraBold}) format('woff2');
                unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
                font-display: swap;
            }
          `,
            },
            MuiCard: {
                styleOverrides: {
                    root: {
                        borderRadius: 40,
                    },
                },
                defaultProps: {
                    variant: "outlined",
                    sx: { display: "flex", flexGrow: 1, flexDirection: "column" },
                },
            },
            MuiCardHeader: {
                styleOverrides: {
                    root: {
                        paddingBottom: 0,
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        borderRadius: 58,
                        fontFamily: "Nunito-Bold",
                    },
                },
            },
            MuiDialogContent: {
                styleOverrides: {
                    root: {
                        backgroundColor: colors.background,
                    },
                },
            },
            MuiDialog: {
                styleOverrides: {
                    paper: {
                        borderRadius: 40,
                    },
                },
            },
            MuiDialogActions: {
                styleOverrides: {
                    root: {
                        boxShadow: "0 -3px 16px 0 rgba(0, 0, 0, 0.16)",
                        justifyContent: "center",
                        padding: 16,
                    },
                },
            },
            MuiAccordion: {
                defaultProps: {
                    variant: "outlined",
                },
                styleOverrides: {
                    rounded: {
                        borderRadius: 10,
                    },
                    root: {
                        "&:first-of-type": {
                            borderTopLeftRadius: 10,
                            borderTopRightRadius: 10,
                        },
                        "&:last-of-type": {
                            borderBottomLeftRadius: 10,
                            borderBottomRightRadius: 10,
                        },
                    },
                },
            },
            MuiFormLabel: {
                styleOverrides: {
                    asterisk: {
                        color: colors.error,
                    },
                },
            },
            MuiTooltip: {
                styleOverrides: {
                    tooltip: {
                        fontSize: 14,
                    },
                },
            },
            MuiTypography: {
                styleOverrides: {
                    root: {
                        whiteSpace: "pre-line",
                    },
                },
            },
        },
        palette: {
            primary: {
                main: colors.primaryColor,
            },
            secondary: {
                main: colors.secondaryColor,
            },
            error: {
                main: colors.error,
            },
            success: {
                main: colors.success,
            },
            neutral: {
                main: colors.lightGray,
                contrastText: colors.black,
            },
        },
    },
    frFR
);

function App() {
    return (
        <WebSocketProvider>
            <AuthProvider>
                <BrowserRouter>
                    <ThemeProvider theme={theme}>
                        <ToastContainer
                            className="toast"
                            position="top-right"
                            autoClose={10000}
                            hideProgressBar
                            newestOnTop={false}
                            rtl={false}
                            pauseOnFocusLoss={false}
                            pauseOnHover
                            style={{ fontFamily: "Nunito" }}
                            closeButton={({ closeToast }) => (
                                <IconActionButton title="" onClick={closeToast} icon={<Close />} />
                            )}
                        />
                        <Box sx={{ display: "flex", height: "100%" }}>
                            <CssBaseline />
                            <AppRouter />
                        </Box>
                    </ThemeProvider>
                </BrowserRouter>
            </AuthProvider>
        </WebSocketProvider>
    );
}

export default App;
