// === NPM
import React from "react";
import { AppBar, Box, Divider, IconButton, Stack, Toolbar, Tooltip } from "@mui/material";
import { PowerSettingsNew } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
// === LOCAL
import LocalStorageService from "../../services/LocalStorageService";
import { CALYPSO_TOKEN } from "../../resources/AppConstant";
import { UseAuth } from "../../routers/useAuth";
import { routerLinks } from "../../routers/RouterConstant";
import { UserType } from "../../interfaces/user";
import { ReactComponent as CalypsoLogo } from "../../assets/icons/navbar/Logo_Calypso.svg";
import { ReactComponent as Avatar } from "../../assets/icons/navbar/Avatar.svg";
import { ReactComponent as Settings } from "../../assets/icons/navbar/setting.svg";
import { ReactComponent as Information } from "../../assets/icons/navbar/information.svg";
import NavBarButton from "../styled/NavbarButton";
import IconActionButton from "../generics/actions/IconActionButton";
import PermissionsCheck from "../generics/PermissionsCheck";
import { UserSituation } from "../../resources/PermissionConstant";
import NotificationBell from "./containers/NotificationBell";
import Download from "./containers/Download";
import { hasAccess } from "../../resources/utils";

interface NavBarProps {
    auth: UseAuth;
}

export function NavBar({ auth }: NavBarProps) {
    const navigate = useNavigate();

    const onLogout = () => {
        LocalStorageService.removeLocaleStorageItem(CALYPSO_TOKEN);
        if (auth.userInfo.type === UserType.PHARMACIST) {
            window.location.assign(
                `${window._env_.REACT_APP_PSC_SSO_AUTHORITY_URL}/protocol/openid-connect/logout?post_logout_redirect_uri=${window.location.origin}&id_token_hint=${auth.userInfo.idToken}`
            );
            return;
        }

        window.location.assign(
            `${window._env_.REACT_APP_SSO_URL}/oauth2/logout?post_logout_redirect_uri=${window.location.origin}`
        );
    };

    const renderIcons = () => {
        const preferencesRoute = hasAccess(auth.userInfo.situation, [
            UserSituation.PUBLIC_SECTOR_VETERINARIAN,
            UserSituation.REGISTERED_IN_PRACTICE,
            UserSituation.THIRD_COUNTRIES_VETERINARY_REGISTERED_DIPLOMA,
        ])
            ? routerLinks.parameters.dpePreferences()
            : routerLinks.parameters.userPreferences();
        return (
            <Stack direction="row" alignItems="center">
                <Tooltip title="Foire aux questions">
                    <IconButton id="faq" onClick={() => navigate(routerLinks.faq)} sx={{ height: "fit-content" }}>
                        <Information />
                    </IconButton>
                </Tooltip>
                <NotificationBell />
                <Download />

                <IconActionButton
                    id="parameters"
                    title="Paramètres"
                    onClick={() => navigate(preferencesRoute)}
                    icon={<Settings />}
                />

                <NavBarButton onClick={() => navigate(routerLinks.profile)} startIcon={<Avatar />} id="account">
                    {`${auth.userInfo.id} - ${auth.userInfo.lastname} ${auth.userInfo.firstname}`}
                </NavBarButton>
            </Stack>
        );
    };

    return (
        <AppBar
            position="fixed"
            sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1,
                backgroundColor: window._env_.REACT_APP_HEADER_COLOR,
                borderBottom: "solid 1px #cedcd5",
            }}
            elevation={0}
        >
            <Toolbar>
                <Box sx={{ display: "flex", alignItems: "center", ml: 11, flexGrow: 1 }}>
                    <Link to={routerLinks.home} className="navbar-container-link" style={{ display: "inline-flex" }}>
                        <CalypsoLogo />
                    </Link>
                </Box>
                {renderIcons()}
                <Divider orientation="vertical" flexItem variant="middle" sx={{ borderRightWidth: 2 }} />
                <Box>
                    <NavBarButton onClick={onLogout} startIcon={<PowerSettingsNew />}>
                        Déconnexion
                    </NavBarButton>
                </Box>
            </Toolbar>
        </AppBar>
    );
}
