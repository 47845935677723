// === NPM
import React, { useState } from "react";
import { Card, CardHeader, CardContent, Stack, CardActions } from "@mui/material";
// === LOCAL
import { IConfig, SendMode } from "../../../interface";
import { IReferential } from "../../../../../../../interfaces/referential";
import { GenericIconText } from "../../../../../../generics/text/GenericIconText";
import { ReactComponent as Duration } from "../../../../../../../assets/icons/shared/duration.svg";
import { ReactComponent as Publish } from "../../../../../../../assets/icons/actions/publish.svg";
import { getEnumKeyByValue } from "../../../../../../../resources/utils";
import SecondaryButton from "../../../../../../styled/SecondaryButton";
import EditConfigDialog from "./EditConfigDialog";
import NotificationService from "../../../../../../../services/NotificationService";
import { HttpStatus } from "../../../../../../../interfaces/global";
import { toast } from "react-toastify";

interface ConfigCardProps {
    config: IConfig;
    types: IReferential[];
    updateConfigs: (config: IConfig) => void;
}

export default function ConfigCard({ config, types, updateConfigs }: ConfigCardProps) {
    const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);

    const title = types.find((t) => t.key === config.key)?.label;

    const handleValid = async (data: { sendMode: keyof typeof SendMode; publicationDayDelay: number }) => {
        const res = await NotificationService.putConfig(config.key, data);
        if (res.status === HttpStatus.OK) {
            toast.success("La configuration a bien été modifiée");
            setOpenEditDialog(false);
            updateConfigs(res.data);
        }
    };
    return (
        <>
            <Card
                variant="outlined"
                sx={{
                    paddingY: 1,
                    paddingX: 2,
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                }}
            >
                <CardHeader title={title} />
                <CardContent>
                    <Stack spacing={2}>
                        <GenericIconText
                            icon={<Publish />}
                            text={SendMode[config.sendMode]}
                            iconTooltip="Mode d'envoi"
                        />
                        {config.sendMode !== getEnumKeyByValue(SendMode, SendMode.MAIL) && (
                            <GenericIconText
                                icon={<Duration />}
                                text={`${config.publicationDayDelay} jours`}
                                iconTooltip="Durée de publication"
                            />
                        )}
                    </Stack>
                </CardContent>
                <CardActions sx={{ justifyContent: "center" }}>
                    <SecondaryButton variant="outlined" onClick={() => setOpenEditDialog(true)}>
                        Modifier la configuration
                    </SecondaryButton>
                </CardActions>
            </Card>
            {openEditDialog && (
                <EditConfigDialog
                    config={config}
                    onClose={() => setOpenEditDialog(false)}
                    title={`Modification des notifications ${title} `}
                    onValid={handleValid}
                />
            )}
        </>
    );
}
