// === NPM
import React, { useState, useContext, Dispatch, SetStateAction } from "react";
// === LOCAL
import { getEnumKeyByValue } from "../../../../../resources/utils";
import { IVaccinationInterventionForm, InjectionType } from "../../interface";

export interface UseFormIntervention {
    form: IVaccinationInterventionForm;
    setForm: Dispatch<SetStateAction<IVaccinationInterventionForm>>;
    hasAudit: boolean;
    setHasAudit: Dispatch<SetStateAction<boolean>>;
}

export const formInterventionContext = React.createContext<any>({});

export const useFormIntervention = (): UseFormIntervention => {
    return useContext(formInterventionContext);
};

export const FormInterventionProvider = ({ children }: React.PropsWithChildren) => {
    const auth = useProvideFormIntervention();
    return <formInterventionContext.Provider value={auth}>{children}</formInterventionContext.Provider>;
};

export const initFormIntervention: IVaccinationInterventionForm = {
    type: "",
    generalInformation: {
        vaccinationDate: "",
        dpeId: "",
        veterinaryId: "",
        workshopId: "",
        id: "",
    },
    animalInformation: {
        gtinCode: "",
        batchUuids: [],
        animalCount: 0,
        doseCount: 0,
        comment: "",
        speciesUuid: "",
        subSpeciesUuid: "",
        injectionType: getEnumKeyByValue(InjectionType, InjectionType.FIRST_DOSE),
        animalBatches: [],
        prescribedDoseCount: 0,
        sector: "",
        tier: "",
    },
    supervisionInformation: {
        complianceType: null,
        userType: null,
        complianceComment: "",
    },
    certificateGeneration: false,
    hasAudit: false,
};

const useProvideFormIntervention = () => {
    const [form, setForm] = useState<IVaccinationInterventionForm>(initFormIntervention);
    const [hasAudit, setHasAudit] = useState<boolean>(false);

    return {
        form,
        setForm,
        hasAudit,
        setHasAudit,
    };
};
