// === NPM
import React from "react";
import { Box, Card, CardActionArea, CardContent, Stack, Typography } from "@mui/material";
// === LOCAL
import { ReactComponent as Notes } from "../../../../../../assets/icons/form/notes.svg";
import { ReactComponent as Feather } from "../../../../../../assets/icons/form/feather.svg";
import { colors } from "../../../../../../resources/CssConstant";
import { IHealthReportingForm } from "../../../interface";

interface FormSelectionProps {
    draft: IHealthReportingForm;
    handleResetForm: () => void;
    handleDeclareForm: () => void;
}

export default function FormSelection({ draft, handleResetForm, handleDeclareForm }: FormSelectionProps) {
    const renderCard = () => {
        return (
            <Stack
                direction={{ sm: "column", md: "row" }}
                spacing={4}
                width="100%"
                justifyContent="space-around"
                alignItems={{ sm: "center", md: "center" }}
            >
                <Card variant="outlined" sx={{ display: "block" }}>
                    <CardActionArea
                        onClick={handleDeclareForm}
                        sx={{
                            borderRadius: "20px",
                            minHeight: "200px",
                            maxWidth: "300px",
                            alignItems: "center",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-around",
                            backgroundColor: colors.white,
                            textAlign: "center",
                        }}
                    >
                        <CardContent>
                            <Feather />
                            <Box>
                                <Typography variant="h5">
                                    {!draft
                                        ? "Effectuer une déclaration d'un signalement sanitaire"
                                        : "Reprendre ma déclaration en cours d'un signalement sanitaire"}
                                </Typography>
                            </Box>
                        </CardContent>
                    </CardActionArea>
                </Card>
                {draft && (
                    <Card variant="outlined" sx={{ display: "block" }}>
                        <CardActionArea
                            onClick={handleResetForm}
                            sx={{
                                borderRadius: "20px",
                                minHeight: "200px",
                                maxWidth: "300px",
                                alignItems: "center",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-around",
                                backgroundColor: colors.white,
                                textAlign: "center",
                            }}
                        >
                            <CardContent>
                                <Notes />
                                <Box>
                                    <Typography variant="h5">
                                        {
                                            "Supprimer ma déclaration en cours et déclarer un nouveau signalement sanitaire"
                                        }
                                    </Typography>
                                </Box>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                )}
            </Stack>
        );
    };

    return (
        <Stack alignItems="center" width="100%" spacing={15} mt={4}>
            <Typography variant="h4">Sélectionnez le formulaire que vous souhaitez utiliser</Typography>
            {renderCard()}
        </Stack>
    );
}
