// === NPM
import { Box, Stack, Tabs } from "@mui/material";
import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

// === LOCAL
import { routerLinks } from "../../../routers/RouterConstant";
import PermissionsTab from "../../../resources/PermissionsTab";
import { colors } from "../../../resources/CssConstant";
import { UserSituation } from "../../../resources/PermissionConstant";

export default function PowerBi() {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.pathname === routerLinks.delivery.powerBi.base())
            navigate(routerLinks.delivery.powerBi.followUp());
    }, []);

    return (
        <Stack height="100%" width="100%">
            <Tabs value={location.pathname} sx={{ ml: 12 }}>
                <PermissionsTab
                    value={routerLinks.delivery.powerBi.followUp()}
                    onClick={() => navigate(routerLinks.delivery.powerBi.followUp())}
                    permissions={[
                        UserSituation.ADMIN_CALYPSO,
                        UserSituation.ANMV_MED,
                    ]}
                    label="Tableaux de suivi"
                />
                <PermissionsTab
                    value={routerLinks.delivery.powerBi.indicators()}
                    onClick={() => navigate(routerLinks.delivery.powerBi.indicators())}
                    permissions={[
                        UserSituation.ADMIN_CALYPSO,
                        UserSituation.ANMV_MED,
                    ]}
                    label="Tableaux d'indicateurs"
                />
                <PermissionsTab
                    value={routerLinks.delivery.powerBi.dashBoard()}
                    onClick={() => navigate(routerLinks.delivery.powerBi.dashBoard())}
                    permissions={[]}
                    label="Tableau de bord"
                />
            </Tabs>
            <Box
                display="flex"
                sx={{
                    flexGrow: 1,
                    mx: 5,
                    mb: 4,
                    pt: 5,
                    px: 5,
                    backgroundColor: colors.background,
                    borderRadius: 5,
                }}
            >
                <Outlet />
            </Box>
        </Stack>
    );
}
