import React from "react";
import { TextField } from "@mui/material";
import { GridFilterInputValueProps } from "@mui/x-data-grid-pro";

export default function InputFilter(props: GridFilterInputValueProps) {
    const { item, applyValue } = props;

    const handleFilterChange = (event) => {
        applyValue({ ...item, value: event.target.value });
    };
    return <TextField label="" size="small" value={item.value || ""} onChange={handleFilterChange} />;
}
