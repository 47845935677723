// === NPM
import React from "react";
import { Add } from "@mui/icons-material";
import { Stack, Box, Typography, Skeleton } from "@mui/material";
// === LOCAL
import GenericButton from "../../../../../../generics/buttons/GenericButton";

export default function QuestionSkeleton() {
    const renderQuestionSkeleton = () => (
        <Stack height="100%" spacing={2} width="100%">
            <Box justifyContent="space-between" display="flex">
                <Typography variant="h4">Gestion des questions</Typography>
                <GenericButton label="Ajouter une question" startIcon={<Add />} disabled onClick={undefined} />
            </Box>
            <Stack spacing={2}>
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                <Skeleton variant="rounded" height={60} />
                <Skeleton variant="rounded" height={60} />
            </Stack>
        </Stack>
    );
    return (
        <Stack height="100%" spacing={8} width="100%">
            {renderQuestionSkeleton()}
        </Stack>
    );
}
