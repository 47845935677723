// === NPM
import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from "react";
import { Box, Card, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, Stack, Typography } from "@mui/material";
// === LOCAL
import { HttpStatus } from "../../../../../../interfaces/global";
import { colors, statusColor } from "../../../../../../resources/CssConstant";
import FarmService from "../../../../../../services/FarmService";
import { StyledCardContent } from "../../../../../styled/StyledCardContent";
import { ReactComponent as Warning } from "../../../../../../assets/icons/shared/danger-triangle.svg";
import { ReactComponent as Info } from "../../../../../../assets/icons/info.svg";
import GenericTextField from "../../../../../generics/inputs/GenericTextField";
import { FarmHeader, IFarmMonitoring } from "../../../../../../interfaces/farm";
import { IMonitoringInterventionWorkshop } from "../../../../../../interfaces/vaccination";
import { useDepartments } from "../../../../../../context/useDepartmentContext";
import GenericAutocomplete from "../../../../../generics/inputs/GenericAutocomplete";
import useTimeout from "../../../../../../hooks/useTimeout";
import IconBannerText from "../../../../../generics/IconBannerText";

interface WorkshopSelectionProps {
    dpeId: string;
    monitoringInterventionDate: string;
    workshops: IMonitoringInterventionWorkshop[];
    setWorkshops: Dispatch<SetStateAction<IMonitoringInterventionWorkshop[]>>;
    farmId: string;
    handleChange: (key: string) => (event: ChangeEvent<HTMLInputElement & HTMLSelectElement>) => void;
    workshopError: string;
    setWorkshopError: Dispatch<SetStateAction<string>>;
    farms: IFarmMonitoring[];
    setFarms: Dispatch<SetStateAction<IFarmMonitoring[]>>;
    disableEdition: boolean;
}

export default function WorkshopSelection({
    dpeId,
    monitoringInterventionDate,
    workshops,
    setWorkshops,
    farmId,
    handleChange,
    workshopError,
    setWorkshopError,
    farms,
    setFarms,
    disableEdition,
}: WorkshopSelectionProps) {
    const { departments } = useDepartments();
    const [farmSearch, setFarmSearch] = useState<string>(farmId);

    const farm = farms.find((f) => f.id === farmId);

    useEffect(() => {
        if (farmSearch) searchWorkshops();
    }, []);

    useTimeout(() => searchWorkshops(), [farmSearch]);

    const searchWorkshops = async () => {
        setWorkshopError("");
        if (!dpeId || !monitoringInterventionDate) {
            setWorkshopError("Veuillez d'abord sélectionner un DPE et une date d'intervention.");
            return;
        }
        const payload = {
            page: 0,
            size: 50,
            id: farmSearch,
            dpeId: dpeId,
            monitoringInterventionDate: monitoringInterventionDate,
        };
        const res = await FarmService.getFarms(payload, FarmHeader.MONITORING);
        if (res.status === HttpStatus.OK) {
            setFarms(res.data);
        }
    };

    const handleWorkshop = (event: React.ChangeEvent<HTMLInputElement>) => {
        const tempWorkshopIds = [...workshops];
        const { value } = event.target;
        const index = tempWorkshopIds.findIndex((ws) => ws.workshopId === value);
        index === -1
            ? tempWorkshopIds.push({
                  workshopId: value,
                  controls: null,
              })
            : tempWorkshopIds.splice(index, 1);
        setWorkshops(tempWorkshopIds);
    };

    const handleChangeFarm = (value: IFarmMonitoring) => {
        if (!value) {
            handleChange("farmId")({ target: { value: "" } } as ChangeEvent<HTMLInputElement & HTMLSelectElement>);
            setWorkshops([]);
            return;
        }
        handleChange("farmId")({ target: { value: value.id } } as ChangeEvent<HTMLInputElement & HTMLSelectElement>);
        setWorkshops(
            value.workshops.map((ws) => ({
                workshopId: ws.id,
                controls: null,
            }))
        );
    };

    const renderWarning = () => (
        <IconBannerText
            message="Certains ateliers sont déjà sélectionnés pour une intervention de surveillance à la même date."
            color={statusColor.warningText}
            backgroundColor={statusColor.warningBackground}
            icon={<Warning />}
        />
    );

    return (
        <Card>
            <StyledCardContent>
                <Stack spacing={4} p={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <GenericAutocomplete
                                label="Identifiant de l'établissement"
                                options={farms}
                                value={farms.find((f) => f.id === farmId) || null}
                                onChange={(e) => {
                                    handleChangeFarm(e.target.value);
                                }}
                                required
                                inputValue={farmSearch}
                                onInputChange={(event, newInputValue) => {
                                    setFarmSearch(newInputValue);
                                }}
                                noOptionsText={
                                    farmSearch ? "Aucun résultat" : "Saisissez des caractères pour lancer la recherche"
                                }
                                getOptionLabel={(option) => option.id}
                                disabled={disableEdition}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <GenericTextField value={farm?.name || ""} label="Nom de l'établissement" disabled />
                        </Grid>
                    </Grid>

                    {farm && farm.workshops.length === 0 && (
                        <Stack spacing={1} direction="row" alignItems="center" py={1}>
                            <Box display="flex" alignItems="center" sx={{ color: colors.primaryColor }}>
                                <Info />
                            </Box>
                            <Typography variant="bold" color={colors.primaryColor}>
                                Aucun atelier n'est associé à cet établissement.
                            </Typography>
                        </Stack>
                    )}
                    {farm && farm.workshops.length > 0 && (
                        <>
                            {farm.workshops.some((ws) => ws.alreadyInvolved) && renderWarning()}
                            <Stack spacing={1} sx={{ maxWidth: { xs: "fit-content", md: "30%" } }}>
                                <Typography variant="bold">Ateliers</Typography>
                                <FormControl component="fieldset" variant="standard">
                                    <FormGroup sx={{ gap: 1 }}>
                                        {farm.workshops
                                            .filter((ws) => ws.dpeId === dpeId)
                                            .map((workshop) => (
                                                <FormControlLabel
                                                    key={workshop.id}
                                                    labelPlacement="start"
                                                    sx={{
                                                        justifyContent: "space-between",
                                                        px: 2,
                                                        ml: 0,
                                                        borderRadius: 10,
                                                        fontWeight: 700,
                                                        border: `1px solid ${colors.primaryColor}`,
                                                        background: workshops.find(
                                                            (ws) => ws.workshopId === workshop.id
                                                        )
                                                            ? colors.lightGreen
                                                            : "",
                                                    }}
                                                    slotProps={{
                                                        typography: {
                                                            fontWeight: 700,
                                                            color: "primary",
                                                        },
                                                    }}
                                                    control={
                                                        <Checkbox
                                                            value={workshop.id}
                                                            checked={
                                                                !!workshops.find((ws) => ws.workshopId === workshop.id)
                                                            }
                                                            onChange={handleWorkshop}
                                                            disabled={disableEdition}
                                                        />
                                                    }
                                                    label={`${workshop.id} -  ${workshop.city} - ${
                                                        departments.find(
                                                            (d) => d.inseeCode === workshop.departmentInseeCode
                                                        ).name
                                                    } `}
                                                />
                                            ))}
                                    </FormGroup>
                                </FormControl>
                            </Stack>
                        </>
                    )}
                    {workshopError && <Typography color="error">{workshopError}</Typography>}
                </Stack>
            </StyledCardContent>
        </Card>
    );
}
