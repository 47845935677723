// === NPM
import { ContentCopy } from "@mui/icons-material";
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    FormControl,
    IconButton,
    Input,
    InputAdornment,
    Stack,
    Tooltip,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// === LOCAL
import "../Login/Login.scss";
import { routerLinks } from "../../routers/RouterConstant";
import ApiKeyService from "../../services/ApiKeyService";
import Spinner from "../generics/layout/Spinner";
import { HttpStatus } from "../../interfaces/global";

export default function RetrieveKey() {
    const location = useLocation();
    const navigate = useNavigate();
    const DEFAULT_TOOLTIP = "Copier dans le presse-papier";
    const [apiKey, setApiKey] = useState<string>("");
    const [tokenError, setTokenError] = useState<boolean>(false);
    const [serverError, setServerError] = useState<boolean>(false);
    const [tooltip, setTooltip] = useState<string>(DEFAULT_TOOLTIP);
    const [loading, setLoading] = useState<boolean>(true);
    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const token = queryParams.get("token");
        if (!token) {
            navigate(routerLinks.home);
            return;
        }
        getApiKey(token);
    }, []);

    const getApiKey = async (token: string) => {
        const res = await ApiKeyService.getKeyFromToken({ token });
        if (res.status === HttpStatus.CREATED) {
            setApiKey(res.data.apiKey);
            setTokenError(false);
        } else if (res.status === HttpStatus.UNAUTHORIZED || res.status === HttpStatus.BAD_REQUEST) {
            setTokenError(true);
        } else {
            setServerError(true);
        }
        setLoading(false);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleCopy = async () => {
        setOpen(true);
        setTooltip("Copié !");

        setTimeout(async () => {
            setOpen(false);
            setTooltip(DEFAULT_TOOLTIP);
        }, 1500);
        if ("clipboard" in navigator) {
            return navigator.clipboard.writeText(apiKey);
        }
        return document.execCommand("copy", true, apiKey);
    };

    const renderContent = () => {
        if (serverError) return <Typography>Une erreur est survenue, veuillez réessayer.</Typography>;

        if (tokenError)
            return (
                <Typography>
                    Votre token est invalide, veuillez contacter l'administrateur Calypso pour en obtenir un nouveau.
                </Typography>
            );

        return (
            <Stack spacing={2}>
                <Typography>Vous pouvez récupérer votre clef d'API ci-dessous</Typography>
                <FormControl sx={{ m: 1 }} variant="outlined">
                    <Input
                        id="outlined-adornment-password"
                        value={apiKey}
                        readOnly
                        endAdornment={
                            <InputAdornment position="end">
                                <Tooltip
                                    title={tooltip}
                                    open={open}
                                    onClose={handleClose}
                                    onOpen={handleOpen}
                                    placement="top"
                                >
                                    <IconButton aria-label="toggle password visibility" onClick={handleCopy} edge="end">
                                        <ContentCopy />
                                    </IconButton>
                                </Tooltip>
                            </InputAdornment>
                        }
                    />
                </FormControl>
            </Stack>
        );
    };

    return (
        <Box className="login-root" height="100%" display="flex" justifyContent="center" alignItems="center">
            <Card
                elevation={0}
                sx={{
                    backgroundColor: "rgba(255,255,255,0.9)",
                    padding: { xs: 2, sm: 3, md: "5em 8em" },
                    margin: 2,
                    maxWidth: "md",
                }}
            >
                <CardHeader title="Récupération de votre  clef d'API" />
                <CardContent>{loading ? <Spinner /> : renderContent()}</CardContent>
            </Card>
        </Box>
    );
}
