// === NPM
import React from "react";
import { Grid, DialogTitle, DialogContent, DialogActions, Dialog, Typography, Stack, Card } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// === LOCAL
import { IWorkshopShort } from "../../../../../../interfaces/farm";
import SecondaryButton from "../../../../../styled/SecondaryButton";
import { StyledCardContent } from "../../../../../styled/StyledCardContent";
import { useProvideGlobal } from "../../../../../../context/useGlobalContext";

interface ConflictDialogProps {
    onClose: () => void;
    workshops: IWorkshopShort[];
    onConfirm: () => void;
}

export default function ConflictDialog({ onClose, workshops, onConfirm }: ConflictDialogProps) {
    const { loadingRequest } = useProvideGlobal();

    const renderWorkshopText = () => {
        if (workshops.length > 1) {
            return (
                <Typography>
                    Les ateliers
                    <Typography variant="bold"> {workshops.map((w) => w.id).join(", ")}</Typography> sont déjà en
                    relation avec un DPE.
                </Typography>
            );
        }
        return (
            <Typography>
                L'atelier
                <Typography variant="bold"> {workshops.map((w) => w.id).join(", ")}</Typography> est déjà en relation
                avec un DPE.
            </Typography>
        );
    };
    return (
        <Dialog maxWidth="md" open>
            <DialogTitle>Erreur d'affectation</DialogTitle>
            <DialogContent dividers>
                <Card>
                    <StyledCardContent>
                        <Stack spacing={2} p={2}>
                            {renderWorkshopText()}

                            <Typography>
                                Merci de vérifier qu'un autre DPE de votre société d’exercice n’est pas concerné.
                            </Typography>
                            <Typography>
                                Dans le cas où vous êtes sûr des informations saisies, souhaitez-vous mettre en alerte
                                cette relation afin que le référent IAHP traite la demande ?{" "}
                            </Typography>
                        </Stack>
                    </StyledCardContent>
                </Card>
            </DialogContent>
            <DialogActions>
                <Grid container justifyContent="center" spacing={2}>
                    <Grid item>
                        <SecondaryButton variant="outlined" onClick={onClose} disabled={loadingRequest}>
                            Annuler
                        </SecondaryButton>
                    </Grid>
                    <Grid item>
                        <LoadingButton onClick={onConfirm} variant="contained" loading={loadingRequest}>
                            Mettre en alerte
                        </LoadingButton>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}
