// === NPM
import React from "react";
import { Grid, Box, Stack, Typography, Divider } from "@mui/material";
// === LOCAL
import GenericAccordion from "../../../generics/layout/GenericAccordion";
import GenericTitleValueText from "../../../generics/text/GenericTitleValueText";
import { IMonitoringInterventionWorkshop, IElisa, IVaccinationSupervision } from "../../../../interfaces/vaccination";
import StyledChip from "../../../styled/StyledChip";

interface ControlsProps {
    workshops: IMonitoringInterventionWorkshop[];
}

export default function Controls({ workshops }: ControlsProps) {
    const renderImeps = (imepList: string[]) => {
        return (
            <Grid item xs={12} md={4}>
                <Stack spacing={1}>
                    <Box mt={-0.25}>
                        <Typography variant="bold" fontSize={18}>
                            IMEPS
                        </Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5, mt: 2 }}>
                        {imepList === null || imepList.length === 0 ? (
                            <Typography>Aucun IMEP renseigné</Typography>
                        ) : (
                            imepList.map((imep) => <StyledChip key={imep} label={imep} />)
                        )}
                    </Box>
                </Stack>
            </Grid>
        );
    };

    const renderElisa = (elisa: IElisa) => {
        return (
            <Grid item xs={12} md={4}>
                <Stack spacing={1}>
                    <Box mt={-0.25}>
                        <Typography variant="bold" fontSize={18}>
                            Contrôle sérologique Elisa-NP
                        </Typography>
                    </Box>
                    <GenericTitleValueText title="Prélèvement" value={elisa.realized ? "Réalisé" : "Non réalisé"} />
                    {elisa.realized && (
                        <GenericTitleValueText title="Nombre de prises de sang" value={elisa.bloodSampleNumber} />
                    )}
                    {elisa.comment && <GenericTitleValueText title="Motif de non prélèvement" value={elisa.comment} />}
                </Stack>
            </Grid>
        );
    };

    const renderVaccinationSupervision = (vaccinationSupervision: IVaccinationSupervision) => {
        return (
            <Grid item xs={12} md={4}>
                <Stack spacing={1}>
                    <Box mt={-0.25}>
                        <Typography variant="bold" fontSize={18}>
                            Contrôle sérologique supervision de la vaccination
                        </Typography>
                    </Box>
                    <GenericTitleValueText
                        title="Contrôle"
                        value={vaccinationSupervision.realized ? "Réalisé" : "Non réalisé"}
                    />
                    {vaccinationSupervision.realized && (
                        <>
                            <GenericTitleValueText
                                title="Nombre d'analyses"
                                value={vaccinationSupervision.sampleNumber}
                            />
                            <GenericTitleValueText
                                title="Résultat des analyses"
                                value={vaccinationSupervision.compliantAnalysis ? "Conforme" : "Non conforme"}
                            />
                            {vaccinationSupervision.comment && (
                                <GenericTitleValueText
                                    title="Motif de non conformité"
                                    value={vaccinationSupervision.comment}
                                />
                            )}
                        </>
                    )}
                </Stack>
            </Grid>
        );
    };

    return (
        <GenericAccordion title="Contrôles" defaultExpanded>
            <Stack spacing={5}>
                {workshops.map((workshop, index) => (
                    <Stack spacing={2} key={workshop.workshopId}>
                        <Typography variant="h5">Atelier {workshop.workshopId}</Typography>
                        <Grid container columnSpacing={2} rowSpacing={4}>
                            {renderImeps(workshop.controls.imeps)}
                            {workshop.controls.elisa && renderElisa(workshop.controls.elisa)}
                            {workshop.controls.vaccinationSupervision &&
                                renderVaccinationSupervision(workshop.controls.vaccinationSupervision)}
                        </Grid>
                        {index < workshops.length - 1 && <Divider sx={{ width: "50%", alignSelf: "center" }} />}
                    </Stack>
                ))}
            </Stack>
        </GenericAccordion>
    );
}
