import { AxiosResponse } from "axios";
import { ArrayOfTwo } from "../../../interfaces/global";
import { IReferential } from "../../../interfaces/referential";
import {
    IMonitoringInterventionForm,
    InterventionCorrectionType,
    MonitoringInterventionStatus,
} from "../../../interfaces/vaccination";

export interface IMonitoringIntervention {
    id: string;
    date: string;
    dpeId: string;
    dpeName: string;
    veterinaryId: string;
    veterinaryFirstname: string;
    veterinaryLastname: string;
    farmId: string;
    farmName: string;
    farmCity: string;
    farmDepartmentCode: string;
    farmDepartmentName: string;
    worshopIds: string[];
    status: MonitoringInterventionStatus;
    elisaSampled: boolean;
    postVaccinationSampled: boolean;
    certificateFileUuid?: string;
    sampleFileUuid?: string;
}

export interface IMonitoringInterventionFilters {
    id: string;
    interventionDate: ArrayOfTwo;
    dpe: string;
    veterinary: string;
    farm: string;
    farmCity: string;
    farmDepartmentCode: string[];
    workshopId: string;
    status: MonitoringInterventionStatus[];
    elisaSampled: string;
    postVaccinationSampled: string;
    isCertified: string;
}

export interface IMonitoringInterventionCorrection {
    type: InterventionCorrectionType;
    reason: string;
    body: IMonitoringInterventionForm;
}

export interface MonitoringInterventionOutletContext {
    horsePower: IReferential[];
    loading: boolean;
    serologicalTypes: IReferential[];
    clinicalNonComplianceReasons: IReferential[];
    nonFullfilmentReasons: IReferential[];
    correctIntervention: (
        correctionType: InterventionCorrectionType,
        reason: string,
        intervention: IMonitoringInterventionForm
    ) => Promise<AxiosResponse<IMonitoringInterventionForm>>;
    formatIntervention: (intervention: IMonitoringInterventionForm) => IMonitoringInterventionForm;
}

export enum SampleType {
    ELISA_NP = "ELISA_NP",
    POST_VACCINATION = "POST_VACCINATION",
}
