// === NPM
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Card, FormControlLabel, FormGroup, Skeleton, Stack, Switch } from "@mui/material";
import { useProvideGlobal } from "../../../context/useGlobalContext";
import { HttpStatus } from "../../../interfaces/global";
import { UserSituation } from "../../../resources/PermissionConstant";
import { useAuth } from "../../../routers/useAuth";
// === LOCAL
import UserService from "../../../services/UserService";
import PermissionsCheck from "../../generics/PermissionsCheck";
import { StyledCardContent } from "../../styled/StyledCardContent";
import { IUserPreferences } from "../interface";

export default function UserPreferences() {
    const auth = useAuth();
    const { loadingButton } = useProvideGlobal();
    const [preferences, setPreferences] = useState<IUserPreferences>(null);

    useEffect(() => {
        getUserPreferences();
    }, []);

    const getUserPreferences = async () => {
        const res = await UserService.getUserPreferences(auth.userInfo.id);
        if (res.status === HttpStatus.OK) {
            setPreferences(res.data);
        }
    };

    const handlePreferences = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const payload = { ...preferences, [event.target.name]: event.target.checked };
        setPreferences(payload);
        const res = await UserService.putUserPreferences(auth.userInfo.id, payload);
        if (res.status === HttpStatus.OK) {
            toast.success("Préférences mises à jour avec succès");
            auth.refreshUserInfo();
        } else {
            setPreferences((prev) => ({
                ...prev,
                [event.target.name]: !event.target.checked,
            }));
        }
    };

    return preferences ? (
        <Stack width="100%" height="100%">
            <Card sx={{ p: 2, width: "100%" }}>
                <StyledCardContent>
                    <FormGroup>
                        <PermissionsCheck requiredPermissions={[UserSituation.REGISTERED_IN_PRACTICE]}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={preferences.iahp}
                                        name="iahp"
                                        onChange={handlePreferences}
                                        disabled={loadingButton}
                                    />
                                }
                                label="Je suis vétérinaire sanitaire d'élevage de canards."
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={preferences.blueTongueSheep}
                                        name="blueTongueSheep"
                                        onChange={handlePreferences}
                                        disabled={loadingButton}
                                    />
                                }
                                label="Je suis vétérinaire sanitaire pour les élevages ovins."
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={preferences.blueTongueCattle}
                                        name="blueTongueCattle"
                                        onChange={handlePreferences}
                                        disabled={loadingButton}
                                    />
                                }
                                label="Je suis vétérinaire sanitaire pour les élevages bovins."
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={preferences.butcherySector}
                                        name="butcherySector"
                                        onChange={handlePreferences}
                                        disabled={loadingButton}
                                    />
                                }
                                label="Je suis vétérinaire d'élevage d'équins."
                            />
                        </PermissionsCheck>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={preferences.emailNotification}
                                    name="emailNotification"
                                    onChange={handlePreferences}
                                    disabled={loadingButton}
                                />
                            }
                            label="Je souhaite recevoir les notifications envoyées à destination de ma boîte mail."
                        />
                    </FormGroup>
                </StyledCardContent>
            </Card>
        </Stack>
    ) : (
        <Skeleton variant="rounded" height={100} width="100%" />
    );
}
