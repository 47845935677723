// === NPM
import {
    Box,
    Card,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    Radio,
    RadioGroup,
    Stack,
    Switch,
    Typography,
} from "@mui/material";
import React, { ChangeEvent, RefObject, useEffect } from "react";
// === LOCAL
import { useForm } from "../../../../../../hooks/useForm";
import { useFormIntervention } from "../useFormIntervention";

import { FORM_TEXT } from "../../../../../../resources/FormUtils";
import { convertEnumToKeyLabelObject, getEnumKeyByValue } from "../../../../../../resources/utils";
import { StyledCardContent } from "../../../../../styled/StyledCardContent";
import { statusColor } from "../../../../../../resources/CssConstant";
import { ReactComponent as Warning } from "../../../../../../assets/icons/shared/danger-triangle.svg";
import {
    IVaccinationInterventionSupervisionInformation,
    ComplianceType,
    InterventionUserType,
} from "../../../interface";
import GenericComment from "../../../../../generics/inputs/GenericComment";

interface SupervisionInformationsProps {
    formRef: RefObject<HTMLButtonElement>;
    onValid: () => void;
}

export default function SupervisionInformations({ formRef, onValid }: SupervisionInformationsProps) {
    const { form, setForm, hasAudit, setHasAudit } = useFormIntervention();

    const { data, errors, handleSubmit, handleChange, cleanValue } =
        useForm<IVaccinationInterventionSupervisionInformation>({
            initialValues: form.supervisionInformation,
            validations: {
                complianceType: {
                    required: {
                        value: true,
                        message: FORM_TEXT.required,
                    },
                },
                userType: {
                    required: {
                        value: true,
                        message: FORM_TEXT.required,
                    },
                },
                complianceComment: {
                    limitValue: {
                        isValid: (value) => !(value.trim().length === 0 && value.length > 0),
                        message: FORM_TEXT.emptyString,
                    },
                    custom: {
                        isValid: (value) => (isCompliant && !value) || (!isCompliant && value.length > 0),
                        message: FORM_TEXT.required,
                    },
                },
            },
            onSubmit: () => {
                onValid();
            },
        });
    const isCompliant = data.complianceType === getEnumKeyByValue(ComplianceType, ComplianceType.COMPLIANT);

    useEffect(() => {
        setForm((prev) => ({
            ...prev,
            supervisionInformation: {
                ...prev.supervisionInformation,
                ...data,
            },
        }));
    }, [data]);

    const renderSupervisionInfo = () => (
        <Card sx={{ p: 2 }}>
            <StyledCardContent>
                <Grid container>
                    <Grid item xs={6} md={4}>
                        <FormControl>
                            <Typography variant="h5">
                                Intervention réalisée par{" "}
                                <Typography component="span" variant="h5" color="error">
                                    *
                                </Typography>
                            </Typography>

                            <RadioGroup
                                value={data.userType}
                                onChange={(e) => {
                                    handleChange("userType")(e as ChangeEvent<HTMLInputElement & HTMLSelectElement>);
                                }}
                            >
                                {convertEnumToKeyLabelObject(InterventionUserType).map((item) => (
                                    <FormControlLabel
                                        key={item.key}
                                        value={item.key}
                                        control={<Radio />}
                                        label={item.label}
                                        labelPlacement="end"
                                    />
                                ))}
                            </RadioGroup>
                            {errors.userType && (
                                <FormHelperText error={!!errors.userType}>{errors.userType}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <FormControl>
                            <Typography variant="h5">
                                Résultat du compte-rendu{" "}
                                <Typography component="span" variant="h5" color="error">
                                    *
                                </Typography>
                            </Typography>
                            <RadioGroup
                                value={data.complianceType}
                                onChange={(e) => {
                                    handleChange("complianceType")(
                                        e as ChangeEvent<HTMLInputElement & HTMLSelectElement>
                                    );
                                    cleanValue("complianceComment");
                                }}
                            >
                                {convertEnumToKeyLabelObject(ComplianceType).map((item) => (
                                    <FormControlLabel
                                        key={item.key}
                                        value={item.key}
                                        control={<Radio />}
                                        label={item.label}
                                        labelPlacement="end"
                                    />
                                ))}
                            </RadioGroup>
                            {errors.complianceType && (
                                <FormHelperText error={!!errors.complianceType}>{errors.complianceType}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                    {data.complianceType && !isCompliant && (
                        <Grid item xs={6} md={4}>
                            <GenericComment
                                label="Motif de non conformité du compte-rendu"
                                value={data.complianceComment}
                                onChange={handleChange("complianceComment")}
                                rows={5}
                                required
                                placeholder="Commentaire obligatoire relatif sur la non conformité du compte-rendu"
                                helperText={errors.complianceComment}
                            />
                        </Grid>
                    )}
                </Grid>
            </StyledCardContent>
        </Card>
    );

    const renderAuditInfo = () => (
        <Card sx={{ p: 2 }}>
            <StyledCardContent>
                <Typography variant="h5">
                    Réalisation de l'audit{" "}
                    <Typography component="span" variant="h5" color="error">
                        *
                    </Typography>
                </Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                    <Typography variant={!hasAudit ? "bold" : "body1"}>Audit non réalisé</Typography>
                    <Switch
                        checked={hasAudit}
                        onChange={(e) => {
                            setHasAudit(e.target.checked);
                        }}
                    />
                    <Typography variant={hasAudit ? "bold" : "body1"}>Audit réalisé</Typography>
                </Stack>
                <Stack
                    spacing={2}
                    p={1}
                    direction="row"
                    mt={2}
                    sx={{ backgroundColor: statusColor.warningBackground, borderRadius: 1 }}
                >
                    <Box display="flex" alignItems="center" sx={{ color: statusColor.warningText }}>
                        <Warning />
                    </Box>

                    <Typography variant="bold" color={statusColor.warningText}>
                        Les informations de l’audit sont à compléter après validation du formulaire d’intervention de
                        vaccination.
                    </Typography>
                </Stack>
            </StyledCardContent>
        </Card>
    );

    return (
        <Stack spacing={3} width="100%">
            <form onSubmit={handleSubmit} noValidate>
                <Stack spacing={3} width="100%">
                    <Typography sx={{ width: { xs: "100%", md: "50%" } }} variant="h5">
                        Compte-rendu de l'intervention
                    </Typography>
                    {renderSupervisionInfo()}
                    <Typography sx={{ width: { xs: "100%", md: "50%" } }} variant="h5">
                        Audit
                    </Typography>
                    {renderAuditInfo()}
                </Stack>
                <button style={{ display: "none" }} type="submit" ref={formRef} />
            </form>
        </Stack>
    );
}
