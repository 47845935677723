// === NPM
import React, { RefObject } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { useOutletContext } from "react-router-dom";
import { KeyboardArrowDown } from "@mui/icons-material";
import {
    Box,
    Card,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
// === LOCAL
import { ReactComponent as Info } from "../../../../../assets/icons/info.svg";
import { IDpeProperties } from "../../../../../interfaces/user";
import { VaccinationOutletContext } from "../../../../../interfaces/vaccination";
import { defaultIntegerLimit } from "../../../../../resources/AppConstant";
import { colors } from "../../../../../resources/CssConstant";
import { Preference } from "../../../../../resources/PermissionConstant";
import { useAuth } from "../../../../../routers/useAuth";
import GenericSelect from "../../../../generics/inputs/GenericSelect";
import GenericTextField from "../../../../generics/inputs/GenericTextField";
import { StyledCardContent } from "../../../../styled/StyledCardContent";
import { DelivererType } from "../interface";
import { OrderInformationSchema } from "../Order";
import { convertToSnakeCase } from "../../../../../resources/utils";

interface OrderInformationsProps {
    dpes: IDpeProperties[];
    orderInformationRef: RefObject<HTMLButtonElement>;
    orderInformationForm: UseFormReturn<OrderInformationSchema>;
    quantityError: string;
    onValid: () => void;
}

export default function OrderInformations({
    dpes,
    orderInformationRef,
    orderInformationForm,
    quantityError,
    onValid,
}: OrderInformationsProps) {
    const { userInfo } = useAuth();

    const { vaccines } = useOutletContext<VaccinationOutletContext>();
    const {
        formState: { errors },
        register,
        handleSubmit,
        watch,
        control,
    } = orderInformationForm;

    const vaccineGtinCode = watch("vaccineGtinCode");
    const quantity = watch("quantity");
    const dpeId = watch("dpeId");

    const selectedVaccine = vaccines.find((v) => v.gtinCode === vaccineGtinCode);

    const hasBlueTonguePref = userInfo.preferences?.blueTongueCattle || userInfo.preferences?.blueTongueSheep;

    const filterDpes = () => {
        if (!hasBlueTonguePref) {
            return dpes.filter((dpe) => dpe.sanitaryDpe);
        }
        return dpes;
    };

    const filterVaccines = () => {
        let result = [...vaccines];

        const prefs = Object.values(Preference).reduce((acc, pref) => {
            if (userInfo.preferences[pref]) {
                // convert it to snake case to match the useKey format
                acc.push(convertToSnakeCase(pref));
            }
            return acc;
        }, []);

        result = result.filter((v) => prefs.includes(v.useKey));

        const dpe = dpes.find((d) => d.id === dpeId);
        if (!dpe?.sanitaryDpe) {
            return result.filter((v) => v.useKey !== "iahp");
        }

        return result;
    };

    return (
        <Card>
            <StyledCardContent>
                <Stack spacing={2} p={2}>
                    <Typography variant="bold">Informations sur les vaccins FCO</Typography>
                    <Stack spacing={1} direction="row" alignItems="start" py={1}>
                        <Box display="flex" alignItems="center" sx={{ color: colors.primaryColor }}>
                            <Info />
                        </Box>
                        <Typography variant="bold" color={colors.primaryColor}>
                            {
                                "La commande des vaccins BLUEVAC3 et BULTAVO3 ne peut être effectuée que pour l'utilisation suivante :\n- Le détenteur d'animaux doit être localisé dans 1 des 6 régions suivantes : Hauts-de-France, Grand-Est, Normandie, Ile-de-France, Centre-Val de Loire et Bourgogne-Franche-Comté.\n- Le responsable de la commande et de la délivrance des vaccins est le vétérinaire sanitaire.\n- Le BLUEVAC3 100ml et 252 ml est destiné aux bovins. Le BULTAVO3 50 ml est destiné aux ovins."
                            }
                        </Typography>
                    </Stack>
                    <Typography variant="bold">Informations du DPE</Typography>
                    {hasBlueTonguePref && (
                        <Stack spacing={1} direction="row" alignItems="center" py={1}>
                            <Box display="flex" alignItems="center" sx={{ color: colors.primaryColor }}>
                                <Info />
                            </Box>
                            <Typography variant="bold" color={colors.primaryColor}>
                                DPE en charge du suivi sanitaire
                            </Typography>
                        </Stack>
                    )}

                    <FormControl fullWidth error={!!errors.dpeId} required>
                        <InputLabel>Nom du DPE</InputLabel>
                        <Controller
                            name="dpeId"
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <>
                                    <Select
                                        label="Nom du DPE"
                                        onChange={onChange}
                                        value={value}
                                        IconComponent={KeyboardArrowDown}
                                    >
                                        {filterDpes().map((dpe) => (
                                            <MenuItem key={dpe.id} value={dpe.id}>
                                                {`${dpe.id} - ${dpe.name}`}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText>{error?.message}</FormHelperText>
                                </>
                            )}
                        />
                    </FormControl>
                    <Typography variant="bold">Commande de vaccins</Typography>
                    <Stack direction="row" spacing={2}>
                        <Controller
                            name="vaccineGtinCode"
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <GenericSelect
                                    onChange={onChange}
                                    options={filterVaccines().map((v) => ({
                                        gtinCode: v.gtinCode,
                                        name: `${v.name} ${v.packaging}`,
                                    }))}
                                    required
                                    optionsValue="gtinCode"
                                    optionsLabel="name"
                                    error={!!error}
                                    helperText={error?.message}
                                    label="Vaccin"
                                    value={value}
                                    disabled={!dpeId}
                                />
                            )}
                        />

                        <TextField
                            {...register("quantity")}
                            label={
                                selectedVaccine?.deliverer === DelivererType.SERVIPHAR
                                    ? "Quantité requise"
                                    : "Quantité livrée"
                            }
                            required
                            fullWidth
                            error={!!errors.quantity || !!quantityError}
                            helperText={(errors.quantity?.message as string) || quantityError}
                            inputProps={{
                                maxLength: defaultIntegerLimit,
                            }}
                        />

                        <GenericTextField
                            value={selectedVaccine?.doseNumber * quantity || 0}
                            label="Doses totales"
                            disabled
                        />
                    </Stack>
                </Stack>
            </StyledCardContent>
            <button style={{ display: "none" }} onClick={handleSubmit(onValid)} ref={orderInformationRef} />
        </Card>
    );
}
