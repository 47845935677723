// === NPM
import React from "react";

// === LOCAL
import { IAccount } from "../../interface";
import GenericTextField from "../../../../../generics/inputs/GenericTextField";
import GenericDialog from "../../../../../generics/dialogs/GenericDialog";
import GenericActionsDialog from "../../../../../generics/dialogs/GenericActionsDialog";

interface AccountInformationDialogProps {
    account: IAccount;
    title: string;
    onClose: any;
}

export default function AccountInformationDialog({ account, title, onClose }: AccountInformationDialogProps) {
    return (
        <GenericDialog
            title={title}
            onClose={onClose}
            renderContent={() => <GenericTextField value={account.email} label="Email" disabled />}
            renderActions={() => <GenericActionsDialog onClose={onClose} displaySubmit={false} />}
        />
    );
}
