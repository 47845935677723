// === NPM
import React from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { Card, FormControl, FormControlLabel, Radio, RadioGroup, Stack, TextField, Typography } from "@mui/material";
import { z } from "zod";
// === LOCAL
import { IConfig, SendMode } from "../../../interface";
import GenericActionsDialog from "../../../../../../generics/dialogs/GenericActionsDialog";
import GenericDialog from "../../../../../../generics/dialogs/GenericDialog";
import { StyledCardContent } from "../../../../../../styled/StyledCardContent";
import { FORM_TEXT } from "../../../../../../../resources/FormUtils";
import { convertEnumToKeyLabelObject } from "../../../../../../../resources/utils";

interface EditConfigDialogProps {
    config: IConfig;
    onClose: () => void;
    title: string;
    onValid: (data: ValidationSchema) => void;
}

const formSchema = z
    .object({
        sendMode: z.enum(["MAIL", "CALYPSO_APPLICATION", "BOTH"]),
        publicationDayDelay: z
            .number()
            .int("La durée de publication doit être un nombre entier")
            .positive("Veuillez renseigner une durée de publication positive")
            .max(99999, "La durée de publication ne peut pas excéder 99999 jours")
            .optional(),
    })
    .superRefine((values, context) => {
        if (values.sendMode !== "MAIL") {
            if (!values.publicationDayDelay) {
                context.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: FORM_TEXT.required,
                    path: ["publicationDayDelay"],
                });
            }
        }
    });

type ValidationSchema = z.infer<typeof formSchema>;

export default function EditConfigDialog({ config, onClose, title, onValid }: EditConfigDialogProps) {
    const {
        formState: { errors },
        handleSubmit,
        control,
        watch,
        register,
    } = useForm<ValidationSchema>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            sendMode: config.sendMode,
            publicationDayDelay: config.publicationDayDelay,
        },
    });

    const sendMode = watch("sendMode");

    return (
        <GenericDialog
            maxWidth="sm"
            title={title}
            onClose={onClose}
            renderContent={() => (
                <Card sx={{ padding: 2 }}>
                    <StyledCardContent>
                        <Stack spacing={2} width="fit-content">
                            <FormControl component="fieldset">
                                <Typography variant="bold">Mode d'envoi</Typography>
                                <Controller
                                    name="sendMode"
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <RadioGroup value={value} onChange={onChange}>
                                            {convertEnumToKeyLabelObject(SendMode).map((mode) => (
                                                <FormControlLabel
                                                    value={mode.key}
                                                    control={<Radio />}
                                                    label={mode.label}
                                                    key={mode.key}
                                                />
                                            ))}
                                        </RadioGroup>
                                    )}
                                />
                            </FormControl>
                            {sendMode !== "MAIL" && (
                                <TextField
                                    label="Durée de publication (en jours)"
                                    type="number"
                                    error={!!errors.publicationDayDelay}
                                    helperText={errors.publicationDayDelay?.message}
                                    {...register("publicationDayDelay", {
                                        setValueAs: (value) => +value,
                                    })}
                                />
                            )}
                        </Stack>
                    </StyledCardContent>
                </Card>
            )}
            renderActions={() => <GenericActionsDialog onClose={onClose} onSubmit={handleSubmit(onValid)} />}
        />
    );
}
