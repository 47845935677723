// === Import: NPM
import { Box, Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// === Import: LOCAL
import { HttpStatus } from "../../../../interfaces/global";
import { routerLinks } from "../../../../routers/RouterConstant";
import { useAuth } from "../../../../routers/useAuth";
import UserService from "../../../../services/UserService";
import { InfoTooltip } from "../../../styled/InfoTooltip";
import Dpe from "./containers/Dpe";
import OrdinalSituation from "./containers/OrdinalSituation";
import PersonalDetails from "./containers/PersonalDetails";
import TreatedSpecies from "./containers/TreatedSpecies";
import VeterinaryProfileSkeleton from "./containers/VeterinaryProfileSkeleton";
import { IOVDpe, IProfileInfo } from "../../interface";
import { UserSituation } from "../../../../resources/PermissionConstant";
import PermissionsCheck from "../../../generics/PermissionsCheck";

const EXTRANET_URL = window._env_.REACT_APP_EXTRANET_URL;

export default function VeterinaryProfile() {
    const auth = useAuth();
    const [profileInfo, setProfileInfo] = useState<IProfileInfo>(null);
    const [dpes, setDpes] = useState<IOVDpe[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const navigate = useNavigate();

    useEffect(() => {
        getProfileInfo();
        getDPE();
    }, []);

    const getProfileInfo = async () => {
        const res = await UserService.getUserInfo(auth.userInfo.id);
        if (res.status === HttpStatus.OK) {
            setProfileInfo(res.data);
            setLoading(false);
        } else if (res.status === HttpStatus.FORBIDDEN) {
            navigate(routerLinks.home);
        }
    };

    const getDPE = async () => {
        const res = await UserService.getUserOVDPE(auth.userInfo.id);
        if (res.status === HttpStatus.OK) {
            setDpes(res.data);
        }
    };

    const updateData = async () => {
        const res = await UserService.getTokenSso();
        if (res.status === HttpStatus.OK) {
            window.open(`${EXTRANET_URL}?token_sso=${res.data}`);
        }
    };

    return loading ? (
        <VeterinaryProfileSkeleton />
    ) : (
        <Grid container p={2} spacing={5}>
            <PermissionsCheck requiredPermissions={[UserSituation.REGISTERED_IN_PRACTICE]}>
                <Grid item xs={12}>
                    <Box width="100%" justifyContent="flex-end" display="flex">
                        <InfoTooltip title="Vous allez être redirigé vers l’extranet de l’Ordre des Vétérinaires où vous accèderez à vos données ordinales et vos démarches en ligne">
                            <Box>
                                <Button variant="contained" onClick={updateData}>
                                    Modifier mes données
                                </Button>
                            </Box>
                        </InfoTooltip>
                    </Box>
                </Grid>
            </PermissionsCheck>
            <Grid item xs={12} lg={6}>
                <PersonalDetails personalInfo={profileInfo?.personalInfo} />
            </Grid>
            <Grid item xs={12} lg={6}>
                <OrdinalSituation ordinalSituation={profileInfo?.ordinalSituation} />
            </Grid>
            <Grid item xs={12}>
                <Dpe dpes={dpes} />
            </Grid>
            <Grid item xs={12}>
                <TreatedSpecies treatedSpecies={profileInfo?.treatedSpecies} dpes={dpes} />
            </Grid>
        </Grid>
    );
}
