// === NPM
import React, { Dispatch, RefObject, useEffect, useRef, useState } from "react";
import { Card, Divider, InputAdornment, Stack, Tooltip, Typography } from "@mui/material";
import { Help } from "@mui/icons-material";
// === LOCAL
import {
    IElisa,
    IMonitoringInterventionWorkshop,
    IVaccinationSupervision,
} from "../../../../../../interfaces/vaccination";
import { StyledCardContent } from "../../../../../styled/StyledCardContent";
import { useFormMonitoringIntervention } from "../../containers/useFormMonitoringIntervention";
import { Form, useForm } from "../../../../../../hooks/useForm";
import Elisa from "./Elisa";
import MonitoringVaccination from "./MonitoringVaccination";
import { FORM_TEXT, PATTERN } from "../../../../../../resources/FormUtils";
import TextListAdder from "./TextListAdder";
import { IMEPMaskComponent } from "./IMEPMaskComponent";
import { SampleType } from "../../../interface";
import { maxIntegerValue } from "../../../../../../resources/AppConstant";

interface WorkshopProps {
    workshop: IMonitoringInterventionWorkshop;
    workshopRef: RefObject<Record<number, HTMLButtonElement>>;
    setIsValidWorkshops: Dispatch<React.SetStateAction<Record<string, boolean>>>;
    isPrevious: boolean;
}

export default function Workshop({ workshop, workshopRef, setIsValidWorkshops, isPrevious }: WorkshopProps) {
    const { form, setForm } = useFormMonitoringIntervention();

    const elisaRef = useRef<HTMLButtonElement>(null);
    const monitoringVaccinationRef = useRef<HTMLButtonElement>(null);

    const imepForm: Form<{
        imep: string;
        imeps: string[];
    }> = useForm<{
        imep: string;
        imeps: string[];
    }>({
        initialValues: workshop.controls?.imeps
            ? { imep: "", imeps: workshop.controls.imeps }
            : {
                  imep: "",
                  imeps: [],
              },
        validations: {
            imep: {
                required: {
                    value: true,
                    message: "Le champ ne peut pas être vide",
                },
                pattern: {
                    value: "[A-Z]{3}[0-9]{2}.[0-9]{3}.[0-9]{3}",
                    message: "Le format de l'IMEP est incorrect",
                },
                custom: {
                    isValid: () => imepForm.data.imeps.length < 10,
                    message: "Le nombre d'IMEP est limité à 10",
                },
            },
        },
        onSubmit: () => {
            const imeps = imepForm.data.imeps;
            imeps.push(imepForm.data.imep);
            imepForm.initValues({ imep: "", imeps });
        },
    });

    const elisaForm = useForm<IElisa>({
        initialValues: workshop.controls?.elisa ?? {
            realized: false,
            bloodSampleNumber: null,
            comment: "",
        },
        validations: {
            bloodSampleNumber: {
                pattern: {
                    value: PATTERN.positiveNumber,
                    message: "La valeur doit être un entier positif",
                },
                custom: {
                    isValid: (value) => (elisaForm.data.realized && value) || !elisaForm.data.realized,
                    message: FORM_TEXT.required,
                },
                limitValue: {
                    isValid: (value) => +value <= maxIntegerValue,
                    message: FORM_TEXT.maxInteger,
                },
            },
            comment: {
                custom: {
                    isValid: (value) => (!elisaForm.data.realized && value?.trim()) || elisaForm.data.realized,
                    message: FORM_TEXT.required,
                },
            },
        },

        onSubmit: () => {
            setIsValidElisa(true);
        },
    });

    const vaccinationSupervisionForm = useForm<IVaccinationSupervision>({
        initialValues: workshop.controls?.vaccinationSupervision ?? {
            realized: false,
            sampleNumber: null,
            compliantAnalysis: null,
            comment: "",
        },
        validations: {
            comment: {
                custom: {
                    isValid: (value) =>
                        (value && !vaccinationSupervisionForm.data.compliantAnalysis && value?.trim().length) ||
                        vaccinationSupervisionForm.data.compliantAnalysis ||
                        !vaccinationSupervisionForm.data.realized,
                    message: FORM_TEXT.required,
                },
            },
            sampleNumber: {
                pattern: {
                    value: PATTERN.positiveNumber,
                    message: "La valeur doit être un nombre positif",
                },
                limitValue: {
                    isValid: (value) => +value <= maxIntegerValue,
                    message: FORM_TEXT.maxInteger,
                },
            },
        },

        onSubmit: () => {
            setIsValidMonitoringVaccination(true);
        },
    });

    const [isValidElisa, setIsValidElisa] = useState<boolean>(false);
    const [isValidMonitoringVaccination, setIsValidMonitoringVaccination] = useState<boolean>(false);
    const [imepListError, setImepListError] = useState<string>(null);

    const samples = form.generalInformation.serologicalSamples.filter((sample) => sample.realized);

    useEffect(() => {
        const listIsValid = validateImepList();

        if (isValidElisa && isValidMonitoringVaccination && listIsValid) {
            updateForm();
            setIsValidWorkshops((prev) => ({ ...prev, [workshop.workshopId]: true }));
            setIsValidElisa(false);
            setIsValidMonitoringVaccination(false);
            setImepListError(null);
        }
        if (!listIsValid) {
            setImepListError("Un IMEP précédemment renseigné ne correspond plus au format AAA11.111.111 attendu.");
            setIsValidElisa(false);
            setIsValidMonitoringVaccination(false);
        }
    }, [isValidElisa, isValidMonitoringVaccination]);

    useEffect(() => {
        updateForm();
    }, [isPrevious]);

    const updateForm = () => {
        setForm((prev) => ({
            ...prev,
            workshops: prev.workshops.map((ws) =>
                ws.workshopId === workshop.workshopId
                    ? {
                          ...ws,
                          controls: {
                              elisa: hasSampleRealized(SampleType.ELISA_NP) ? elisaForm.data : null,
                              vaccinationSupervision: hasSampleRealized(SampleType.POST_VACCINATION)
                                  ? vaccinationSupervisionForm.data
                                  : null,
                              imeps: imepForm.data.imeps,
                          },
                      }
                    : ws
            ),
        }));
    };

    const hasSampleRealized = (sampleType: string) => {
        return samples.some((sample) => sample.sampleType === sampleType && sample.realized);
    };

    const handleValidate = () => {
        setIsValidElisa(false);
        setIsValidMonitoringVaccination(false);
        if (hasSampleRealized(SampleType.ELISA_NP)) {
            elisaRef.current.click();
        } else {
            setIsValidElisa(true);
        }
        if (hasSampleRealized(SampleType.POST_VACCINATION)) {
            monitoringVaccinationRef.current.click();
        } else {
            setIsValidMonitoringVaccination(true);
        }
    };

    const validateImepList = () => {
        const imepFormatRegexp: RegExp = /[A-Z]{3}\d{2}.\d{3}.\d{3}/;
        return imepForm.data.imeps.every((imep) => imepFormatRegexp.test(imep));
    };

    const renderImeps = () => {
        return (
            <TextListAdder
                form={imepForm}
                label="IMEP"
                valueLabel="imep"
                elementsList={imepForm.data.imeps}
                elementsListLabel="imeps"
                error={!!imepForm.errors.imep || !!imepListError}
                helperText={imepForm.errors.imep || imepListError}
                inputComponent={IMEPMaskComponent as any}
                emptyText="Après avoir saisi un IMEP, cliquez sur l'icone '+' ou appuyez sur la touche 'entrée' pour sa prise en compte"
                startAdornment={
                    <InputAdornment position="start">
                        <Tooltip title="Le format attendu est AAA11.111.111, merci de remplacer les _ par des majuscules et les # par des chiffres.">
                            <Help />
                        </Tooltip>
                    </InputAdornment>
                }
            />
        );
    };

    return (
        <Stack spacing={2} width="100%">
            <Typography variant="h5">{workshop.workshopId}</Typography>
            <Card sx={{ p: 2 }}>
                <StyledCardContent>
                    <Stack spacing={6} width="100%">
                        {renderImeps()}
                        {hasSampleRealized(SampleType.ELISA_NP) && <Elisa form={elisaForm} elisaRef={elisaRef} />}
                        {hasSampleRealized(SampleType.ELISA_NP) && hasSampleRealized(SampleType.POST_VACCINATION) && (
                            <Divider sx={{ width: "50%", alignSelf: "center" }} />
                        )}
                        {hasSampleRealized(SampleType.POST_VACCINATION) && (
                            <MonitoringVaccination
                                monitoringVaccinationRef={monitoringVaccinationRef}
                                form={vaccinationSupervisionForm}
                            />
                        )}
                    </Stack>
                </StyledCardContent>
            </Card>
            <button
                style={{ display: "none" }}
                onClick={handleValidate}
                ref={(el) => (workshopRef.current[workshop.workshopId] = el)}
            />
        </Stack>
    );
}
