// === NPM
import React from "react";
import { Card, CardHeader, CardContent, CardActions, Stack } from "@mui/material";
// === LOCAL
import { GenericIconText } from "../../../../../../generics/text/GenericIconText";
import { IAccountWithKeys } from "../../../interface";
import AccountTableActions from "../../AccountTableActions";
import { ReactComponent as Flag } from "../../../../../../../assets/icons/shared/flag.svg";
import { ReactComponent as Start } from "../../../../../../../assets/icons/shared/start.svg";
import { toLocaleDateFormat } from "../../../../../../../resources/utils";

interface SIPartnersCardProps {
    account: IAccountWithKeys;
    getAccounts: () => void;
}

export default function SIPartnersCard({ account, getAccounts }: SIPartnersCardProps) {
    const formattedCreationDate = account.creationDate ? toLocaleDateFormat(account.creationDate) : "";
    const formattedEndDate = account.endDate ? toLocaleDateFormat(account.endDate) : "Pas de date renseignée";

    return (
        <Card variant="outlined" sx={{ paddingY: 1, paddingX: 2, width: "100%" }}>
            <CardHeader title={account.label} />
            <CardContent>
                <Stack spacing={2}>
                    <GenericIconText icon={<Start />} text={formattedCreationDate} iconTooltip="Date de création" />
                    <GenericIconText icon={<Flag />} text={formattedEndDate} iconTooltip="Date de fin" />
                </Stack>
            </CardContent>
            <CardActions sx={{ flexDirection: "row-reverse" }}>
                <AccountTableActions row={account} getAccounts={getAccounts} />
            </CardActions>
        </Card>
    );
}
