// === NPM
import React, { useRef, useState } from "react";
import FormStepper from "./FormStepper";
import { Box, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AxiosResponse } from "axios";
// === LOCAL
import GenericConfirmDialog from "../../../../generics/dialogs/GenericConfirmDialog";
import { initFormIntervention, useFormIntervention } from "./useFormIntervention";
import { routerLinks } from "../../../../../routers/RouterConstant";
import { getEnumKeyByValue } from "../../../../../resources/utils";
import InterventionInformations from "./InterventionInformations/InterventionInformations";
import AnimalInformations from "./AnimalInformations/AnimalInformations";
import { IFarm } from "../../../../../interfaces/farm";
import SupervisionInformations from "./SupervisionInformations/SupervisionInformations";
import RecapDialog from "./RecapDialog";
import AuditDialog from "../../containers/AuditDialog";
import VaccinationService from "../../../../../services/VaccinationService";
import { HttpStatus } from "../../../../../interfaces/global";
import { UserWithoutProperties } from "../../../../../interfaces/user";
import { IAudit, IVaccinationInterventionForm, VaccinationInterventionType } from "../../interface";

export default function Form() {
    const { setForm, form, hasAudit } = useFormIntervention();
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = useState<number>(0);
    const [openConfirmCancelDialog, setOpenConfirmCancelDialog] = useState<boolean>(false);
    const [farm, setFarm] = useState<IFarm>(null);
    const [veterinaries, setVeterinaries] = useState<UserWithoutProperties[]>([]);
    const [vaccineBatches, setVaccineBatches] = useState<{ uuid: string; number: string }[]>([]);
    const [openRecapDialog, setOpenRecapDialog] = useState<boolean>(false);
    const [openAuditDialog, setOpenAuditDialog] = useState<boolean>(false);
    const [selectedAuditInfo, setSelectedAuditInfo] = useState<IAudit>(null);

    const formRef = useRef<HTMLButtonElement>(null);

    const handleConfirmCancel = (confirm: boolean) => {
        setOpenConfirmCancelDialog(false);
        if (confirm) {
            resetData();
        }
    };

    const resetData = () => {
        setForm(initFormIntervention);
        setActiveStep(0);
        navigate(routerLinks.iahp.vaccinationIntervention.view());
    };

    const handleValidIntervention = async (certificateGeneration: boolean) => {
        if (!form.generalInformation.id) {
            const res = await VaccinationService.postIntervention(cleanImep(form), certificateGeneration);
            if (res.status === HttpStatus.CREATED) {
                afterValidIntervention("Intervention enregistrée", res.data);
            }
        } else {
            const res = await VaccinationService.putIntervention(
                form.generalInformation.id,
                cleanImep(form),
                certificateGeneration
            );

            if (res.status === HttpStatus.OK) {
                afterValidIntervention("Intervention mise à jour", res.data);
            }
        }
    };

    const cleanImep = (intervention: IVaccinationInterventionForm): IVaccinationInterventionForm => {
        const form = intervention;
        form.animalInformation.animalBatches.forEach((batch) => {
            batch.imepNumber = batch.imepNumber ? batch.imepNumber : null;
        });
        return form;
    };

    const afterValidIntervention = (message: string, form: IVaccinationInterventionForm) => {
        toast.success(message);
        setOpenRecapDialog(false);
        setForm(form);
        handleAuditCase();
    };

    const handleAuditCase = async () => {
        if (!hasAudit && form.hasAudit) {
            const res = await VaccinationService.deleteAudit(form.generalInformation.id);
            if (res.status === HttpStatus.NO_CONTENT) {
                resetData();
                toast.success("Informations d'audit supprimées");
            }
        } else if (hasAudit) {
            setOpenAuditDialog(true);
        } else {
            resetData();
        }
    };

    const getAudit = async (id: string) => {
        const res = await VaccinationService.getAudit(id);
        if (res.status === HttpStatus.OK) {
            setSelectedAuditInfo(res.data);
        }
    };

    const onValidAudit = async (hasAudit: boolean, audit: IAudit) => {
        let res: AxiosResponse;
        if (!hasAudit && !form.hasAudit) {
            handleCloseAudit();
        } else if (!hasAudit && form.hasAudit) {
            res = await VaccinationService.deleteAudit(form.generalInformation.id);
            if (res.status === HttpStatus.NO_CONTENT) {
                handleCloseAudit();
                toast.success("Informations d'audit supprimées");
            }
        } else if (hasAudit && !form.hasAudit) {
            res = await VaccinationService.postAudit(form.generalInformation.id, audit);
            if (res.status === HttpStatus.CREATED) {
                handleCloseAudit();
                toast.success("Informations d’audit enregistrées");
            }
        } else {
            res = await VaccinationService.putAudit(form.generalInformation.id, audit);
            if (res.status === HttpStatus.OK) {
                handleCloseAudit();
                toast.success("Informations d’audit modifiées");
            }
        }
    };

    const handleCloseAudit = () => {
        setOpenAuditDialog(false);
        resetData();
    };

    const renderSteps = () => {
        switch (activeStep) {
            case 0:
                return (
                    <InterventionInformations
                        formRef={formRef}
                        onValid={() => setActiveStep(activeStep + 1)}
                        setFarm={setFarm}
                        farm={farm}
                        veterinaries={veterinaries}
                        setVeterinaries={setVeterinaries}
                    />
                );
            case 1:
                return (
                    <AnimalInformations
                        onValid={() => setActiveStep(activeStep + 1)}
                        formRef={formRef}
                        vaccineBatches={vaccineBatches}
                        setVaccineBatches={setVaccineBatches}
                    />
                );
            case 2:
                return (
                    <SupervisionInformations
                        onValid={() => {
                            setOpenRecapDialog(true);
                            if (hasAudit && form.hasAudit) getAudit(form.generalInformation.id);
                        }}
                        formRef={formRef}
                    />
                );
        }
    };

    return (
        <>
            <Stack spacing={2} width="100%">
                <Typography variant="h4">
                    Intervention de vaccination{" "}
                    {form.type === getEnumKeyByValue(VaccinationInterventionType, VaccinationInterventionType.HATCHERY)
                        ? "au couvoir"
                        : "en élevage"}
                </Typography>
                <FormStepper
                    activeStep={activeStep}
                    handleNextStep={() => {
                        formRef.current.click();
                    }}
                    handlePreviousStep={() => setActiveStep(activeStep - 1)}
                    setOpenConfirmCancelDialog={setOpenConfirmCancelDialog}
                >
                    <Box sx={{ flexGrow: 1, my: 4 }}>{renderSteps()}</Box>
                </FormStepper>
                {openConfirmCancelDialog && (
                    <GenericConfirmDialog
                        title="Annuler la saisie"
                        message="Êtes-vous sûr de vouloir annuler ? Vous allez perdre toutes les informations saisies."
                        onClose={handleConfirmCancel}
                    />
                )}
            </Stack>
            {openRecapDialog && (
                <RecapDialog
                    onClose={() => setOpenRecapDialog(false)}
                    farm={farm}
                    vaccineBatches={vaccineBatches}
                    hasAudit={hasAudit}
                    onValid={handleValidIntervention}
                    veterinaries={veterinaries}
                />
            )}
            {openAuditDialog && (
                <AuditDialog
                    onClose={handleCloseAudit}
                    initAudit={hasAudit}
                    onValid={onValidAudit}
                    interventionId={form.generalInformation.id}
                    initialValues={selectedAuditInfo}
                />
            )}
        </>
    );
}
