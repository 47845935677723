// === NPM
import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, MenuItem, MenuList, styled, Tooltip, tooltipClasses, TooltipProps, Typography } from "@mui/material";
// === LOCAL
import { colors } from "../../../resources/CssConstant";
import PermissionsCheck from "../../generics/PermissionsCheck";
import { IDrawerMenu, IDrawerSubSection, IDrawerSubSectionWithChild } from "../interface";

interface DrawerTooltipProps {
    title: string;
    children: JSX.Element;
    open?: boolean;
    subSection?: IDrawerSubSectionWithChild | IDrawerSubSection;
    onClose: () => void;
    onOpen: (e: any) => void;
}

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: colors.white,
        boxShadow: theme.shadows[1],
        color: colors.black,
        fontSize: 16,
        padding: 0,
        borderRadius: 5,
    },
}));

export default function DrawerTooltip({ title, children, open, subSection, onClose, onOpen }: DrawerTooltipProps) {
    const navigate = useNavigate();

    const renderDrawerMenu = (menu: IDrawerMenu) => (
        <PermissionsCheck requiredPermissions={menu.permissions} key={menu.path} preferences={menu.preferences}>
            <MenuItem
                onClick={() => {
                    navigate(menu.path);
                    onClose();
                }}
            >
                {menu.label}
            </MenuItem>
        </PermissionsCheck>
    );
    return (
        <StyledTooltip
            open={open}
            onOpen={onOpen}
            onClose={onClose}
            leaveDelay={100}
            title={
                <>
                    <Box sx={{ background: colors.background, p: 1, color: colors.primaryColor }}>
                        <Typography variant="bold">{title}</Typography>
                    </Box>
                    {subSection && <MenuList>{subSection.children?.map((menu) => renderDrawerMenu(menu))}</MenuList>}
                </>
            }
            placement="right"
        >
            {children}
        </StyledTooltip>
    );
}
