import { Stack, Typography, Card, Skeleton } from "@mui/material";
import React from "react";

export default function PharmacistProfileSkeleton() {
    return (
        <Stack spacing={3} height="100%" direction="row">
            <Stack spacing={3}>
                <Typography variant="h5">Informations personnelles</Typography>
                <Card sx={{ height: "100%", display: "flex", flexGrow: 1, flexDirection: "column" }}>
                    <Skeleton variant="rectangular" animation="wave" height={200} width={300} />
                </Card>
            </Stack>
            <Stack spacing={3}>
                <Typography variant="h5">Informations de mon officine</Typography>
                <Card sx={{ height: "100%", display: "flex", flexGrow: 1, flexDirection: "column" }}>
                    <Skeleton variant="rectangular" animation="wave" height={200} width={300} />
                </Card>
            </Stack>
        </Stack>
    );
}
