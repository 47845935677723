// === Import: NPM
import React from "react";
import { Card, CardContent, Grid, Stack, Typography } from "@mui/material";
// === Import: LOCAL
import { GenericText } from "../../../../generics/text/GenericText";
import GenericTitleValueText from "../../../../generics/text/GenericTitleValueText";
import { IPersonalInfo } from "../../../interface";

interface PersonalDetailsProps {
    personalInfo: IPersonalInfo;
}
export default function PersonalDetails({ personalInfo }: PersonalDetailsProps) {
    const { personalAddress, mailingAddress } = personalInfo;

    return (
        <Stack spacing={3} height="100%">
            <Typography variant="h5">Informations personnelles</Typography>
            <Card sx={{ height: "100%", display: "flex", flexGrow: 1, flexDirection: "column" }}>
                <CardContent>
                    <Grid container p={2}>
                        <Grid item xs={12} sm={6}>
                            <Stack>
                                <GenericTitleValueText title="Numéro ordinal" value={personalInfo.ordinalNumber} />
                                <GenericTitleValueText title="Civilité" value={personalInfo.civility} />
                                <GenericTitleValueText title="Prénom" value={personalInfo.firstName} />
                                <GenericTitleValueText title="Nom" value={personalInfo.lastName} />
                                <GenericText bold value="Adresse personnelle" margin />
                                <GenericText value={personalAddress.way} />
                                <GenericText value={personalAddress.additionalAddress1} />
                                <GenericText value={personalAddress.additionalAddress2} />
                                <GenericText
                                    value={`${personalAddress.zipCode ?? ""} ${personalAddress.city ?? ""} ${
                                        personalAddress.country ?? ""
                                    }`}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Stack>
                                <GenericText bold value="Adresse de correspondance" margin />
                                <GenericText value={mailingAddress.way} />
                                <GenericText value={mailingAddress.additionalAddress1} />
                                <GenericText value={mailingAddress.additionalAddress2} />
                                <GenericText
                                    value={`${mailingAddress.zipCode} ${mailingAddress.city}  ${
                                        mailingAddress.country ?? ""
                                    }`}
                                />
                                <GenericText bold value="Coordonnées de contact" margin />
                                <GenericText value={personalInfo.phone} />
                                <GenericText value={personalInfo.email} />
                                <GenericText value={personalInfo.emailVet} />
                            </Stack>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Stack>
    );
}
