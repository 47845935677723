import { AxiosResponse } from "axios";
import { HttpMethod, Loader, SearchParams } from "../interfaces/global";
import HttpService from "./HttpService";
import { FarmHeader, IFarm, IFarmMonitoring, IWorkshop, IWorkshopRelation } from "../interfaces/farm";
import { endpoints } from "./EndpointConstant";
import { formatParams } from "../resources/utils";
import { WorkshopFilters } from "../components/Referentials/containers/Global/containers/Workshops/Workshops";
import { IReferential } from "../interfaces/referential";
import { ViewRelationFilters } from "../components/VaccinationIahp/Farm/containers/ViewRelation/ViewRelation";
import { CALYPSO_HEADERS } from "../resources/AppConstant";
import { IClosureRelation } from "../components/VaccinationIahp/Farm/interface";

class FarmService {
    async getFarm(farmId: string, payload?: { speciesUuid: string }): Promise<AxiosResponse<IFarm>> {
        return HttpService.fetch({
            url: endpoints.farmService.farm(farmId),
            method: HttpMethod.GET,
            params: formatParams(payload),
            validateStatus: false,
        });
    }

    async getFarms(
        payload: SearchParams & { dpeId: string; monitoringInterventionDate: string },
        dataType: FarmHeader
    ): Promise<AxiosResponse<IFarmMonitoring[]>> {
        return HttpService.fetch({
            url: endpoints.farmService.farms(),
            method: HttpMethod.GET,
            params: formatParams(payload),
            headers: {
                [CALYPSO_HEADERS.DATA_TYPE]: dataType,
            },
        });
    }

    async getFarmByWorkshop(workshopId: string, payload?: { speciesUuid: string }): Promise<AxiosResponse<IFarm>> {
        return HttpService.fetch({
            url: endpoints.farmService.workshops.farmByWorkshop(workshopId),
            method: HttpMethod.GET,
            params: formatParams(payload),
            validateStatus: false,
        });
    }

    async postWorkshopAlert(workshopIds: string[], dpeId: string, openingDate: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.farmService.workshops.alert(),
            method: HttpMethod.POST,
            data: {
                workshopIds,
                dpeId,
                openingDate,
            },
            loader: Loader.REQUEST,
        });
    }

    async patchFarmWorkshop(
        farmId: string,
        payload: { workshopIds: string[]; dpeId: string; openingDate: string },
        cerfaFile: File
    ): Promise<AxiosResponse> {
        const blob = new Blob([JSON.stringify(payload)], {
            type: "application/json",
        });

        const formData = new FormData();
        formData.append("file", cerfaFile);
        formData.append("link", blob);
        return HttpService.fetch({
            url: endpoints.farmService.farmLinkedWorkshops(farmId),
            method: HttpMethod.PATCH,
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: formData,
            loader: Loader.REQUEST,
        });
    }

    async getWorkshops(payload: SearchParams & Partial<WorkshopFilters>): Promise<AxiosResponse<IWorkshop[]>> {
        return HttpService.fetch({
            url: endpoints.farmService.workshops.base,
            method: HttpMethod.GET,
            params: formatParams(payload),
            loader: Loader.TABLE,
        });
    }

    async postWorkshops(file: File): Promise<AxiosResponse> {
        const formData = new FormData();
        formData.append("file", file);

        return HttpService.fetch({
            url: endpoints.farmService.workshops.base,
            method: HttpMethod.POST,
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: formData,
            loader: Loader.REQUEST,
        });
    }

    async getWorkshopLinks(
        payload: SearchParams & Partial<ViewRelationFilters>
    ): Promise<AxiosResponse<IWorkshopRelation[]>> {
        return HttpService.fetch({
            url: endpoints.farmService.workshops.linked(),
            method: HttpMethod.GET,
            params: formatParams(payload),
            loader: Loader.TABLE,
        });
    }

    async getWorkshopFile(workshopId: string, fileUuid: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.farmService.workshops.file(workshopId, fileUuid),
            method: HttpMethod.GET,
            responseType: "blob",
            loader: Loader.BUTTON,
        });
    }

    async patchCerfa(relationId: string, file: File): Promise<AxiosResponse<string>> {
        const formData = new FormData();
        formData.append("file", file);

        return HttpService.fetch({
            url: endpoints.farmService.workshops.cerfa(relationId),
            method: HttpMethod.PATCH,
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: formData,
            loader: Loader.REQUEST,
        });
    }

    async getClosureReasons(): Promise<AxiosResponse<IReferential[]>> {
        return HttpService.fetch({
            url: endpoints.farmService.referential.closureReasons(),
            method: HttpMethod.GET,
        });
    }

    async patchClosure(workshopId: string, data: IClosureRelation): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.farmService.workshops.closure(workshopId),
            method: HttpMethod.PATCH,
            data: data,
            loader: Loader.REQUEST,
        });
    }
}

export default new FarmService();
