// === Import: NPM
import React, { useMemo } from "react";
import {
    DataGridPro,
    GridSelectionModel,
    GridSortModel,
    GridOverlay,
    GridToolbarContainer,
    GridLinkOperator,
    GridFilterInputValueProps,
    useGridApiRef,
    getGridSingleSelectOperators,
    getGridDateOperators,
    getGridStringOperators,
    GridFilterItem,
    GridCellParams,
    GridToolbarExport,
    GridRenderCellParams,
    GridRowClassNameParams,
    GridValueGetterParams,
    GridToolbarColumnsButton,
    GridCallbackDetails,
    GridRowParams,
    MuiEvent,
    GridColumnVisibilityModel,
    GridFilterModel,
    GridRowModel,
} from "@mui/x-data-grid-pro";
import LinearProgress from "@mui/material/LinearProgress";
import { makeStyles } from "@mui/styles";

import { Box, Button, MenuItem, Select, Stack } from "@mui/material";
import { isDateInRange } from "../../../resources/utils";
import { GenericGridColumns } from "../../../interfaces/global";
import { colors } from "../../../resources/CssConstant";
import CustomPagination from "./components/CustomPagination";
import DateRangeFilterTable from "./components/DateRangeFilterTable";
import InputFilter from "./components/InputFilter";
import OverflowTooltip from "./components/OverflowTooltip";
import { defaultRowsPerPage } from "../../../resources/AppConstant";
import { SaveAlt } from "@mui/icons-material";
import { useProvideGlobal } from "../../../context/useGlobalContext";

interface GenericTableProps {
    rows: Array<object>;
    columns: GenericGridColumns[];
    sortModel?: GridSortModel;
    onSortModelChange?: (model: GridSortModel, details: GridCallbackDetails) => void;
    checkboxSelection?: boolean;
    selectionModel?: GridSelectionModel;
    onSelectionModelChange?: (selectionModel: GridSelectionModel, details: GridCallbackDetails) => void;
    hideFooter?: boolean;
    rowCount?: number;
    disableColumnSelector?: boolean;
    disableToolbarButton?: boolean;
    disableColumnResize?: boolean;
    page?: number;
    onPageChange?: (page: number, details: GridCallbackDetails) => void;
    sortingOrder?: Array<"asc" | "desc">;
    sortingMode?: "client" | "server";
    rowsPerPageOptions?: number[];
    pageSize?: number;
    onPageSizeChange?: (pageSize: number, details: GridCallbackDetails) => void;
    paginationMode?: "client" | "server";
    autoHeight?: boolean;
    onFilterModelChange?: (model: GridFilterModel, details: GridCallbackDetails) => void;
    filterModel?: GridFilterModel;
    filterMode?: "client" | "server";
    getRowId?: (row: GridRowModel) => string;
    noRowsMessage?: string;
    getRowClassName?: (params: GridRowClassNameParams) => string;
    onRowClick?: (params: GridRowParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => void;
    columnVisibilityModel?: GridColumnVisibilityModel;
    onColumnVisibilityModelChange?: (model: GridColumnVisibilityModel, details: GridCallbackDetails) => void;
}

interface GenericTableWithCsvExportProps extends GenericTableProps {
    disableCsvExportButton: boolean;
    csvExportButtonLabel: string;
    onCsvExportClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

interface GenericTableWithoutCsvExportProps extends GenericTableProps {
    disableCsvExportButton?: never;
    csvExportButtonLabel?: never;
    onCsvExportClick?: never;
}

function CustomLoadingOverlay() {
    return (
        <GridOverlay>
            <div style={{ position: "absolute", top: 0, width: "100%" }}>
                <LinearProgress />
            </div>
        </GridOverlay>
    );
}

function CustomToolbar(
    disableToolbarButton: boolean,
    disableCsvExportButton: boolean,
    csvExportButtonLabel: string,
    onCsvExportClick: (event: React.MouseEvent<HTMLButtonElement>) => void
) {
    return (
        <GridToolbarContainer style={{ justifyContent: "flex-end" }}>
            <GridToolbarColumnsButton
                placeholder={undefined}
                onResize={undefined}
                nonce={undefined}
                onResizeCapture={undefined}
            />
            {!(disableCsvExportButton ?? true) && (
                <Box mx={2}>
                    <Button startIcon={<SaveAlt />} size="small" onClick={onCsvExportClick}>
                        {csvExportButtonLabel}
                    </Button>
                </Box>
            )}
            {!disableToolbarButton && (
                <GridToolbarExport
                    csvOptions={{
                        fileName: "export",
                        delimiter: ";",
                        utf8WithBom: true,
                    }}
                />
            )}
        </GridToolbarContainer>
    );
}

// eslint-disable-next-line react/display-name
const renderSelectFilter = (col: GenericGridColumns) => (props: GridFilterInputValueProps) => {
    const { item, applyValue } = props;
    const optionValue = col.optionValue || "key";
    const handleFilterChange = (event) => {
        applyValue({ ...item, value: event.target.value });
    };
    return (
        <Select value={item.value || ""} variant="outlined" size="small" onChange={handleFilterChange}>
            {col.optionsValues?.map((option, i) => (
                <MenuItem key={i} value={option[optionValue]}>
                    {option.label}
                </MenuItem>
            ))}
        </Select>
    );
};

GenericTable.defaultProps = {
    sortingOrder: ["asc", "desc", null],
    sortingMode: "client",
    filterMode: "client",
    paginationMode: "client",
    pageSize: 50,
    disableColumnSelector: true,
    noRowsMessage: "Pas de résultat",
    rowsPerPageOptions: defaultRowsPerPage,
};

const useStyles = makeStyles({
    root: {
        "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
            outline: "none",
        },
        "border": "none",
    },
    columnHeader: {
        fontFamily: "Nunito-Bold",
        fontSize: 16,
    },
    columnHeaderTitle: {
        lineHeight: "100%",
        WebkitLineClamp: 2,
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        whiteSpace: "normal",
    },
    columnHeaders: {
        borderBottom: `solid 1px  ${colors.secondaryColor}`,
    },
    row: {
        fontSize: 16,
    },
});

export default function GenericTable(props: GenericTableWithCsvExportProps | GenericTableWithoutCsvExportProps) {
    const classes = useStyles();
    const apiRef = useGridApiRef();

    const { loadingTable } = useProvideGlobal();

    const cellValue = (col: GenericGridColumns, params: GridRenderCellParams): string => {
        let value = params.row[col.field];
        if (col.valueFormatter) {
            value = col.valueFormatter({ field: col.field, value: params.value, api: apiRef });
        } else if (col.valueGetter) {
            value = col.valueGetter(params as GridValueGetterParams);
        }
        return value;
    };

    const columns = useMemo(
        () =>
            props.columns.map((col) => {
                if (!col.renderCell) {
                    col.renderCell = (params) => {
                        const value = cellValue(col, params);
                        return <OverflowTooltip value={value} />;
                    };
                }
                switch (col.type) {
                    case "singleSelect":
                        return {
                            ...col,
                            filterOperators: getGridSingleSelectOperators().map((operator) => ({
                                ...operator,
                                InputComponent: renderSelectFilter(col),
                            })),
                        };
                    case "date":
                        return {
                            ...col,
                            filterOperators: getGridDateOperators()
                                .filter((operator) => operator.value === "onOrAfter")
                                .map((operator) => ({
                                    ...operator,
                                    getApplyFilterFn: (filterItem: GridFilterItem) => {
                                        if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
                                            return null;
                                        }
                                        return (params: GridCellParams): boolean => {
                                            return isDateInRange(params.value, filterItem.value);
                                        };
                                    },
                                    InputComponent: DateRangeFilterTable,
                                })),
                        };
                    default:
                        return {
                            ...col,
                            filterOperators: getGridStringOperators()
                                .filter((operator) => operator.value === "contains")
                                .map((operator) => ({
                                    ...operator,
                                    InputComponent: InputFilter,
                                })),
                        };
                }
            }),
        [props.columns]
    );

    return (
        <DataGridPro
            autoHeight={props.autoHeight}
            classes={classes}
            apiRef={apiRef}
            rows={props.rows}
            columns={columns}
            initialState={{ pinnedColumns: { right: ["actions"] } }}
            components={{
                LoadingOverlay: CustomLoadingOverlay,
                Toolbar: () =>
                    CustomToolbar(
                        props.disableToolbarButton,
                        props.disableCsvExportButton,
                        props.csvExportButtonLabel,
                        props.onCsvExportClick
                    ),
                Pagination: CustomPagination,
                NoRowsOverlay: () => (
                    <Stack height="100%" alignItems="center" justifyContent="center">
                        {props.noRowsMessage}
                    </Stack>
                ),
            }}
            componentsProps={{
                pagination: {
                    rowsPerPage: props.rowsPerPageOptions,
                },
                filterPanel: {
                    // Force usage of "Or" operator
                    linkOperators: [GridLinkOperator.Or],
                    // Display columns by ascending alphabetical order
                    filterFormProps: {
                        // Customize inputs by passing props
                        linkOperatorInputProps: {
                            variant: "outlined",
                            size: "small",
                            // sx: { display: "none" },
                        },
                        columnInputProps: {
                            variant: "outlined",
                            size: "small",
                            sx: { mt: "auto" },
                        },
                        operatorInputProps: {
                            variant: "outlined",
                            size: "small",
                            sx: { display: "none" },
                        },
                        deleteIconProps: {
                            sx: {
                                "& .MuiSvgIcon-root": { color: "#d32f2f" },
                            },
                        },
                    },
                    sx: {
                        // Customize inputs using css selectors
                        "& .MuiDataGrid-filterForm": { p: 2 },
                        "& .MuiDataGrid-filterFormLinkOperatorInput": { mr: 2 },
                        "& .MuiDataGrid-filterFormColumnInput": { mr: 2, width: 150 },
                        "& .MuiDataGrid-filterFormOperatorInput": { mr: 2 },
                        "& .MuiDataGrid-filterFormValueInput": { width: 300 },
                    },
                },
            }}
            getRowHeight={() => "auto"}
            sx={{
                "& .MuiDataGrid-cellContent": { minHeight: 52, display: "flex", alignItems: "center" },
            }}
            loading={loadingTable}
            hideFooter={props.hideFooter}
            rowCount={props.rowCount}
            pageSize={props.pageSize}
            rowsPerPageOptions={props.rowsPerPageOptions}
            page={props.page}
            sortingMode={props.sortingMode}
            paginationMode={props.paginationMode}
            onPageSizeChange={props.onPageSizeChange}
            onPageChange={props.onPageChange}
            sortModel={props.sortModel}
            pagination
            onSortModelChange={props.onSortModelChange}
            sortingOrder={props.sortingOrder}
            onFilterModelChange={props.onFilterModelChange}
            filterMode={props.filterMode}
            filterModel={props.filterModel}
            disableColumnMenu
            disableColumnFilter
            disableSelectionOnClick
            disableColumnReorder
            disableColumnResize={props.disableColumnResize}
            disableColumnSelector={props.disableColumnSelector}
            getRowId={props.getRowId}
            getRowClassName={props.getRowClassName}
            onRowClick={props.onRowClick}
            columnVisibilityModel={props.columnVisibilityModel}
            onColumnVisibilityModelChange={props.onColumnVisibilityModelChange}
        />
    );
}
