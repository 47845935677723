// === NPM
import React, { Dispatch, RefObject, SetStateAction, useEffect, useState } from "react";
import { Box, Card, Checkbox, FormControlLabel, FormGroup, Stack, Typography } from "@mui/material";
import { useOutletContext } from "react-router-dom";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
// === LOCAL
import { createPayload, getEnumKeyByValue } from "../../../../../../../../resources/utils";
import { UserType } from "../../../../../../../../interfaces/user";
import Veterinaries from "./containers/Veterinaries";
import FamDam from "./containers/FamDam";
import Pharmacist from "./containers/Pharmacist";
import TransferList from "../TransferList";
import { StyledCardContent } from "../../../../../../../styled/StyledCardContent";
import UserService from "../../../../../../../../services/UserService";
import { HttpStatus } from "../../../../../../../../interfaces/global";
import {
    INotificationCreateUpdate,
    ReceiverUser,
    NotificationContext,
    BroadcastType,
    AllowedTargetType,
    AllowedTargetSituation,
} from "../../../../interface";

interface RecipientsProps {
    onValid: () => void;
    form: INotificationCreateUpdate;
    setForm: Dispatch<SetStateAction<INotificationCreateUpdate>>;
    formRef: RefObject<HTMLButtonElement>;
    selectedReceiversType: string;
    receiversUsers: ReceiverUser[];
    setReceiversUsers: Dispatch<SetStateAction<ReceiverUser[]>>;
}

export default function Recipients({
    form,
    setForm,
    formRef,
    onValid,
    selectedReceiversType,
    receiversUsers,
    setReceiversUsers,
}: RecipientsProps) {
    const { allowedTargets } = useOutletContext<NotificationContext>();

    const isGenericBroadcast = form.broadcastType === getEnumKeyByValue(BroadcastType, BroadcastType.GENERIC);

    const formSchema = z.object({
        receiversSituationIds: isGenericBroadcast
            ? z.string().array().min(1, "Vous devez sélectionner au moins une situation")
            : z.string().array().max(0),
        receiversUserIds: isGenericBroadcast
            ? z.string().array().max(0)
            : z.string().array().min(1, "La liste doit contenir au moins un destinataire"),
    });

    type ValidationSchema = z.infer<typeof formSchema>;

    const {
        formState: { errors },
        handleSubmit,
        watch,
        setValue,
    } = useForm<ValidationSchema>({
        resolver: zodResolver(formSchema),
        defaultValues: { receiversSituationIds: form.receiversSituationIds, receiversUserIds: form.receiversUserIds },
    });

    const watchReceiversSituationIds = watch("receiversSituationIds");

    const [loading, setLoading] = useState<boolean>(true);
    const [users, setUsers] = useState<ReceiverUser[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<ReceiverUser[]>(
        receiversUsers.filter((user) => user.type === selectedReceiversType) ?? []
    );

    const situations = allowedTargets.find((target) => target.type === selectedReceiversType)?.situations;

    useEffect(() => {
        handleChangeUsers();
    }, [selectedUsers]);

    const handleValidateForm = (data: ValidationSchema) => {
        setForm((prev) => ({
            ...prev,
            ...data,
        }));
        onValid();
    };

    const getUsers = async (userType: string, filters) => {
        setLoading(true);
        const payload = {
            page: 0,
            size: 100,
            situation: allowedTargets.find((item) => item.type === userType)?.situations,
            ...createPayload(filters),
        };
        const res = await UserService.getAllNotificationUsers(payload);
        if (res.status === HttpStatus.OK) {
            setUsers(res.data);
        }
        setLoading(false);
    };

    const handleChangeUsers = () => {
        setValue(
            "receiversUserIds",
            selectedUsers?.map((item) => item.id)
        );
        setReceiversUsers(selectedUsers.map((user) => ({ ...user, type: selectedReceiversType })));
    };

    const handleChangeSituations = (key: string) => {
        if (watchReceiversSituationIds.includes(key)) {
            setValue(
                "receiversSituationIds",
                watchReceiversSituationIds.filter((item) => item !== key)
            );
        } else {
            setValue("receiversSituationIds", [...watchReceiversSituationIds, key]);
        }
    };

    const renderUsers = () => {
        switch (selectedReceiversType) {
            case UserType.VETERINARY:
                return <Veterinaries getUsers={getUsers} />;
            case UserType.FAM_DAM:
                return <FamDam getUsers={getUsers} />;
            case UserType.PHARMACIST:
                return <Pharmacist getUsers={getUsers} />;
        }
    };

    return (
        <form onSubmit={handleSubmit(handleValidateForm)} noValidate>
            <Stack spacing={2}>
                <Typography sx={{ width: { xs: "100%", md: "50%" } }} variant="h5">
                    Destinataires
                </Typography>
                <Card>
                    <StyledCardContent>
                        <Stack spacing={4} sx={{ p: 2 }}>
                            <Typography variant="h5">Utilisateurs</Typography>
                            {situations?.length > 1 &&
                                form.broadcastType === getEnumKeyByValue(BroadcastType, BroadcastType.GENERIC) && (
                                    <Box>
                                        <Typography>
                                            Situations associées au type{" "}
                                            {
                                                AllowedTargetType[
                                                    allowedTargets.find(
                                                        (target) => target.type === selectedReceiversType
                                                    )?.type
                                                ]
                                            }
                                            :
                                        </Typography>
                                        <FormGroup>
                                            {situations?.map((item) => (
                                                <FormControlLabel
                                                    key={item}
                                                    control={
                                                        <Checkbox
                                                            defaultChecked={watchReceiversSituationIds.includes(item)}
                                                            onChange={() => handleChangeSituations(item)}
                                                        />
                                                    }
                                                    label={AllowedTargetSituation[item]}
                                                />
                                            ))}
                                        </FormGroup>
                                    </Box>
                                )}
                            {situations?.length === 1 &&
                                form.broadcastType === getEnumKeyByValue(BroadcastType, BroadcastType.GENERIC) && (
                                    <>
                                        <Typography variant="body1">
                                            Vous avez choisi le type d'utilisateur suivant :{" "}
                                            {
                                                AllowedTargetType[
                                                    allowedTargets.find(
                                                        (target) => target.type === selectedReceiversType
                                                    )?.type
                                                ]
                                            }
                                            .
                                        </Typography>
                                        <Typography variant="body1">Vous pouvez passer cette étape.</Typography>
                                    </>
                                )}
                            {errors.receiversSituationIds && (
                                <Typography color="red">{errors.receiversSituationIds?.message}</Typography>
                            )}
                            {form.broadcastType === getEnumKeyByValue(BroadcastType, BroadcastType.TARGETED) && (
                                <>
                                    {renderUsers()}
                                    <TransferList
                                        users={users}
                                        selectedUsers={selectedUsers}
                                        setSelectedUsers={setSelectedUsers}
                                        errors={errors.receiversUserIds?.message}
                                        loading={loading}
                                    />
                                </>
                            )}
                        </Stack>
                    </StyledCardContent>
                </Card>
            </Stack>
            <button style={{ display: "none" }} type="submit" ref={formRef} />
        </form>
    );
}
