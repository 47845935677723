// === Import: NPM
import React from "react";
import { Grid, Stack, Typography } from "@mui/material";

// === Import: LOCAL
import DpeCard from "./DpeCard";
import InfoIcon from "../../../../generics/InfoIcon";
import { IOVDpe } from "../../../interface";

interface DpeProps {
    dpes: IOVDpe[];
}
export default function Dpe({ dpes }: DpeProps) {
    return (
        <>
            <Stack direction="row" spacing={2} sx={{ mb: 3 }} alignItems="center">
                <Typography variant="h5">Domiciles professionnels d'exercice</Typography>
                <InfoIcon tooltip="Chaque Domicile Professionnel d’Exercice (DPE) possède son numéro ordinal. Il est composé de : Numéro ordinal société-nombre ou Numéro ordinal vétérinaire-nombre. Sur les fiches, ce numéro précède le nom du DPE" />
            </Stack>
            <Grid container spacing={2}>
                {dpes.map((dpe, i) => (
                    <Grid item xs={12} key={i} sm={6} md={4}>
                        <DpeCard dpe={dpe} />
                    </Grid>
                ))}
            </Grid>
        </>
    );
}
