// === NPM
import { Stack } from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
// === LOCAL
import PresetFilter from "./components/PresetFilter";

export type PresetFilterConfiguration<T extends {}> = {
    icon: JSX.Element;
    title: string;
    value: string;
    config: T;
};

export type PresetFilterConfigurations<T extends {}, U extends {}> = Record<keyof T, PresetFilterConfiguration<U>>;

type GenericPresetFiltersProps<T extends {}, U extends {}> = {
    superFilterConfiguration: PresetFilterConfigurations<T, U>;
    inputFilters: U;
    setInputFilters: Dispatch<SetStateAction<U>>;
    clearFilters: () => void;
};

export const GenericPresetFilters = <T extends {}, U extends {}>({
    superFilterConfiguration,
    inputFilters,
    setInputFilters,
    clearFilters,
}: GenericPresetFiltersProps<T, U>) => {
    const [selectedFilter, setSelectedFilter] = useState<string>(null);

    useEffect(() => {
        const selected = Object.entries(superFilterConfiguration).find(
            (item) => JSON.stringify((item[1] as PresetFilterConfiguration<U>).config) === JSON.stringify(inputFilters)
        );
        if (selected) setSelectedFilter(selected[0]);
        else setSelectedFilter(null);
    }, [inputFilters]);

    const handleClick = (name: string, config: U) => {
        clearFilters();
        if (selectedFilter !== name) {
            setInputFilters(config);
        }
    };

    const renderFilters = () => {
        return Object.entries(superFilterConfiguration).map(
            ([name, config]: [string, PresetFilterConfiguration<U>]) => (
                <PresetFilter
                    key={name}
                    icon={config.icon}
                    title={config.title}
                    value={config.value}
                    onClick={() => handleClick(name, config.config)}
                    selected={selectedFilter === name}
                />
            )
        );
    };

    return (
        <Stack direction="row" spacing={2}>
            {renderFilters()}
        </Stack>
    );
};
