// === NPM
import React, { useEffect, useState } from "react";
import { Stack, Tabs } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
// === LOCAL
import PermissionsTab from "../../../../resources/PermissionsTab";
import PageContent from "../../../generics/layout/PageContent";
import { routerLinks } from "../../../../routers/RouterConstant";
import { SelectedNotificationProvider } from "./containers/useSelectedNotification";
import { HttpStatus } from "../../../../interfaces/global";
import { AllowedTargetType, IAllowedTarget } from "./interface";
import { UserSituation } from "../../../../resources/PermissionConstant";
import NotificationService from "../../../../services/NotificationService";
import { getEnumKeyByValue } from "../../../../resources/utils";
import { useAuth } from "../../../../routers/useAuth";

export default function Notifications() {
    const location = useLocation();
    const navigate = useNavigate();
    const auth = useAuth();
    const isAnmvNotif = auth.userInfo.situation === UserSituation.ANMV_NOTIF;
    const specificTypes = isAnmvNotif
        ? [
              {
                  type: getEnumKeyByValue(AllowedTargetType, AllowedTargetType.ALL),
                  situations: [getEnumKeyByValue(AllowedTargetType, AllowedTargetType.ALL)],
              },
          ]
        : [
              {
                  type: getEnumKeyByValue(AllowedTargetType, AllowedTargetType.ALL),
                  situations: [getEnumKeyByValue(AllowedTargetType, AllowedTargetType.ALL)],
              },
              {
                  type: getEnumKeyByValue(AllowedTargetType, AllowedTargetType.TRAINING_ORGANIZATION),
                  situations: [getEnumKeyByValue(AllowedTargetType, AllowedTargetType.TRAINING_ORGANIZATION)],
              },
              {
                  type: getEnumKeyByValue(AllowedTargetType, AllowedTargetType.VIMS),
                  situations: [getEnumKeyByValue(AllowedTargetType, AllowedTargetType.VIMS)],
              },
          ];

    const [allowedTargets, setAllowedTargets] = useState<IAllowedTarget[]>([]);

    useEffect(() => {
        getUserTypes();
    }, []);

    const getUserTypes = async () => {
        const res = await NotificationService.getAllowedTargets();
        if (res.status === HttpStatus.OK) {
            const allUsers = [...res.data.filter((target) => target.situations.length > 0), ...specificTypes];
            setAllowedTargets(allUsers);
        }
    };

    return (
        <Stack height="100%" width="100%" px={2}>
            <Tabs value={location.pathname} sx={{ ml: 12 }}>
                <PermissionsTab
                    value={routerLinks.admin.notifications.gestion()}
                    onClick={() => navigate(routerLinks.admin.notifications.gestion())}
                    label="Gestion des notifications manuelles"
                />
                <PermissionsTab
                    value={routerLinks.admin.notifications.form()}
                    onClick={() => navigate(routerLinks.admin.notifications.form())}
                    label="Saisie d'une notification"
                />
                <PermissionsTab
                    value={routerLinks.admin.notifications.autoNotifConfig()}
                    onClick={() => navigate(routerLinks.admin.notifications.autoNotifConfig())}
                    label="Gestion des notifications automatiques"
                    permissions={[UserSituation.ADMIN_CALYPSO]}
                />
                <PermissionsTab
                    value={routerLinks.admin.notifications.automatic()}
                    onClick={() => navigate(routerLinks.admin.notifications.automatic())}
                    label="Suivi des notifications automatiques"
                    permissions={[UserSituation.ADMIN_CALYPSO]}
                />
            </Tabs>
            <PageContent>
                <SelectedNotificationProvider>
                    <Outlet context={{ allowedTargets }} />
                </SelectedNotificationProvider>
            </PageContent>
        </Stack>
    );
}
