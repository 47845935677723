// === NPM
import React from "react";
import { Grid, DialogTitle, DialogContent, DialogActions, Dialog, Typography } from "@mui/material";
// === LOCAL
import SecondaryButton from "../../styled/SecondaryButton";
import GenericButton from "../buttons/GenericButton";
import { useProvideGlobal } from "../../../context/useGlobalContext";

interface GenericConfirmDialogProps {
    onClose: (value: boolean) => void;
    title: string;
    message: string;
    loading?: boolean;
}

export default function GenericConfirmDialog({ onClose, title, message, loading }: GenericConfirmDialogProps) {
    const { loadingRequest } = useProvideGlobal();
    const handleCancel = () => {
        onClose(false);
    };

    const handleOk = () => {
        onClose(true);
    };

    return (
        <Dialog maxWidth="md" open>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent dividers>
                <Typography>{message}</Typography>
            </DialogContent>
            <DialogActions>
                <Grid container justifyContent="center" spacing={2}>
                    <Grid item>
                        <SecondaryButton variant="outlined" onClick={handleCancel} disabled={loadingRequest || loading}>
                            Non
                        </SecondaryButton>
                    </Grid>
                    <Grid item>
                        <GenericButton onClick={handleOk} label="Oui" loading={loadingRequest || loading} />
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}
