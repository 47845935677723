import { Dispatch, SetStateAction } from "react";
import { ArrayOfTwo } from "../../../interfaces/global";
import { ISpeciesDetails, IReferential } from "../../../interfaces/referential";
import { IVaccine, InterventionCorrectionType } from "../../../interfaces/vaccination";
import { AxiosResponse } from "axios";

export enum VaccinationInterventionType {
    BREEDING = "Élevage",
    HATCHERY = "Couvoir",
}

export enum InjectionType {
    FIRST_DOSE = "1ère injection",
    SECOND_DOSE = "2ème injection",
    REMINDER = "Rappel",
}

export interface InterventionOutletContext extends VaccinationInterventionOutletContext {
    species: ISpeciesDetails[];
    subSpecies: ISpeciesDetails[];
    horsePower: IReferential[];
    sectors: IReferential[];
    tiers: IReferential[];
}

export interface IIntervention {
    id: string;
    farmId: string;
    workshopId: string;
    vaccinationDate: string;
    vaccineGtinCode: string;
    injectionType: InjectionType;
    animalCount: number;
    doseCount: number;
    interventionType: VaccinationInterventionType;
    certificateFileUuid: string;
    hasAudit: boolean;
    dpeId: string;
    dpeName: string;
    veterinaryId: string;
    veterinaryFirstname: string;
    veterinaryLastname: string;
    workshopCity: string;
    workshopDepartmentCode: string;
    workshopDepartmentName: string;
}

export interface IVaccinationInterventionFilters {
    id: string;
    farmId: string;
    workshopId: string;
    vaccinationDate: ArrayOfTwo;
    vaccineGtinCode: string[];
    injectionType: InjectionType[];
    vaccinationInterventionType: VaccinationInterventionType[];
    isCertified: string;
    hasAudit: string;
    dpe: string;
    veterinary: string;
    workshopCity: string;
    workshopDepartmentCode: string[];
}

export interface IVaccinationInterventionForm {
    type: keyof typeof VaccinationInterventionType | "";
    generalInformation: IVaccinationInterventionGeneralInformation;
    animalInformation: IVaccinationInterventionAnimalInformation;
    supervisionInformation: IVaccinationInterventionSupervisionInformation;
    certificateGeneration: boolean;
    hasAudit: boolean;
}

export interface IVaccinationInterventionDetails {
    type: keyof typeof VaccinationInterventionType;
    generalInformation: IInterventionGeneralInformationDetails;
    animalInformation: IVaccinationInterventionAnimalInformation;
    supervisionInformation: IVaccinationInterventionSupervisionInformation;
    hasAudit: boolean;
    certificateFileUuid: string;
}

interface IInterventionGeneralInformationDetails extends IVaccinationInterventionGeneralInformation {
    id: string;
    veterinaryFirstname: string;
    veterinaryLastname: string;
    dpeName: string;
    farmId: string;
    farmName: string;
}

export interface IVaccinationInterventionGeneralInformation {
    vaccinationDate: string;
    dpeId: string;
    veterinaryId: string;
    workshopId: string;
    id: string;
}

export interface IVaccinationInterventionSupervisionInformation {
    complianceType: keyof typeof ComplianceType;
    userType: keyof typeof InterventionUserType;
    complianceComment: string;
}

export interface IAudit {
    complianceType: keyof typeof ComplianceType;
    date: string;
    comment: string;
    distance: number;
    plateNumber: string;
    horsePower: string;
}

export interface IVaccinationInterventionAnimalInformation {
    gtinCode: string;
    batchUuids: string[];
    animalCount: number;
    doseCount: number;
    prescribedDoseCount: number;
    comment: string;
    speciesUuid: string;
    subSpeciesUuid: string;
    injectionType: keyof typeof InjectionType;
    sector: string;
    tier: string;
    animalBatches: IAnimalBatch[];
}

export interface IAnimalBatch {
    uuid?: string;
    imepNumber: string;
    previousInterventionId: string;
    declaredWorkshopId: string;
    hatchingDate: string;
    installationDate: string;
}

export enum ComplianceType {
    COMPLIANT = "Conforme",
    NOT_COMPLIANT_MINOR = "Non conforme mineur",
    NOT_COMPLIANT_MAJOR = "Non conforme majeur",
}

export enum InterventionUserType {
    VETERINARY = "Vétérinaire",
    VACCINATION_TEAM = "Équipe de vaccination",
    BREEDER = "Éleveur",
}

export interface CorrectionForm {
    motif: string;
}

export interface VaccinationInterventionOutletContext {
    vaccines: IVaccine[];
    scrappingReasons: IReferential[];
    loading: boolean;
    correctIntervention: (
        intervention: IVaccinationInterventionForm,
        reason: string,
        type: InterventionCorrectionType
    ) => Promise<AxiosResponse<IVaccinationInterventionForm>>;
    correctionReason: string;
    setCorrectionReason: Dispatch<SetStateAction<string>>;
}

export interface IVaccinationInterventionCorrection {
    type: InterventionCorrectionType;
    reason: string;
    body: IVaccinationInterventionForm;
}
