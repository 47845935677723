// === NPM
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import { Box, Card, FormControl, Stack, TextField, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { z } from "zod";
// === LOCAL
import { useProvideGlobal } from "../../../../context/useGlobalContext";
import useTimeout from "../../../../hooks/useTimeout";
import { HttpStatus } from "../../../../interfaces/global";
import { IScrappingShort, VaccinationOutletContext, VaccineBatch } from "../../../../interfaces/vaccination";
import { defaultIntegerLimit, maxIntegerValue } from "../../../../resources/AppConstant";
import { FORM_TEXT } from "../../../../resources/FormUtils";
import { Preference } from "../../../../resources/PermissionConstant";
import { convertToSnakeCase } from "../../../../resources/utils";
import { routerLinks } from "../../../../routers/RouterConstant";
import { useAuth } from "../../../../routers/useAuth";
import VaccinationService from "../../../../services/VaccinationService";
import GenericConfirmDialog from "../../../generics/dialogs/GenericConfirmDialog";
import GenericAutocomplete from "../../../generics/inputs/GenericAutocomplete";
import GenericDatePicker from "../../../generics/inputs/GenericDatePicker";
import GenericSelect from "../../../generics/inputs/GenericSelect";
import SecondaryButton from "../../../styled/SecondaryButton";
import { StyledCardContent } from "../../../styled/StyledCardContent";

const scrappingSchema = z.object({
    dpeId: z
        .string({
            required_error: FORM_TEXT.required,
            invalid_type_error: FORM_TEXT.required,
        })
        .trim()
        .min(1, { message: FORM_TEXT.required }),
    vaccineGtinCode: z
        .string({
            required_error: FORM_TEXT.required,
            invalid_type_error: FORM_TEXT.required,
        })
        .trim()
        .min(1, { message: FORM_TEXT.required }),
    batchUuid: z
        .string({
            required_error: FORM_TEXT.required,
            invalid_type_error: FORM_TEXT.required,
        })
        .trim()
        .min(1, { message: FORM_TEXT.required }),
    quantity: z.coerce
        .number({
            required_error: FORM_TEXT.required,
            invalid_type_error: "Veuillez saisir un nombre positif",
        })
        .max(maxIntegerValue, { message: "Vous avez saisi une valeur trop élevée" })
        .positive({ message: "Veuillez saisir un nombre positif" }),
    date: z
        .string({
            required_error: FORM_TEXT.required,
            invalid_type_error: FORM_TEXT.required,
        })
        .trim()
        .min(1, { message: FORM_TEXT.required })
        .refine((value) => (value ? DateTime.fromISO(value) <= DateTime.now().startOf("day") : false), {
            message: "La date doit être inférieure ou égale à la date du jour",
        }),
    reason: z
        .string({
            required_error: FORM_TEXT.required,
            invalid_type_error: FORM_TEXT.required,
        })
        .trim()
        .min(1, { message: FORM_TEXT.required })
        .nullable(),
});

type ScrappingSchema = z.infer<typeof scrappingSchema>;

export default function Scrapping() {
    const auth = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    const { loadingRequest } = useProvideGlobal();
    const { vaccines, scrappingReasons } = useOutletContext<VaccinationOutletContext>();
    const initialValues = location.state?.scrapping;
    const {
        formState: { errors },
        control,
        handleSubmit,
        setValue,
        watch,
        register,
    } = useForm<ScrappingSchema>({
        resolver: zodResolver(scrappingSchema),
        defaultValues: {
            dpeId: initialValues?.dpeId ?? "",
            vaccineGtinCode: initialValues?.vaccineGtinCode ?? "",
            batchUuid: initialValues?.batchUuid ?? "",
            quantity: initialValues?.quantity ?? undefined,
            date: initialValues?.date ?? DateTime.now().toISODate(),
            reason: initialValues?.reason ?? "",
        },
    });

    const watchGtinCode = watch("vaccineGtinCode");
    const dpeId = watch("dpeId");

    const [vaccineBatches, setVaccineBatches] = useState<VaccineBatch[]>([]);
    const [vaccineBatchSearch, setVaccineBatchSearch] = useState<string>("");
    const [selectedBatch, setSelectedBatch] = useState<VaccineBatch>(location.state?.batch ?? null);
    const [openBackModal, setOpenBackModal] = useState<boolean>(false);
    const dpes = auth.userInfo.properties?.dpes;
    const hasBlueTonguePref = auth.userInfo.preferences?.blueTongueCattle || auth.userInfo.preferences?.blueTongueSheep;

    const filterDpes = () => {
        if (!hasBlueTonguePref) {
            return dpes.filter((dpe) => dpe.sanitaryDpe);
        }
        return dpes;
    };

    const filterVaccines = () => {
        let result = [...vaccines];

        const prefs = Object.values(Preference).reduce((acc, pref) => {
            if (auth.userInfo.preferences[pref]) {
                // convert it to snake case to match the useKey format
                acc.push(convertToSnakeCase(pref));
            }
            return acc;
        }, []);

        result = result.filter((v) => prefs.includes(v.useKey));

        const dpe = dpes.find((d) => d.id === dpeId);
        if (!dpe?.sanitaryDpe) {
            return result.filter((v) => v.useKey !== "iahp");
        }

        return result;
    };

    useTimeout(() => {
        if (watchGtinCode !== "") {
            getBatches(watchGtinCode, vaccineBatchSearch);
        }
    }, [watchGtinCode, vaccineBatchSearch]);

    const getBatches = async (gtinCode: string, number: string) => {
        const res = await VaccinationService.getVaccineBatches(gtinCode, { page: 0, size: 50, number: number });
        if (res.status === HttpStatus.OK) {
            setVaccineBatches(res.data);
        }
    };

    const updateOrCreateScrapping = (data: ScrappingSchema) => {
        const scrappingData: IScrappingShort = {
            dpeId: data.dpeId,
            vaccineGtinCode: data.vaccineGtinCode,
            batchUuid: data?.batchUuid,
            quantity: data?.quantity.toString(),
            date: data?.date,
            reason: data?.reason,
        };
        if (initialValues) {
            updateScrapping(scrappingData);
        } else {
            createScrapping(scrappingData);
        }
    };

    const createScrapping = async (data: IScrappingShort) => {
        const res = await VaccinationService.postScrapping(data);
        if (res.status === HttpStatus.CREATED) {
            navigate(routerLinks.vaccinationCampaign.vaccine.scrappingView());
            toast.success("Mise au rebut enregistrée avec succès");
        }
    };

    const updateScrapping = async (data: IScrappingShort) => {
        const res = await VaccinationService.putScrapping(location.state.id, data);
        if (res.status === HttpStatus.OK) {
            navigate(routerLinks.vaccinationCampaign.vaccine.scrappingView());
            toast.success("Mise au rebut mise à jour avec succès");
        }
    };

    const handleClose = (confirm: boolean) => {
        if (confirm) {
            navigate(routerLinks.vaccinationCampaign.vaccine.scrappingView());
        }

        setOpenBackModal(false);
    };

    const handleChangeBatch = (event) => {
        setValue("batchUuid", event.target.value?.uuid ?? "");
        setSelectedBatch(event.target.value);
    };

    return (
        <>
            <Box
                component="form"
                width="100%"
                height="100%"
                noValidate
                onSubmit={handleSubmit(updateOrCreateScrapping)}
            >
                <Stack width="100%" spacing={4}>
                    <Typography variant="h4">
                        {location.state
                            ? `Modification de la mise au rebut n°${location.state.id}`
                            : "Nouvelle mise au rebut"}
                    </Typography>
                    <Card>
                        <StyledCardContent>
                            <Stack spacing={2} p={2}>
                                <Typography variant="bold">Informations du DPE</Typography>

                                <FormControl fullWidth error={!!errors.dpeId} required>
                                    <Controller
                                        name="dpeId"
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <GenericSelect
                                                value={value}
                                                label="N° ordre - Nom du DPE"
                                                onChange={onChange}
                                                required
                                                options={filterDpes().map((dpe) => ({
                                                    id: dpe.id,
                                                    name: `${dpe.id} - ${dpe.name}`,
                                                }))}
                                                optionsValue="id"
                                                optionsLabel="name"
                                                error={!!error}
                                                helperText={error?.message}
                                            />
                                        )}
                                    />
                                </FormControl>

                                <Typography variant="bold">Informations du vaccin</Typography>
                                <Stack spacing={2} direction="row">
                                    <FormControl fullWidth error={!!errors.vaccineGtinCode} required>
                                        <Controller
                                            name="vaccineGtinCode"
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <GenericSelect
                                                    value={value}
                                                    label="Vaccin"
                                                    onChange={(event) => {
                                                        onChange(event);
                                                        setSelectedBatch(null);
                                                        setValue("batchUuid", "");
                                                    }}
                                                    required
                                                    options={filterVaccines().map((v) => ({
                                                        id: v.gtinCode,
                                                        name: `${v.name} ${v.packaging}`,
                                                    }))}
                                                    optionsValue="id"
                                                    optionsLabel="name"
                                                    error={!!error}
                                                    helperText={error?.message}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                    <GenericAutocomplete
                                        options={vaccineBatches}
                                        value={selectedBatch}
                                        onChange={handleChangeBatch}
                                        inputValue={vaccineBatchSearch}
                                        onInputChange={(event, newInputValue) => {
                                            setVaccineBatchSearch(newInputValue);
                                        }}
                                        labelParameter="number"
                                        optionValue="uuid"
                                        noOptionsText={
                                            vaccineBatchSearch
                                                ? "Aucun résultat"
                                                : "Saisissez des caractères pour lancer la recherche"
                                        }
                                        disabled={!watchGtinCode}
                                        label="Numéro de lot du vaccin"
                                        error={!!errors.batchUuid}
                                        helperText={errors.batchUuid?.message}
                                        required
                                    />
                                </Stack>
                                <Typography variant="bold">Informations de mise au rebut</Typography>
                                <Stack direction="row" spacing={2}>
                                    <TextField
                                        fullWidth
                                        {...register("quantity")}
                                        required
                                        label="Nombre de flacons mis au rebut"
                                        error={!!errors?.quantity}
                                        helperText={errors?.quantity?.message}
                                        inputProps={{
                                            maxLength: defaultIntegerLimit,
                                        }}
                                    />
                                    <Controller
                                        name="date"
                                        control={control}
                                        render={({ field: { value, onChange }, fieldState: { error } }) => (
                                            <GenericDatePicker
                                                value={value}
                                                onChange={onChange}
                                                label="Date de mise au rebut"
                                                required
                                                name="date"
                                                maxDate={DateTime.now()}
                                                error={!!error}
                                                helperText={error?.message}
                                            />
                                        )}
                                    />
                                    <FormControl fullWidth error={!!errors.reason} required>
                                        <Controller
                                            name="reason"
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <GenericSelect
                                                    value={value}
                                                    label="Motif de mise au rebut"
                                                    optionsValue="key"
                                                    optionsLabel="label"
                                                    error={!!error}
                                                    helperText={error?.message}
                                                    onChange={onChange}
                                                    options={scrappingReasons}
                                                    required
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Stack>
                            </Stack>
                        </StyledCardContent>
                    </Card>
                    <Box display="flex" justifyContent="flex-end" gap={2} width="100%">
                        <SecondaryButton
                            variant="outlined"
                            onClick={() => setOpenBackModal(true)}
                            disabled={loadingRequest}
                        >
                            Annuler la saisie
                        </SecondaryButton>
                        <LoadingButton type="submit" variant="contained" loading={loadingRequest}>
                            Valider
                        </LoadingButton>
                    </Box>
                </Stack>
            </Box>

            {openBackModal && (
                <GenericConfirmDialog
                    title="Annuler la saisie"
                    onClose={handleClose}
                    message="Êtes-vous sûr de vouloir annuler ? Vous allez perdre toutes les informations saisies."
                />
            )}
        </>
    );
}
