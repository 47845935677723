// === NPM
import { AxiosResponse } from "axios";
// === LOCAL
import HttpService from "./HttpService";
import { endpoints } from "./EndpointConstant";
import { HttpMethod, Loader } from "../interfaces/global";
import { IDownload } from "../components/Downloads/interface";

class DownloadService {
    async getDownloads(): Promise<AxiosResponse<IDownload[]>> {
        return HttpService.fetch({
            url: endpoints.downloadService.downloads(),
            method: HttpMethod.GET,
        });
    }

    async getDownload(uuid: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.downloadService.download(uuid),
            method: HttpMethod.GET,
            responseType: "blob",
            loader: Loader.BUTTON,
        });
    }
}

export default new DownloadService();
