// === NPM
import React, { useEffect, useState } from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Stack, Collapse, Typography, Alert, AlertTitle } from "@mui/material";

// === LOCAL
import { IAlertBanner } from "../interface";
import { ReactComponent as Danger } from "../../../assets/icons/shared/danger-triangle.svg";
import IconActionButton from "../../generics/actions/IconActionButton";
import { useNotificationInfo } from "./useNotificationInfo";

export default function AlertBanners() {
    const { alertBanners, getNotificationInfo } = useNotificationInfo();
    const [copyAlertBanners, setCopyAlertBanners] = useState<IAlertBanner[]>(alertBanners);

    useEffect(() => {
        getNotificationInfo();
    }, []);

    useEffect(() => {
        setCopyAlertBanners(alertBanners);
    }, [alertBanners]);

    const renderBanner = (alertBanner: IAlertBanner) => {
        const alertBannerIndex = copyAlertBanners.findIndex(
            (comparedAlertBanner) => comparedAlertBanner === alertBanner
        );
        return (
            <Collapse key={alertBanner.uuid} in={alertBannerIndex >= 0}>
                <Alert
                    iconMapping={{
                        error: <Danger />,
                    }}
                    variant="filled"
                    severity="error"
                    action={
                        <IconActionButton
                            color="inherit"
                            title="Fermer l'alerte"
                            onClick={() =>
                                setCopyAlertBanners(
                                    copyAlertBanners.filter((banner) => banner.uuid !== alertBanner.uuid)
                                )
                            }
                            icon={<CloseRoundedIcon />}
                        />
                    }
                    sx={{ borderRadius: 3, boxShadow: "3px 3px 3px grey", mx: 0.5 }}
                >
                    <AlertTitle>
                        <Typography variant="bold">{alertBanner.subject}</Typography>
                    </AlertTitle>
                    {alertBanner.content}
                </Alert>
            </Collapse>
        );
    };

    return <Stack spacing={1}>{alertBanners.map((alertBanner) => renderBanner(alertBanner))}</Stack>;
}
