// === NPM
import React, { useState } from "react";
import { Typography, Stack, Card, FormControlLabel, Checkbox } from "@mui/material";
import { useOutletContext } from "react-router-dom";
// === LOCAL
import GenericDialog from "../../../../generics/dialogs/GenericDialog";
import { useFormIntervention } from "./useFormIntervention";
import { getEnumKeyByValue } from "../../../../../resources/utils";
import { ReactComponent as Warning } from "../../../../../assets/icons/shared/danger-triangle.svg";
import { useAuth } from "../../../../../routers/useAuth";
import { IFarm } from "../../../../../interfaces/farm";
import { StyledCardContent } from "../../../../styled/StyledCardContent";
import { statusColor } from "../../../../../resources/CssConstant";
import SecondaryButton from "../../../../styled/SecondaryButton";
import GenericButton from "../../../../generics/buttons/GenericButton";
import { UserWithoutProperties } from "../../../../../interfaces/user";
import SupervisionAccordion from "../../containers/SupervisionAccordion";
import AnimalAccordion from "../../containers/AnimalAccordion";
import InterventionAccordion from "../../containers/InterventionAccordion";
import IconBannerText from "../../../../generics/IconBannerText";
import { VaccinationInterventionType, VaccinationInterventionOutletContext } from "../../interface";
import { InterventionCorrectionType } from "../../../../../interfaces/vaccination";
import { useProvideGlobal } from "../../../../../context/useGlobalContext";

interface RecapDialogProps {
    onClose: () => void;
    farm: IFarm;
    vaccineBatches: { uuid: string; number: string }[];
    hasAudit: boolean;
    onValid: (certificateGeneration: boolean) => void;
    veterinaries: UserWithoutProperties[];
}

export default function RecapDialog({
    onClose,
    farm,
    vaccineBatches,
    hasAudit,
    veterinaries,
    onValid,
}: RecapDialogProps) {
    const { loadingRequest } = useProvideGlobal();
    const { correctionReason, correctIntervention } = useOutletContext<VaccinationInterventionOutletContext>();
    const { form } = useFormIntervention();
    const { userInfo } = useAuth();
    const [generateCertificat, setGenerateCertificat] = useState<boolean>(false);
    const selectedVaccineBatches = vaccineBatches.filter((vb) => form.animalInformation.batchUuids.includes(vb.uuid));
    const selectedVeterinaries = veterinaries.find((v) => v.id === form.generalInformation.veterinaryId);

    const renderContent = () => (
        <Stack spacing={2} width="100%">
            <Typography variant="h5" sx={{ mb: 2 }}>
                Intervention de vaccination{" "}
                {form.type === getEnumKeyByValue(VaccinationInterventionType, VaccinationInterventionType.HATCHERY)
                    ? "au couvoir"
                    : "en élevage"}
            </Typography>
            <InterventionAccordion
                generalInformation={form.generalInformation}
                farmName={farm.name}
                farmId={farm.id}
                dpeName={userInfo.properties.dpes.find((dpe) => dpe.id === form.generalInformation.dpeId).name}
                veterinaryFirstname={selectedVeterinaries.firstname}
                veterinaryLastname={selectedVeterinaries.lastname}
            />
            <AnimalAccordion
                animalInformation={form.animalInformation}
                selectedVaccineBatches={selectedVaccineBatches}
                vaccinationDate={form.generalInformation.vaccinationDate}
            />
            <SupervisionAccordion supervisionInformation={form.supervisionInformation} hasAudit={hasAudit} />
            {!form.certificateGeneration && (
                <>
                    <Typography variant="h5">Certificat de vaccination</Typography>
                    {renderCertificat()}
                </>
            )}
        </Stack>
    );

    const renderCertificat = () => (
        <Card sx={{ p: 2 }}>
            <StyledCardContent>
                <Stack spacing={2}>
                    <IconBannerText
                        message={`Cocher la case suivante vous permet de générer un certificat de vaccination. Une fois le certificat généré, vous ne pourrez plus modifier les données de cette intervention, excepté les informations portant sur l'audit.`}
                        color={statusColor.warningText}
                        backgroundColor={statusColor.warningBackground}
                        icon={<Warning />}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={generateCertificat}
                                onChange={(e) => setGenerateCertificat(e.target.checked)}
                            />
                        }
                        label="Je souhaite générer un certificat de vaccination"
                    />
                </Stack>
            </StyledCardContent>
        </Card>
    );

    const displayActionButton = () => (
        <>
            <SecondaryButton onClick={onClose} variant="outlined" disabled={loadingRequest}>
                Annuler
            </SecondaryButton>
            {correctionReason ? (
                <GenericButton
                    onClick={() => correctIntervention(form, correctionReason, InterventionCorrectionType.UPDATE)}
                    color="primary"
                    label="Enregistrer l'intervention de vaccination"
                    loading={loadingRequest}
                />
            ) : (
                <>
                    <GenericButton
                        onClick={() => onValid(false)}
                        color="primary"
                        label="Enregistrer l'intervention de vaccination"
                        disabled={generateCertificat}
                        loading={loadingRequest}
                    />
                    {!form.certificateGeneration && (
                        <GenericButton
                            onClick={() => onValid(true)}
                            color="primary"
                            label="Valider et générer le certificat"
                            disabled={!generateCertificat}
                            loading={loadingRequest}
                        />
                    )}
                </>
            )}
        </>
    );

    return (
        <GenericDialog
            maxWidth="xl"
            title="Récapitulatif de l'intervention de vaccination"
            onClose={onClose}
            renderContent={() => renderContent()}
            renderActions={displayActionButton}
        />
    );
}
