// === NPM
import React, { RefObject } from "react";
import { Card, FormControl, Stack, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { ZodIssueCode, z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
// === LOCAL
import GenericDateTimePicker from "../../../../../../../generics/inputs/GenericDateTimePicker";
import { StyledCardContent } from "../../../../../../../styled/StyledCardContent";
import { getEnumKeyByValue } from "../../../../../../../../resources/utils";
import { INotificationCreateUpdate, SendMode } from "../../../../interface";
import { stringRequired } from "../../../../../../../../resources/FormUtils";

interface PublicationDatesProps {
    onValid: (data: { publicationDate: string; publicationEndDate: string }) => void;
    form: INotificationCreateUpdate;
    formRef: RefObject<HTMLButtonElement>;
}

export default function PublicationDates({ form, formRef, onValid }: PublicationDatesProps) {
    const formSchema = z
        .object({
            publicationDate: stringRequired().refine(
                (value) => (value ? DateTime.fromISO(value) > DateTime.now() : false),
                { message: "La date de publication ne doit pas être dans le passé." }
            ),
            publicationEndDate: z.string().optional().nullable(),
        })
        .superRefine((values, context) => {
            if (
                values.publicationDate >= values.publicationEndDate ||
                (!values.publicationEndDate && form.sendMode !== getEnumKeyByValue(SendMode, SendMode.MAIL))
            ) {
                context.addIssue({
                    code: ZodIssueCode.custom,
                    message: "La date de publication ne peut pas excéder la date de fin de publication.",
                    path: ["publicationEndDate"],
                });
            }
        });

    type ValidationSchema = z.infer<typeof formSchema>;

    const {
        formState: { errors },
        handleSubmit,
        control,
        watch,
    } = useForm<ValidationSchema>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            publicationDate: form.publicationDate,
            publicationEndDate:
                form.sendMode === getEnumKeyByValue(SendMode, SendMode.MAIL) ? null : form.publicationEndDate,
        },
    });

    const watchPublicationDate = watch("publicationDate");

    const handleValidateForm = (data: ValidationSchema) => {
        onValid(data as { publicationDate: string; publicationEndDate: string });
    };

    const disablePublicationDateRange = (date: DateTime) => {
        return date < DateTime.now().startOf("day");
    };

    const disablePublicationEndDateRange = (date: DateTime) => {
        return date < DateTime.fromISO(watchPublicationDate).startOf("day");
    };

    return (
        <form onSubmit={handleSubmit(handleValidateForm)} noValidate>
            <Stack spacing={2}>
                <Typography sx={{ width: { xs: "100%", md: "50%" } }} variant="h5">
                    Dates de publication
                </Typography>
                <Card>
                    <StyledCardContent>
                        <Stack spacing={4} sx={{ p: 2 }}>
                            <Typography variant="h5">Date de publication</Typography>
                            <FormControl fullWidth required error={!!errors.publicationDate}>
                                <Controller
                                    name="publicationDate"
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                                        <GenericDateTimePicker
                                            value={value}
                                            name="publicationDate"
                                            label="Date de publication"
                                            onChange={onChange}
                                            error={!!error}
                                            helperText={error?.message}
                                            disableDate={disablePublicationDateRange}
                                        />
                                    )}
                                />
                            </FormControl>
                            {form.sendMode === getEnumKeyByValue(SendMode, SendMode.CALYPSO_APPLICATION) && (
                                <>
                                    <Typography variant="h5">Date de fin de publication</Typography>
                                    <FormControl fullWidth required error={!!errors.publicationEndDate}>
                                        <Controller
                                            name="publicationEndDate"
                                            control={control}
                                            render={({ field: { value, onChange }, fieldState: { error } }) => (
                                                <GenericDateTimePicker
                                                    value={value}
                                                    name="publicationEndDate"
                                                    label="Date de fin de publication"
                                                    onChange={onChange}
                                                    error={!!error}
                                                    helperText={error?.message}
                                                    disableDate={disablePublicationEndDateRange}
                                                    maxDateTime={DateTime.fromISO(watchPublicationDate).plus({
                                                        months: 6,
                                                    })}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </>
                            )}
                        </Stack>
                    </StyledCardContent>
                </Card>
            </Stack>
            <button style={{ display: "none" }} type="submit" ref={formRef} />
        </form>
    );
}
