// === NPM
import { FormControl, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import React from "react";

// === LOCAL
import { FilterConfiguration } from "../GenericFilters";

interface SelectFilterProps {
    config: FilterConfiguration;
    value: string[];
    handleSelectFilter: (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => void;
    name: string;
}

export default function SelectFilter({ config, value, handleSelectFilter, name }: SelectFilterProps) {
    return (
        <FormControl sx={{ m: 2 }} component="fieldset" variant="standard">
            <FormGroup>
                {config.values
                    .sort((v1, v2) => v1.label.localeCompare(v2.label))
                    .map((v) => {
                        return (
                            <FormControlLabel
                                key={v.key}
                                control={
                                    <Checkbox
                                        checked={value.includes(v.key)}
                                        onChange={handleSelectFilter(name)}
                                        name={v.key}
                                    />
                                }
                                label={v.label}
                            />
                        );
                    })}
            </FormGroup>
        </FormControl>
    );
}
