// === NPM
import React from "react";
import { Stack, Typography, Box, Card, CardActionArea, CardContent } from "@mui/material";
// === LOCAL
import { colors } from "../../../../../resources/CssConstant";
import { ReactComponent as Hatchery } from "../../../../../assets/icons/form/hatchery.svg";
import { ReactComponent as Breeding } from "../../../../../assets/icons/form/breeding.svg";
import { useFormIntervention } from "./useFormIntervention";
import { getEnumKeyByValue } from "../../../../../resources/utils";
import { VaccinationInterventionType } from "../../interface";

export default function FormSelection() {
    const { setForm } = useFormIntervention();

    const renderCard = () => {
        return (
            <Stack
                direction={{ sm: "column", md: "row" }}
                spacing={4}
                width="100%"
                justifyContent="space-around"
                alignItems={{ sm: "center", md: "center" }}
            >
                <Card variant="outlined" sx={{ display: "block" }}>
                    <CardActionArea
                        onClick={() =>
                            setForm((prev) => ({
                                ...prev,
                                type: getEnumKeyByValue(
                                    VaccinationInterventionType,
                                    VaccinationInterventionType.HATCHERY
                                ),
                            }))
                        }
                        sx={{
                            borderRadius: "20px",
                            minHeight: "200px",
                            maxWidth: "300px",
                            alignItems: "center",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-around",
                            backgroundColor: colors.white,
                            textAlign: "center",
                        }}
                    >
                        <CardContent>
                            <Hatchery />
                            <Box>
                                <Typography variant="h5">Vaccination au couvoir</Typography>
                            </Box>
                        </CardContent>
                    </CardActionArea>
                </Card>
                <Card variant="outlined" sx={{ display: "block" }}>
                    <CardActionArea
                        onClick={() =>
                            setForm((prev) => ({
                                ...prev,
                                type: getEnumKeyByValue(
                                    VaccinationInterventionType,
                                    VaccinationInterventionType.BREEDING
                                ),
                            }))
                        }
                        sx={{
                            borderRadius: "20px",
                            minHeight: "200px",
                            maxWidth: "300px",
                            alignItems: "center",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-around",
                            backgroundColor: colors.white,
                            textAlign: "center",
                        }}
                    >
                        <CardContent>
                            <Breeding />
                            <Box>
                                <Typography variant="h5">Vaccination en élevage</Typography>
                            </Box>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Stack>
        );
    };

    return (
        <Stack alignItems="center" width="100%" spacing={15} mt={4}>
            <Typography variant="h4">Sélectionnez le lieu de vaccination</Typography>
            {renderCard()}
        </Stack>
    );
}
