import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/material";
import { colors } from "../../resources/CssConstant";

const SecondaryButton = styled(LoadingButton)(`
  &.MuiLoadingButton-root {
    background: ${colors.white}
  },

`);

export default SecondaryButton;
