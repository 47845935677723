// === Import: NPM
import React from "react";
import { Card, CardContent, Grid, Stack, Typography } from "@mui/material";
// === Import: LOCAL
import { GenericText } from "../../../../generics/text/GenericText";
import { IOrdinalSituation } from "../../../interface";

interface OrdinalSituationProps {
    ordinalSituation: IOrdinalSituation;
}
export default function OrdinalSituation({ ordinalSituation }: OrdinalSituationProps) {
    const { dpaAddress } = ordinalSituation;
    return (
        <Stack spacing={3} height="100%">
            <Typography variant="h5">Situation ordinale</Typography>
            <Card sx={{ height: "100%", display: "flex", flexGrow: 1, flexDirection: "column" }}>
                <CardContent>
                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            <Stack>
                                <GenericText bold value="Situation" margin />
                                <GenericText value={ordinalSituation.ordinalSituation} />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Stack>
                                <GenericText bold value="Région ordinale" margin />
                                <GenericText value={ordinalSituation.subscriptionRegion} />
                                <GenericText bold value="Coordonnées du DPA" margin />
                                <GenericText value={ordinalSituation.dpaAddressName} />
                                <GenericText value={dpaAddress.way} />
                                <GenericText value={dpaAddress.additionalAddress1} />
                                <GenericText value={dpaAddress.additionalAddress2} />
                                <GenericText
                                    value={`${dpaAddress.zipCode} ${dpaAddress.city} ${dpaAddress.country ?? ""}`}
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Stack>
    );
}
