// === NPM
import { Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
// === LOCAL
import { ArrayOfTwo, HttpStatus, ICityFilter } from "../../../../../../interfaces/global";
import { IAgency } from "../../../../../../interfaces/user";
import UserService from "../../../../../../services/UserService";
import Agency from "./containers/Agency";
import LocalAgencyFamDam from "./containers/LocalAgencyFamDam";
import FamDamUsers from "./containers/FamDamUsers";
import { IFamDamUsersFilters } from "../../interface";

export interface LocalAgencyFilters {
    authorizationNumber: string;
    name: string;
    openingDate: ArrayOfTwo[];
    closingDate: ArrayOfTwo[];
    city: ICityFilter[];
    agencyName: string;
    archived: string[];
}

export default function FamDam() {
    const [localAgencyFilters, setLocalAgencyFilters] = useState<LocalAgencyFilters>({
        authorizationNumber: "",
        name: "",
        openingDate: [null, null],
        closingDate: [null, null],
        city: [],
        agencyName: "",
        archived: [],
    });
    const [famDamUsersFilters, setFamDamUsersFilters] = useState<IFamDamUsersFilters>({
        id: "",
        agencyName: "",
        firstname: "",
        lastname: "",
        email: "",
        situation: [],
    });
    const [activeAgencies, setActiveAgencies] = useState<IAgency[]>([]);

    useEffect(() => {
        getActiveAgencies();
    }, []);

    const getActiveAgencies = async () => {
        const res = await UserService.getAgencies({
            page: 0,
            size: 300,
            active: "true",
            sorts: ["name,asc"],
        });
        if (res.status === HttpStatus.OK) {
            setActiveAgencies(res.data);
        }
    };

    return (
        <Stack height="100%" spacing={3} width="100%">
            <Agency
                getActiveAgencies={getActiveAgencies}
                setLocalAgencyFilters={setLocalAgencyFilters}
                setFamDamUsersFilters={setFamDamUsersFilters}
            />
            <LocalAgencyFamDam
                activeAgencies={activeAgencies}
                inputFilters={localAgencyFilters}
                setInputFilters={setLocalAgencyFilters}
            />
            <FamDamUsers inputFilters={famDamUsersFilters} setInputFilters={setFamDamUsersFilters} />
        </Stack>
    );
}
