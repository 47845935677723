// === NPM
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Box } from "@mui/material";

// === LOCAL
import { IAccount, IAccountWithKeys } from "../interface";
import ViewAction from "../../../../generics/actions/ViewAction";
import EditAction from "../../../../generics/actions/EditAction";
import DeleteAction from "../../../../generics/actions/DeleteAction";
import { HttpStatus } from "../../../../../interfaces/global";
import ApiKeyService from "../../../../../services/ApiKeyService";
import ConfirmDialogWithWarning from "../../../../generics/dialogs/ConfirmDialogWithWarning";
import AccountInformationDialog from "./dialogs/AccountInformationDialog";
import ModifyAccountInformationDialog from "./dialogs/ModifyAccountInformationDialog";

interface AccountTableActionsProps {
    row: IAccountWithKeys;
    getAccounts: () => void;
}

export default function AccountTableActions({ row, getAccounts }: AccountTableActionsProps) {
    const [selectedAccount, setSelectedAccount] = useState<IAccountWithKeys>(null);
    const [openConfirmDeleteAccount, setOpenConfirmDeleteAccount] = useState<boolean>(false);
    const [openViewAccountInformation, setOpenViewAccountInformation] = useState<boolean>(false);
    const [openModifyAccountInformation, setOpenModifyAccountInformation] = useState<boolean>(false);

    const modifyAccount = async (account: IAccount) => {
        const res = await ApiKeyService.patchAccount(account.uuid, { email: account.email, endDate: account.endDate });
        if (res.status === HttpStatus.OK) {
            setOpenModifyAccountInformation(false);
            toast.success("Compte modifié avec succès");
            getAccounts();
        }
    };

    const handleRenewToken = async (account: IAccount) => {
        const res = await ApiKeyService.postAccountToken(account.uuid);
        if (res.status === HttpStatus.NO_CONTENT) {
            setOpenModifyAccountInformation(false);
            toast.success("Token renouvelé avec succès");
            getAccounts();
        }
    };

    const handleDeleteAccount = async (value: boolean) => {
        if (!value) {
            setOpenConfirmDeleteAccount(false);
            setSelectedAccount(null);
            return;
        }
        const res = await ApiKeyService.deleteAccount(selectedAccount.uuid);
        if (res.status === HttpStatus.NO_CONTENT) {
            toast.success("Compte supprimé avec succès");
            getAccounts();
            setOpenConfirmDeleteAccount(false);
            setSelectedAccount(null);
        }
    };

    return (
        <Box>
            <ViewAction
                title="Voir le détail"
                onClick={() => {
                    setSelectedAccount(row);
                    setOpenViewAccountInformation(true);
                }}
            />
            <EditAction
                title="Mettre à jour"
                onClick={() => {
                    setSelectedAccount(row);
                    setOpenModifyAccountInformation(true);
                }}
            />
            <DeleteAction
                title="Supprimer le compte"
                onClick={() => {
                    setSelectedAccount(row);
                    setOpenConfirmDeleteAccount(true);
                }}
            />
            {openConfirmDeleteAccount && (
                <ConfirmDialogWithWarning
                    title="Suppression du compte"
                    warningDisplay={!!selectedAccount.apiKeysNumber}
                    onClose={handleDeleteAccount}
                    warningText={`La suppression de ce compte entraînera la suppression de ${selectedAccount.apiKeysNumber} clef(s).`}
                    confirmationText={`Êtes-vous sûr de vouloir supprimer le compte ${selectedAccount.label} ?`}
                />
            )}
            {openViewAccountInformation && (
                <AccountInformationDialog
                    title={selectedAccount.label}
                    onClose={() => setOpenViewAccountInformation(false)}
                    account={selectedAccount}
                />
            )}
            {openModifyAccountInformation && (
                <ModifyAccountInformationDialog
                    title={selectedAccount.label}
                    onClose={() => setOpenModifyAccountInformation(false)}
                    account={selectedAccount}
                    onValid={modifyAccount}
                    onTokenRenew={handleRenewToken}
                />
            )}
        </Box>
    );
}
