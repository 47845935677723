import { GridFilterModel } from "@mui/x-data-grid-pro";
import { DateTime } from "luxon";
import { FilterConfiguration, FilterConfigurations } from "../components/generics/filters/GenericFilters";
import { FilterType } from "../interfaces/global";
import { DocumentMimeTypeFile, displayFormat } from "./AppConstant";
import { UserSituation } from "./PermissionConstant";

export const objToQueryParams = (obj: { [key: string]: any }) => {
    return new URLSearchParams(obj).toString();
};

export const formatParams = (obj: { [key: string]: any }) => {
    if (!obj) return;
    const params = Object.fromEntries(Object.entries(obj).filter(([, v]) => v != null));
    return new URLSearchParams(params);
};

export const hasAccess = (userSituation: string, authorizedUserSituations: UserSituation[]) => {
    return authorizedUserSituations.includes(userSituation as UserSituation);
};

export const isDateInRange = (date: string, dateRange: string[]) => {
    if (!date) {
        return true;
    }
    if (!dateRange[0]) {
        return DateTime.fromISO(dateRange[1]) >= DateTime.fromFormat(date, displayFormat);
    } else if (!dateRange[1]) {
        return DateTime.fromISO(dateRange[0]) <= DateTime.fromFormat(date, displayFormat);
    } else {
        return (
            DateTime.fromISO(dateRange[0]) <= DateTime.fromFormat(date, displayFormat) &&
            DateTime.fromISO(dateRange[1]) >= DateTime.fromFormat(date, displayFormat)
        );
    }
};

export const toLocaleDateFormat = (date: string) => {
    const value = DateTime.fromISO(date);
    if (!value.isValid) return;
    return value.toLocaleString();
};

export const createPayload = (data: Record<string, any>): Record<string, any> => {
    const payload = {};

    Object.entries(data).forEach(([name, value]) => {
        if (Array.isArray(value) && value.every((v) => !v)) return;
        if (value && (value.length > 0 || typeof value === "number")) {
            payload[name] = value;
        }
    });
    return payload;
};

export const createPayloadWithObject = <T>(
    data: Record<string, any>,
    filterConfiguration: FilterConfigurations<T>
): Record<string, any> => {
    const payload = {};

    Object.entries(data).forEach(([name, value]) => {
        const configuration: FilterConfiguration = filterConfiguration[name];
        if (Array.isArray(value) && value.every((v) => !v)) return;
        if (configuration.objectProperties && value) {
            const object = configuration.objectProperties;
            if (!payload[object.filterKey]) payload[object.filterKey] = [];
            payload[object.filterKey].push(JSON.stringify({ ...object.objectData, [object.field]: value }));
        } else if (value && (value.length > 0 || typeof value === "number")) {
            payload[name] = value;
        }
    });
    return payload;
};

export const convertFiltersToDataGridFilters = (
    filters: { [key: string]: any },
    configuration: FilterConfigurations<{ [key: string]: any }>
): GridFilterModel => {
    const model: GridFilterModel = { items: [] };

    Object.entries(filters).forEach(([key, value], index) => {
        if (Array.isArray(value) && value.every((v) => v === null)) return;
        let operatorValue = "";
        if (value.length > 0) {
            switch (configuration[key].type) {
                case FilterType.DATEPICKER:
                    operatorValue = "onOrAfter";
                    model.items.push({ columnField: key, value, operatorValue, id: index });
                    break;
                case FilterType.INPUT:
                    operatorValue = "contains";
                    model.items.push({ columnField: key, value, operatorValue, id: index });
                    break;
                case FilterType.SINGLE_SELECT:
                case FilterType.SELECT_AUTOCOMPLETE:
                case FilterType.SELECT:
                    operatorValue = "isAnyOf";
                    model.items.push({ columnField: key, value, operatorValue, id: index });
                    break;
            }
        }
    });
    return model;
};

export const convertEnumToKeyLabelObject = (enumVal: Record<string, string>) => {
    return Object.entries(enumVal).map(([key, label]) => ({ key, label }));
};

export const isArrayTruthy = (array: any[]) => {
    return array.length > 0 && array.every((item) => item);
};

export const getEnumKeyByValue = <T>(enumObj: T, enumValue: string): keyof T => {
    const indexOfKey = Object.values(enumObj).indexOf(enumValue as unknown);

    const key = Object.keys(enumObj)[indexOfKey];

    return key as keyof T;
};

export const validateFile = (file: File) => {
    if (file.size > 1e7) {
        return {
            code: "file-too-large",
            message: `La taille des fichiers ne doit pas excéder 10 Mo.`,
        };
    }
    if (!DocumentMimeTypeFile.includes(file.type)) {
        return {
            code: "file-invalid-type",
            message: `Les formats acceptés sont .docx, .jpg, .pdf`,
        };
    }
    return null;
};

export const nullifyEmptyFields = <T>(objectToClean: T): T => {
    Object.entries(objectToClean).forEach(([name, value]) => {
        if (value === "") {
            objectToClean[name] = null;
        } else if (value && typeof value === "object") {
            objectToClean[name] = nullifyEmptyFields(value);
        } else {
            objectToClean[name] = value;
        }
    });
    return objectToClean;
};

export const convertToSnakeCase = (str: string) => {
    return str.replace(/([A-Z])/g, "_$1").toLowerCase();
};
