// === NPM
import { Grid, Stack, Typography } from "@mui/material";
import React, { ChangeEvent, RefObject } from "react";
// === LOCAL
import GenericSwitch from "../../../../../generics/buttons/GenericSwitch";
import GenericTextField from "../../../../../generics/inputs/GenericTextField";
import { IElisa } from "../../../../../../interfaces/vaccination";
import { Form } from "../../../../../../hooks/useForm";
import { defaultIntegerLimit } from "../../../../../../resources/AppConstant";
import GenericComment from "../../../../../generics/inputs/GenericComment";

export interface IElisaProps {
    elisaRef: RefObject<HTMLButtonElement>;
    form: Form<IElisa>;
}

export default function Elisa({ elisaRef, form }: IElisaProps) {
    const { data, errors, handleSubmit, handleChange, cleanValue, initValues } = form;

    const handleSwitch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = {
            target: { value: null, checked: e.target.checked },
        } as ChangeEvent<HTMLInputElement & HTMLSelectElement>;
        handleChange("realized")(target);
    };

    return (
        <form onSubmit={handleSubmit} noValidate>
            <Stack spacing={4} width="100%">
                <Typography sx={{ fontSize: 16, fontWeight: 600 }}>Contrôle sérologique ELISA NP</Typography>
                <Grid container>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <GenericSwitch
                                title="Réalisation du contrôle"
                                leftText="Non réalisé"
                                rightText="Réalisé"
                                value={data.realized}
                                onChange={(e) => {
                                    handleSwitch(e);
                                    cleanValue("comment");
                                    initValues({ bloodSampleNumber: e.target.checked ? 20 : null });
                                }}
                            />
                        </Grid>
                        {!data.realized && (
                            <Grid item xs={12} md={4}>
                                <GenericComment
                                    label="Motif de non réalisation"
                                    value={data.comment}
                                    onChange={handleChange("comment")}
                                    required
                                    helperText={errors.comment}
                                    maxLength={100}
                                    rows={1}
                                    placeholder="Motif de non réalisation"
                                />
                            </Grid>
                        )}
                        {data.realized && (
                            <Grid item xs={12} md={4}>
                                <GenericTextField
                                    label="Nombre de prises de sang"
                                    value={data.bloodSampleNumber || ""}
                                    onChange={handleChange("bloodSampleNumber")}
                                    error={!!errors.bloodSampleNumber}
                                    helperText={errors.bloodSampleNumber}
                                    required
                                    maxLength={defaultIntegerLimit}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Stack>
            <button style={{ display: "none" }} type="submit" ref={elisaRef} />
        </form>
    );
}
