// === NPM
import React, { useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";

// === LOCAL
import ReferentialService from "../../../services/ReferentialService";
import { HttpStatus } from "../../../interfaces/global";
import useIsFirstRender from "../../../hooks/useIsFirstRender";
import GenericAutocomplete from "./GenericAutocomplete";

interface ICityOption {
    name: string;
    postalCode: string;
}

interface CityPostalCodeAutocompleteProps {
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    defaultCitySearch?: string;
    defaultPostalCodeSearch?: string;
    initialValues?: ICityOption;
    disabled?: boolean;
    errors?: ICityOption;
    required?: boolean;
}

export default function CityPostalCodeAutocomplete({
    defaultCitySearch = "",
    defaultPostalCodeSearch = "",
    handleChange,
    initialValues,
    disabled,
    required,
    errors,
}: CityPostalCodeAutocompleteProps) {
    const [city, setCity] = useState<ICityOption>(initialValues);
    const [citySearch, setCitySearch] = useState<string>(defaultCitySearch ?? "");
    const [postalCodeSearch, setPostalCodeSearch] = useState<string>(defaultPostalCodeSearch ?? "");
    const [citiesOptions, setCitiesOptions] = useState<ICityOption[]>([]);

    const timeoutRef = useRef(null);
    const isFirstRender = useIsFirstRender();

    useEffect(() => {
        if (defaultCitySearch && defaultPostalCodeSearch) getCities();
    }, [defaultCitySearch, defaultPostalCodeSearch]);

    useEffect(() => {
        if (isFirstRender) {
            return;
        }

        if (timeoutRef.current !== null) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(async () => {
            getCities();
        }, 500);
    }, [citySearch, postalCodeSearch]);

    const getCities = async () => {
        const payload = { page: 0, size: 10 };
        if (citySearch) payload["name"] = citySearch;
        if (postalCodeSearch) payload["postalCode"] = postalCodeSearch;

        const res = await ReferentialService.getCities(payload);
        const options: ICityOption[] = [];
        if (res.status === HttpStatus.OK) {
            res.data.forEach((c) => {
                c.postalCodes.forEach((p) => options.push({ name: c.name, postalCode: p }));
            });
        }
        setCitiesOptions(options);
    };

    const renderCityOptions = (p, o) => (
        <Box component="li" {...p} key={p.id}>
            {o.name} ({o.postalCode})
        </Box>
    );

    return (
        <>
            <GenericAutocomplete
                value={city}
                options={citiesOptions}
                optionValue="label"
                onChange={(event) => {
                    setCity(event.target.value);
                    handleChange(event);
                }}
                renderOption={renderCityOptions}
                label="Code postal"
                onInputChange={(event, newInputValue) => {
                    if (newInputValue === "") {
                        setCitySearch("");
                    }
                    setPostalCodeSearch(newInputValue);
                }}
                disabled={disabled}
                inputValue={postalCodeSearch}
                isOptionEqualToValue={(option, value) => option.postalCode === value?.postalCode}
                getOptionLabel={(option) => option.postalCode ?? ""}
                required={required}
                error={!!errors?.postalCode}
                helperText={errors?.postalCode}
                freeSolo={disabled}
            />
            <GenericAutocomplete
                value={city}
                options={citiesOptions}
                optionValue="label"
                onChange={(event) => {
                    setCity(event.target.value);
                    handleChange(event);
                }}
                label="Ville"
                renderOption={renderCityOptions}
                onInputChange={(event, newInputValue) => {
                    if (newInputValue === "") {
                        setPostalCodeSearch("");
                    }
                    setCitySearch(newInputValue);
                }}
                disabled={disabled}
                inputValue={citySearch}
                isOptionEqualToValue={(option, value) =>
                    option.name === value?.name && option.postalCode === value?.postalCode
                }
                getOptionLabel={(option) => option.name ?? ""}
                required={required}
                error={!!errors?.name}
                helperText={errors?.name}
                freeSolo={disabled}
            />
        </>
    );
}
