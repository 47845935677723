// === NPM
import React, { useState, useContext, Dispatch, SetStateAction } from "react";

export interface UseTour<T> {
    tour: T;
    setTour: Dispatch<SetStateAction<T>>;
}

export const tourContext = React.createContext<UseTour<any>>({
    tour: null,
    setTour: undefined,
});

export const useTour = <T extends {}>(): UseTour<T> => {
    return useContext(tourContext);
};

export const TourProvider = ({ children }: React.PropsWithChildren) => {
    const tour = useProvideTour();
    return <tourContext.Provider value={tour}>{children}</tourContext.Provider>;
};

const useProvideTour = <T extends {}>() => {
    const [tour, setTour] = useState<T>(null);

    return {
        tour,
        setTour,
    };
};
