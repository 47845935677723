// === NPM
import React, { RefObject } from "react";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { KeyboardArrowDown } from "@mui/icons-material";
import { Card, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { z } from "zod";
// === LOCAL
import { FORM_TEXT, stringRequired } from "../../../../../../../../../resources/FormUtils";
import { getEnumKeyByValue } from "../../../../../../../../../resources/utils";
import CityPostalCodeAutocomplete from "../../../../../../../../generics/inputs/CityPostalCodeAutocomplete";
import GenericSelect from "../../../../../../../../generics/inputs/GenericSelect";
import GenericErrorMessageText from "../../../../../../../../generics/text/GenericErrorMessageText";
import { StyledCardContent } from "../../../../../../../../styled/StyledCardContent";
import { HolderType, IHealthReportingForm, IWorkshopInformations, TypeIdentifiant } from "../../../../../../interface";
import { useFormHealthReporting } from "../../../useFormHealthReporting";

interface WorkshopInformationsProps {
    formRef: RefObject<HTMLButtonElement>;
    getValuesRef: RefObject<HTMLButtonElement>;
    handleNextStep: (partialValues: Partial<IHealthReportingForm>, finalStep?: boolean) => void;
    setSaveAndQuit: (value: boolean) => void;
}

const workshopInformationSchema = z
    .object({
        holderType: stringRequired(),
        holderLastname: stringRequired()
            .max(50, { message: "Ce champ ne peut pas contenir plus de 50 caractères" })
            .transform((value) => value.toUpperCase()),
        holderFirstname: stringRequired().max(50, { message: "Ce champ ne peut pas contenir plus de 50 caractères" }),
        address: stringRequired().max(100, { message: "Ce champ ne peut pas contenir plus de 100 caractères" }),
        postalCode: stringRequired(),
        city: stringRequired(),
        //les champs conditionnels
        typeIdentifiant: z.string().trim().optional().nullable(),
        holderId: z.string().trim().optional().nullable(),
        businessName: z
            .string()
            .max(50, "Ce champ ne peut pas contenir plus de 50 caractères")
            // .regex()
            .trim()
            .optional()
            .nullable(),
    })
    .superRefine((values, context) => {
        if (values.holderType === getEnumKeyByValue(HolderType, HolderType.PROFESSIONAL)) {
            if (!values.typeIdentifiant) {
                context.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: FORM_TEXT.required,
                    path: ["typeIdentifiant"],
                });
            }
            if (!values.businessName) {
                context.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: FORM_TEXT.required,
                    path: ["businessName"],
                });
            }
            if (!values.holderId) {
                context.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: FORM_TEXT.required,
                    path: ["holderId"],
                });
            } else if (
                values.typeIdentifiant === getEnumKeyByValue(TypeIdentifiant, TypeIdentifiant.SIRET) &&
                !values.holderId.match("^[0-9]{14}$")
            ) {
                context.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: "L'identifiant détenteur doit contenir 14 chiffres",
                    path: ["holderId"],
                });
            } else if (
                values.typeIdentifiant === getEnumKeyByValue(TypeIdentifiant, TypeIdentifiant.SIREN) &&
                !values.holderId.match("^[0-9]{9}$")
            ) {
                context.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: "L'identifiant détenteur doit contenir 9 chiffres",
                    path: ["holderId"],
                });
            } else if (
                values.typeIdentifiant === getEnumKeyByValue(TypeIdentifiant, TypeIdentifiant.EDE) &&
                !values.holderId.match("^[0-9]{8}$")
            ) {
                context.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: "L'identifiant détenteur doit contenir 8 chiffres",
                    path: ["holderId"],
                });
            }
        }
    });

type WorkshopInformationValidationSchema = z.infer<typeof workshopInformationSchema>;

export default function WorkshopInformations({
    formRef,
    getValuesRef,
    handleNextStep,
    setSaveAndQuit,
}: WorkshopInformationsProps) {
    const { form, setForm } = useFormHealthReporting();
    const {
        formState: { errors },
        control,
        register,
        handleSubmit,
        setValue,
        watch,
        getValues,
    } = useForm<WorkshopInformationValidationSchema>({
        resolver: zodResolver(workshopInformationSchema),
        defaultValues: {
            holderType: form?.workshopInformations?.holderType ?? null,
            typeIdentifiant: form?.workshopInformations?.typeIdentifiant ?? null,
            holderId: form?.workshopInformations?.holderId ?? null,
            businessName: form?.workshopInformations?.businessName ?? null,
            holderLastname: form?.workshopInformations?.holderLastname ?? null,
            holderFirstname: form?.workshopInformations?.holderFirstname ?? null,
            address: form?.workshopInformations?.address ?? null,
            postalCode: form?.workshopInformations?.postalCode ?? "",
            city: form?.workshopInformations?.city ?? "",
        },
    });

    const watchFarmerType = watch("holderType");
    const holderId = watch("holderId");
    const businessName = watch("businessName");
    const address = watch("address");
    const holderFirstname = watch("holderFirstname");
    const holderLastname = watch("holderLastname");

    const handleCityPostalCodeChange = (event) => {
        setValue("postalCode", event.target.value?.postalCode ?? "");
        setValue("city", event.target.value?.name ?? "");
    };

    const handleFarmTypeChange = () => {
        setValue("typeIdentifiant", null);
        setValue("holderId", "");
        setValue("businessName", "");
    };

    const validateWorkshopInformations = (formValues: WorkshopInformationValidationSchema) => {
        handleNextStep({ workshopInformations: formValues as IWorkshopInformations });
    };

    const saveDraftValues = () => {
        const values = getValues();
        setForm({
            ...form,
            workshopInformations: values as IWorkshopInformations,
        });
        setSaveAndQuit(true);
    };

    const renderFarmerInformations = () => (
        <Card sx={{ p: 2 }}>
            <StyledCardContent>
                <Grid container spacing={2}>
                    <Grid item md={4} xs={12}>
                        <FormControl fullWidth error={!!errors.holderType} required>
                            <InputLabel>Type détenteur</InputLabel>
                            <Controller
                                name="holderType"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        label="Type détenteur"
                                        {...field}
                                        value={field.value ?? ""}
                                        onChange={(event) => {
                                            field.onChange(event);
                                            handleFarmTypeChange();
                                        }}
                                        IconComponent={KeyboardArrowDown}
                                    >
                                        {Object.keys(HolderType).map((holderType) => (
                                            <MenuItem key={holderType} value={holderType}>
                                                {HolderType[holderType]}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                            />
                            <GenericErrorMessageText fieldError={errors?.holderType} />
                        </FormControl>
                    </Grid>

                    {watchFarmerType === getEnumKeyByValue(HolderType, HolderType.PROFESSIONAL) && (
                        <>
                            <Grid item md={4} xs={12}>
                                <Controller
                                    name="typeIdentifiant"
                                    control={control}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <GenericSelect
                                            value={value || ""}
                                            label="Type d'identifiant"
                                            onChange={(e) => {
                                                onChange(e);
                                                setValue("businessName", null);
                                                setValue("holderId", null);
                                            }}
                                            optionsValue="key"
                                            error={!!error}
                                            helperText={error?.message}
                                            options={Object.keys(TypeIdentifiant).map((type) => ({
                                                key: type,
                                                label: TypeIdentifiant[type],
                                            }))}
                                            required
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item md={4} xs={12}>
                                <TextField
                                    {...register("holderId")}
                                    label="Identifiant détenteur"
                                    required
                                    fullWidth
                                    error={!!errors?.holderId}
                                    helperText={errors?.holderId?.message}
                                    InputLabelProps={{ shrink: !!holderId }}
                                />
                            </Grid>

                            <Grid item md={4} xs={12}>
                                <TextField
                                    {...register("businessName")}
                                    label="Raison sociale"
                                    required
                                    fullWidth
                                    error={!!errors?.businessName}
                                    helperText={errors?.businessName?.message ?? `${businessName?.length ?? 0}/50`}
                                    InputLabelProps={{ shrink: !!businessName }}
                                    inputProps={{ maxLength: 50 }}
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
            </StyledCardContent>
        </Card>
    );

    const renderOwnerInformations = () => (
        <Card sx={{ p: 2 }}>
            <StyledCardContent>
                <Grid container spacing={2}>
                    <Grid item md={4} xs={12}>
                        <TextField
                            {...register("holderLastname")}
                            label="Nom du détenteur"
                            required
                            fullWidth
                            error={!!errors?.holderLastname}
                            helperText={errors?.holderLastname?.message ?? `${holderLastname?.length ?? 0}/50`}
                            inputProps={{ maxLength: 50, style: { textTransform: "uppercase" } }}
                        />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <TextField
                            {...register("holderFirstname")}
                            label="Prénom du détenteur"
                            required
                            fullWidth
                            error={!!errors?.holderFirstname}
                            helperText={errors?.holderFirstname?.message ?? `${holderFirstname?.length ?? 0}/50`}
                            inputProps={{ maxLength: 50 }}
                        />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <TextField
                            {...register("address")}
                            label="Adresse"
                            required
                            fullWidth
                            error={!!errors?.address}
                            helperText={errors?.address?.message ?? `${address?.length ?? 0}/100`}
                            inputProps={{ maxLength: 100 }}
                        />
                    </Grid>

                    <Grid item xs={12} md={8}>
                        <Stack spacing={2} direction="row">
                            <CityPostalCodeAutocomplete
                                handleChange={handleCityPostalCodeChange}
                                defaultCitySearch={form?.workshopInformations?.city}
                                defaultPostalCodeSearch={form?.workshopInformations?.postalCode}
                                initialValues={
                                    form?.workshopInformations?.city
                                        ? {
                                              name: form?.workshopInformations?.city,
                                              postalCode: form?.workshopInformations?.postalCode,
                                          }
                                        : null
                                }
                                errors={{ name: errors?.city?.message, postalCode: errors?.postalCode?.message }}
                                required
                            />
                        </Stack>
                    </Grid>
                </Grid>
            </StyledCardContent>
        </Card>
    );

    return (
        <Stack spacing={3} width="100%">
            <form onSubmit={handleSubmit(validateWorkshopInformations)} noValidate>
                <Stack spacing={3} width="100%">
                    <Typography sx={{ width: { xs: "100%", md: "50%" } }} variant="h5">
                        Atelier d'élevage
                    </Typography>
                    {renderFarmerInformations()}
                    <Typography sx={{ width: { xs: "100%", md: "50%" } }} variant="h5">
                        Informations du détenteur
                    </Typography>
                    {renderOwnerInformations()}
                </Stack>
                <button style={{ display: "none" }} type="submit" ref={formRef} />
                <button style={{ display: "none" }} onClick={() => saveDraftValues()} ref={getValuesRef} />
            </form>
        </Stack>
    );
}
