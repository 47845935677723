// === Import: NPM
import React from "react";
// === Import: LOCAL
import { ReactComponent as Archive } from "../../../assets/icons/actions/archive.svg";
import { IActionButton } from "../../../interfaces/global";
import IconActionButton from "./IconActionButton";

export default function ArchiveAction({ title, onClick }: IActionButton) {
    return <IconActionButton title={title} onClick={onClick} icon={<Archive />} />;
}
