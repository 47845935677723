// === NPM
import React, { useEffect, useState } from "react";
import { Typography, Stack } from "@mui/material";
// === LOCAL
import GenericDialog from "../../../../generics/dialogs/GenericDialog";
import { getEnumKeyByValue } from "../../../../../resources/utils";
import SecondaryButton from "../../../../styled/SecondaryButton";
import SupervisionAccordion from "../../containers/SupervisionAccordion";
import AnimalAccordion from "../../containers/AnimalAccordion";
import InterventionAccordion from "../../containers/InterventionAccordion";
import VaccinationService from "../../../../../services/VaccinationService";
import { HttpStatus } from "../../../../../interfaces/global";
import { IVaccinationInterventionDetails, VaccinationInterventionType } from "../../interface";

interface RecapDialogProps {
    onClose: () => void;
    intervention: IVaccinationInterventionDetails;
}

export default function ViewDialog({ onClose, intervention }: RecapDialogProps) {
    const [vaccineBatches, setVaccineBatches] = useState<{ uuid: string; number: string }[]>([]);

    useEffect(() => {
        getVaccineBatches();
    }, []);

    const getVaccineBatches = async () => {
        const payload = {
            page: 0,
            size: 50,
            uuid: intervention.animalInformation.batchUuids,
        };
        const res = await VaccinationService.getVaccineBatches(intervention.animalInformation.gtinCode, payload);
        if (res.status === HttpStatus.OK) {
            setVaccineBatches(res.data);
        }
    };

    const renderContent = () => (
        <Stack spacing={2} width="100%">
            <Typography variant="h5" sx={{ mb: 2 }}>
                Intervention de vaccination{" "}
                {intervention.type ===
                getEnumKeyByValue(VaccinationInterventionType, VaccinationInterventionType.HATCHERY)
                    ? "au couvoir"
                    : "en élevage"}
            </Typography>
            <InterventionAccordion
                generalInformation={intervention.generalInformation}
                farmName={intervention.generalInformation.farmName}
                farmId={intervention.generalInformation.farmId}
                dpeName={intervention.generalInformation.dpeName}
                veterinaryFirstname={intervention.generalInformation.veterinaryFirstname}
                veterinaryLastname={intervention.generalInformation.veterinaryLastname}
            />
            <AnimalAccordion
                animalInformation={intervention.animalInformation}
                selectedVaccineBatches={vaccineBatches.filter((vb) =>
                    intervention.animalInformation.batchUuids.includes(vb.uuid)
                )}
                vaccinationDate={intervention.generalInformation.vaccinationDate}
            />
            <SupervisionAccordion
                supervisionInformation={intervention.supervisionInformation}
                hasAudit={intervention.hasAudit}
            />
        </Stack>
    );

    const displayActionButton = () => (
        <SecondaryButton onClick={onClose} variant="outlined">
            Fermer
        </SecondaryButton>
    );

    return (
        <GenericDialog
            maxWidth="xl"
            title="Récapitulatif de l'intervention de vaccination"
            onClose={onClose}
            renderContent={() => renderContent()}
            renderActions={displayActionButton}
        />
    );
}
