// === NPM
import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Box, Tabs, Typography } from "@mui/material";
import { HttpStatus } from "../../../interfaces/global";
import { IReferential } from "../../../interfaces/referential";
import { UserType } from "../../../interfaces/user";
import { IVaccine } from "../../../interfaces/vaccination";
import { Preference, UserSituation } from "../../../resources/PermissionConstant";
import PermissionsTab from "../../../resources/PermissionsTab";
// === LOCAL
import { routerLinks } from "../../../routers/RouterConstant";
import { useAuth } from "../../../routers/useAuth";
import VaccinationService from "../../../services/VaccinationService";
import PageContent from "../../generics/layout/PageContent";

export default function Vaccination() {
    const auth = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [vaccines, setVaccines] = useState<IVaccine[]>([]);
    const [scrappingReasons, setScrappingReasons] = useState<IReferential[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        Promise.all([getVaccines(), getScrappingReasons()]).then(() => {
            setLoading(false);
        });
    };

    const getVaccines = async () => {
        const res = await VaccinationService.getVaccines();
        if (res.status === HttpStatus.OK) {
            setVaccines(res.data);
        }
    };

    const getScrappingReasons = async () => {
        const res = await VaccinationService.getScrappingReasons();
        if (res.status === HttpStatus.OK) {
            setScrappingReasons(res.data);
        }
    };

    return (
        <>
            <Tabs value={location.pathname} sx={{ ml: 12 }}>
                <PermissionsTab
                    value={routerLinks.vaccinationCampaign.vaccine.view()}
                    onClick={() => navigate(routerLinks.vaccinationCampaign.vaccine.view())}
                    label="Mes commandes de vaccins"
                />
                {auth.userInfo.situation === UserSituation.REGISTERED_IN_PRACTICE && (
                    <PermissionsTab
                        value={routerLinks.vaccinationCampaign.vaccine.order()}
                        onClick={() => navigate(routerLinks.vaccinationCampaign.vaccine.order())}
                        label="Saisir une commande"
                        permissions={[UserSituation.REGISTERED_IN_PRACTICE]}
                    />
                )}
                <PermissionsTab
                    value={routerLinks.vaccinationCampaign.vaccine.scrappingView()}
                    onClick={() => navigate(routerLinks.vaccinationCampaign.vaccine.scrappingView())}
                    label="Mes mises au rebut"
                />
                <PermissionsTab
                    value={routerLinks.vaccinationCampaign.vaccine.scrappingDeclare()}
                    onClick={() => navigate(routerLinks.vaccinationCampaign.vaccine.scrappingDeclare())}
                    label="Saisir une mise au rebut"
                    permissions={[UserSituation.REGISTERED_IN_PRACTICE]}
                />
            </Tabs>
            <PageContent>
                {auth.userInfo.type !== UserType.VETERINARY ||
                (auth.userInfo.properties.dpes.some((dpe) => dpe.sanitaryDpe) &&
                    auth.userInfo.preferences.iahp === true) ||
                auth.userInfo.preferences.blueTongueSheep === true ||
                auth.userInfo.preferences.blueTongueCattle === true ? (
                    <Outlet context={{ vaccines, scrappingReasons, loading }} />
                ) : (
                    <Box display="flex" alignItems="center" justifyContent="center" width="100%">
                        <Typography variant="h4">
                            Vous ne pouvez pas effectuer de saisie car vous ne possédez pas de DPE ayant un lien
                            vétérinaire sanitaire.
                        </Typography>
                    </Box>
                )}
            </PageContent>
        </>
    );
}
