import { styled, TooltipProps, tooltipClasses, Tooltip } from "@mui/material";
import React from "react";

export const InfoTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 500,
    },
});
