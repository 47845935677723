// === NPM
import React, { useEffect } from "react";
import { Box, Divider, Grid, Stack, Typography } from "@mui/material";

// === LOCAL
import { colors } from "../../resources/CssConstant";
import { ReactComponent as Info } from "../../assets/icons/navbar/information.svg";
import DownloadCard from "./containers/DownloadCard";
import PageContent from "../generics/layout/PageContent";
import { useDownload } from "./useDownload";

export default function Downloads() {
    const { downloads, getAvailableDownloads } = useDownload();

    useEffect(() => {
        getAvailableDownloads();
    }, []);

    const renderWarning = () => (
        <Stack spacing={1} direction="row" alignItems="center" py={1}>
            <Box display="flex" alignItems="center" sx={{ color: colors.primaryColor }}>
                <Info />
            </Box>
            <Typography variant="bold" color={colors.primaryColor}>
                Vos fichiers sont disponibles pendant 3 jours avant d'être supprimés.
            </Typography>
        </Stack>
    );

    const renderDownloadContent = () => {
        return downloads.length > 0 ? (
            <Grid container spacing={2}>
                {downloads.map((download) => (
                    <Grid item xs={12} md={6} lg={4} key={download.uuid}>
                        <DownloadCard download={download} />
                    </Grid>
                ))}
            </Grid>
        ) : (
            <Typography sx={{ display: "flex", justifyContent: "center" }} id="download-no-content">
                Aucun téléchargement trouvé.
            </Typography>
        );
    };

    return (
        <PageContent>
            <Stack width="100%" spacing={3}>
                <Typography variant="h4">Mes téléchargements</Typography>
                <Box>
                    {renderWarning()}
                    <Box py={2}>
                        <Divider />
                    </Box>
                    {renderDownloadContent()}
                </Box>
            </Stack>
        </PageContent>
    );
}
