// === NPM
import React from "react";
import { Typography, Stack, Card } from "@mui/material";
// === LOCAL
import { IFarm, IWorkshopShort } from "../../../../../../interfaces/farm";
import { StyledCardContent } from "../../../../../styled/StyledCardContent";
import GenericActionsDialog from "../../../../../generics/dialogs/GenericActionsDialog";
import GenericDialog from "../../../../../generics/dialogs/GenericDialog";
import { ReactComponent as Home } from "../../../../../../assets/icons/shared/home.svg";
import { ReactComponent as Paper } from "../../../../../../assets/icons/shared/paper.svg";
import { ReactComponent as Pet } from "../../../../../../assets/icons/shared/pet.svg";
import { ReactComponent as Workshop } from "../../../../../../assets/icons/shared/workshop.svg";
import { GenericIconText } from "../../../../../generics/text/GenericIconText";
import { ISpeciesDetails } from "../../../../../../interfaces/referential";

interface ConfirmDialogProps {
    onClose: () => void;
    onConfirm: () => void;
    farm: IFarm;
    dpe: { id: string; name: string };
    fileName?: string;
    workshops: IWorkshopShort[];
    species: ISpeciesDetails;
}

export default function ConfirmDialog({
    onClose,
    onConfirm,
    farm,
    dpe,
    fileName,
    workshops,
    species,
}: ConfirmDialogProps) {
    const renderContent = () => (
        <Card>
            <StyledCardContent>
                <Stack spacing={4} p={2}>
                    <Stack spacing={1}>
                        <Typography variant="bold">Catégorie d'espèces</Typography>
                        <GenericIconText icon={<Pet />} text={species.label} iconTooltip="Catégorie d'espèce" />
                    </Stack>
                    <Stack spacing={1}>
                        <Typography variant="bold">Élevage</Typography>
                        <GenericIconText icon={<Home />} text={farm?.name} iconTooltip="Nom de l'élevage" />
                        <GenericIconText
                            icon={<Workshop />}
                            text={`Atelier${workshops.length > 1 ? "s" : ""} ${workshops.map((w) => w.id).join(", ")}`}
                            iconTooltip="Atelier"
                        />
                    </Stack>
                    <Stack spacing={1}>
                        <Typography variant="bold">Affectation à un DPE</Typography>
                        <GenericIconText
                            icon={<Home />}
                            text={`${dpe.id} - ${dpe.name}`}
                            iconTooltip="Informations du DPE"
                        />
                        <GenericIconText
                            icon={<Paper />}
                            text={
                                fileName
                                    ? `Je dispose du CERFA permettant de confirmer la validité de cette déclaration : ${fileName}`
                                    : "Je ne dispose pas du CERFA permettant de confirmer la validité de cette déclaration"
                            }
                            iconTooltip=""
                        />
                    </Stack>
                </Stack>
            </StyledCardContent>
        </Card>
    );

    return (
        <GenericDialog
            title="Confirmer la déclaration de relation"
            onClose={onClose}
            renderContent={() => renderContent()}
            renderActions={() => (
                <GenericActionsDialog validLabel="Valider la relation" onClose={onClose} onSubmit={onConfirm} />
            )}
        />
    );
}
