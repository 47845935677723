// === NPM
import React, { useEffect } from "react";
// === LOCAL
import FormSelection from "./containers/FormSelection";
import { initFormIntervention, useFormIntervention } from "./containers/useFormIntervention";
import Form from "./containers/Form";

export default function FormIntervention() {
    const { form, setForm } = useFormIntervention();

    useEffect(() => {
        return () => {
            setForm(initFormIntervention);
        };
    }, []);

    return !form?.type ? <FormSelection /> : <Form />;
}
