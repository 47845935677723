// === Import: NPM
import { Box } from "@mui/material";
import React from "react";
import { Navigate } from "react-router-dom";
// === Import: LOCAL
import RoleRoute from "../../routers/RoleRoute";
import { routerLinks } from "../../routers/RouterConstant";
import { useAuth } from "../../routers/useAuth";
import PageContent from "../generics/layout/PageContent";
import AnmvProfile from "./containers/AnmvProfile/AnmvProfile";
import PharmacistProfile from "./containers/PharmacistProfile/PharmacistProfile";
import VeterinaryProfile from "./containers/VeterinaryProfile/VeterinaryProfile";
import { UserSituation } from "../../resources/PermissionConstant";

export default function Profile() {
    const auth = useAuth();

    const renderProfile = () => {
        switch (auth.userInfo.situation) {
            case UserSituation.ANMV_MED:
                return <AnmvProfile />;
            case UserSituation.PUBLIC_SECTOR_VETERINARIAN:
            case UserSituation.THIRD_COUNTRIES_VETERINARY_REGISTERED_DIPLOMA:
            case UserSituation.REGISTERED_IN_PRACTICE:
                return (
                    <RoleRoute
                        redirection={routerLinks.home}
                        permissions={[
                            UserSituation.PUBLIC_SECTOR_VETERINARIAN,
                            UserSituation.THIRD_COUNTRIES_VETERINARY_REGISTERED_DIPLOMA,
                            UserSituation.REGISTERED_IN_PRACTICE,
                        ]}
                    >
                        <VeterinaryProfile />
                    </RoleRoute>
                );
            case UserSituation.PHARMACIST:
                return <PharmacistProfile />;
            default:
                return <Navigate to={routerLinks.home} />;
        }
    };

    return (
        <Box p={2}>
            <PageContent>{renderProfile()}</PageContent>
        </Box>
    );
}
