// === NPM
import React from "react";
import { Grid, Box, Stack, Typography } from "@mui/material";
// === LOCAL
import { getEnumKeyByValue } from "../../../../resources/utils";
import GenericAccordion from "../../../generics/layout/GenericAccordion";
import GenericTitleValueText from "../../../generics/text/GenericTitleValueText";
import { ReactComponent as Calendar } from "../../../../assets/icons/shared/calendar.svg";
import { ReactComponent as User } from "../../../../assets/icons/shared/user.svg";
import { IVaccinationInterventionSupervisionInformation, InterventionUserType, ComplianceType } from "../interface";

interface SupervisionAccordionProps {
    supervisionInformation: IVaccinationInterventionSupervisionInformation;
    hasAudit: boolean;
}

export default function SupervisionAccordion({ supervisionInformation, hasAudit }: SupervisionAccordionProps) {
    return (
        <GenericAccordion title="Supervision de la vaccination" defaultExpanded>
            <Grid container rowSpacing={3} columnSpacing={2}>
                <Grid item xs={12} md={4} display="flex" gap={1}>
                    <Box>
                        <Calendar />
                    </Box>
                    <Stack spacing={1}>
                        <Box mt={-0.25}>
                            <Typography variant="bold" fontSize={18}>
                                Compte rendu de l'intervention
                            </Typography>
                        </Box>
                        <GenericTitleValueText
                            title="Intervention réalisée par"
                            value={InterventionUserType[supervisionInformation.userType]}
                        />
                        <GenericTitleValueText
                            title="Résultat du compte-rendu"
                            value={ComplianceType[supervisionInformation.complianceType]}
                        />
                        {supervisionInformation.complianceType !==
                            getEnumKeyByValue(ComplianceType, ComplianceType.COMPLIANT) && (
                            <GenericTitleValueText
                                title="Motif de non-conformité du compte-rendu"
                                value={supervisionInformation.complianceComment}
                            />
                        )}
                    </Stack>
                </Grid>
                <Grid item xs={12} md={3.5} display="flex" gap={1}>
                    <Box>
                        <User />
                    </Box>
                    <Stack spacing={1}>
                        <Box mt={-0.25}>
                            <Typography variant="bold" fontSize={18}>
                                Audit
                            </Typography>
                        </Box>
                        <GenericTitleValueText title="Audit réalisé" value={hasAudit ? "Oui" : "Non"} />
                    </Stack>
                </Grid>
            </Grid>
        </GenericAccordion>
    );
}
