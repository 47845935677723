// === Import: NPM
import React, { Dispatch, SetStateAction, useState } from "react";
import { Box, Button, Chip, Divider, FormControl, FormControlLabel, Radio, RadioGroup, Stack } from "@mui/material";
import { Close } from "@mui/icons-material";
// === Import: LOCAL
import { colors } from "../../../resources/CssConstant";
import GenericButtonFilter from "./components/GenericButtonFilter";
import GenericTextField from "../inputs/GenericTextField";
import { FilterType, ICityFilter, Sort, SortDirection } from "../../../interfaces/global";
import { toLocaleDateFormat } from "../../../resources/utils";
import DateRangeFilter from "./components/DateRangeFilter";
import SelectWithAutocomplete from "./components/SelectWithAutocomplete";
import SelectFilter from "./components/SelectFilter";
import SingleSelectFilter from "./components/SingleSelectFilter";
import CitySearchFilter from "./components/CitySearchFilter";
import YearFilter from "./components/YearFilter";
import IntervalFilter from "./components/IntervalFilter";

interface FilterConfigurationNoSelect {
    label: string;
    type:
        | FilterType.DATEPICKER
        | FilterType.INPUT
        | FilterType.CITY_SEARCH
        | FilterType.YEARPICKER
        | FilterType.INTERVAL;
    values?: never;
    objectProperties?: ObjectProperties;
}

interface FilterConfigurationSelect {
    label: string;
    type: FilterType.SELECT | FilterType.SINGLE_SELECT | FilterType.SELECT_AUTOCOMPLETE;
    values: { key: string; label: string }[];
    objectProperties?: ObjectProperties;
}

// Use this to define a filter as an element of an object
interface ObjectProperties {
    filterKey: string;
    objectData: object;
    field: string;
}

export interface SortingConfiguration<U> {
    label: string;
    value: SortDirection;
    field: keyof U;
}
interface FiltersProps<T> {
    inputFilters: T;
    initialValues: T;
    filterConfigurations: FilterConfigurations<T>;
    setInputFilters: Dispatch<SetStateAction<T>>;
    sortingConfigurations?: never;
    sort?: never;
    setSort?: never;
}

interface FiltersAndSortingProps<T, U> {
    inputFilters: T;
    initialValues: T;
    filterConfigurations: FilterConfigurations<T>;
    setInputFilters: Dispatch<SetStateAction<T>>;
    // Sorting part is optional but if it used, all sorting props need to be defined
    sortingConfigurations: SortingConfiguration<U>[];
    sort: Sort;
    setSort: Dispatch<SetStateAction<Sort>>;
}

// Defining type that will be used for inputFilters, and precise type for city if it used
type InputFilter<T extends {}> = T & {
    // city should only be used with FiltersConfiguration type : CITY_SEARCH
    city: ICityFilter[];
};

// Create a type that omit City if it's not defined, but forced a specific format if city exist
export type GenericFiltersProps<T extends {}, U extends {}> =
    | FiltersProps<T | InputFilter<T>>
    | FiltersAndSortingProps<T | InputFilter<T>, U>;

export type AnchorEl<T extends {}> = Partial<Record<keyof T, HTMLButtonElement | null>>;

export type FilterConfiguration = FilterConfigurationNoSelect | FilterConfigurationSelect;

export type FilterConfigurations<T extends {}> =
    | Record<keyof T, FilterConfiguration>
    | Record<keyof InputFilter<T>, FilterConfiguration>;

export const GenericFilters = <T extends {}, U extends {}>({
    filterConfigurations,
    inputFilters,
    initialValues,
    setInputFilters,
    sortingConfigurations,
    sort,
    setSort,
}: GenericFiltersProps<T | InputFilter<T>, U>) => {
    const [anchorEls, setAnchorEls] = useState<AnchorEl<T>>({});
    const [anchorElSorting, setAnchorSorting] = useState<HTMLButtonElement | null>(null);

    const setAnchor = (key: string) => (el: HTMLButtonElement | null) => {
        setAnchorEls({ ...anchorEls, [key]: el });
    };

    const handleDeleteFilter = (deletedFilter: { name: string; value: string; position?: number }) => {
        let currFilter = inputFilters[deletedFilter.name];
        const currConfig = filterConfigurations[deletedFilter.name];
        if (Array.isArray(currFilter)) {
            if (currConfig.type === FilterType.DATEPICKER) {
                currFilter.splice(deletedFilter.position, 1, null);
            } else {
                currFilter = currFilter.filter((f, i) => i !== deletedFilter.position);
            }
        } else {
            currFilter = "";
        }
        setInputFilters((prevState) => ({
            ...prevState,
            [deletedFilter.name]: currFilter,
        }));
    };

    const updateInputFilter = (event, name) => {
        setInputFilters((prevState) => ({
            ...prevState,
            [name]: event.target.value,
        }));
    };

    const handleSelectFilter = (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name } = event.target;
        const currFilter = inputFilters[key];
        currFilter.indexOf(name) === -1 ? currFilter.push(name) : currFilter.splice(currFilter.indexOf(name), 1);
        setInputFilters((prevState) => ({
            ...prevState,
            [key]: [...currFilter],
        }));
    };

    const handleSingleSelectFilter = (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputFilters((prevState) => ({
            ...prevState,
            [key]: event.target.value,
        }));
    };

    const handleSortChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSort({ direction: event.target.value, field: event.target.name });
    };

    const handleCitySearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        const currFilter = inputFilters["city"];
        if (currFilter.findIndex((c) => c === value) === -1) currFilter.push(value);
        setInputFilters((prevState) => ({
            ...prevState,
            city: currFilter,
        }));
    };

    const renderFilters = () => {
        return Object.entries(filterConfigurations).map(([name, conf]) => {
            const nullableElement = [FilterType.DATEPICKER, FilterType.INTERVAL];
            return (
                <GenericButtonFilter
                    key={name}
                    active={
                        (nullableElement.includes(conf.type) && inputFilters[name].some((f) => !!f)) ||
                        (!nullableElement.includes(conf.type) && inputFilters[name].length > 0)
                    }
                    buttonLabel={conf.label}
                    open={Boolean(anchorEls[name])}
                    anchor={anchorEls[name]}
                    setAnchor={setAnchor(name)}
                >
                    {renderFilterInput(name, conf)}
                </GenericButtonFilter>
            );
        });
    };

    const renderFilterInput = (name: string, config: FilterConfiguration) => {
        switch (config.type) {
            case FilterType.INPUT:
                return (
                    <Box p={2}>
                        <GenericTextField
                            value={inputFilters[name]}
                            onChange={(event) => updateInputFilter(event, name)}
                            label={config.label}
                            size="small"
                            variant="standard"
                            autoFocus
                        />
                    </Box>
                );
            case FilterType.SELECT:
                return (
                    <SelectFilter
                        config={config}
                        value={inputFilters[name]}
                        name={name}
                        handleSelectFilter={handleSelectFilter}
                    />
                );
            case FilterType.SELECT_AUTOCOMPLETE:
                return (
                    <SelectWithAutocomplete
                        config={config}
                        value={inputFilters[name]}
                        name={name}
                        handleSelectFilter={handleSelectFilter}
                    />
                );
            case FilterType.SINGLE_SELECT:
                return (
                    <SingleSelectFilter
                        config={config}
                        value={inputFilters[name]}
                        name={name}
                        handleSingleSelectFilter={handleSingleSelectFilter}
                    />
                );
            case FilterType.DATEPICKER:
                return (
                    <Box sx={{ p: 2 }}>
                        <DateRangeFilter value={inputFilters[name]} onChange={updateInputFilter} name={name} />
                    </Box>
                );
            case FilterType.CITY_SEARCH:
                return (
                    <Box sx={{ p: 2, width: 300 }}>
                        <CitySearchFilter handleChange={handleCitySearchChange} />
                    </Box>
                );
            case FilterType.YEARPICKER:
                return (
                    <Box sx={{ p: 2 }}>
                        <YearFilter value={inputFilters[name]} name={name} onChange={updateInputFilter} />
                    </Box>
                );
            case FilterType.INTERVAL:
                return (
                    <Box sx={{ p: 2 }}>
                        <IntervalFilter value={inputFilters[name]} name={name} onChange={updateInputFilter} />
                    </Box>
                );
        }
    };

    const renderSorting = () => {
        return (
            <Box display="flex" flexGrow={1} justifyContent="flex-end">
                <GenericButtonFilter
                    active
                    buttonLabel="Trier par"
                    open={Boolean(anchorElSorting)}
                    anchor={anchorElSorting}
                    setAnchor={setAnchorSorting}
                >
                    <FormControl>
                        <RadioGroup value={sort} onChange={handleSortChange}>
                            {sortingConfigurations.map((config, key) => (
                                <React.Fragment key={key}>
                                    <FormControlLabel
                                        sx={{ p: 1 }}
                                        value={config.value}
                                        name={config.field as string}
                                        control={<Radio />}
                                        label={config.label}
                                        checked={sort.direction === config.value && sort.field == config.field}
                                    />
                                    <Divider />
                                </React.Fragment>
                            ))}
                        </RadioGroup>
                    </FormControl>
                </GenericButtonFilter>
            </Box>
        );
    };

    const displayChipValue = (filter: { name: string; value: string; position?: number }) => {
        const config = filterConfigurations[filter.name];
        let value = filter.value;
        switch (config.type) {
            case FilterType.INPUT:
                value = filter.value;
                break;
            case FilterType.SELECT_AUTOCOMPLETE:
            case FilterType.SINGLE_SELECT:
            case FilterType.SELECT:
                value = config.values?.find((su) => su.key === filter.value)?.label;
                break;
            case FilterType.DATEPICKER:
                if (filter.position === 1) {
                    value = "Jusqu'au ";
                } else {
                    value = "A partir du ";
                }
                value += toLocaleDateFormat(filter.value);
                break;
        }
        return value;
    };

    const renderChipFilter = () => {
        const chipList = [];

        Object.entries(inputFilters).forEach(([name, value]) => {
            if (Array.isArray(value)) {
                value.forEach((val, index) => {
                    if (!val) return;
                    if (filterConfigurations[name].type === FilterType.CITY_SEARCH) {
                        chipList.push({ name: name, value: `${val.postalCode} - ${val.name}`, position: index });
                        return;
                    }
                    chipList.push({ name: name, value: val, position: index });
                });
            } else if (value) {
                chipList.push({ name, value });
            }
        });

        return (
            chipList.length > 0 && (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5, mt: 2 }}>
                    {chipList.map((f, i) => {
                        // retrieve config & got value from it
                        return (
                            <Chip
                                key={i}
                                label={`${filterConfigurations[f.name].label} : ${displayChipValue(f)}`}
                                onDelete={() => handleDeleteFilter(f)}
                                sx={{
                                    "& .MuiChip-deleteIcon": {
                                        color: colors.black,
                                    },
                                }}
                                deleteIcon={<Close />}
                            />
                        );
                    })}
                    <Button
                        onClick={() => {
                            setInputFilters({ ...initialValues });
                        }}
                        sx={{ textTransform: "none", ml: "auto", mb: 1 }}
                        color="secondary"
                    >
                        Effacer les filtres
                    </Button>
                </Box>
            )
        );
    };

    return (
        <>
            <Stack direction="row" sx={{ mb: 2, flexWrap: "wrap" }}>
                {renderFilters()}
                {sortingConfigurations?.length > 0 && renderSorting()}
            </Stack>
            {renderChipFilter()}
            <Divider />
        </>
    );
};
