import { AxiosResponse } from "axios";
import { HttpMethod } from "../interfaces/global";
import { IUserInfo } from "../interfaces/user";
import { endpoints } from "./EndpointConstant";
import HttpService from "./HttpService";

class AuthenticationService {
    async login(data: { code: string }): Promise<AxiosResponse<IUserInfo>> {
        return HttpService.fetch({
            url: endpoints.login,
            method: HttpMethod.POST,
            data,
        });
    }

    async loginPsc(data: { code: string }): Promise<AxiosResponse<IUserInfo>> {
        return HttpService.fetch({
            url: endpoints.loginPsc,
            method: HttpMethod.POST,
            data,
        });
    }

    async introspection(): Promise<AxiosResponse<IUserInfo>> {
        return HttpService.fetch({
            url: endpoints.introspection,
            method: HttpMethod.GET,
        });
    }

    async refreshUserInfo(): Promise<AxiosResponse<IUserInfo>> {
        return HttpService.fetch({
            url: endpoints.userInfo,
            method: HttpMethod.GET,
        });
    }

    async refresh(): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.refresh,
            method: HttpMethod.POST,
        });
    }
}

export default new AuthenticationService();
