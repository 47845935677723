import { AxiosResponse } from "axios";
import { ArrayOfTwo, HttpMethod, IExportFilename, Loader, SearchParams } from "../interfaces/global";

import { formatParams } from "../resources/utils";
import { endpoints } from "./EndpointConstant";
import HttpService from "./HttpService";
import { IReferential } from "../interfaces/referential";
import { customClientHeader, CALYPSO_HEADERS } from "../resources/AppConstant";
import {
    IImport,
    INonDelivery,
    IDrugDeliveryReporting,
    IDrugPrescriptionReporting,
    IFileContent,
} from "../components/AntimicrobialDisposal/Dashboard/interface";
import {
    IManualDelivery,
    IManualDeliveryDraft,
    IManualPrescription,
    IManualPrescriptionDetails,
} from "../components/AntimicrobialDisposal/ManualDelivery/interface";

class DrugService {
    async getImports(
        payload: SearchParams &
            Partial<{
                date: string[];
                importStatus: string[];
                typeCode: string;
                importType: string[];
                senderId: string;
                softwareName: string;
            }>
    ): Promise<AxiosResponse<IImport[]>> {
        return HttpService.fetch({
            url: endpoints.drugService.imports.base,
            method: HttpMethod.GET,
            params: formatParams(payload),
            loader: Loader.TABLE,
        });
    }

    async postImport(file: File): Promise<AxiosResponse> {
        const formData = new FormData();
        formData.append("file", file);

        return HttpService.fetch({
            url: endpoints.drugService.imports.base,
            method: HttpMethod.POST,
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: formData,
            loader: Loader.REQUEST,
        });
    }

    async getTemplate(): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.drugService.imports.template(),
            method: HttpMethod.GET,
            responseType: "blob",
        });
    }

    async getFile(importUuid: string, fileUuid: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.drugService.imports.importFile(importUuid, fileUuid),
            method: HttpMethod.GET,
            responseType: "blob",
            loader: Loader.BUTTON,
        });
    }

    async getSoftwares(): Promise<AxiosResponse<string[]>> {
        return HttpService.fetch({
            url: endpoints.drugService.imports.softwares(),
            method: HttpMethod.GET,
        });
    }

    async postNonDeliveries(data: INonDelivery): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.drugService.nonDeliveries(),
            method: HttpMethod.POST,
            data,
            loader: Loader.REQUEST,
        });
    }

    async getNonDeliveriesAvailable(payload: {
        year?: string;
        localAgencies?: string[];
    }): Promise<AxiosResponse<IReferential[]>> {
        return HttpService.fetch({
            url: endpoints.drugService.nonDeliveries(),
            method: HttpMethod.GET,
            params: formatParams(payload),
        });
    }

    async getDrugDeliveryReporting(
        payload: SearchParams &
            Partial<{
                dpeOrdinalNumber: string;
                prescriptionVeterinaryId: string;
                prescriptionId: string;
                deliveryDate: ArrayOfTwo[];
                transferorId: string;
                transferorName: string;
                transferorType: string[];
                speciesUuid: string[];
                subSpeciesUuid: string[];
                excluded: string[];
            }>
    ): Promise<AxiosResponse<IDrugDeliveryReporting[]>> {
        return HttpService.fetch({
            url: endpoints.drugService.reporting.drugDeliveries(),
            method: HttpMethod.GET,
            params: formatParams(payload),
            loader: Loader.TABLE,
        });
    }

    async excludeDelivery(uuid: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.drugService.reporting.excludeDelivery(uuid),
            method: HttpMethod.PATCH,
            loader: Loader.REQUEST,
        });
    }

    async reinstateDelivery(uuid: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.drugService.reporting.reinstateDelivery(uuid),
            method: HttpMethod.PATCH,
            loader: Loader.REQUEST,
        });
    }

    async getDrugPrescriptionReporting(payload: SearchParams): Promise<AxiosResponse<IDrugPrescriptionReporting[]>> {
        return HttpService.fetch({
            url: endpoints.drugService.reporting.drugPrescriptions(),
            method: HttpMethod.GET,
            params: formatParams(payload),
            loader: Loader.TABLE,
        });
    }

    // MANUAL DELIVERY

    async getDraft(userId: string, noToaster = false): Promise<AxiosResponse<IManualDeliveryDraft>> {
        const clientHeader = { ...customClientHeader };
        clientHeader.noToasterOnError = noToaster;
        return HttpService.fetch({
            url: endpoints.drugService.drafts.draft(userId),
            method: HttpMethod.GET,
            headers: {
                [CALYPSO_HEADERS.CLIENT_HEADER]: JSON.stringify(clientHeader),
            },
        });
    }
    async deleteDraft(userId: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.drugService.drafts.draft(userId),
            method: HttpMethod.DELETE,
        });
    }

    async putDraft(userId: string, data: IManualDelivery): Promise<AxiosResponse<IManualDelivery>> {
        return HttpService.fetch({
            url: endpoints.drugService.drafts.draft(userId),
            method: HttpMethod.PUT,
            data,
        });
    }

    async getPrescription(
        prescriptionId: string,
        dpeId: string,
        veterinaryId: string
    ): Promise<AxiosResponse<Omit<IManualDelivery, "deliveries">>> {
        return HttpService.fetch({
            url: endpoints.drugService.prescriptions.base,
            method: HttpMethod.GET,
            params: formatParams({ prescriptionId, dpeId, veterinaryId }),
            validateStatus: false,
            loader: Loader.BUTTON,
        });
    }

    async getDeliveries(
        prescriptionId: string,
        dpeId: string,
        veterinaryId: string
    ): Promise<AxiosResponse<Omit<IManualDelivery, "prescription">>> {
        return HttpService.fetch({
            url: endpoints.drugService.prescriptions.deliveries(),
            method: HttpMethod.GET,
            params: formatParams({ prescriptionId, dpeId, veterinaryId }),
            validateStatus: false,
            loader: Loader.BUTTON,
        });
    }

    async postDeliveries(data: IManualDelivery): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.drugService.imports.deliveries(),
            method: HttpMethod.POST,
            data,
            loader: Loader.REQUEST,
        });
    }

    async getManualPrescriptions(
        payload: SearchParams &
            Partial<{
                senderId: string;
                date: ArrayOfTwo;
                prescriptionId: string;
                prescriptionVeterinaryId: string;
                speciesUuid: string[];
                subSpeciesUuid: string[];
                farmId: string;
                farmName: string;
            }>
    ): Promise<AxiosResponse<IManualPrescription[]>> {
        return HttpService.fetch({
            url: endpoints.drugService.manualPrescriptions,
            method: HttpMethod.GET,
            params: formatParams(payload),
            loader: Loader.TABLE,
        });
    }

    async getDrugsByPrescription(prescriptionUuid: string): Promise<AxiosResponse<IManualPrescriptionDetails>> {
        return HttpService.fetch({
            url: endpoints.drugService.prescriptions.drugs(prescriptionUuid),
            method: HttpMethod.GET,
            loader: Loader.BUTTON,
        });
    }

    async getFileContent(importUuid: string, fileUuid: string): Promise<AxiosResponse<IFileContent>> {
        return HttpService.fetch({
            url: endpoints.drugService.imports.fileContent(importUuid, fileUuid),
            method: HttpMethod.GET,
            loader: Loader.BUTTON,
        });
    }

    async exportImportReports(data: IExportFilename): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.drugService.exports.importReports(),
            method: HttpMethod.POST,
            data,
            loader: Loader.REQUEST,
        });
    }
}

export default new DrugService();
