// === Import: NPM
import React from "react";
import { Card, CardContent, CardHeader } from "@mui/material";
// === Import: LOCAL
import { GenericText } from "../../../../generics/text/GenericText";
import { IOVDpe, ITreatedSpecies } from "../../../interface";

interface TreatedSpeciesCardProps {
    dpes: IOVDpe[];
    treatedSpecies: ITreatedSpecies;
}
export default function TreatedSpeciesCard({ dpes, treatedSpecies }: TreatedSpeciesCardProps) {
    const dpeData: IOVDpe[] = treatedSpecies.dpes?.reduce((filtered, dpe) => {
        const dpeFound = dpes?.find((d) => d.ordinalNumber === dpe.ordinalNumber);
        dpeFound && filtered.push(dpeFound);
        return filtered;
    }, []);
    return (
        <Card variant="outlined" sx={{ height: "100%" }}>
            <CardHeader
                sx={{ m: 2 }}
                titleTypographyProps={{ fontSize: 18, fontWeight: 500 }}
                title={`${treatedSpecies.ranking} - ${treatedSpecies.name}`}
            />
            <CardContent>
                <ul>
                    {dpeData.map((dpe, i) => (
                        <GenericText
                            component="li"
                            key={i}
                            value={`${dpe.name} - ${dpe.address && dpe.address.city}`}
                        />
                    ))}
                </ul>
            </CardContent>
        </Card>
    );
}
