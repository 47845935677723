// === NPM
import React, { useState, useContext, Dispatch, SetStateAction, useEffect } from "react";
import { useLocation } from "react-router-dom";
// === LOCAL
import { IHealthReportingForm } from "../../../interface";
import { routerLinks } from "../../../../../../routers/RouterConstant";

export interface UseFormHealthReporting {
    form: IHealthReportingForm;
    setForm: Dispatch<SetStateAction<IHealthReportingForm>>;
    isFilling: boolean;
    setIsFilling: Dispatch<SetStateAction<boolean>>;
    resetData: () => void;
    activeStep: number;
    setActiveStep: Dispatch<SetStateAction<number>>;
}

export const formHealthReportingContext = React.createContext<any>({});

export const useFormHealthReporting = (): UseFormHealthReporting => {
    return useContext(formHealthReportingContext);
};

export function FormHealthReportingProvider({ children }: any) {
    const auth = useProvideFormHealthReporting();
    return <formHealthReportingContext.Provider value={auth}>{children}</formHealthReportingContext.Provider>;
}

function useProvideFormHealthReporting() {
    const location = useLocation();
    const [form, setForm] = useState<IHealthReportingForm>(null);
    const [isFilling, setIsFilling] = useState<boolean>(false);
    const [activeStep, setActiveStep] = useState<number>(0);

    useEffect(() => {
        if (location.pathname !== routerLinks.healthReporting.animalAbuse.form()) resetData();
    }, [location.pathname]);

    const resetData = () => {
        setForm(null);
        setIsFilling(false);
        setActiveStep(0);
    };

    return {
        form,
        setForm,
        isFilling,
        setIsFilling,
        resetData,
        activeStep,
        setActiveStep,
    };
}
