// === NPM
import React, { useEffect, useState } from "react";
import { Stack, Tabs } from "@mui/material";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
// === LOCAL
import PageContent from "../../../generics/layout/PageContent";
import { routerLinks } from "../../../../routers/RouterConstant";
import { ICategory, ITag, UserTypeHeader, IQuestionShort } from "../../../../interfaces/faq";
import { HttpStatus } from "../../../../interfaces/global";
import FaqService from "../../../../services/FaqService";
import PermissionsTab from "../../../../resources/PermissionsTab";

export function Faq() {
    const location = useLocation();
    const navigate = useNavigate();
    const [tags, setTags] = useState<ITag[]>([]);
    const [categories, setCategories] = useState<ICategory[]>([]);
    const [questions, setQuestions] = useState<IQuestionShort[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        Promise.all([getTags(), getCategories(), getQuestions()]).then(() => {
            setLoading(false);
        });
    };

    const getTags = async () => {
        const res = await FaqService.getTags();
        if (res.status === HttpStatus.OK) setTags(res.data);
    };

    const getCategories = async () => {
        const res = await FaqService.getCategories(UserTypeHeader.ADMIN);
        if (res.status === HttpStatus.OK) setCategories(res.data);
    };

    const getQuestions = async () => {
        const res = await FaqService.getQuestions(UserTypeHeader.ADMIN);
        if (res.status === HttpStatus.OK) setQuestions(res.data);
    };

    const isTouchDevice = () => {
        if ("ontouchstart" in window) {
            return true;
        }
        return false;
    };

    return (
        <Stack height="100%" width="100%" px={2}>
            <Tabs value={location.pathname} sx={{ ml: 12 }}>
                <PermissionsTab
                    value={routerLinks.admin.faq.categoryAndTag()}
                    onClick={() => navigate(routerLinks.admin.faq.categoryAndTag())}
                    label="Catégories et tags"
                />
                <PermissionsTab
                    value={routerLinks.admin.faq.questions()}
                    onClick={() => navigate(routerLinks.admin.faq.questions())}
                    label="Gestion des questions"
                />
            </Tabs>
            <PageContent>
                <DndProvider backend={isTouchDevice() ? TouchBackend : HTML5Backend}>
                    <Outlet
                        context={{
                            tags,
                            getTags,
                            categories,
                            setCategories,
                            getCategories,
                            loading,
                            questions,
                            getQuestions,
                            setQuestions,
                        }}
                    />
                </DndProvider>
            </PageContent>
        </Stack>
    );
}
